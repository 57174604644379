import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Button from "../component/Button";
import { LangSelect } from "../component/LangSelect";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import i18n from "../../locales";
import ContactUsModal from "../../common/component/ContactUsModal";
import ReactModal from "react-modal";
import { modalStyles } from "../../pages/home/Home.style";

const HeaderWrap = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  width: 100vw;
  min-width: 1200px;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: all 0.3s ease;
  &:has(> .banner) {
    .header-container {
      background-color: transparent;
    }
    height: 120px;

    &[data-scroll="false"] {
      background-color: transparent;
    }

    @media (max-width: 1199px) {
      .hamburger-menu {
        &[data-open="true"] {
          transform: translateY(120px);
        }
      }
    }
  }
  &[data-scroll="true"] {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  }
  > div {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    background-color: white;
    position: relative;
    z-index: 1201;
    a {
      display: flex;
      cursor: pointer;
      svg {
        path {
          transition: all 0.3s ease;
        }
      }
    }
    aside {
      display: flex;
      align-items: center;
      gap: 0 16px;
      .menu {
        display: flex;
        align-items: center;
        gap: 0 16px;
        padding-right: 16px;
      }
    }
  }
  .hamburger {
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    padding: 0;
    display: flex;
    cursor: pointer;
  }
  .hamburger-menu {
    width: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    padding: 32px 20px 24px;
    z-index: 1200;
    transform: translateY(-100%);
    transition: all 0.3s ease;
    button {
      border: 0;
      border-radius: 4px;
      background-color: transparent;
      padding: 0;
      font-size: 14px;
      display: flex;
      cursor: pointer;
    }
    .menu {
      display: flex;
      flex-direction: column;
      gap: 32px 0;
      margin-bottom: 40px;
      button {
        color: #505967;
        font-weight: 500;
        &.active {
          color: #568dfd;
          font-weight: 600;
        }
      }
    }
    .lang {
      display: flex;
      align-items: center;
      gap: 0 16px;
      button {
        color: #b1b8c0;
        &.active {
          color: #505967;
          font-weight: 500;
        }
      }
      span {
        color: #d2d6da;
      }
    }
    &[data-open="true"] {
      box-shadow: 0px 4px 40px rgba(205, 205, 205, 0.25);
      transform: translateY(80px);
    }
  }

  .banner {
    display: flex;
    justify-content: center;

    max-width: none;
    max-height: 3.125rem;
    background-color: #fff;
    padding: 0.5rem 1rem;
    position: relative;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

    > div:first-child {
      flex: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      font-size: 0.9125rem;
      font-weight: 600;

      margin-left: 1rem;

      > strong {
        font-size: 1rem;
      }

      > button {
        cursor: pointer;
        outline: none;
        border: none;

        background-color: #568dfd;
        color: #ffffff;
        border-radius: 6px;

        font-size: 0.8125rem;
        font-weight: 700;
        transition: all 0.3s ease;

        margin-left: 1rem;
        height: 1.8125rem;

        &:hover {
          background-color: #417fff;
        }
      }
    }

    > div:last-child {
      button {
        cursor: pointer;
        outline: none;
        border: none;

        background-color: transparent;
        font-size: 1.25rem;

        transition: opacity 0.3s ease;

        &:hover {
          opacity: 0.65;
        }
      }
    }

    @media (max-width: 1199px) {
      padding: 0.5rem;
    }

    @media (max-width: 599px) {
      > div:first-child {
        font-size: 0.625rem;

        > strong {
          font-size: 0.75rem;
        }

        > button {
          font-size: 0.625rem;
          height: 1.625rem;
        }
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    min-width: 100vw;
    height: 60px;
    > div {
      padding: 0 30px;
      a {
        svg {
          width: 101px;
          height: 26px;
        }
      }
      aside {
        gap: 0 12px;
      }
    }
    .hamburger-menu {
      &[data-open="true"] {
        transform: translateY(60px);
      }
    }
  }
  @media (max-width: 599px) {
    min-width: 100vw;
    height: 60px;
    > div {
      padding: 0 20px;
      a {
        svg {
          width: 101px;
          height: 26px;
        }
      }
      aside {
        gap: 0 12px;
      }
    }
    .hamburger-menu {
      &[data-open="true"] {
        transform: translateY(60px);
      }
    }
  }
`;

interface HeaderProps {
  language: string;
  onChangeLanguage: (value: string) => void;
}

interface BannerDataProps {
  downloadLink: string;
  lang: string;
  title: string;
  buttonText: string;
}

const Header = (props: HeaderProps) => {
  const { language, onChangeLanguage } = props;
  const [bannerData, setBannerData] = useState<BannerDataProps>({
    downloadLink: "",
    lang: "",
    title: "",
    buttonText: "",
  });
  const { t } = useTranslation("main");
  const location = useLocation();
  const navigate = useNavigate();
  const matchRoot = useMatch("/");
  const matchKr = useMatch("/kr");
  const matchJp = useMatch("/jp");
  const matchJpSub = useMatch("/jp/*");
  const matchKrSub = useMatch("/kr/*");
  const matchCase = useMatch("/kr/case");
  const matchPayment = useMatch("/kr/payment");
  const matchFaq = useMatch("/kr/faq");
  const matchKrContents = useMatch("/kr/contents/*");
  const matchKrWebinarVol2 = useMatch("/kr/contents/webinar/230921");
  const matchJpEbook = useMatch("/jp/contents/ebook/*");
  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  // const isMo = useMediaQuery({ query: "(max-width:599px)" });
  const [scrollActive, setScrollActive] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isOpenContentUsModal, setIsOpenContentUsModal] = useState(false);
  const [isBannerClose, setIsBannerClose] = useState(false);
  const excuteOnlyOnce = useRef(false);
  const getBanner = async () =>
    await fetch(process.env.REACT_APP_BANNER_URL || "")
      .then((res) => res.json())
      .then((res) => setBannerData(res[0]));

  function closeContentUsModal() {
    setIsOpenContentUsModal(false);
  }

  function handleScroll() {
    if (window.scrollY > 50) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!excuteOnlyOnce.current) {
      excuteOnlyOnce.current = true;
      getBanner();
    }
  }, []);

  return (
    <HeaderWrap data-scroll={scrollActive}>
      {(location.pathname === "/" || location.pathname === "/kr") &&
        // || location.pathname === "/jp"
        !scrollActive &&
        !isBannerClose &&
        bannerData.title && (
          <div className="banner">
            <div>
              {bannerData.title}
              <button
                className="gtm-trigger-top-banner"
                onClick={() => window.open(bannerData.downloadLink)}
              >
                {bannerData.buttonText}
              </button>
            </div>
            <div>
              <button onClick={() => setIsBannerClose(true)}>&#x2715;</button>
            </div>
          </div>
        )}
      <div className="header-container">
        <a
          href={language === "ko" ? "/" : language === "ja" ? "/jp" : "/en"}
          style={{ width: "150px" }}
        >
          <svg
            width="330"
            height="81"
            viewBox="0 0 330 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M96.3965 13.6575C92.8036 13.6575 89.876 16.5851 89.876 20.178C89.876 23.771 92.8036 26.6985 96.3965 26.6985C99.9895 26.6985 102.917 23.771 102.917 20.178C102.917 16.5851 99.9895 13.6575 96.3965 13.6575Z"
              fill="#003D99"
            />
            <path
              d="M66.4551 60.5986H76.868L76.9012 39.1075H87.3141V29.3267H66.4883L66.4551 60.5986Z"
              fill="#003D99"
            />
            <path d="M101.619 29.3267H91.2065V60.6319H101.619V29.3267Z" fill="#003D99" />
            <path d="M224.312 5.8396H214.365V60.5988H224.312V5.8396Z" fill="#003D99" />
            <path
              d="M123.111 29.3267H105.545L105.512 73.0076H115.925L115.958 60.6319H123.111C131.76 60.6319 138.746 53.6123 138.746 44.9959C138.746 36.3795 131.76 29.3267 123.111 29.3267ZM123.111 50.8511H115.958V39.1075H123.111C126.338 39.1075 128.966 41.7356 128.966 44.9626C128.966 48.1896 126.371 50.8511 123.111 50.8511Z"
              fill="#003D99"
            />
            <path
              d="M39.7409 29.3268V39.1076H51.5178C50.0873 46.5264 42.9014 52.1487 34.1852 52.1487C24.3046 52.1487 16.287 44.1311 16.287 34.2172C16.287 24.3034 24.3046 16.2858 34.1852 16.2858C39.3085 16.2858 43.8994 18.4482 47.1597 21.8748L54.5452 14.4893C49.3887 9.1664 42.1695 5.8396 34.1852 5.8396C18.5492 5.8396 5.84082 18.548 5.84082 34.2172C5.84082 49.8865 18.5159 62.5948 34.1852 62.5948C48.1577 62.5948 59.7683 52.4481 62.0971 39.1076C62.3632 37.5108 62.5295 35.8806 62.5295 34.2172C62.5295 32.5538 62.3632 30.9237 62.0971 29.3268H39.7409Z"
              fill="#003D99"
            />
            <path
              d="M189.7 51.6497C179.926 51.6497 171.995 43.7186 171.995 33.9112C171.995 24.1037 179.926 16.1726 189.7 16.1726C194.767 16.1726 199.308 18.3118 202.535 21.7018L209.84 14.3961C204.74 9.12981 197.598 5.8396 189.7 5.8396C174.234 5.8396 161.662 18.4116 161.662 33.9112C161.662 49.4107 174.2 61.9827 189.7 61.9827C197.564 61.9827 204.674 58.7258 209.774 53.4927L202.831 46.3201C199.637 49.5771 194.933 51.653 189.7 51.653V51.6497Z"
              fill="#003D99"
            />
            <path
              d="M319.758 5.8396V29.3268H313.936C305.286 29.3268 298.3 36.3464 298.3 44.9628C298.3 53.5792 305.286 60.5988 313.936 60.5988H329.705L329.738 5.8396H319.758ZM313.903 50.818C310.676 50.818 308.048 48.1898 308.048 44.9628C308.048 41.7358 310.676 39.1076 313.903 39.1076H319.725V50.8512H313.903V50.818Z"
              fill="#003D99"
            />
            <path
              d="M284.86 46.2283C284.86 47.1266 284.66 47.925 284.261 48.6569C283.862 49.3888 283.329 49.9211 282.631 50.3203C281.932 50.7195 281.134 50.9191 280.269 50.9191C279.404 50.9191 278.605 50.7195 277.907 50.3203C277.208 49.9211 276.642 49.3555 276.243 48.6236C275.844 47.8918 275.644 47.0933 275.644 46.1951V29.2949H265.697V46.1618V46.1951C265.697 48.8898 266.329 51.3849 267.594 53.6138C268.858 55.8428 270.654 57.606 272.883 58.9035C275.112 60.1677 277.607 60.7997 280.335 60.7997C283.063 60.7997 285.525 60.1677 287.721 58.9035C289.95 57.6393 291.713 55.8428 293.01 53.6138C294.275 51.3849 294.907 48.8898 294.907 46.1951V46.1618V29.2949H284.959L284.86 46.2283Z"
              fill="#003D99"
            />
            <path
              d="M260.348 36.3709C258.871 33.776 256.778 31.6834 254.183 30.2063C251.588 28.7292 248.704 27.9707 245.53 27.9707C242.356 27.9707 239.436 28.7292 236.841 30.2063C234.246 31.6834 232.153 33.776 230.676 36.3709C229.199 38.9658 228.44 41.8867 228.44 45.0605C228.44 48.2343 229.199 51.1186 230.676 53.7135C232.153 56.3084 234.246 58.401 236.841 59.8781C239.436 61.3552 242.356 62.1137 245.53 62.1137C248.704 62.1137 251.588 61.3552 254.183 59.8781C256.778 58.401 258.871 56.3084 260.348 53.7135C261.825 51.1186 262.583 48.1977 262.583 45.0605C262.583 41.9233 261.825 38.9658 260.348 36.3709ZM251.528 48.6368C250.95 49.6781 250.118 50.5397 249.057 51.1918C248.039 51.8172 246.884 52.1233 245.527 52.1233C244.17 52.1233 243.088 51.8206 242.014 51.2018C240.903 50.5198 240.091 49.6914 239.525 48.6701C238.91 47.5622 238.61 46.3812 238.61 45.0605C238.61 43.7397 238.887 42.5354 239.485 41.421C240.071 40.3697 240.866 39.5446 241.927 38.8926C243.015 38.2638 244.176 37.9611 245.494 37.9611C246.811 37.9611 247.999 38.2638 249.05 38.8859C250.105 39.4947 250.94 40.3431 251.532 41.411C252.147 42.5188 252.447 43.6998 252.447 45.0239C252.447 46.348 252.147 47.5257 251.532 48.6335L251.528 48.6368Z"
              fill="#003D99"
            />
          </svg>
        </a>
        {!matchKrContents && !matchJpEbook && (
          <aside>
            {isPc && (matchRoot || matchKr || matchKrSub) && (
              <div className="menu">
                <Button
                  isText
                  label="고객사 사례"
                  className={matchCase ? "active" : ""}
                  onClick={() => navigate("/kr/case")}
                />
                <Button
                  isText
                  label="요금제"
                  className={matchPayment ? "active" : ""}
                  onClick={() => navigate("/kr/payment")}
                />
                <Button
                  isText
                  label="블로그"
                  onClick={() => window.open("https://blog.gripcloud.show")}
                />
                <Button
                  isText
                  label="자주 묻는 질문"
                  className={matchFaq ? "active" : ""}
                  onClick={() => navigate("/kr/faq")}
                />
              </div>
            )}
            {/* NOTE: 일본어 네비게이션 메뉴 */}
            {isPc && (matchJp || matchJpSub) && (
              <div className="menu">
                <Button
                  isText
                  label="ブログ"
                  className={matchPayment ? "active" : ""}
                  onClick={() => window.open("https://blog.gripcloud.show/ja-jp/grip-cloud-blog")}
                />
              </div>
            )}
            {isPc && <LangSelect language={language} onChangeLanguage={onChangeLanguage} />}
            <Button
              className="gtm-trigger-request-demo"
              label={t("inquire")}
              isSmall
              onClick={() => {
                language === "en"
                  ? (window.location.href =
                      "https://share.hsforms.com/12EXYe0b3RB6sfrwgqCIR2Aeg9vg")
                  : language === "ja"
                  ? (window.location.href =
                      "https://share.hsforms.com/1krPh2pJ8TOyG7S2dV8H9vQeg9vg")
                  : (window.location.href =
                      "https://share.hsforms.com/1t4S49W6nT_iKkHROACvRvAeg9vg");
              }}
            />
            {!isPc && (
              <button className="hamburger" onClick={() => setOpenMenu(!openMenu)}>
                {!openMenu ? (
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <path
                      d="M25 23C25.2549 23.0003 25.5 23.0979 25.6854 23.2728C25.8707 23.4478 25.9822 23.687 25.9972 23.9414C26.0121 24.1958 25.9293 24.4464 25.7657 24.6418C25.6021 24.8373 25.3701 24.9629 25.117 24.993L25 25H9C8.74512 24.9997 8.49997 24.9021 8.31463 24.7272C8.1293 24.5522 8.01777 24.313 8.00283 24.0586C7.98789 23.8042 8.07067 23.5536 8.23426 23.3582C8.39786 23.1627 8.6299 23.0371 8.883 23.007L9 23H25ZM25 16C25.2652 16 25.5196 16.1054 25.7071 16.2929C25.8946 16.4804 26 16.7348 26 17C26 17.2652 25.8946 17.5196 25.7071 17.7071C25.5196 17.8946 25.2652 18 25 18H9C8.73478 18 8.48043 17.8946 8.29289 17.7071C8.10536 17.5196 8 17.2652 8 17C8 16.7348 8.10536 16.4804 8.29289 16.2929C8.48043 16.1054 8.73478 16 9 16H25ZM25 9C25.2652 9 25.5196 9.10536 25.7071 9.29289C25.8946 9.48043 26 9.73478 26 10C26 10.2652 25.8946 10.5196 25.7071 10.7071C25.5196 10.8946 25.2652 11 25 11H9C8.73478 11 8.48043 10.8946 8.29289 10.7071C8.10536 10.5196 8 10.2652 8 10C8 9.73478 8.10536 9.48043 8.29289 9.29289C8.48043 9.10536 8.73478 9 9 9H25Z"
                      fill="#989898"
                    />
                  </svg>
                ) : (
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0013 18.4141L22.6583 24.0711C22.8469 24.2533 23.0995 24.3541 23.3617 24.3518C23.6239 24.3495 23.8747 24.2444 24.0601 24.059C24.2455 23.8736 24.3507 23.6227 24.353 23.3606C24.3552 23.0984 24.2544 22.8458 24.0723 22.6571L18.4153 17.0001L24.0723 11.3431C24.2544 11.1545 24.3552 10.9019 24.353 10.6397C24.3507 10.3776 24.2455 10.1267 24.0601 9.94133C23.8747 9.75592 23.6239 9.65075 23.3617 9.64848C23.0995 9.6462 22.8469 9.74699 22.6583 9.92915L17.0013 15.5861L11.3443 9.92915C11.1548 9.75149 10.9037 9.65451 10.644 9.65873C10.3843 9.66295 10.1365 9.76803 9.9529 9.95174C9.76931 10.1355 9.66441 10.3834 9.66037 10.6431C9.65634 10.9028 9.7535 11.1538 9.93129 11.3431L15.5873 17.0001L9.93029 22.6571C9.83478 22.7494 9.7586 22.8597 9.70619 22.9817C9.65378 23.1037 9.62619 23.235 9.62504 23.3677C9.62388 23.5005 9.64919 23.6322 9.69947 23.7551C9.74975 23.878 9.824 23.9897 9.91789 24.0835C10.0118 24.1774 10.1234 24.2517 10.2463 24.302C10.3692 24.3523 10.5009 24.3776 10.6337 24.3764C10.7665 24.3752 10.8977 24.3477 11.0197 24.2953C11.1417 24.2428 11.252 24.1667 11.3443 24.0711L17.0013 18.4141Z"
                      fill="#989898"
                    />
                  </svg>
                )}
              </button>
            )}
          </aside>
        )}

        {matchKrWebinarVol2 && (
          <Button
            label={t("subscription")}
            isSmall
            onClick={() =>
              document
                .querySelector("section")
                ?.scrollIntoView({ behavior: "smooth", block: "center" })
            }
            style={{ backgroundColor: "black" }}
          />
        )}
      </div>
      <nav className="hamburger-menu" data-open={openMenu}>
        {(matchRoot || matchKr || matchKrSub) && (
          <div className="menu">
            <button
              className={matchCase ? "active" : ""}
              onClick={() => {
                navigate("/kr/case");
                setOpenMenu(false);
              }}
            >
              고객사 사례
            </button>
            <button
              className={matchPayment ? "active" : ""}
              onClick={() => {
                navigate("/kr/payment");
                setOpenMenu(false);
              }}
            >
              요금제
            </button>
            <button
              className={matchPayment ? "active" : ""}
              onClick={() => {
                window.open("https://blog.gripcloud.show");
              }}
            >
              블로그
            </button>
            <button
              className={matchFaq ? "active" : ""}
              onClick={() => {
                navigate("/kr/faq");
                setOpenMenu(false);
              }}
            >
              자주 묻는 질문
            </button>
          </div>
        )}
        {/* NOTE: 일본어 햄버거 메뉴 */}

        {(matchJp || matchJpSub) && (
          <div className="menu">
            <button
              className={matchPayment ? "active" : ""}
              onClick={() => {
                window.open("https://blog.gripcloud.show/ja-jp/grip-cloud-blog");
              }}
            >
              ブログ
            </button>
          </div>
        )}

        <div className="lang">
          <button
            className={language === "ko" ? "active" : ""}
            onClick={() => {
              onChangeLanguage("ko");
              i18n.changeLanguage("ko");
              navigate("kr");
              setOpenMenu(false);
            }}
          >
            KOR
          </button>
          <span>|</span>
          <button
            className={language === "en" ? "active" : ""}
            onClick={() => {
              onChangeLanguage("en");
              i18n.changeLanguage("en");
              navigate("en");
              setOpenMenu(false);
            }}
          >
            ENG
          </button>
          <span>|</span>
          <button
            className={language === "ja" ? "active" : ""}
            onClick={() => {
              onChangeLanguage("ja");
              i18n.changeLanguage("ja");
              navigate("jp");
              setOpenMenu(false);
            }}
          >
            JP
          </button>
        </div>
      </nav>

      <ReactModal
        isOpen={isOpenContentUsModal}
        onRequestClose={closeContentUsModal}
        contentLabel="ContactUs Modal"
        style={modalStyles}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
      >
        <ContactUsModal language={language} onClose={closeContentUsModal} />
      </ReactModal>
    </HeaderWrap>
  );
};

export default Header;
