import { useMediaQuery } from 'react-responsive'
import { HomeSection } from '../../../pages/home/Home.style'
import styled from 'styled-components'

const PartnerSliderWrap = styled.div`

    @keyframes topPrimary {
        from    { left: 0; }
        to      { left: -5840px; }
    }
    @keyframes topSecondary {
        from    { left: 5840px; }
        to      { left: 0; }
    }

    @keyframes topPrimaryMo {
        from    { left: 0; }
        to      { left: -4380px; }
    }
    @keyframes topSecondaryMo {
        from    { left: 4380px; }
        to      { left: 0; }
    }

    @keyframes bottomPrimary {
        from    { right: 0; }
        to      { right: -5840px; }
    }
    @keyframes bottomSecondary {
        from    { right: 5840px; }
        to      { right: 0; }
    }

    width: 100%;
    position: relative;
    &.top {
        height: 56px;
        @media (max-width: 599px) {
            height: 41px;
        }
    }
    &.bottom {
        height: 88px;
    }
    .partner-slider {
        position: absolute;
        top: 0;
        animation-play-state: running;
        &.top {
            width: 5840px;
            left: 0;
            &.primary {
                animation: topPrimary 80s linear 0s infinite;
            }
            &.secondary {
                animation: topSecondary 80s linear 0s infinite;
            }
            @media (max-width: 599px) {
                width: 4380px;
                &.primary {
                    animation: topPrimaryMo 60s linear 0s infinite;
                }
                &.secondary {
                    animation: topSecondaryMo 60s linear 0s infinite;
                }
            }
        }
        &.bottom {
            width: 5840px;
            right: 0;
            margin-top: 2rem;
            &.primary {
                animation: bottomPrimary 80s linear 0s infinite;
            }
            &.secondary {
                animation: bottomSecondary 80s linear 0s infinite;
            }
        } 
    }
`

const LogoSlideSection = () => {
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    return (
        <HomeSection pt={isMo ? 10 : 50} pb={isMo ? 30 : 50} style={{ minHeight: 'auto' }}>
            <PartnerSliderWrap className='top'>
                <div>
                    <img className='partner-slider top primary' src='/assets/images/logo01.png' alt='' />
                    <img className='partner-slider top secondary' src='/assets/images/logo01.png' alt='' />
                </div>
            </PartnerSliderWrap>
            {!isMo &&
                <PartnerSliderWrap className='bottom'>
                    <div>
                        <img className='partner-slider bottom primary' src='/assets/images/logo02.png' alt='' />
                        <img className='partner-slider bottom secondary' src='/assets/images/logo02.png' alt='' />
                    </div>
                </PartnerSliderWrap>
            }
        </HomeSection>
    )
}

export default LogoSlideSection