import styled from "styled-components";

export const EbookSectionWrap = styled.div`
    display: flex;
    gap: 0 100px;
    &.top {
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        padding: 0 40px;
        .section-img {
            width: 100%;
            max-width: 1000px;
            margin-left: -10%;
            img {
                width: 100%;
            }

            &.vol3 {
                display: flex;
                justify-content: center;

                img {
                    width: 85%;
                }
            }
        }
        .form-box {
            width: 100%;
            max-width: 600px;
            padding: 60px 0;
        }

        &.second {
            padding: 0;
            gap: 0;

            .contents-box {
                &.vol3 {
                    padding-top: 120px;
                }
                width: 100%;
                padding-top: 70px;
            }
        }

        &.vol4 {
            display: flex;
            justify-content: flex-start;
            position: relative;

            /** PC */
            height: 800px;

            /** tablet, mobile */
            /* height: 650px; */

            .contents-box {
                /** PC */
                padding-top: 120px;

                /** tablet, mobile */
                /* padding-top: 40px; */
            }

            .section-img {
                position: absolute;
                max-width: initial;
                margin: 0;

                /** PC */
                bottom: -10px;
                left: 35%;

                /** tablet */
                /* bottom: -100px;
                left: 35%; */

                /** mobile */
                /* bottom: -40px;
                left: -10%; */

                img {
                    /** PC */
                    width: 80%;
                    /** Tablet */
                    /* width: 85%; */

                    /** mobile */
                    /* width: 150%; */
                }
            }
        }
    }
    &.center {
        &.second {
            align-items: center;
        }

        .section-img {
            width: 100%;
            max-width: 681px;
            img {
                width: 100%;
            }

            &.vol3 {
                display: flex;
                justify-content: center;

                img {
                    width: 85%;
                }
            }
        }

        .contents-box {
            width: 100%;
            padding-top: 70px;

            &.vol3 {
                padding-top: 0;
            }
            ul {
                margin-top: 40px;
                li {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    background: rgba(255, 255, 255, 0.8);
                    border: 1px solid #ffffff;
                    border-radius: 12px;
                    padding: 24px 20px;
                    display: flex;
                    align-items: center;
                    gap: 0 8px;
                    &:not(:first-of-type) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    &.bottom {
        align-items: center;
        .section-img {
            width: 100%;
            max-width: 711px;
            img {
                width: 100%;
            }

            &.vol3 {
                display: flex;
                justify-content: center;

                img {
                    width: 85%;
                }
            }
        }
        .contents-box {
            width: 100%;
            padding-top: 50px;
            > article {
                margin-top: 50px;
                p {
                    text-align: justify;
                }
            }
        }
        button.outlined {
            width: 100%;
            height: 46px;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 0 6px;
        }
    }
    @media (min-width: 600px) and (max-width: 1199px) {
        flex-direction: column;
        gap: 0;
        &.top {
            &.second {
                padding: 0 30px;

                .section-img {
                    margin-left: 0;
                }
            }
            .form-box {
                padding: 40px 0 0;
                margin: 0 auto;
            }
            &.vol4 {
                height: 650px;

                .contents-box {
                    padding-top: 40px;
                }

                .section-img {
                    /** tablet */
                    bottom: -100px;
                    left: 35%;

                    img {
                        /** Tablet */
                        width: 85%;
                    }
                }
            }
        }
        &.center {
            .section-img {
                margin: 50px auto;
                width: 70%;
            }
            .contents-box {
                padding-top: 0;
                ul {
                    margin-top: 0;
                    li {
                        background: rgba(255, 255, 255, 0.8);
                        border: 1px solid #ffffff;
                        border-radius: 12px;
                        padding: 24px 20px;
                        display: flex;
                        align-items: center;
                        gap: 0 8px;
                        &:not(:first-of-type) {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
        &.bottom {
            .section-img {
                margin: 0 auto 80px;
                width: 70%;
            }
            .contents-box {
                padding-top: 0;
            }

            &.vol4 {
                .section-img {
                    margin: 80px auto;
                }
            }
        }
    }
    @media (max-width: 599px) {
        flex-direction: column;
        gap: 0;
        &.top {
            &.second {
                padding: 0 20px;

                .contents-box {
                    &.vol3 {
                        padding-top: 0;
                    }
                    padding-top: 35px;
                }

                .section-img {
                    width: 500px;
                    margin-left: -30%;

                    &.vol3 {
                        width: auto;
                        display: flex;
                        justify-content: center;
                        margin-left: 0;
                        img {
                            width: 80%;
                        }
                    }
                }

                /* .cta-button {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    width: 100% !important;
                    border-radius: 0;
                } */
            }

            padding: 0 20px;
            .form-box {
                padding: 0;
                margin: 0 auto;
            }

            &.vol4 {
                min-height: 600px;
                height: initial;
                .contents-box {
                    /** tablet */
                    padding-top: 40px;

                    text-align: center;
                }

                .section-img {
                    /** mobile */
                    bottom: -91px;
                    left: -7%;

                    img {
                        /** mobile */
                        width: 130%;
                    }
                }
            }
        }
        &.center {
            .section-img {
                margin: 16px auto 32px;
                width: 70%;
            }
            .contents-box {
                padding-top: 0;
                ul {
                    margin-top: 0;
                    li {
                        font-size: 13px;
                        line-height: 16px;
                        letter-spacing: -0.075px;
                        padding: 16px 12px;
                        gap: 0 4px;
                        &:not(:first-of-type) {
                            margin-top: 8px;
                        }
                        svg {
                            width: 20px;
                        }
                    }
                }
            }
        }
        &.bottom {
            .section-img {
                &.vol3 {
                    margin: 1rem auto;
                }
                margin: 0 0 24px auto;
                width: 55%;
            }
            .contents-box {
                padding-top: 0;
            }

            &.vol4 {
                .section-img {
                    margin: 24px 0 24px auto;
                }
            }
        }
    }
`;

interface CheckIconProps {
    fill: string;
}

export const CheckIcon: React.FC<CheckIconProps> = ({ fill }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
                d="M15.9 20.7L12.675 17.475C12.4 17.2 12.05 17.0625 11.625 17.0625C11.2 17.0625 10.85 17.2 10.575 17.475C10.3 17.75 10.1625 18.1 10.1625 18.525C10.1625 18.95 10.3 19.3 10.575 19.575L14.85 23.85C15.15 24.15 15.5 24.3 15.9 24.3C16.3 24.3 16.65 24.15 16.95 23.85L25.425 15.375C25.7 15.1 25.8375 14.75 25.8375 14.325C25.8375 13.9 25.7 13.55 25.425 13.275C25.15 13 24.8 12.8625 24.375 12.8625C23.95 12.8625 23.6 13 23.325 13.275L15.9 20.7ZM18 33C15.925 33 13.975 32.606 12.15 31.818C10.325 31.03 8.7375 29.9615 7.3875 28.6125C6.0375 27.2625 4.969 25.675 4.182 23.85C3.395 22.025 3.001 20.075 3 18C3 15.925 3.394 13.975 4.182 12.15C4.97 10.325 6.0385 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.969 12.15 4.182C13.975 3.395 15.925 3.001 18 3C20.075 3 22.025 3.394 23.85 4.182C25.675 4.97 27.2625 6.0385 28.6125 7.3875C29.9625 8.7375 31.0315 10.325 31.8195 12.15C32.6075 13.975 33.001 15.925 33 18C33 20.075 32.606 22.025 31.818 23.85C31.03 25.675 29.9615 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0315 23.85 31.8195C22.025 32.6075 20.075 33.001 18 33Z"
                fill={fill}
            />
        </svg>
    );
};
