import React from 'react'
import { useNavigate, useLocation, Navigate} from 'react-router-dom'
import styled from 'styled-components'
import Button from './Button'
import Typography from './Typography'

const NotFoundWrap = styled.div`
    height: calc(100vh - 347px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px 0;
    text-align: center;
`
interface Props {
    onChangeLanguage: (value: string) => void
}

const NotFound: React.FC<Props> = ({onChangeLanguage}) => {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const exceptionLocation = ['/jp','/kr','/us']

    if (exceptionLocation.includes(pathname)) {
        switch (pathname) {
            case '/jp':
                onChangeLanguage('/ja')
                return <Navigate to={'/ja'} />

            case '/kr':
                onChangeLanguage('/ko')
                return <Navigate to={'/ko'} />

            case '/us':
                onChangeLanguage('/en')
                return <Navigate to={'/en'} />

        }
        return (<React.Fragment></React.Fragment>)
    } else {
        return (
            <NotFoundWrap>
                <Typography label='404' size={100} weight={800} />
                <Typography label='Oops! This Page Could Not Be Found' size={32} weight={700} />
                <Typography label={<>SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST, HAVE BEEN REMOVED.<br />NAME CHANGED OR IS TEMPORARILY UNAVAILABLE</>} size={16} lineHeight={24} />
                <Button label='GO TO HOMEPAGE' onClick={() => navigate('/')} />
            </NotFoundWrap>
        )
    }


}

export default NotFound