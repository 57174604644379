import styled from "styled-components";
import Typography from "./Typography";
// import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import InquiryForm from "./InquiryForm";
import { useTranslation } from "react-i18next";

const ContactUsModalWrap = styled.div`
    width: 528px;
    height: 822px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    > button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: 0;
        border-radius: 8px;
        padding: 0;
        background-color: transparent;
        transition: all 0.3s ease;
        display: flex;
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    header {
        padding: 40px 20px 10px;
    }
    section {
        flex: 1;
        overflow-y: scroll;
        margin: 10px 0 24px;
        padding: 0 20px;
    }
    @media (min-width: 600px) and (max-width: 1199px) {
        width: 410px;
    }
    @media (max-width: 599px) {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
`;

interface ContactUsModalProps {
    language: string;
    formId?: string;
    onClose: () => void;
}

const ContactUsModal = (props: ContactUsModalProps) => {
    const { language, onClose, formId } = props;
    const { t } = useTranslation("main");

    return (
        <ContactUsModalWrap>
            <button id="btnModalClose" onClick={onClose}>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        d="M30.4999 9.51667C30.3457 9.36217 30.1625 9.23959 29.9609 9.15595C29.7593 9.07232 29.5432 9.02927 29.3249 9.02927C29.1066 9.02927 28.8905 9.07232 28.6888 9.15595C28.4872 9.23959 28.3041 9.36217 28.1499 9.51667L19.9999 17.65L11.8499 9.50001C11.6956 9.3457 11.5124 9.2233 11.3108 9.13979C11.1092 9.05629 10.8931 9.01331 10.6749 9.01331C10.4567 9.01331 10.2406 9.05629 10.039 9.13979C9.83737 9.2233 9.65419 9.3457 9.49988 9.50001C9.34558 9.65431 9.22318 9.83749 9.13967 10.0391C9.05616 10.2407 9.01318 10.4568 9.01318 10.675C9.01318 10.8932 9.05616 11.1093 9.13967 11.3109C9.22318 11.5125 9.34558 11.6957 9.49988 11.85L17.6499 20L9.49988 28.15C9.34558 28.3043 9.22318 28.4875 9.13967 28.6891C9.05616 28.8907 9.01318 29.1068 9.01318 29.325C9.01318 29.5432 9.05616 29.7593 9.13967 29.9609C9.22318 30.1625 9.34558 30.3457 9.49988 30.5C9.65419 30.6543 9.83737 30.7767 10.039 30.8602C10.2406 30.9437 10.4567 30.9867 10.6749 30.9867C10.8931 30.9867 11.1092 30.9437 11.3108 30.8602C11.5124 30.7767 11.6956 30.6543 11.8499 30.5L19.9999 22.35L28.1499 30.5C28.3042 30.6543 28.4874 30.7767 28.689 30.8602C28.8906 30.9437 29.1067 30.9867 29.3249 30.9867C29.5431 30.9867 29.7592 30.9437 29.9608 30.8602C30.1624 30.7767 30.3456 30.6543 30.4999 30.5C30.6542 30.3457 30.7766 30.1625 30.8601 29.9609C30.9436 29.7593 30.9866 29.5432 30.9866 29.325C30.9866 29.1068 30.9436 28.8907 30.8601 28.6891C30.7766 28.4875 30.6542 28.3043 30.4999 28.15L22.3499 20L30.4999 11.85C31.1332 11.2167 31.1332 10.15 30.4999 9.51667Z"
                        fill="black"
                    />
                </svg>
            </button>

            <header>{!formId && <Typography label={t("inquire")} size={28} weight={700} />}</header>

            <section>
                <InquiryForm language={language} formId={formId} />
            </section>
        </ContactUsModalWrap>
    );
};

export default ContactUsModal;
