import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeIn,
  fadeUp,
  HomeSection,
  HomeSectionInner,
  screen,
} from "../../../../pages/home/Home.style";
import Typography from "../../Typography";
import { useTranslation } from "react-i18next";

const PartnerSectionWrap = styled.div`
  @media (max-width: 599px) {
    padding: 0 20px;
  }

  @keyframes partnerKrPrimary {
    from {
      top: 0;
    }
    to {
      top: -1048px;
    }
  }
  @keyframes partnerKrSecondary {
    from {
      top: 1048px;
    }
    to {
      top: 0;
    }
  }

  @keyframes partnerKrPrimaryTa {
    from {
      top: 0;
    }
    to {
      top: -1557px;
    }
  }
  @keyframes partnerKrSecondaryTa {
    from {
      top: 1557px;
    }
    to {
      top: 0;
    }
  }

  @keyframes partnerKrPrimaryMo {
    from {
      top: 0;
    }
    to {
      top: -947px;
    }
  }
  @keyframes partnerKrSecondaryMo {
    from {
      top: 947px;
    }
    to {
      top: 0;
    }
  }

  @keyframes partnerKrPrimaryXMo {
    from {
      top: 0;
    }
    to {
      top: -708px;
    }
  }
  @keyframes partnerKrSecondaryXMo {
    from {
      top: 708px;
    }
    to {
      top: 0;
    }
  }

  > article {
    margin-bottom: 48px;
    text-align: center;
    p {
      letter-spacing: -1px;
    }
  }
  > div {
    height: 530px;
    overflow: hidden;
    text-align: center;
    > div {
      height: 100%;
    }
    .partner-slider-wrap {
      position: relative;
      height: 100%;

      .partner-slider {
        width: 100%;
        height: auto;
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 2.5rem 3.94rem;
        align-items: center;
        justify-items: center;

        @media (min-width: 768px) and (max-width: 1199px) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: 2rem 2.5rem;
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1.25rem 0;
        }

        img {
          max-width: 11.5rem;

          @media (max-width: 767px) {
            max-width: 6.3125rem;
          }
        }

        &.top {
          animation: partnerKrPrimary 18s linear 0s infinite;

          @media (min-width: 768px) and (max-width: 1199px) {
            animation: partnerKrPrimaryTa 18s linear 0s infinite;
          }
          @media (max-width: 767px) {
            animation: partnerKrPrimaryMo 20s linear 0s infinite;
          }
        }
        &.bottom {
          animation: partnerKrSecondary 18s linear 0s infinite;

          @media (min-width: 768px) and (max-width: 1199px) {
            animation: partnerKrSecondaryTa 18s linear 0s infinite;
          }
          @media (max-width: 767px) {
            animation: partnerKrSecondaryMo 20s linear 0s infinite;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    > article {
      margin-bottom: 75px;
      text-align: left;
    }
    > div {
      height: 540px;
    }
  }
  @media (min-width: 375px) and (max-width: 767px) {
    > article {
      margin-bottom: 32px;
      text-align: left;
    }
    > div {
      height: 400px;
    }
  }
  @media (max-width: 374px) {
    > article {
      margin-bottom: 32px;
      text-align: left;
    }
    > div {
      height: 288px;
    }
  }
`;

const url = "/assets/images";
const PartnerSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });
  // const isMoSlide = useMediaQuery({ query: "(min-width:375px) and (max-width:767px)" });

  const imageResource: { src: string; alt: string }[] = [
    { src: `${url}/samsung_logo.png`, alt: "samsung-logo" },
    { src: `${url}/lotte_himart_logo.png`, alt: "lotte-himart-logo" },
    { src: `${url}/lg_household_healthcare_logo.png`, alt: "lg-household-healthcare-logo" },
    { src: `${url}/cjthemarket_logo.png`, alt: "cjthemarket-logo" },
    { src: `${url}/oliveyoung_logo.png`, alt: "oliveyoung-logo" },
    { src: `${url}/ssg_logo.png`, alt: "ssg-logo" },
    { src: `${url}/hana_logo.png`, alt: "hana-logo" },
    { src: `${url}/inbody_logo.png`, alt: "inbody-logo" },
    { src: `${url}/kurly_logo.png`, alt: "kurly-logo" },
    { src: `${url}/the_hyundai_logo.png`, alt: "the-hyundai-logo" },
    { src: `${url}/qoo10_logo.png`, alt: "qoo10-logo" },
    { src: `${url}/cu_logo.png`, alt: "cu-logo" },
    { src: `${url}/kb_logo.png`, alt: "kb-logo" },
    { src: `${url}/akmall_logo.png`, alt: "akmall-logo" },
    { src: `${url}/shinsegae_logo.png`, alt: "shinsegae-logo" },
    { src: `${url}/amorepacific_logo.png`, alt: "amorepacific-logo" },
    { src: `${url}/aritaum_logo.png`, alt: "aritaum-logo" },
    { src: `${url}/innisfree_logo.png`, alt: "innisfree-logo" },
    { src: `${url}/navien_logo.png`, alt: "navien-logo" },
    { src: `${url}/wetable_logo.png`, alt: "wetable-logo" },
    { src: `${url}/hurom_logo.png`, alt: "hurom-logo" },
    { src: `${url}/atomy_logo.png`, alt: "atomy-logo" },
    { src: `${url}/yogiyo_logo.png`, alt: "yogiyo-logo" },
    { src: `${url}/happy_point_logo.png`, alt: "happy-point-logo" },
    { src: `${url}/jaju_logo.png`, alt: "jaju-logo" },
    { src: `${url}/bgf_retail_logo.png`, alt: "bgf-retail-logo" },
    { src: `${url}/locknlock_logo.png`, alt: "locknlock-logo" },
    { src: `${url}/onthelook_logo.png`, alt: "onthelook-logo" },
    { src: `${url}/hago_logo.png`, alt: "hago-logo" },
    { src: `${url}/lusol_logo.png`, alt: "lusol-logo" },
    { src: `${url}/allmytour_logo.png`, alt: "allmytour-logo" },
    { src: `${url}/wadiz_logo.png`, alt: "wadiz-logo" },
    { src: `${url}/cozyma_logo.png`, alt: "cozyma-logo" },
    { src: `${url}/nugu_logo.png`, alt: "nugu-logo" },
    { src: `${url}/daehyun_logo.png`, alt: "daehyun-logo" },
    { src: `${url}/liiv_next_logo.png`, alt: "liiv-next-logo" },
    { src: `${url}/nonghyup_logo.png`, alt: "nonghyup-logo" },
    { src: `${url}/emart_everyday_logo.png`, alt: "emart-everyday-logo" },
    { src: `${url}/shoeprize_logo.png`, alt: "shoeprize-logo" },
    { src: `${url}/verygoodtour_logo.png`, alt: "verygoodtour-logo" },
    { src: `${url}/jungkwanjang_logo.png`, alt: "jungkwanjang-logo" },
    { src: `${url}/kidsning_logo.png`, alt: "kidsning-logo" },
    { src: `${url}/ksbed_logo.png`, alt: "ksbed-logo" },
    { src: `${url}/eoding_logo.png`, alt: "eoding-logo" },
    { src: `${url}/kyowon_logo.png`, alt: "kyowon-logo" },
  ];

  return (
    <HomeSection pt={isPc ? 150 : isTa ? 120 : 74} pb={isPc ? 150 : isTa ? 120 : 74}>
      <HomeSectionInner>
        <PartnerSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography
                  label={
                    language === "ko" ? "업계 최고의 기업들이 선택한" : "業界をリードする企業が選ぶ"
                  }
                  size={isPc ? 64 : 48}
                  weight={700}
                  lineHeight={isPc ? 76 : 64}
                  mb={isPc ? 12 : 0}
                />
                <Typography
                  label={language === "ko" ? <span>그립 클라우드</span> : <span>Grip Cloud</span>}
                  size={isPc ? 64 : 48}
                  weight={700}
                  lineHeight={isPc ? 76 : 64}
                />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography
                label={
                  language === "ko" ? "업계 최고의 기업들이 선택한" : "業界をリードする企業が選ぶ"
                }
                size={language === "ko" ? 30 : 26}
                weight={700}
                lineHeight={40}
              />
              <Typography
                label={language === "ko" ? <span>그립 클라우드</span> : <span>Grip Cloud</span>}
                size={language === "ko" ? 30 : 26}
                weight={700}
                lineHeight={40}
              />
            </article>
          )}
          <div>
            <Reveal className="reveal" keyframes={fadeIn} duration={600} delay={200}>
              <div className="partner-slider-wrap">
                <div className="partner-slider top">
                  {imageResource.map((img, index) => (
                    <img src={img.src} alt={img.alt} key={`logo-${index}`} />
                  ))}
                </div>
                <div className="partner-slider bottom">
                  {imageResource.map((img, index) => (
                    <img src={img.src} alt={img.alt} key={`logo-${index}`} />
                  ))}
                </div>
              </div>
            </Reveal>
          </div>
        </PartnerSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default PartnerSection;
