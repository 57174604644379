import { useEffect, useState } from "react";
import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Divider from "../../common/component/Divider";
import { PC, Tablet, Mobile } from "../../common/component/MediaQuery";
import Typography from "../../common/component/Typography";
import InnerTypography from "../../common/component/InnerTypography";
import { fadeUp, media } from "../home/Home.style";
import { PaymentData } from "./Payment.data";
import { PaymentWrap } from "./Payment.style";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import styled from "styled-components";

const Payment = () => {
  const navigate = useNavigate();
  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const [paymentCurrentSlide, setPaymentCurrentSlide] = useState<number>(0);
  const paymentSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    infinite: true,
    beforeChange: (current: any, next: number) => {
      setPaymentCurrentSlide(next);
    },
    appendDots: (dots: any) => (
      <div>
        <ul className="light">{dots}</ul>
      </div>
    ),
    customPaging: (index: any) => (
      <button
        style={{
          backgroundColor: paymentCurrentSlide >= index ? "#568DFD" : undefined,
          borderTopRightRadius: paymentCurrentSlide === index ? "3px" : 0,
          borderBottomRightRadius: paymentCurrentSlide === index ? "3px" : 0,
        }}
      />
    ),
  };

  const [position, setPosition] = useState<number>(0);

  useEffect(() => {
    const handleShowButton = () => {
      setPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <PaymentWrap>
      <Reveal className="reveal" keyframes={fadeUp} duration={600} delay={100}>
        <Typography
          label={
            <>
              <span>그립 클라우드</span> 요금제
            </>
          }
          size={isPc ? 64 : isTa ? 48 : 30}
          weight={700}
          mt={isMo ? 32 : 70}
        />
        <Typography
          label="비즈니스 단계에 따른 합리적인 가격"
          size={isMo ? 16 : 20}
          weight={400}
          mt={isPc ? 24 : 16}
        />
      </Reveal>
      <PC>
        <section>
          <div className="figure-box">
            <Reveal
              className="reveal"
              keyframes={fadeUp}
              duration={600}
              delay={200}
              cascade
              damping={0.25}
            >
              {PaymentData.map((item: any, index: number) => {
                return (
                  <figure key={index}>
                    <div className="line" style={{ backgroundColor: item.color }}></div>
                    <InnerTypography.Paragraph
                      fontSize={"3.25rem"}
                      fontWeight={700}
                      margin={"0 0 1rem 1rem"}
                      color={item.color}
                    >
                      {item.title}
                    </InnerTypography.Paragraph>
                    <InnerTypography.Paragraph
                      fontSize={"0.875rem"}
                      margin={"0 0 0 1rem"}
                      lineHeight={"1.25rem"}
                      color="#969696"
                    >
                      {item.description}
                    </InnerTypography.Paragraph>
                    {/* <Typography label={item.benefitTitle} weight={800} size={28} /> */}
                    <Divider color="#F2F2F2" mt={32} mb={26} />
                    <ul>
                      {item.benefit.map((child: any, index: number) => {
                        return (
                          <li key={index}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M9.9998 13.6L15.8998 7.69999C16.0831 7.51665 16.3165 7.42499 16.5998 7.42499C16.8831 7.42499 17.1165 7.51665 17.2998 7.69999C17.4831 7.88332 17.5748 8.11665 17.5748 8.39999C17.5748 8.68332 17.4831 8.91665 17.2998 9.09999L10.6998 15.7C10.4998 15.9 10.2665 16 9.9998 16C9.73314 16 9.4998 15.9 9.2998 15.7L6.6998 13.1C6.51647 12.9167 6.4248 12.6833 6.4248 12.4C6.4248 12.1167 6.51647 11.8833 6.6998 11.7C6.88314 11.5167 7.11647 11.425 7.3998 11.425C7.68314 11.425 7.91647 11.5167 8.0998 11.7L9.9998 13.6Z"
                                fill="#343434"
                              />
                            </svg>
                            <FontWeightMedium>{child}</FontWeightMedium>
                          </li>
                        );
                      })}
                      {item.title === "Lite" && (
                        <li>
                          <span style={{ color: "#969696", fontSize: 14 }}>
                            옵션 : 쇼츠 솔루션 통합 지원, 화이트 라벨
                          </span>
                        </li>
                      )}
                    </ul>
                    <button
                      className="gtm-trigger-contact-sales"
                      style={{
                        backgroundColor: item.color,
                        fontFamily: "Pretendard",
                        fontWeight: 800,
                      }}
                      onClick={() => navigate("/kr/payment-inquiry")}
                    >
                      {item.title} 요금제 문의
                    </button>
                  </figure>
                );
              })}
            </Reveal>
          </div>
        </section>
      </PC>
      <Tablet>
        <article>
          <Slider {...paymentSettings}>
            {PaymentData.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <figure>
                    <div className="line" style={{ backgroundColor: item.color }}></div>
                    <InnerTypography.Paragraph
                      fontSize={"2.75rem"}
                      fontWeight={700}
                      margin={"0 0 1rem 1rem"}
                      color={item.color}
                    >
                      {item.title}
                    </InnerTypography.Paragraph>
                    <InnerTypography.Paragraph
                      fontSize={"0.6875rem"}
                      margin={"0 0 0 1rem"}
                      lineHeight={"1rem"}
                      color="#969696"
                    >
                      {item.description}
                    </InnerTypography.Paragraph>
                    {/* <Typography label={item.benefitTitle} weight={800} size={22} /> */}
                    <Divider color="#F2F2F2" mt={20} mb={20} />
                    <ul>
                      {item.benefit.map((child: any, index: number) => {
                        return (
                          <li key={index}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M9.9998 13.6L15.8998 7.69999C16.0831 7.51665 16.3165 7.42499 16.5998 7.42499C16.8831 7.42499 17.1165 7.51665 17.2998 7.69999C17.4831 7.88332 17.5748 8.11665 17.5748 8.39999C17.5748 8.68332 17.4831 8.91665 17.2998 9.09999L10.6998 15.7C10.4998 15.9 10.2665 16 9.9998 16C9.73314 16 9.4998 15.9 9.2998 15.7L6.6998 13.1C6.51647 12.9167 6.4248 12.6833 6.4248 12.4C6.4248 12.1167 6.51647 11.8833 6.6998 11.7C6.88314 11.5167 7.11647 11.425 7.3998 11.425C7.68314 11.425 7.91647 11.5167 8.0998 11.7L9.9998 13.6Z"
                                fill="#343434"
                              />
                            </svg>
                            <FontWeightMedium>{child}</FontWeightMedium>
                          </li>
                        );
                      })}
                      {item.title === "Lite" && (
                        <li>
                          <span style={{ color: "#969696", fontSize: 12 }}>
                            옵션 : 쇼츠 솔루션 통합 지원, 화이트 라벨
                          </span>
                        </li>
                      )}
                    </ul>
                    <button
                      className="gtm-trigger-contact-sales"
                      style={{
                        backgroundColor: item.color,
                        fontFamily: "Pretendard",
                        fontWeight: 800,
                      }}
                      onClick={() => navigate("/kr/payment-inquiry")}
                    >
                      {item.title} 요금제 문의
                    </button>
                  </figure>
                </div>
              );
            })}
          </Slider>
        </article>
      </Tablet>
      <Mobile>
        <Reveal className="reveal header" keyframes={fadeUp} duration={600} delay={200}>
          <header>
            <div>
              <button
                className={position <= 470 ? "active" : undefined}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                Lite
              </button>
              <button
                className={position > 471 && position < 940 ? "active" : undefined}
                onClick={() => window.scrollTo({ top: 471, behavior: "smooth" })}
              >
                Basic
              </button>
              <button
                className={position >= 941 && position < 1410 ? "active" : undefined}
                onClick={() => window.scrollTo({ top: 941, behavior: "smooth" })}
              >
                Pro
              </button>
              <button
                className={position >= 1411 ? "active" : undefined}
                onClick={() => window.scrollTo({ top: 1411, behavior: "smooth" })}
              >
                Enterprise
              </button>
            </div>
          </header>
        </Reveal>
        <article>
          {PaymentData.map((item: any, index: number) => {
            return (
              <figure key={index} id={item.title}>
                <div className="line" style={{ backgroundColor: item.color }}></div>
                <InnerTypography.Paragraph
                  fontSize={"2.5rem"}
                  fontWeight={700}
                  margin={"0 0 1rem 1rem"}
                  color={item.color}
                >
                  {item.title}
                </InnerTypography.Paragraph>
                <InnerTypography.Paragraph
                  fontSize={"0.875rem"}
                  margin={"0 0 0 1rem"}
                  lineHeight={"1.25rem"}
                  color="#969696"
                >
                  {item.description}
                </InnerTypography.Paragraph>
                {/* <Typography label={item.benefitTitle} weight={800} size={22} /> */}
                <Divider color="#F2F2F2" mt={26} mb={26} />
                <ul>
                  {item.benefit.map((child: any, index: number) => {
                    return (
                      <li key={index}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M9.9998 13.6L15.8998 7.69999C16.0831 7.51665 16.3165 7.42499 16.5998 7.42499C16.8831 7.42499 17.1165 7.51665 17.2998 7.69999C17.4831 7.88332 17.5748 8.11665 17.5748 8.39999C17.5748 8.68332 17.4831 8.91665 17.2998 9.09999L10.6998 15.7C10.4998 15.9 10.2665 16 9.9998 16C9.73314 16 9.4998 15.9 9.2998 15.7L6.6998 13.1C6.51647 12.9167 6.4248 12.6833 6.4248 12.4C6.4248 12.1167 6.51647 11.8833 6.6998 11.7C6.88314 11.5167 7.11647 11.425 7.3998 11.425C7.68314 11.425 7.91647 11.5167 8.0998 11.7L9.9998 13.6Z"
                            fill="#343434"
                          />
                        </svg>
                        <FontWeightMedium>{child}</FontWeightMedium>
                      </li>
                    );
                  })}
                  {item.title === "Lite" && (
                    <li>
                      <span style={{ color: "#969696", fontSize: 12 }}>
                        옵션 : 쇼츠 솔루션 통합 지원, 화이트 라벨
                      </span>
                    </li>
                  )}
                </ul>
                <button
                  className="gtm-trigger-contact-sales"
                  style={{
                    backgroundColor: item.color,
                    fontFamily: "Pretendard",
                    fontWeight: 800,
                  }}
                  onClick={() => navigate("/kr/payment-inquiry")}
                >
                  {item.title} 요금제 문의
                </button>
              </figure>
            );
          })}
        </article>
      </Mobile>
      <CommentWrap>
        <div>
          &bull;&nbsp;쇼츠 솔루션 전용 요금제 별도 이용이 가능합니다. 문의주시면 상세 요금제 안내
          드립니다.
        </div>
        <div>&bull;&nbsp;월 이용량은 TB 단위이며, 초과 시 추가 요금이 부과 됩니다.</div>
        <div>
          &bull;&nbsp;멀티 채널 스트리밍은 자사몰 라이브를 유튜브, 인스타 등 다른 플랫폼에 동시 송출
          할 수 있는 기능입니다. (최소 6개월 이상 계약 시 적용)
        </div>
      </CommentWrap>
    </PaymentWrap>
  );
};

const FontWeightMedium = styled.span`
  font-weight: 400;
`;
const CommentWrap = styled.div`
  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.225rem;
    letter-spacing: -0.01738rem;
    color: rgba(150, 150, 150, 1);

    ${media.mo} {
      font-size: 0.8125rem;
      line-height: 1.1375rem;
    }
  }

  padding-bottom: 2.25rem;
`;
export default Payment;
