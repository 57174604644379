import { useMediaQuery } from 'react-responsive'
import { HomeSection, HomeSectionInner, HomeWrap } from '../home/Home.style'
import { PolicyWrap } from './Policy.style'

const PolicyJp = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" })

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 100 : isTa ? 80 : 60} pb={isPc ? 100 : isTa ? 80 : 60}>
                <HomeSectionInner>
                    <PolicyWrap>
                        <h1>個人情報保護方針</h1>
                        <h3>
                            Grip Company Co.,Ltd(以下「当社」といいます。)は、Grip cloudサービス等の提供にあたって、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律(以下「個人情報保護法」といいます。)を遵守すると共に、以下のプライバシーポリシー(以下「本プライバシーポリシー」といいます。)に従い、適切な取扱い及び保護に努めます。
                        </h3>
                        <h2>1. 個人情報の範囲</h2>
                        <p>本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報をいうものとします。</p>
                        <h2>2. 個人情報の利用範囲</h2>
                        <div>
                            <p>当社は、お客様の個人情報を、以下の目的で利用致します。</p>
                            <ul>
                                <li>当社及び当社の関連会社の商品及びサービス(Grip cloudサービス及びこれに付帯するサービスを含む。)(以下「Grip cloudサービス等」といいます。)を提供し、継続し若しくは、改善(機能及び内容の追加等を含む。)するため。</li>
                                <li>当社及び当社の関連会社の商品及びサービス(Grip cloudサービス及びこれに付帯するサービスを含む。)に関する情報提供を行うため</li>
                                <li>お客様についての取引記録及び交渉経過の保存、契約書管理のため</li>
                                <li>お客様からのGrip cloudサービス等への意見、質問及び要望等への応対、案内、回答等のため</li>
                                <li>お客様、当社、当社関連会社又は第三者の正当な利益を保護するために関する利用規約、ポリシー等に違反する行為に対する対応のため</li>
                                <li>Grip cloudサービス等についての技術的なお知らせ、アップデート、セキュリティ警告及びサービス、並びに運営メッセージの送信のため</li>
                                <li>Grip cloudサービス等に関連する統計数値の把握、市場調査、マーケティングのため</li>
                                <li>Grip cloudサービス等の改善及び新たにサービスを研究し、開発し、または提供を行うため</li>
                                <li>Grip cloudサービス等に関する利用規約、ポリシー等の変更などを通知するため</li>
                                <li>お客様に当社、当社関連会社又は第三者の提供するサービスを、ダイレクトメール、メールマガジン等、その他当社が管理又は運用する媒体により、広告し、送付し、若しくは、表示等するため(但し、関係諸法令の認める内容と方法に限定されます。)</li>
                                <li>Grip cloudサービス等に関連して、個別を識別できない形式に加工した統計データを作成するため</li>
                                <li>Grip cloudサービスのお客様の利用状況等のお客様の属性分析を行い、訪問履歴や購入履歴の特定、注文配送、オンライン広告の配信、広告効果の測定等を広告配信事業者、クラウドコンピューティングシステム事業者に対して依頼するため</li>
                                <li>その他前各号に定める利用目的に付随する目的を達成するため</li>
                            </ul>
                        </div>
                        <h2>3. 個人情報利用目的の変更</h2>
                        <p>当社は、個人情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。</p>
                        <h2>4. 個人情報利用の制限</h2>
                        <div>
                            <p>当社は、個人情報保護法その他の法令により許容される場合を除き、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。</p>
                            <ul>
                                <li>法令に基づく場合</li>
                                <li>人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
                                <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
                                <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                                <li>お客様の同意を得たとき</li>
                            </ul>
                        </div>
                        <h2>5. 個人情報の適正な取得</h2>
                        <p>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。</p>
                        <h2>6. 個人情報の安全管理</h2>
                        <p>当社は、当社が取り扱う個人情報につき、漏えい、滅失又はき損を防止するため、組織的、人的、物理的及び技術的な安全管理措置を、継続的に講じるものとします。当社の講じる安全管理措置の詳細についてお知りになりたい場合には、12条の連絡先までご連絡下さい。</p>
                        <h2>7. 第三者提供</h2>
                        <div>
                            <p>当社は、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめお客様の同意を得ないで、個人情報を第三者に提供しません。但し、下記に定める場合は、当社は個人情報を第三者に提供することがあります。</p>
                            <ul>
                                <li>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
                                <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                                <li>個人情報保護法の定めに基づき共同利用する場合</li>
                            </ul>
                            <p>当社は、お客様から同意を得た場合または個人情報保護法その他関連法令で認められる場合を除き、個人情報を外国にある第三者に提供しません。但し、下記に定める場合は、当社は、個人情報を外国にある第三者に提供することがあります。</p>
                            <ol>
                                <li>個人の権利利益を保護する上で我が国と同等の水準にあると認められる個人情報の保護に関する制度を有している外国として規則で定める国にある第三者に提供する場合</li>
                                <li>個人情報の提供を受ける者との間で、当該提供を受ける者における当該個人情報の取扱いについて、適切かつ合理的な方法により、個人情報保護法の趣旨に沿った措置の実施が確保されている場合</li>
                                <li>個人情報の提供を受ける者が、個人情報の取扱いに係る国際的な枠組みに基づく認定を受けている場合</li>
                            </ol>
                            <p>また、上記にかかわらず、当社は、Grip cloudサービスの運営にあたり、お客様に本プライバシーポリシーに同意いただいた上で、日本のお客様の個人情報を主に以下のようなケースで外国にある委託先、子会社、関連会社などの第三者国に提供移転することがあります。当社は、当該第三者との間で、適切なデータ保護に関する事項を規定した契約を締結するなどし、当該第三者がOECDプライバシーガイドライン8原則に対応する措置を講じます。</p>
                            <h4>◇ アプリケーションの開発や運用</h4>
                            <div>
                                <p>Grip cloudサービスの開発・運用に関する業務のために、以下の国又は地域に所在する企業(グループ会社や当該企業の委託先等を含みます。)にお客様の個人情報を提供することがあります。</p>
                                <p>移転先 : 韓国</p>
                                <ul>
                                    <li>韓国の個人情報保護法制 : <a href='https://www.ppc.go.jp/files/pdf/korea_report.pdf' target='_blank' rel="noreferrer">https://www.ppc.go.jp/files/pdf/korea_report.pdf</a></li>
                                </ul>
                            </div>
                            <h4>◇ カスタマーサポート</h4>
                            <div>
                                <p>以下の国又は地域に所在する委託先企業(グループ会社や当該企業の委託先等を含みます。)から回答をさせていただく場合があります。</p>
                                <p>移転先 : 韓国</p>
                            </div>
                            <h4>◇ お客様の属性分析</h4>
                            <div>
                                <p>当社は、Grip cloudサービスの利用状況など、当社が保有するお客様の個人情報を利用して、訪問履歴や購入履歴の特定、注文配送、オンライン広告の配信、広告効果の測定をします。その一環として、当社は、お客様の個人情報を、広告配信事業者、クラウドコンピューティングシステム事業者等に提供する場合があります。</p>
                                <p>移転先 : 韓国、アメリカ合衆国</p>
                                <ul>
                                    <li>韓国の個人情報保護法制 : <a href='https://www.ppc.go.jp/files/pdf/korea_report.pdf' target='_blank' rel="noreferrer">https://www.ppc.go.jp/files/pdf/korea_report.pdf</a></li>
                                    <li>アメリカ合衆国の個人情報保護法制 : <a href='https://www.ppc.go.jp/files/pdf/USA_report.pdf' target='_blank' rel="noreferrer">https://www.ppc.go.jp/files/pdf/USA_report.pdf</a></li>
                                </ul>
                            </div>
                            <h4>◇ 日本のお客様の個人情報の保管場所</h4>
                            <div>
                                <p>当社は日本のお客様の個人情報を韓国のデータセンターで保管しています。</p>
                                <ul>
                                    <li>韓国の個人情報保護法制 : <a href='https://www.ppc.go.jp/files/pdf/korea_report.pdf' target='_blank' rel="noreferrer">https://www.ppc.go.jp/files/pdf/korea_report.pdf</a></li>
                                </ul>
                                <p>なお、適宜適切な事業者を選定して委託を行うべく、現時点では受託業者及びその所在国が特定できていないものの、上記以外の受託業者に対して個人情報の提供を行う場合があります。この場合、改めてお客様の同意を取得せず、新たな受託業者に対する委託を開始することがあります。</p>
                            </div>
                        </div>
                        <h2>8. 個人情報の開示・訂正・利用停止等</h2>
                        <p>当社は、お客様の個人情報について、個人情報保護法の定める範囲において、個人情報の開示(第三者提供記録の開示を含みます。)、訂正、利用停止等(以下「開示等」といいます。)を求めることができます。この場合、お客様は、当社の定めるところに従い、お客様が開示等を行う正当な権限を有することを証する書面等を提出する必要があります。開示等請求に当たっては、12条の連絡先までご連絡下さい。念のために付言すると、当社が開示等の義務を負うのは、個人情報保護法が当社に法的義務を課している場合のみとなります。</p>
                        <h2>9. Google Analyticsの利用に関する情報</h2>
                        <p>当社は、Grip cloudサービスの向上のため、Google社のGoogle Analyticsを利用してウェブサイトなどのGrip cloudサービスの計測を行っています。データ取得のためにGoogle社がCookieを設定し、または既存のCookieを読み取る場合があります。その際、Google社に対して、お客様がアクセスしたページのURLやIPアドレスなどの情報を自動的に送信します。当社は、それらの情報を、利用状況の把握やGrip cloudサービスなどに利用する場合があります。Google AnalyticsにおけるGoogle社によるデータの取扱いについては、Google Analyticsサービス利用規約およびGoogle社プライバシーポリシーをご確認ください。</p>
                        <h2>10. その他</h2>
                        <ul>
                            <li>お客様がインターネット上でGrip cloudサービスの内容を閲覧する場合において、当社は、お客様がインターネット上で閲覧又は検索を行うときにサーバーが収集又は自ら生成する下記に定める記録を保存します。</li>
                            <li>使用記録 : 当社は、お客様がGrip cloudサービスを通じてメッセージの送受信を行うとき、これらのメッセージ情報を収集し、その情報には時刻、日付、送信者、受信者等の情報が含まれます。当社は、お客様がGrip cloudサービスを利用する際の情報も収集し、その情報にはお客様のウェブブラウザの種類、言語、利用時間、IPアドレス等が含まれます。</li>
                            <li>デバイス情報 : 当社は、お客様がGrip cloudサービスを利用する際のデバイス情報を収集し、その情報にはデバイスの種類、使用するOS及びそのバージョン、デバイスID、電話番号、並びにモバイルネットワーク情報が含まれます。そのほか、Grip cloudサービスは、お客様がGrip cloudサービスを利用する際の機能を有効にサポートするため、お客様の同意を経て、お客様のデバイス内の連絡先及び写真・動画を保存するアプリケーションにアクセスできます。 </li>
                            <li>位置情報 : Grip cloudサービスは、お客様がGrip cloudサービスを利用する際の機能を有効にサポートするため、お客様の同意を経て、お客様のデバイスの位置情報を収集します。 </li>
                            <li>データトラッキング : 当社は、各種データトラッキング技術を使用して情報を収集し、Grip cloudサービスおよびユーザーエクスペリエンスの改善に役立てます。 </li>
                            <li>クッキーポリシー : Grip cloudサービスは、クッキーを通じてGrip cloudサービスのウェブサーバーにお客様のデバイス関連記録を識別させ、お客様がログイン状態の保持に同意した後又は毎回ログインした後にのみ、Grip cloudサービスがクッキーを通じてお客様個人に対応します。ほとんどのウェブブラウザではあらかじめクッキーを受け入れるように設定されていますが、クッキーをブロックすることもできます。お客様は、ウェブブラウザの設定において、「すべてのクッキーを受け入れる」、「クッキー設定時に通知を受け取る」、「すべてのクッキーをブロックする」等、ウェブブラウザのクッキーの受け入れの程度を自ら変更することができます。Grip cloudサービス又はその他有料サービスを利用するに当たり、すべての関連サービスを受けられるよう、クッキーを有効にすることをお勧めします。 </li>
                            <li>Grip cloudサービスとリンクしているウェブサイトもお客様の個人情報を取得する可能性があります。お客様が自発的にこれらのリンクするウェブサイトに提供した個人情報(第８条に定める範囲と同様のものを含みます。)について、これらのリンクするウェブサイトが定めるプライバシーポリシーが適用されるものとし、当社は、これらのリンクするウェブサイトに関連して、いかなる責任も負わないものとします。 </li>
                            <li>技術的な制約や悪意ある第三者の攻撃等のため、最善の安全措置を講じた場合においても、情報の安全性を完全には保証できないことをご理解ください。お客様がGrip cloudサービス等のウェブサイトやソフトウェアにアクセスするとき、当社には制御することのできない要因によって情報の安全性が脅かされる可能性があることをご理解ください。お客様は、定期的にパスワードを変更し、自身のアカウント・パスワード等の個人情報を他人に開示しない等、自らの個人情報の安全性を守る積極的な措置を講じてください。 </li>
                            <li>国際送信 : お客様は、当社が当社、当社関連会社又はその外部委託先の管理するデータ処理センター等の所在地において、お客様の個人情報を送信、利用及び処理できることに同意します。</li>
                        </ul>
                        <h2>11. 本プライバシーポリシーの改定</h2>
                        <p>当社は、合理的な裁量によって、本プライバシーポリシーを改定することがあります。</p>
                        <h2>12. 連絡先</h2>
                        <p>本プライバシーポリシーにかかわるご連絡は、下記にて、お願い致します。</p>
                        <p>Grip Company Co.,Ltd</p>
                        <p>大韓民国京畿道城南市盆唐区板橋駅路152、11階</p>
                        <p>jp.cloud@gripcorp.co</p>
                    </PolicyWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    )
}

export default PolicyJp