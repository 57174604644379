import { useState } from "react";
import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, EbookSectionWrap } from "./Ebook.style";
import Button from "../../common/component/Button";

const EbookJpSecond = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 60 : isTa ? 60 : 34} pb={isPc ? 1 : isTa ? 80 : 20}>
                <HomeSectionInner>
                    <EbookSectionWrap className="top second">
                        <div className="contents-box">
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="コーマスの常識を変える"
                                    size={isPc ? 60 : isTa ? 46 : 26}
                                    weight={700}
                                    lineHeight={isPc ? 72 : isTa ? 57 : 32}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="：10つのライブ"
                                    size={isPc ? 60 : isTa ? 46 : 26}
                                    weight={700}
                                    lineHeight={isPc ? 72 : isTa ? 57 : 32}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                            </Reveal>
                            <article>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeIn}
                                    duration={600}
                                    delay={200}
                                >
                                    <Typography
                                        label="ライブを通じてお客様と出会い"
                                        size={isPc ? 24 : isTa ? 16 : 14}
                                        lineHeight={isPc ? 33 : isTa ? 22 : 16}
                                        mb={4}
                                    />
                                    <Typography
                                        label="新しいビジネスのチャンスを作りだす"
                                        size={isPc ? 24 : isTa ? 16 : 14}
                                        lineHeight={isPc ? 33 : isTa ? 22 : 16}
                                        mb={4}
                                    />
                                    <Typography
                                        label="企業たちの10つのストーリー"
                                        size={isPc ? 24 : isTa ? 16 : 14}
                                        lineHeight={isPc ? 33 : isTa ? 22 : 15}
                                        mb={isPc ? 64 : isTa ? 46 : 28}
                                    />
                                </Reveal>
                                {(isPc || isTa) && (
                                    <Button
                                        className="cta-button"
                                        label={"無料ダウンロード"}
                                        style={{ width: 160, height: 48, zIndex: 1 }}
                                        onClick={() => {
                                            typeof window.gtag === "function" &&
                                                window.gtag("event", "free_download", {
                                                    event_category: "ebook_free_download",
                                                    event_label: "ebook_vol2",
                                                    lang: "jp",
                                                });

                                            window.location.href =
                                                "https://share.hsforms.com/15lZ0RJcFRkSKLWR1z1EjvAeg9vg";
                                        }}
                                    />
                                )}
                            </article>
                        </div>

                        <div className="section-img">
                            <img
                                src="/assets/images/ebook/bookjp-230620.png"
                                alt="コーマスの常識を変える：10つのライブE-Book表紙"
                            />
                        </div>
                        {isMo && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    className="cta-button"
                                    label={"無料ダウンロード"}
                                    style={{ width: 160, height: 48, zIndex: 1 }}
                                    onClick={() => {
                                        typeof window.gtag === "function" &&
                                            window.gtag("event", "free_download", {
                                                event_category: "ebook_free_download",
                                                event_label: "ebook_vol2",
                                                lang: "jp",
                                            });

                                        window.location.href =
                                            "https://share.hsforms.com/15lZ0RJcFRkSKLWR1z1EjvAeg9vg";
                                    }}
                                />
                            </div>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#E6EEFF"
                pt={isPc ? 110 : isTa ? 72 : 64}
                pb={isPc ? 140 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="center second">
                        {!isPc && (
                            <div>
                                <Typography
                                    label="このような方に"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="おすすめです。"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                />
                            </div>
                        )}
                        <div className="section-img">
                            <img
                                src="/assets/images/ebook/Illustrator-230620-1.png"
                                alt="ライブコマースを配信中の女性"
                            />
                        </div>
                        <div className="contents-box">
                            {isPc && (
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="このような方に"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="おすすめです。"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </Reveal>
                            )}
                            {isPc ? (
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            差別化された顧客経験を提供したい事業担当者
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            短期間で効果的に商品をプロモーションしたい担当者
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            商品の情報を立体的に伝えたい担当者
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            オンラインを通じてオフライン訪問を誘導したい担当者
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            リアルなライブコマース成功事例が気になる方
                                        </li>
                                    </Reveal>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        差別化された顧客経験を提供したい事業担当者
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        短期間で効果的に商品をプロモーションしたい担当者
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        商品の情報を立体的に伝えたい担当者
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        オンラインを通じてオフライン訪問を誘導したい担当者
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        リアルなライブコマース成功事例が気になる方
                                    </li>
                                </ul>
                            )}
                        </div>
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 130 : isTa ? 72 : 64} pb={isPc ? 130 : isTa ? 90 : 40}>
                <HomeSectionInner>
                    <EbookSectionWrap className="bottom">
                        <div className="contents-box">
                            {!isPc && (
                                <>
                                    <Typography
                                        label="このような内容を"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="ご覧いただけます。"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </>
                            )}
                            {isPc && (
                                <>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <Typography
                                            label="このような内容を"
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                            mb={isPc ? 14 : isTa ? 10 : 8}
                                        />
                                        <Typography
                                            label="ご覧いただけます。"
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        />
                                    </Reveal>
                                    <article>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeIn}
                                            duration={600}
                                            delay={200}
                                        >
                                            <Typography
                                                label="最近、世界的に注目を集めている販売方法があります。モバイルを通じて「ライブ」で商品を販売する「ライブコマース」です。「動画を活用する販売方法」といえば、テレビショッピングが代表的でしたが、技術発展のおかげでスマホから「誰でもいつでも動画を活用して販売できる」ようになりました。テキストや画像より一度に多くの情報を伝えられる動画のメリットにオンラインの利便性が加え、新しいコマースが誕生したのです。"
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="動画を活用したコマースは増え続けており、自社ECサイトで定期的にライブコマースを実施する企業も続々と登場しています。ライブコマースはどんな新しいチャンスを作っているのでしょうか？今回の Grip Insight では、ライブによる韓国Eコマース業界の変化をリードしてきたGrip companyの社員たちがライブコマース事例とその特徴を紹介します。"
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="この事例から貴社のブランドに合うライブについてのアイデアを得られるかもです。ライブというやり方でお客様との接点を増やい、新しいチャンスを作り出してください。"
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                        </Reveal>
                                    </article>
                                </>
                            )}
                        </div>
                        <div className="section-img">
                            <img
                                src="/assets/images/ebook/illustrator-230620-2.png"
                                alt="買い物かごを持っている女性"
                            />
                        </div>
                        {!isPc && (
                            <article>
                                <Typography
                                    label="最近、世界的に注目を集めている販売方法があります。モバイルを通じて「ライブ」で商品を販売する「ライブコマース」です。「動画を活用する販売方法」といえば、テレビショッピングが代表的でしたが、技術発展のおかげでスマホから「誰でもいつでも動画を活用して販売できる」ようになりました。テキストや画像より一度に多くの情報を伝えられる動画のメリットにオンラインの利便性が加え、新しいコマースが誕生したのです。"
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                <Typography
                                    label="動画を活用したコマースは増え続けており、自社ECサイトで定期的にライブコマースを実施する企業も続々と登場しています。ライブコマースはどんな新しいチャンスを作っているのでしょうか？今回の Grip Insight では、ライブによる韓国Eコマース業界の変化をリードしてきたGrip companyの社員たちがライブコマース事例とその特徴を紹介します。"
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                {!isMo && (
                                    <Typography
                                        label="この事例から貴社のブランドに合うライブについてのアイデアを得られるかもです。ライブというやり方でお客様との接点を増やい、新しいチャンスを作り出してください。"
                                        size={isPc ? 22 : 20}
                                        lineHeight={isPc ? 33 : 30}
                                        mb={24}
                                    />
                                )}
                                {open && isMo && (
                                    <Typography
                                        label="この事例から貴社のブランドに合うライブについてのアイデアを得られるかもです。ライブというやり方でお客様との接点を増やい、新しいチャンスを作り出してください。"
                                        size={14}
                                        lineHeight={22}
                                        mb={20}
                                    />
                                )}
                                {isMo && (
                                    <Button
                                        className="outlined"
                                        label={
                                            <>
                                                {open ? "内容を簡単に見る" : "内容を開いてみる"}
                                                <svg
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                    style={{
                                                        transform: !open
                                                            ? "rotate(0)"
                                                            : "rotate(180deg)",
                                                    }}
                                                >
                                                    <path
                                                        d="M2.6665 5.8335L7.99984 11.1668L13.3332 5.8335"
                                                        stroke="#363636"
                                                        strokeWidth="1.77778"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </>
                                        }
                                        onClick={handleOpen}
                                    />
                                )}
                            </article>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default EbookJpSecond;
