import styled from "styled-components"

const DividerWrap = styled.hr`
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
`

interface DividerProps {
    mt?: number
    mb?: number
    color?: string
    orientation?: 'horizontal' | 'vertical'
}

const Divider = (props: DividerProps) => {
    const { mt, mb, color, orientation = 'horizontal' } = props

    return (
        <DividerWrap style={{ marginTop: mt ? mt : 0, marginBottom: mb ? mb : 0, borderColor: color ? color : '#414141', borderWidth: orientation === 'horizontal' ? '0px 0px thin' : '0px thin 0px 0px' }} />
    )
}

export default Divider
