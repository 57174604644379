import React from 'react'
import styled, { keyframes } from "styled-components"

const ripple = keyframes`
    to {
        transform: rotate(1turn);
    }
`

export const FeatureCardWrap = styled.div`
    position: relative;
    background-color: #1F1F1F;
    border-radius: 24px;
    margin-right: 24px;
    overflow: hidden;
    > legend {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 70px;
        font-weight: 800;
        line-height: 1;
        color: #4F4F4F;
    }
    > div {
        height: 100%;
        padding: 130px 0 0 80px;
        position: relative;
        z-index: 2;
        .game-tab {
            width: max-content;
            height: 64px;
            padding: 4px;
            background-color: #343434;
            border-radius: 32px;
            display: flex;
            gap: 0 4px;
            margin-top: 30px;
            button {
                display: flex;
                gap: 0 6px;
                align-items: center;
                height: 56px;
                /* padding: 12px 16px; */
                border: 0;
                border-radius: 28px;
                background-color: transparent;
                cursor: pointer;
                transition: all 0.3s ease;
                img {
                    width: 32px;
                    height: 32px;
                }
                p {
                    color: #B3B3B3;
                }
                &:hover {
                    background-color: white;
                    p {
                        color: #3C3C3C;
                    }
                }
                &.active {
                    background-color: white;
                    p {
                        color: #3C3C3C;
                    }
                }
            }
        }
    }
    > aside {
        text-align: right;
        position: absolute;
        bottom: 30px;
        right: 40px;
        z-index: 10;
        @media (min-width:600px) and (max-width: 1199px) {
            bottom: 20px;
            right: 32px;
        }
        @media (max-width: 599px) {
            bottom: 12px;
            right: 12px;
        }
    }
    > article {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        .visual {
            width: 100%;
            height: 100%;
            &.alpha {
                position: relative;
                table {
                    position: absolute;
                    top: 130px;
                    right: 78px;
                    width: 540px;
                    background-color: #343434;
                    border-radius: 12px;
                    color: white;
                    line-height: 1;
                    thead {
                        tr {
                            th {
                                font-size: 30px;
                                font-weight: 800;
                                padding: 40px 0 12px;
                            }
                        }
                    }
                    tbody {
                        tr {
                            background-color: #4F4F4F;
                            &:first-of-type {
                                background-color: #6F9EFF;
                                td {
                                    font-size: 18px;
                                    font-weight: 700;
                                }
                            }
                            td {
                                height: 54px;
                                font-size: 16px;
                                font-weight: 500;
                                text-align: center;
                                vertical-align: middle;
                            }
                        }
                    }
                    tfoot {
                        tr {
                            td {
                                height: 48px;
                            }
                        }
                    }
                }
                .video {
                    position: absolute;
                    bottom: 100px;
                    right: 54px;
                    display: flex;
                    width: 146px;
                    height: 319px;
                    box-shadow: 0px 30px 30px 0px rgba(0,0,0,0.1);
                    border-radius: 12px;
                    overflow: hidden;
                    isolation: isolate;
                    background-repeat: no-repeat;
                    background-size: contain;
                    video {
                        width: 100%;
                    }
                }
                @media (min-width:600px) and (max-width: 1199px) {
                    table {
                        top: auto;
                        bottom: 80px;
                        right: 50px;
                        width: calc(100% - 100px);
                    }
                }
                @media (max-width: 599px) {
                    table {
                        position: relative;
                        top: auto;
                        right: auto;
                        width: calc(100% - 40px);
                        margin-top: 30px;
                        margin-left: 20px;
                        thead {
                            tr {
                                th {
                                    font-size: 18px;
                                    padding: 26px 0 10px;
                                }
                            }
                        }
                        tbody {
                            tr {
                                &:first-of-type {
                                    td {
                                        font-size: 12px;
                                        svg {
                                            width: 24px;
                                            height: 24px;
                                        }
                                    }
                                }
                                td {
                                    height: 36px;
                                    font-size: 10px;
                                    svg {
                                            width: 16px;
                                            height: 16px;
                                        }
                                }
                            }
                        }
                        tfoot {
                            tr {
                                td {
                                    height: 32px;
                                }
                            }
                        }
                    }
                }
            }
            &.beta {
                .video {
                    position: absolute;
                    top: 74px;
                    right: 160px;
                    display: flex;
                    width: 242px;
                    height: 529px;
                    border-radius: 12px;
                    overflow: hidden;
                    isolation: isolate;
                    background-repeat: no-repeat;
                    background-size: contain;
                    video {
                        width: 100%;
                    }
                    &.en {
                        height: 524px;
                    }
                }
                .circle {
                    position: absolute;
                    bottom: 80px;
                    right: 64px;
                    width: 140px;
                    height: 140px;
                    border-radius: 70px;
                    background-color: #568DFD;
                    box-shadow: 0px 57.4059px 22.5859px rgba(0, 0, 0, 0.01), 0px 31.9967px 19.7627px rgba(0, 0, 0, 0.05), 0px 14.1162px 14.1162px rgba(0, 0, 0, 0.09), 0px 3.76432px 7.52864px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    > div {
                        text-align: center;
                        > div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 8px;
                            margin-bottom: 4px;
                        }
                    }
                }
                @media (min-width:600px) and (max-width: 1199px) {
                    .video {
                        top: auto;
                        bottom: 50px;
                        left: 50%;
                        right: auto;
                        width: 183px;
                        height: 400px;
                        border-radius: 12px;
                        overflow: hidden;
                        isolation: isolate;
                        transform: translateX(-50%);
                        &.en {
                            height: 396px;
                        }
                    }
                }
                @media (max-width: 599px) {
                    .video {
                        top: 20px;
                        bottom: auto;
                        left: 50%;
                        right: auto;
                        width: 183px;
                        height: 400px;
                        border-radius: 12px;
                        overflow: hidden;
                        isolation: isolate;
                        transform: translateX(-50%);
                        &.en {
                            height: 396px;
                        }
                    }
                }
            }
            &.gamma {
                > div {
                    position: relative;
                    margin-top: 72px;
                    margin-left: auto;
                    margin-right: 24px;
                    width: 584px;
                    height: 530px;
                    .radar {
                        position: absolute;
                        top: 5px;
                        left: 44px;
                        width: 500px;
                        height: 500px;
                        background: url('/assets/images/radar_outer.svg') no-repeat;
                        /* img {
                            animation: ${ripple} 3s infinite linear;
                        } */
                    }
                    .video {
                        position: absolute;
                        top: 0;
                        left: 173px;
                        display: flex;
                        width: 242px;
                        height: 529px;
                        border-radius: 12px;
                        overflow: hidden;
                        isolation: isolate;
                        background-repeat: no-repeat;
                        background-size: contain;
                        video {
                            width: 100%;
                        }
                        &.en {
                            height: 524px;
                        }
                    }
                    .sns-twitter {
                        position: absolute;
                        top: 80px;
                        left: 16px;
                    }
                    .sns-youtube {
                        position: absolute;
                        top: 167px;
                        right: 0;
                    }
                    .sns-grip {
                        position: absolute;
                        top: 325px;
                        left: 0;
                    }
                }
                @media (min-width:600px) and (max-width: 1199px) {
                    > div {
                        margin-top: calc(100% - 320px);
                        margin-left: auto;
                        margin-right: auto;
                        width: 458px;
                        height: 396px;
                        .radar {
                            top: 9px;
                            left: 37px;
                            width: 378px;
                            height: 378px;
                            background-size: 378px 378px;
                            img {
                                width: 378px;
                                height: 378px;
                            }
                        }
                        .video {
                            top: 0;
                            left: 135px;
                            width: 183px;
                            height: 400px;
                            border-radius: 12px;
                            overflow: hidden;
                            isolation: isolate;
                            &.en {
                                height: 396px;
                            }
                        }
                        img {
                            width: 100px;
                            height: 100px;
                            &.sns-twitter {
                                top: 54px;
                                left: 0;
                            }
                            &.sns-youtube {
                                top: 128px;
                                right: 0;
                            }
                            &.sns-grip {
                                top: 250px;
                                left: 0;
                            }
                        }
                    }
                }
                @media (max-width: 599px) {
                    > div {
                        margin-top: 30px;
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                        max-width: 430px;
                        height: 398px;
                        .radar {
                            top: 35px;
                            left: 50%;
                            width: 300px;
                            height: 300px;
                            background-size: 300px 300px;
                            transform: translateX(-50%);
                            img {
                                width: 300px;
                                height: 300px;
                            }
                        }
                        .video {
                            top: 0;
                            left: 50%;
                            width: 183px;
                            height: 400px;
                            border-radius: 12px;
                            overflow: hidden;
                            isolation: isolate;
                            transform: translateX(-50%);
                            &.en {
                                height: 396px;
                            }
                        }
                        img {
                            width: 70px;
                            height: 70px;
                            &.sns-twitter {
                                top: 84px;
                                left: 20px;
                            }
                            &.sns-youtube {
                                top: 150px;
                                right: 20px;
                            }
                            &.sns-grip {
                                top: 230px;
                                left: 20px;
                            }
                        }
                    }
                }
            }
            &.delta {
                .dashboard {
                    height: 309px;
                    position: absolute;
                    top: 89px;
                    right: 66px;
                    aspect-ratio: 569/309;
                }
                .chart {
                    height: 181px;
                    position: absolute;
                    top: 250px;
                    right: 142px;
                    aspect-ratio: 541/181;
                }
                .list {
                    position: absolute;
                    bottom: 120px;
                    left: 80px;
                    width: 1000px;
                    background-color: #343434;
                    box-shadow: 0px 31px 31px rgba(0, 0, 0, 0.09);
                    border-radius: 8px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    > div {
                        display: flex;
                        align-items: center;
                        gap: 0 6px;
                        margin-top: 4px;
                        &:first-of-type {
                            margin-top: 12px;
                        }
                    }
                }
                @media (min-width:600px) and (max-width: 1199px) {
                    .dashboard {
                        height: 287px;
                        top: auto;
                        bottom: 170px;
                        right: 50%;
                        transform: translateX(50%);
                    }
                    .list {
                        bottom: 70px;
                        left: auto;
                        right: 34px;
                        width: max-content;
                        > div {
                            gap: 0 4px;
                            &:first-of-type {
                                margin-top: 10px;
                            }
                        }
                    }
                }
                @media (max-width: 599px) {
                    .dashboard {
                        height: 179px;
                        max-height: 250px;
                        top: auto;
                        bottom: 300px;
                        right: 50%;
                        transform: translateX(50%);
                        z-index: 2;
                    }
                    .list {
                        border-radius: 0;
                        padding: 80px 20px 120px;
                        bottom: 0;
                        left: auto;
                        right: 0;
                        width: 100%;
                        z-index: 1;
                        > div {
                            gap: 0 4px;
                            &:first-of-type {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
            &.epsilon {
                > div {
                    position: relative;
                    margin-top: 74px;
                    margin-left: auto;
                    margin-right: 50px;
                    width: 560px;
                    height: 529px;
                    .video {
                        position: absolute;
                        top: 0;
                        left: 159px;
                        display: flex;
                        width: 242px;
                        height: 529px;
                        border-radius: 12px;
                        overflow: hidden;
                        isolation: isolate;
                        background-repeat: no-repeat;
                        background-size: contain;
                        z-index: 5;
                        video {
                            width: 100%;
                        }
                        &.en {
                            height: 524px;
                        }
                    }
                    .bubble-grid {
                        display: grid;
                        grid-template-columns: 1fr minmax(180px, auto) 1fr;
                        position: relative;
                        z-index: 6;
                        > div {
                            display: flex;
                            flex-direction: column;
                            &:first-of-type {
                                align-items: flex-end;
                            }
                        }
                    }
                    .bubble {
                        display: flex;
                        width: max-content;
                        padding: 18px;
                        box-shadow: 0px 20px 20px 0px rgba(0,0,0,0.1);
                        &.bubble-01 {
                            margin-top: 130px;
                            background-color: #FFFFFF;
                            border-radius: 16px 16px 0px 16px;
                        }
                        &.bubble-02 {
                            margin-top: 170px;
                            background: #568DFD;
                            border-radius: 16px 16px 16px 0px;
                            p {
                                color: white;
                            }
                        }
                        &.bubble-03 {
                            margin-top: 70px;
                            background: #568DFD;
                            border-radius: 16px 16px 0px 16px;
                            p {
                                color: white;
                            }
                        }
                        &.bubble-04 {
                            margin-top: 120px;
                            background-color: #FFFFFF;
                            border-radius: 16px 16px 16px 0px;
                        }
                    }
                }
                @media (min-width:600px) and (max-width: 1199px) {
                    > div {
                        margin-top: calc(100% - 320px);
                        margin-left: auto;
                        margin-right: auto;
                        width: calc(100% - 60px);
                        height: 396px;
                        .video {
                            left: 50%;
                            width: 183px;
                            height: 400px;
                            border-radius: 12px;
                            overflow: hidden;
                            isolation: isolate;
                            transform: translateX(-50%);
                            &.en {
                                height: 396px;
                            }
                        }
                        .bubble-grid {
                            grid-template-columns: 1fr minmax(140px, auto) 1fr;
                        }
                        .bubble {
                            padding: 16px;
                            &.bubble-01 {
                                margin-top: 60px;
                            }
                            &.bubble-02 {
                                margin-top: 120px;
                            }
                            &.bubble-03 {
                                margin-top: 90px;
                            }
                            &.bubble-04 {
                                margin-top: 100px;
                            }
                        }
                    }
                }
                @media (max-width: 599px) {
                    > div {
                        margin-top: 30px;
                        margin-left: auto;
                        margin-right: auto;
                        width: calc(100% - 30px);
                        .video {
                            left: 50%;
                            width: 183px;
                            height: 400px;
                            border-radius: 12px;
                            overflow: hidden;
                            isolation: isolate;
                            transform: translateX(-50%);
                            &.en {
                                height: 396px;
                            }
                        }
                        .bubble-grid {
                            grid-template-columns: 1fr minmax(120px, auto) 1fr;
                        }
                        .bubble {
                            padding: 12px;
                            &.bubble-01 {
                                margin-top: 60px;
                                border-radius: 12px 12px 0px 12px;
                            }
                            &.bubble-02 {
                                margin-top: 120px;
                                border-radius: 12px 12px 12px 0px;
                            }
                            &.bubble-03 {
                                margin-top: 90px;
                                border-radius: 12px 12px 0px 12px;
                            }
                            &.bubble-04 {
                                margin-top: 100px;
                                border-radius: 12px 12px 12px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width:600px) and (max-width: 1199px) {
        > div {
            padding: 74px 0 0 50px;
        }
    }
    
    @media (max-width: 599px) {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        border-radius: 0;
        > div {
            height: auto;
            padding: 50px 0 0 20px;
        }
        > article {
            flex: 1;
            position: relative;
            width: 100%;
            height: auto;
        }
    }
`

export class GripCheck extends React.Component {
    render() {
        return (
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8333 21.5334L25.1749 12.1918C25.4652 11.9015 25.8347 11.7563 26.2833 11.7563C26.7319 11.7563 27.1013 11.9015 27.3916 12.1918C27.6819 12.482 27.827 12.8515 27.827 13.3001C27.827 13.7487 27.6819 14.1182 27.3916 14.4084L16.9416 24.8584C16.6249 25.1751 16.2555 25.3334 15.8333 25.3334C15.411 25.3334 15.0416 25.1751 14.7249 24.8584L10.6083 20.7418C10.318 20.4515 10.1729 20.082 10.1729 19.6334C10.1729 19.1848 10.318 18.8154 10.6083 18.5251C10.8985 18.2348 11.268 18.0897 11.7166 18.0897C12.1652 18.0897 12.5347 18.2348 12.8249 18.5251L15.8333 21.5334Z" fill="white" />
            </svg>
        )
    }
}
export class OtherCheck extends React.Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.9998 13.6L15.8998 7.70005C16.0831 7.51672 16.3165 7.42505 16.5998 7.42505C16.8831 7.42505 17.1165 7.51672 17.2998 7.70005C17.4831 7.88338 17.5748 8.11671 17.5748 8.40005C17.5748 8.68338 17.4831 8.91672 17.2998 9.10005L10.6998 15.7C10.4998 15.9 10.2665 16 9.9998 16C9.73314 16 9.4998 15.9 9.2998 15.7L6.6998 13.1C6.51647 12.9167 6.4248 12.6834 6.4248 12.4C6.4248 12.1167 6.51647 11.8834 6.6998 11.7C6.88314 11.5167 7.11647 11.425 7.3998 11.425C7.68314 11.425 7.91647 11.5167 8.0998 11.7L9.9998 13.6Z" fill="#568DFD" />
            </svg>
        )
    }
}
