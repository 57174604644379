import styled from "styled-components";

export const WebinarSectionWrap = styled.div`
    display: flex;
    gap: 0 100px;
    &.top {
        &.vol1 {
            .section-img {
                width: 100%;
                max-width: 1000px;
                margin-left: -10%;
                img {
                    width: 100%;
                }
            }
            .form-box {
                width: 100%;
                max-width: 600px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        &.vol2 {
            .col-1 {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                .text-container {
                    .text-title {
                        width: 100%;
                        text-align: center;

                        font-size: 3.5rem;
                        font-weight: 700;
                        line-height: 69.44px;

                        margin-bottom: 1.75rem;

                        span {
                            color: #9d70ff;
                        }
                    }
                    .text-description {
                        text-align: center;
                        margin-bottom: 1.75rem;
                        p:nth-child(1) {
                            color: #444444;

                            font-weight: 500;
                            font-size: 1.375rem;
                        }
                        .schedule {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 1.25rem;

                            font-size: 1.125rem;
                            font-weight: 400;
                            color: #626262;

                            > div {
                                display: inline-flex;
                                align-items: center;

                                &:first-of-type {
                                    margin-right: 0.75rem;
                                }
                            }
                        }
                    }
                }
            }

            .section-img {
                width: 40rem;
                height: 39.625rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .form-box {
                width: 100%;
                max-width: 600px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    &.session {
        flex-direction: column;
        text-align: center;
        .contents-box {
            width: 100%;
            ul {
                li {
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 31px;
                    background: rgba(255, 255, 255, 0.9);
                    border: 1px solid #ffffff;
                    border-radius: 12px;
                    padding: 31px 40px;
                    display: flex;
                    align-items: center;
                    gap: 0 16px;
                    > div {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        gap: 8px 0;
                        span {
                            font-size: 24px;
                            line-height: 29px;
                            color: #6196fc;
                        }
                        i {
                            font-style: normal;
                            font-weight: 400;
                        }
                    }
                    &:not(:first-of-type) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    &.center {
        .section-img {
            width: 100%;
            max-width: 526px;
            margin-top: auto;
            margin-bottom: auto;
            img {
                width: 100%;
            }
        }
        .contents-box {
            width: 100%;
            ul {
                margin-top: 40px;
                li {
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 31px;
                    background: #ffffff;
                    border: 1px solid #ffffff;
                    border-radius: 12px;
                    padding: 32px 24px;
                    display: flex;
                    align-items: center;
                    gap: 0 8px;
                    &:not(:first-of-type) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    &.bottom {
        flex-direction: column;
        .contents-box {
            display: flex;
            article {
                margin-top: 50px;
                max-width: 610px;
                p {
                    color: #3e3e3e;
                }
            }
            .section-img {
                max-width: 693px;
                margin-left: auto;
                img {
                    width: 100%;
                }
            }
        }

        &.vol2 {
            .contents-box {
                .section-img {
                    position: relative;
                    bottom: 5rem;
                    width: 29.375rem;
                    height: 32.25rem;
                    max-width: inherit;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    @media (min-width: 600px) and (max-width: 1199px) {
        flex-direction: column;
        gap: 0;
        &.top {
            &.vol1 {
                .section-img {
                    max-width: 568px;
                    margin-left: auto;
                    margin-right: auto;
                }
                .form-box {
                    padding: 30px 0 0;
                    margin: 0 auto;
                }
            }

            &.vol2 {
                .col-1 {
                    flex-flow: row nowrap;
                    align-items: flex-start;
                    justify-content: center;
                    .text-container {
                        .text-title {
                            font-size: 2.5rem;
                            text-align: start;

                            line-height: 124%;
                            margin-bottom: 1.5rem;
                        }
                        .text-description {
                            text-align: start;
                            margin-bottom: 0;
                            p:nth-child(1) {
                                font-weight: 500;
                                font-size: 1.0625rem;
                            }
                            .schedule {
                                flex-flow: column nowrap;
                                font-size: 1rem;
                                align-items: flex-start;
                                margin-top: 1.13rem;
                            }
                        }
                    }
                }
                .section-img {
                    margin-top: 3rem;
                    width: 25.125rem;
                    height: 24.875rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .form-box {
                    padding: 30px 0 0;
                    margin: 0 auto;
                }
            }
        }
        &.session {
            text-align: left;
            .contents-box {
                ul {
                    li {
                        padding: 35px 40px;
                    }
                }
            }
        }
        &.center {
            .section-img {
                margin: 50px auto;
                width: 70%;
            }
            .contents-box {
                padding-top: 0;
                ul {
                    li {
                        padding: 42px 24px;
                    }
                }
            }
        }
        &.bottom {
            .contents-box {
                padding-top: 0;
                flex-direction: column;
                article {
                    order: 2;
                    margin-top: 0;
                    max-width: 100%;
                }
                .section-img {
                    order: 1;
                    margin: 64px auto;
                    max-width: 600px;
                }
            }
            &.vol2 {
                .contents-box {
                    .section-img {
                        position: initial;
                        width: 24.125rem;
                        height: 26.5rem;
                        max-width: inherit;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 599px) {
        flex-direction: column;
        gap: 0;
        &.top {
            &.vol1 {
                .section-img {
                    width: 658px;
                    max-width: none;
                    transform: translateX(-50%);
                    margin-left: 50%;
                    margin-right: auto;
                }
                .form-box {
                    padding: 0;
                }
            }

            &.vol2 {
                .col-1 {
                    .text-container {
                        .text-title {
                            font-size: 2.5rem;
                            line-height: 124%;

                            margin-bottom: 1.25rem;
                        }
                        .text-description {
                            text-align: start;
                            margin-bottom: 0;
                            p:nth-child(1) {
                                font-weight: 500;
                                font-size: 1.125rem;
                            }
                            .schedule {
                                font-size: 1rem;
                                margin-top: 0.88rem;
                            }
                        }
                    }
                }
                .section-img {
                    margin-top: 2.25rem;
                    width: 21.25rem;
                    height: 21rem;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .form-box {
                    padding: 0;
                }
            }
        }
        &.session {
            text-align: left;
            .contents-box {
                ul {
                    li {
                        padding: 32px 16px;
                        font-size: 16px;
                        line-height: 19px;
                        gap: 0 8px;
                        img {
                            width: 48px;
                        }
                        > div {
                            span {
                                font-size: 16px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
        }
        &.center {
            .section-img {
                margin: 40px auto;
            }
            .contents-box {
                padding-top: 0;
                ul {
                    margin-top: 0;
                    li {
                        font-size: 13px;
                        line-height: 16px;
                        padding: 20px 12px;
                        gap: 0 6px;
                        &:not(:first-of-type) {
                            margin-top: 8px;
                        }
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
        &.bottom {
            .contents-box {
                padding-top: 0;
                flex-direction: column;
                article {
                    order: 2;
                    margin-top: 0;
                    max-width: 100%;
                }
                .section-img {
                    order: 1;
                    margin: 32px auto;
                    max-width: 600px;
                }
            }

            &.vol2 {
                .contents-box {
                    .section-img {
                        position: initial;
                        width: 12.1875rem;
                        height: 13.375rem;
                        max-width: inherit;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
`;

export const CheckIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
                d="M15.9 20.7L12.675 17.475C12.4 17.2 12.05 17.0625 11.625 17.0625C11.2 17.0625 10.85 17.2 10.575 17.475C10.3 17.75 10.1625 18.1 10.1625 18.525C10.1625 18.95 10.3 19.3 10.575 19.575L14.85 23.85C15.15 24.15 15.5 24.3 15.9 24.3C16.3 24.3 16.65 24.15 16.95 23.85L25.425 15.375C25.7 15.1 25.8375 14.75 25.8375 14.325C25.8375 13.9 25.7 13.55 25.425 13.275C25.15 13 24.8 12.8625 24.375 12.8625C23.95 12.8625 23.6 13 23.325 13.275L15.9 20.7ZM18 33C15.925 33 13.975 32.606 12.15 31.818C10.325 31.03 8.7375 29.9615 7.3875 28.6125C6.0375 27.2625 4.969 25.675 4.182 23.85C3.395 22.025 3.001 20.075 3 18C3 15.925 3.394 13.975 4.182 12.15C4.97 10.325 6.0385 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.969 12.15 4.182C13.975 3.395 15.925 3.001 18 3C20.075 3 22.025 3.394 23.85 4.182C25.675 4.97 27.2625 6.0385 28.6125 7.3875C29.9625 8.7375 31.0315 10.325 31.8195 12.15C32.6075 13.975 33.001 15.925 33 18C33 20.075 32.606 22.025 31.818 23.85C31.03 25.675 29.9615 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0315 23.85 31.8195C22.025 32.6075 20.075 33.001 18 33Z"
                fill="#568DFD"
            />
        </svg>
    );
};
