import styled from "styled-components"
import { Mobile, PC, Tablet } from '../component/MediaQuery'
import Typography from '../component/Typography'
import i18n from '../../locales'
import { Link, useNavigate } from 'react-router-dom'

const FooterWrap = styled.footer`
    min-width: 1200px;
    background-color: #FBFBFB;
    padding: 54px 40px 24px;
    > div {
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px 0;
        > article {
            display: flex;
            align-items: center;
            gap: 0 18px;
            > a {
                width: 48px;
                height: 48px;
                border-radius: 24px;
                background-color: white;
                filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
            }
        }
        > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px 0;
            div {
                display: flex;
                align-items: center;
                gap: 0 24px;
            }
        }
        > figure {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                color: #999999;
                font-size: 13px;
                &:hover {
                    color: #7A7A7A;
                }
            }
        }
        > aside {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0 16px;
            button {
                font-size: 13px;
                color: #7A7A7A;
                border: 0;
                background-color: transparent;
                padding: 0;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover {
                    color: #568DFD;
                }
            }
            span {
                font-size: 10px;
            }
        }
    }
    @media (min-width:600px) and (max-width: 1199px) {
        min-width: 600px;
        padding: 48px 30px 32px;
        > div {
            > section {
                div {
                    gap: 0 16px;
                }
            }
        }
    }
    
    @media (max-width: 599px) {
        min-width: 100%;
        padding: 48px 20px 80px;
        > div {
            > section {
                div {
                    gap: 0 16px;
                }
                div:not(:first-of-type) {
                    flex-direction: column;
                    gap: 8px 0;
                }
            }
        }
    }
`

interface FooterProps {
    onChangeLanguage: (value: string) => void
}

const Footer = (props: FooterProps) => {
    const { onChangeLanguage } = props
    const navigate = useNavigate()

    return (
        <FooterWrap>
            <div>
                <article>
                    <a href="https://www.youtube.com/channel/UCeBJYPGZjceVjLWKZ00ccPg" target='_blank' rel="noreferrer">
                        <img src="/assets/images/sns_youtube.svg" alt="youtube" />
                    </a>
                    <a href="https://twitter.com/GripJp" target='_blank' rel="noreferrer">
                        <img src="/assets/images/sns_twitter.svg" alt="twitter" />
                    </a>
                    <a href="https://www.linkedin.com/showcase/grip-cloud-japan/" target='_blank' rel="noreferrer">
                        <img src="/assets/images/sns_linkedin.svg" alt="linkedin" />
                    </a>
                </article>
                <section>
                    <PC>
                        <div>
                            <Typography label='Grip Company Co.,Ltd' size={13} color='#7A7A7A' />
                            <Typography label='代表取締役 : キム・ハンナ' size={13} color='#7A7A7A' />
                            <Typography label='事業者登録番号 : 239-87-01063' size={13} color='#7A7A7A' />
                            <Typography label='通信販売業申告番号 : 2022-城南盆唐A-0605' size={13} color='#7A7A7A' />
                        </div>
                        <div>
                            <Typography label='住所 : 京畿道城南市盆唐区板橋駅路152、11階' size={13} color='#7A7A7A' />
                            <Typography label='代表番号 : +82-70-5055-2133' size={13} color='#7A7A7A' />
                            <Typography label='サービスに関するご連絡 : jp.cloud@gripcorp.co' size={13} color='#7A7A7A' />
                        </div>
                    </PC>
                    <Tablet>
                        <div>
                            <Typography label='Grip Company Co.,Ltd' size={13} color='#7A7A7A' />
                            <Typography label='代表取締役 : キム・ハンナ' size={13} color='#7A7A7A' />
                            <Typography label='事業者登録番号 : 239-87-01063' size={13} color='#7A7A7A' />
                        </div>
                        <div>
                            <Typography label='通信販売業申告番号 : 2022-城南盆唐A-0605' size={13} color='#7A7A7A' />
                            <Typography label='住所 : 京畿道城南市盆唐区板橋駅路152、11階' size={13} color='#7A7A7A' />
                        </div>
                        <div>
                            <Typography label='代表番号 : +82-70-5055-2133' size={13} color='#7A7A7A' />
                            <Typography label='サービスに関するご連絡 : jp.cloud@gripcorp.co' size={13} color='#7A7A7A' />
                        </div>
                    </Tablet>
                    <Mobile>
                        <div>
                            <Typography label='Grip Company Co.,Ltd' size={13} color='#7A7A7A' />
                            <Typography label='代表取締役 : キム・ハンナ' size={13} color='#7A7A7A' />
                        </div>
                        <div>
                            <Typography label='事業者登録番号 : 239-87-01063' size={13} color='#7A7A7A' />
                            <Typography label='通信販売業申告番号 : 2022-城南盆唐A-0605' size={13} color='#7A7A7A' />
                            <Typography label='住所 : 京畿道城南市盆唐区板橋駅路152、11階' size={13} color='#7A7A7A' />
                            <Typography label='代表番号 : +82-70-5055-2133' size={13} color='#7A7A7A' />
                            <Typography label='サービスに関するご連絡 : jp.cloud@gripcorp.co' size={13} color='#7A7A7A' />
                        </div>
                    </Mobile>
                </section>
                <figure>
                    {/* <Link to='https://app.catchsecu.com/document/P/e2ba3fe4cc6c329' target='_blank'>個人情報保護方針</Link> */}
                    <Link to='https://hubspot.gripcloud.show/%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E4%BF%9D%E8%AD%B7%E6%96%B9%E9%87%9D' target='_blank'>個人情報保護方針</Link>
                </figure>
                <Typography label='© 2018 GripCompany' size={13} color='#7A7A7A' />
                <aside>
                    <button
                        onClick={() => {
                            onChangeLanguage('ko')
                            i18n.changeLanguage('ko')
                            navigate('kr')
                        }}
                    >
                        한국어
                    </button>
                    <span>|</span>
                    <button
                        onClick={() => {
                            onChangeLanguage('en')
                            i18n.changeLanguage('en')
                            navigate('en')
                        }}
                    >
                        English
                    </button>
                    <span>|</span>
                    <button
                        onClick={() => {
                            onChangeLanguage('ja')
                            i18n.changeLanguage('ja')
                            navigate('jp')
                        }}
                    >
                        日本語
                    </button>
                </aside>
            </div>
        </FooterWrap>
    )
}

export default Footer
