import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, WebinarSectionWrap } from "./Webinar.style";
// import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import InquiryForm from "../../common/component/InquiryForm";
import { Mobile, PC, Tablet } from "../../common/component/MediaQuery";

const Webinar = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 120 : isTa ? 60 : 34} pb={isPc ? 90 : isTa ? 80 : 40}>
                <HomeSectionInner>
                    <WebinarSectionWrap className="top vol1">
                        <div className="section-img">
                            <img src="/assets/images/webinar/top.png" alt="webinar" />
                        </div>
                        <div className="form-box">
                            {isPc && (
                                <Typography
                                    label="아래 양식을 작성하시면 웨비나 신청이 완료됩니다."
                                    size={26}
                                    weight={600}
                                    mb={32}
                                />
                            )}
                            <InquiryForm
                                language="ko"
                                formId="626bed0c-bd4e-477f-a6bd-6af8c2ce540f"
                            />
                        </div>
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#E2ECFF"
                pt={isPc ? 160 : isTa ? 98 : 72}
                pb={isPc ? 90 : isTa ? 142 : 50}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="session">
                        {isPc ? (
                            <>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label={<>그립 클라우드 웨비나 세션을 소개합니다.</>}
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        mb={74}
                                    />
                                </Reveal>
                                <div className="contents-box">
                                    <ul>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeUp}
                                            duration={600}
                                            delay={100}
                                            cascade
                                            damping={0.25}
                                        >
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 1</span>
                                                    미디어 커머스의 현 주소: 시장 현황과 전망
                                                </div>
                                            </li>
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 2</span>
                                                    <p>
                                                        미디어 커머스로 이룬 D2C 성공 사례:{" "}
                                                        <i>올리브영, SSG.COM, BGF리테일 등</i>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 3</span>
                                                    그립 클라우드로 미디어 커머스 확장하는 법
                                                </div>
                                            </li>
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 4</span>
                                                    Q&A
                                                </div>
                                            </li>
                                        </Reveal>
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <Typography
                                    label={
                                        <>
                                            그립 클라우드 <br />
                                            웨비나 세션을 소개합니다.
                                        </>
                                    }
                                    size={isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isTa ? 57 : 38}
                                    mb={isTa ? 56 : 24}
                                />
                                <div className="contents-box">
                                    <ul>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 1</span>
                                                미디어 커머스의 현 주소: <br className="mo" />
                                                시장 현황과 전망
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 2</span>
                                                <p>
                                                    미디어 커머스로 이룬 D2C 성공 사례:
                                                    <br />
                                                    <i>올리브영, SSG.COM, BGF리테일 등</i>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 3</span>
                                                그립 클라우드로 미디어 커머스 확장하는 법
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 4</span>
                                                Q&A
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#6196FC"
                pt={isPc ? 180 : isTa ? 72 : 64}
                pb={isPc ? 180 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="center">
                        {isMo && (
                            <>
                                <Typography
                                    label="이런 분들께"
                                    size={32}
                                    weight={700}
                                    lineHeight={38}
                                    mb={10}
                                    color="white"
                                />
                                <Typography
                                    label="웨비나를 추천합니다."
                                    size={32}
                                    weight={700}
                                    lineHeight={38}
                                    color="white"
                                />
                            </>
                        )}
                        <div className="section-img">
                            <img src="/assets/images/webinar/center.png" alt="" />
                        </div>
                        {isPc ? (
                            <div className="contents-box">
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="이런 분들께"
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        mb={10}
                                        color="white"
                                    />
                                    <Typography
                                        label="웨비나를 추천합니다."
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        color="white"
                                    />
                                </Reveal>
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li>
                                            <CheckIcon />
                                            자사몰을 가지고 있는 기업 담당자 누구나
                                        </li>
                                        <li>
                                            <CheckIcon />
                                            미디어 커머스로 로켓 성장을 이루고 싶은 담당자
                                        </li>
                                        <li>
                                            <CheckIcon />
                                            D2C 전략을 고민하고 있는 담당자
                                        </li>
                                        <li>
                                            <CheckIcon />
                                            미디어 커머스의 전망이 궁금한 누구나
                                        </li>
                                    </Reveal>
                                </ul>
                            </div>
                        ) : (
                            <div className="contents-box">
                                {!isMo && (
                                    <>
                                        <Typography
                                            label="이런 분들께"
                                            size={isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isTa ? 57 : 38}
                                            mb={10}
                                            color="white"
                                        />
                                        <Typography
                                            label="웨비나를 추천합니다."
                                            size={isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isTa ? 57 : 38}
                                            color="white"
                                        />
                                    </>
                                )}
                                <ul>
                                    <li>
                                        <CheckIcon />
                                        자사몰을 가지고 있는 기업 담당자 누구나
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        미디어 커머스로 로켓 성장을 이루고 싶은 담당자
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        D2C 전략을 고민하고 있는 담당자
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        미디어 커머스의 전망이 궁금한 누구나
                                    </li>
                                </ul>
                            </div>
                        )}
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#F8F8F8"
                pt={isPc ? 200 : isTa ? 120 : 64}
                pb={isPc ? 150 : isTa ? 80 : 100}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="bottom">
                        <PC>
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="고객의 인게이지먼트가 올라가면"
                                    size={64}
                                    weight={700}
                                    lineHeight={76}
                                    mb={14}
                                />
                                <Typography
                                    label="매출은 증가할 수밖에 없습니다."
                                    size={64}
                                    weight={700}
                                    lineHeight={76}
                                    mb={14}
                                />
                            </Reveal>
                        </PC>
                        <Tablet>
                            <Typography
                                label="고객의 인게이지먼트가 올라가면"
                                size={48}
                                weight={700}
                                lineHeight={57}
                                mb={10}
                            />
                            <Typography
                                label="매출은 증가할 수밖에 없습니다."
                                size={48}
                                weight={700}
                                lineHeight={57}
                                mb={10}
                            />
                        </Tablet>
                        <Mobile>
                            <Typography
                                label="고객의 인게이지먼트가"
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                            <Typography
                                label="올라가면 매출은"
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                            <Typography
                                label="증가할 수밖에 없습니다."
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                        </Mobile>
                        <div className="contents-box">
                            {isPc ? (
                                <article>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeIn}
                                        duration={600}
                                        delay={200}
                                    >
                                        <Typography
                                            label="이제 기업에게 미디어 커머스는 단순히 마케팅 채널 중 하나가 아닙니다. 고객의 인게이지먼트를 올리고 폭넓은 브랜드 경험을 심어줄 수 있는 무기입니다."
                                            size={22}
                                            lineHeight={35}
                                            mb={8}
                                        />
                                        <Typography
                                            label="기업은 고객 개개인의 라이프스타일을 반영하는 마케팅 전략을 구사해야 합니다. 올리브영, SSG.COM과 같은 업계 선두 기업들이 어떤 전략으로 고객의 인게이지먼트를 올리고 폭발적인 매출 성장도 이끌어냈는지 자세하게 들려드리겠습니다."
                                            size={22}
                                            lineHeight={35}
                                        />
                                    </Reveal>
                                </article>
                            ) : (
                                <article>
                                    <Typography
                                        label="이제 기업에게 미디어 커머스는 단순히 마케팅 채널 중 하나가 아닙니다. 고객의 인게이지먼트를 올리고 폭넓은 브랜드 경험을 심어줄 수 있는 무기입니다."
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                        mb={isTa ? 24 : 16}
                                    />
                                    <Typography
                                        label="기업은 고객 개개인의 라이프스타일을 반영하는 마케팅 전략을 구사해야 합니다. 올리브영, SSG.COM과 같은 업계 선두 기업들이 어떤 전략으로 고객의 인게이지먼트를 올리고 폭발적인 매출 성장도 이끌어냈는지 자세하게 들려드리겠습니다."
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                    />
                                </article>
                            )}
                            <div className="section-img">
                                <img src="/assets/images/webinar/bottom.png" alt="" />
                            </div>
                        </div>
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default Webinar;
