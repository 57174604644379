import { useState, useRef } from "react";
import Reveal from "react-awesome-reveal";
import { Trans } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { ServiceCard } from "../../../pages/home/Home.data";
import { HomeSectionInner, fadeUp, HomeSection } from "../../../pages/home/Home.style";
import { Mobile } from "../MediaQuery";
import Typography from "../Typography";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

const ServiceSectionWrap = styled.div`
  > article {
    text-align: center;
    margin-bottom: 56px;
  }
  > div {
    width: fit-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 360px));
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    figure {
      aspect-ratio: 1/1;
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    > article {
      margin-bottom: 40px;
    }
    > div {
      grid-template-columns: repeat(2, minmax(100px, 360px));
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      figure {
        height: 100%;
        padding: 0 16px 24px;
        aspect-ratio: auto;
        background-color: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: all 0.3s ease;
        &:hover {
          transform: scale(1.05);
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  @media (max-width: 599px) {
    > article {
      margin-bottom: 32px;
      text-align: left;
    }
    > div {
      width: 100%;
      display: block;
      margin: 0;
      figure {
        aspect-ratio: auto;
        height: 220px;
        margin-right: 20px;
        padding: 0 10px;
        img {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
`;

const ServiceSection = () => {
  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const serviceSlideEl = useRef<any>(null);
  const [serviceCurrentSlide, setServiceCurrentSlide] = useState<number>(0);

  const next = () => {
    if (serviceSlideEl.current) {
      serviceSlideEl.current.slickNext();
    }
  };
  const previous = () => {
    if (serviceSlideEl.current) {
      serviceSlideEl.current.slickPrev();
    }
  };

  const serviceSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    autoplay: false,
    arrows: false,
    beforeChange: (current: any, next: number) => {
      setServiceCurrentSlide(next);
    },
    appendDots: (dots: any) => (
      <div>
        <ul className="light">{dots}</ul>
        <button className="slide-button" onClick={previous}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M12 3L6 9L12 15" stroke="#363636" strokeWidth="2" />
          </svg>
        </button>
        <button className="slide-button" onClick={next}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M6 3L12 9L6 15" stroke="#363636" strokeWidth="2" />
          </svg>
        </button>
      </div>
    ),
    customPaging: (index: any) => (
      <button
        style={{
          backgroundColor: serviceCurrentSlide >= index ? "#568DFD" : undefined,
          borderTopRightRadius: serviceCurrentSlide === index ? "3px" : 0,
          borderBottomRightRadius: serviceCurrentSlide === index ? "3px" : 0,
        }}
      />
    ),
  };

  return (
    <HomeSection bg="#F3F3F3" pt={isPc ? 140 : isTa ? 120 : 150} pb={isMo ? 172 : 120}>
      <HomeSectionInner>
        <ServiceSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography
                  label={
                    <Trans i18nKey={"main:service.title"}>
                      service.title
                      <br className="none-pc" />
                    </Trans>
                  }
                  size={isPc ? 80 : 64}
                  lineHeight={isPc ? 80 : 76}
                  weight={800}
                  mb={16}
                />
                <Typography
                  label={
                    <Trans i18nKey={"main:service.desc"}>
                      service.desc
                      <br className="none-pc" />
                    </Trans>
                  }
                  size={24}
                  lineHeight={36}
                />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography
                label={
                  <Trans i18nKey={"main:service.title"}>
                    service.title
                    <br />
                  </Trans>
                }
                size={34}
                lineHeight={40}
                weight={800}
                mb={16}
              />
              <Typography
                label={
                  <Trans i18nKey={"main:service.desc"}>
                    service.desc
                    <br />
                  </Trans>
                }
                size={16}
                lineHeight={24}
              />
            </article>
          )}
          <div>
            {!isMo && (
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={400}
                delay={1000}
                cascade
                damping={0.25}
              >
                {ServiceCard.map((item: any, i: number) => {
                  return (
                    <figure key={i}>
                      <img src={item.icon} alt="" />
                      <Typography
                        label={
                          <Trans i18nKey={item.title}>
                            {item.title}
                            <br />
                            <br />
                          </Trans>
                        }
                        size={24}
                        weight={700}
                        lineHeight={34}
                        mb={16}
                      />
                      <Typography
                        label={
                          <Trans i18nKey={item.description}>
                            {item.description}
                            <br className="none-mo" />
                            <br className="none-mo" />
                          </Trans>
                        }
                        size={16}
                        lineHeight={24}
                        color="#969696"
                      />
                    </figure>
                  );
                })}
              </Reveal>
            )}
            <Mobile>
              <Slider ref={serviceSlideEl} {...serviceSettings}>
                {ServiceCard.map((item: any, i: number) => {
                  return (
                    <div key={i} style={{ width: 200 }}>
                      <figure>
                        <img src={item.icon} alt="" />
                        <Typography
                          label={
                            <Trans i18nKey={item.title}>
                              {item.title}
                              <br />
                              <br />
                            </Trans>
                          }
                          size={14}
                          weight={700}
                          lineHeight={20}
                          mb={10}
                        />
                        <Typography
                          label={
                            <Trans i18nKey={item.description}>
                              {item.description}
                              <br className="none-mo" />
                              <br className="none-mo" />
                            </Trans>
                          }
                          size={11}
                          lineHeight={16}
                          color="#969696"
                        />
                      </figure>
                    </div>
                  );
                })}
              </Slider>
            </Mobile>
          </div>
        </ServiceSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default ServiceSection;
