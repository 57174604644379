import styled from 'styled-components'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import FooterKr from '../layout/FooterKr'
import FooterEn from '../layout/FooterEn'

const DefaultContainerWrap = styled.div`
    width: 100vw;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    background-color: white;
    > main {
        flex-grow: 1;
        height: calc(100% - 80px);
        margin-top: 80px;
    }
    @media (min-width:600px) and (max-width: 1199px) {
        min-width: 100%;
        > main {
            height: calc(100% - 60px);
            margin-top: 60px;
        }
    }
    @media (max-width: 599px) {
        min-width: 100%;
        > main {
            height: calc(100% - 60px);
            margin-top: 60px;
        }
    }
`

interface DefaultContainerProps {
    children: React.ReactNode
    language: string
    onChangeLanguage: (value: string) => void
}

const DefaultContainer = (props: DefaultContainerProps) => {
    const { children, language, onChangeLanguage } = props

    return (
        <DefaultContainerWrap>
            <Header language={language} onChangeLanguage={onChangeLanguage} />
            <main>
                {children}
            </main>
            {language === 'ko' ?
                <FooterKr onChangeLanguage={onChangeLanguage} />
                :
                language === 'ja' ?
                    <Footer onChangeLanguage={onChangeLanguage} />
                    :
                    <FooterEn onChangeLanguage={onChangeLanguage} />
            }
        </DefaultContainerWrap>
    )
}

export default DefaultContainer
