import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import NotFound from "./common/component/NotFound";
import DefaultContainer from "./common/container/DefaultContainer";
import { GlobalStyle } from "./GlobalStyle";
import i18n from "./locales";
import Home from "./pages/home/Home";
import Content from "./pages/contents/Content";
import HomeKr from "./pages/home/HomeKr";
import Case from "./pages/case/Case";
import Guide from "./pages/faq/faq";
import Payment from "./pages/payment/Payment";
import PaymentInquiry from "./pages/payment/PaymentInquiry";
import Inquiry from "./common/component/Inquiry";
import Ebook from "./pages/ebook/Ebook";
import { Toaster } from "react-hot-toast";
import Webinar from "./pages/\bwebinar/Webinar";
import WebinarVol2 from "./pages/\bwebinar/Webinar.vol2";
import WebinarJp from "./pages/\bwebinar/WebinarJp";
import EbookJp from "./pages/ebook/EbookJp";
import PolicyJp from "./pages/policy/PolicyJp";
import EbookSecond from "./pages/ebook/Ebook.second";
import EbookJpSecond from "./pages/ebook/EbookJp.second";
import EbookVol3 from "./pages/ebook/Ebook.vol3";
import EbookVol4 from "./pages/ebook/Ebook.vol4";

function App() {
    const [language, setLanguage] = useState<string>("");
    const supportLanguage = ["/", "/kr", "/en", "/jp"];
    const reloadHubspot = (value: string) => {
        const langs: { [key: string]: string } = {
            ja: "jp",
            en: "en",
            ko: "kr",
        };

        window.location.href = `/${langs[value]}`;
    };
    const onChangeLanguage = (value: string) => {
        if (value) {
            setLanguage(value);
            reloadHubspot(value);
        }
    };

    useEffect(() => {
        const locale = window.location.pathname.slice(1).split("/")[0];
        i18n.changeLanguage(
            locale === "" || locale === "kr" ? "ko" : locale === "jp" ? "ja" : "en"
        ).then(() => {
            setLanguage(i18n.language);
        });
    }, [language]);

    // useEffect(() => {
    //     const onMessage = (event: MessageEvent) => {
    //         if (
    //             event.data.type === "hsFormCallback" &&
    //             event.data.eventName === "onFormSubmitted"
    //         ) {
    //             debugger;
    //             const closeNode = document.querySelector("#btnModalClose");
    //             // 현재 열려진 modal close
    //             if (closeNode instanceof HTMLButtonElement) {
    //                 closeNode.click();
    //             }
    //         }
    //     };
    //     window.addEventListener("message", onMessage);

    //     return () => window.removeEventListener("message", onMessage);
    // }, []);

    return (
        <>
            <GlobalStyle />
            {/* <MetaTag language={language} /> */}
            <Toaster />
            <DefaultContainer language={language} onChangeLanguage={onChangeLanguage}>
                <Routes>
                    {supportLanguage.map((route) => (
                        <Route
                            key={route}
                            path={route}
                            element={
                                route === "/" || route === "/kr" ? (
                                    <HomeKr language="ko" />
                                ) : route === "/jp" ? (
                                    <Home language="ja" />
                                ) : (
                                    <Home language="en" />
                                )
                            }
                        />
                    ))}
                    <Route path="/jp/contents/:contentId" element={<Content language="ja" />} />

                    <Route
                        path="/jp/contents/ebook/Qoo10-live-commerces-success-case"
                        element={<EbookJp />}
                    />
                    <Route path="/jp/ebook/livecommerce-10cases" element={<EbookJpSecond />} />

                    <Route
                        path="/jp/contents/webinar/live-commerces-success-case-vol1"
                        element={<WebinarJp />}
                    />
                    <Route path="/jp/policy" element={<PolicyJp />} />
                    <Route path="/en/contents/:contentId" element={<Content language="en" />} />
                    <Route path="/inquiry" element={<Inquiry language={language} />} />
                    <Route path="/kr/case" element={<Case />} />
                    <Route path="/kr/payment" element={<Payment />} />
                    <Route path="/kr/payment-inquiry" element={<PaymentInquiry />} />
                    <Route path="/kr/faq" element={<Guide />} />

                    <Route
                        path="/kr/contents/ebook/230417"
                        element={<Navigate to="/kr/ebook/qoo10-livecommerce" replace />}
                    />

                    <Route path="/kr/ebook/qoo10-livecommerce" element={<Ebook />} />
                    <Route path="/kr/ebook/livecommerce-10cases" element={<EbookSecond />} />
                    <Route path="/kr/ebook/reoffline" element={<EbookVol3 />} />
                    <Route path="/kr/ebook/liveshopping" element={<EbookVol4 />} />

                    <Route path="/kr/contents/webinar/230426" element={<Webinar />} />
                    <Route path="/kr/contents/webinar/230921" element={<WebinarVol2 />} />
                    <Route path="*" element={<NotFound onChangeLanguage={onChangeLanguage} />} />
                </Routes>
            </DefaultContainer>
        </>
    );
}

export default App;
