import i18n, { Resource } from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as en from "./en"
import * as ko from "./ko"
import * as jp from "./jp"

const resources: Resource = {
    en: {
        ...en
    },
    ko: {
        ...ko
    },
    ja: {
        ...jp
    }
} as const

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next).init({
        detection: { order: ["path", "navigator"] },
        resources,
        fallbackLng: 'ko',
        debug: false,
        keySeparator: ".",
        react: {
            useSuspense: false
        }
    })

export default i18n
