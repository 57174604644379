import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, EbookSectionWrap } from "./Ebook.style";
import Button from "../../common/component/Button";

const EbookSecond = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    return (
        <HomeWrap>
            <HomeSection
                className="vol4"
                pt={isPc ? 60 : isTa ? 60 : 34}
                pb={isPc ? 1 : isTa ? 80 : 20}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="top vol4">
                        <div className="contents-box vol4">
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="상세페이지 중심의"
                                    size={isPc ? 64 : isTa ? 46 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="쇼핑은 이제 그만"
                                    size={isPc ? 64 : isTa ? 46 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 24 : isTa ? 16 : 8}
                                />
                            </Reveal>
                            <article>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeIn}
                                    duration={600}
                                    delay={200}
                                >
                                    <Typography
                                        label="직접 물어보고 보여주는 라이브 쇼핑의 시대"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={4}
                                    />
                                    <Typography
                                        label="소비자를 사로잡은 커머스 성공 사례"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={isPc ? 72 : isTa ? 54 : 36}
                                    />
                                </Reveal>
                                {(isPc || isTa) && (
                                    <Button
                                        className="cta-button"
                                        label={"무료 다운로드"}
                                        style={{ width: 150, height: 48, zIndex: 1 }}
                                        onClick={() => {
                                            typeof window.gtag === "function" &&
                                                window.gtag("event", "free_download", {
                                                    event_category: "ebook_free_download",
                                                    event_label: "liveshopping",
                                                    lang: "kr",
                                                });

                                            window.location.href =
                                                "https://share.hsforms.com/1rC0To245TReMu2eIAeazDweg9vg";
                                        }}
                                    />
                                )}
                            </article>
                        </div>
                        <div className="section-img vol4">
                            <img
                                className="vol4"
                                src="/assets/images/ebook/Ebook-vol4.png"
                                alt="Ebook vol4"
                            />
                        </div>
                        {isMo && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    className="cta-button"
                                    label={"무료 다운로드"}
                                    style={{ width: 150, height: 48, zIndex: 1 }}
                                    onClick={() => {
                                        typeof window.gtag === "function" &&
                                            window.gtag("event", "free_download", {
                                                event_category: "ebook_free_download",
                                                event_label: "liveshopping",
                                                lang: "kr",
                                            });

                                        window.location.href =
                                            "https://share.hsforms.com/1rC0To245TReMu2eIAeazDweg9vg";
                                    }}
                                />
                            </div>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#BBDEFF"
                pt={isPc ? 110 : isTa ? 72 : 64}
                pb={isPc ? 140 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="center second">
                        {!isPc && (
                            <div>
                                <Typography
                                    label="이런 분들께"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="이 책을 추천합니다."
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                />
                            </div>
                        )}
                        <div className="section-img vol3">
                            <img src="/assets/images/ebook/Ebook-vol4-1.png" alt="ebook-vol4-1" />
                        </div>
                        <div className="contents-box vol3">
                            {isPc && (
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="이런 분들께"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="이 책을 추천합니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </Reveal>
                            )}
                            {isPc ? (
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={300}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            상세페이지 기획 및 제작에 어려움을 겪고 계신 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            이탈율은 낮추고 구매 전환율을 높이고 싶은 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            제품의 특장점을 효과적으로 전달하고 싶은 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            라이브를 통한 소통형 커머스에 관심이 있으신 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            고객 관계 강화를 통해 팬덤을 키우고 싶으신 분
                                        </li>
                                    </Reveal>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        상세페이지 기획 및 제작에 어려움을 겪고 계신 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        이탈율은 낮추고 구매 전환율을 높이고 싶은 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        제품의 특장점을 효과적으로 전달하고 싶은 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        라이브를 통한 소통형 커머스에 관심이 있으신 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        고객 관계 강화를 통해 팬덤을 키우고 싶으신 분
                                    </li>
                                </ul>
                            )}
                        </div>
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 130 : isTa ? 72 : 64} pb={isPc ? 130 : isTa ? 90 : 40}>
                <HomeSectionInner>
                    <EbookSectionWrap className="bottom vol4">
                        <div className="contents-box" style={{ paddingTop: 0 }}>
                            {!isPc && (
                                <>
                                    <Typography
                                        label="이런 내용을"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="책에 담았습니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </>
                            )}
                            {isPc && (
                                <>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <Typography
                                            label="이런 내용을"
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                            mb={isPc ? 14 : isTa ? 10 : 8}
                                        />
                                        <Typography
                                            label="책에 담았습니다."
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        />
                                    </Reveal>
                                    <article>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeIn}
                                            duration={600}
                                            delay={200}
                                        >
                                            <Typography
                                                label="2010년대 초반 스마트폰이 대중적으로 보급되면서 도래한 모바일 커머스 시대. 하지만 기기만 바뀌었을 뿐, 이미지와 텍스트로 이루어진 상세 페이지 중심의 이커머스 문법은 모바일에서도 그대로 적용되고 있습니다. 빠르게 원하는 정보를 습득하는 숏폼 트렌드가 확산하면서 일방향적인 상세페이지의 한계는 더욱 분명해지고 있죠."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="이번 Grip Insight 리포트에서는 변화한 소비 트렌드와 함께 상세페이지 중심의 커머스가 가지는 한계를 분석하고, 모바일 시대에 최적화된 판매 및 소통 방식을 소개하고자 합니다. 각 브랜드별 성공 사례를 통해 현업에서 적용할 수 있는 생생한 인사이트를 얻으시길 바랍니다."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                        </Reveal>
                                    </article>
                                </>
                            )}
                        </div>
                        <div className="section-img">
                            <img src="/assets/images/ebook/Ebook-vol4-2.png" alt="ebook-vol4-2" />
                        </div>
                        {!isPc && (
                            <article>
                                <Typography
                                    label="2010년대 초반 스마트폰이 대중적으로 보급되면서 도래한 모바일 커머스 시대. 하지만 기기만 바뀌었을 뿐, 이미지와 텍스트로 이루어진 상세 페이지 중심의 이커머스 문법은 모바일에서도 그대로 적용되고 있습니다. 빠르게 원하는 정보를 습득하는 숏폼 트렌드가 확산하면서 일방향적인 상세페이지의 한계는 더욱 분명해지고 있죠."
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                <Typography
                                    label="이번 Grip Insight 리포트에서는 변화한 소비 트렌드와 함께 상세페이지 중심의 커머스가 가지는 한계를 분석하고, 모바일 시대에 최적화된 판매 및 소통 방식을 소개하고자 합니다. 각 브랜드별 성공 사례를 통해 현업에서 적용할 수 있는 생생한 인사이트를 얻으시길 바랍니다."
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                            </article>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default EbookSecond;
