import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, EbookSectionWrap } from "./Ebook.style";
import Button from "../../common/component/Button";

const EbookSecond = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 60 : isTa ? 60 : 34} pb={isPc ? 1 : isTa ? 80 : 20}>
                <HomeSectionInner>
                    <EbookSectionWrap className="top second">
                        <div className="contents-box vol3">
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="다시 뜨는 오프라인"
                                    size={isPc ? 64 : isTa ? 46 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="이커머스 생존 전략"
                                    size={isPc ? 64 : isTa ? 46 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 24 : isTa ? 16 : 8}
                                />
                            </Reveal>
                            <article>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeIn}
                                    duration={600}
                                    delay={200}
                                >
                                    <Typography
                                        label="변화된 소비 트렌드와"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={4}
                                    />
                                    <Typography
                                        label="이에 대응하는 온라인 리부팅 인사이트"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={isPc ? 72 : isTa ? 54 : 36}
                                    />
                                </Reveal>
                                {(isPc || isTa) && (
                                    <Button
                                        className="cta-button"
                                        label={"무료 다운로드"}
                                        style={{ width: 150, height: 48, zIndex: 1 }}
                                        onClick={() => {
                                            typeof window.gtag === "function" &&
                                                window.gtag("event", "free_download", {
                                                    event_category: "ebook_free_download",
                                                    event_label: "ebook_vol3",
                                                    lang: "kr",
                                                });

                                            window.location.href =
                                                "https://share.hsforms.com/1WfbsJnISTyiUbRffsZJd_Aeg9vg";
                                        }}
                                    />
                                )}
                            </article>
                        </div>
                        <div className="section-img vol3">
                            <img
                                className="vol3"
                                src="/assets/images/ebook/Ebook-vol3.png"
                                alt="Ebook vol3"
                            />
                        </div>
                        {isMo && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    className="cta-button"
                                    label={"무료 다운로드"}
                                    style={{ width: 150, height: 48, zIndex: 1 }}
                                    onClick={() => {
                                        typeof window.gtag === "function" &&
                                            window.gtag("event", "free_download", {
                                                event_category: "ebook_free_download",
                                                event_label: "ebook_vol3",
                                                lang: "kr",
                                            });

                                        window.location.href =
                                            "https://share.hsforms.com/1WfbsJnISTyiUbRffsZJd_Aeg9vg";
                                    }}
                                />
                            </div>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#DEDDFF"
                pt={isPc ? 110 : isTa ? 72 : 64}
                pb={isPc ? 140 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="center second">
                        {!isPc && (
                            <div>
                                <Typography
                                    label="이런 분들께"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="이 책을 추천합니다."
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                />
                            </div>
                        )}
                        <div className="section-img vol3">
                            <img src="/assets/images/ebook/Ebook-vol3-1.png" alt="ebook-vol3-1" />
                        </div>
                        <div className="contents-box vol3">
                            {isPc && (
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="이런 분들께"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="이 책을 추천합니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </Reveal>
                            )}
                            {isPc ? (
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={300}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            효과적으로 제품을 프로모션하고 싶은 이커머스 운영 담당자
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            엔데믹 시대 이커머스 성공 전략이 궁금하신 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            숏폼을 활용한 마케팅 사례가 궁금하신 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            라이브를 통한 소통형 커머스를 시도하고 싶으신 분
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon fill={"#6196FC"} />
                                            온라인 브랜드의 오프라인 진출 성공 사례가 궁금하신 분
                                        </li>
                                    </Reveal>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        효과적으로 제품을 프로모션하고 싶은 이커머스 운영 담당자
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        엔데믹 시대 이커머스 성공 전략이 궁금하신 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        숏폼을 활용한 마케팅 사례가 궁금하신 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        라이브를 통한 소통형 커머스를 시도하고 싶으신 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        온라인 브랜드의 오프라인 진출 성공 사례가 궁금하신 분
                                    </li>
                                </ul>
                            )}
                        </div>
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 130 : isTa ? 72 : 64} pb={isPc ? 130 : isTa ? 90 : 40}>
                <HomeSectionInner>
                    <EbookSectionWrap className="bottom">
                        <div className="contents-box" style={{ paddingTop: 0 }}>
                            {!isPc && (
                                <>
                                    <Typography
                                        label="이런 내용을"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="책에 담았습니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </>
                            )}
                            {isPc && (
                                <>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <Typography
                                            label="이런 내용을"
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                            mb={isPc ? 14 : isTa ? 10 : 8}
                                        />
                                        <Typography
                                            label="책에 담았습니다."
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        />
                                    </Reveal>
                                    <article>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeIn}
                                            duration={600}
                                            delay={200}
                                        >
                                            <Typography
                                                label="일상을 되찾은 엔데믹 시대, 오프라인으로 소비 수요가 빠르게 이동하고 있습니다. 반면 코로나19를 기점으로 이례적인 상승 곡선을 그렸던 온라인 쇼핑의 성장세는 정체 국면에 접어들었습니다. 이커머스 시장의 위기를 극복하기 위해서는 어떠한 전략이 필요할까요?"
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="이번 Grip Insight 리포트에서는 온라인 쇼핑이 차별화된 D2C 전략을 수립할 수 있는 방법을 소개해 드리고자 합니다. 소비자들이 오프라인 쇼핑에 몰리는 현상의 이면을 잘 분석하고, 대응한다면 온라인 시장이 또 한번 크게 도약할 수 있는 계기가 될 것입니다."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                        </Reveal>
                                    </article>
                                </>
                            )}
                        </div>
                        <div className="section-img vol3">
                            <img src="/assets/images/ebook/Ebook-vol3-2.png" alt="ebook-vol3-2" />
                        </div>
                        {!isPc && (
                            <article>
                                <Typography
                                    label="일상을 되찾은 엔데믹 시대, 오프라인으로 소비 수요가 빠르게 이동하고 있습니다. 반면 코로나19를 기점으로 이례적인 상승 곡선을 그렸던 온라인 쇼핑의 성장세는 정체 국면에 접어들었습니다. 이커머스 시장의 위기를 극복하기 위해서는 어떠한 전략이 필요할까요?"
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                <Typography
                                    label="이번 Grip Insight 리포트에서는 온라인 쇼핑이 차별화된 D2C 전략을 수립할 수 있는 방법을 소개해 드리고자 합니다. 소비자들이 오프라인 쇼핑에 몰리는 현상의 이면을 잘 분석하고, 대응한다면 온라인 시장이 또 한번 크게 도약할 수 있는 계기가 될 것입니다."
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                            </article>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default EbookSecond;
