import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, WebinarSectionWrap } from "./Webinar.style";
// import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import InquiryForm from "../../common/component/InquiryForm";
import { Mobile, PC, Tablet } from "../../common/component/MediaQuery";

const Webinar = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 120 : isTa ? 60 : 34} pb={isPc ? 90 : isTa ? 80 : 40}>
                <HomeSectionInner>
                    <WebinarSectionWrap className="top">
                        <div className="section-img">
                            <img src="/assets/images/webinar/webinar_230526.png" alt="webinar" />
                        </div>
                        <div className="form-box">
                            {isPc && (
                                <Typography
                                    label="お申込みフォームをご記入くださいませ。"
                                    size={26}
                                    weight={600}
                                    mb={32}
                                />
                            )}
                            <InquiryForm language="ja" />
                        </div>
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#E2ECFF"
                pt={isPc ? 160 : isTa ? 98 : 72}
                pb={isPc ? 90 : isTa ? 142 : 50}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="session">
                        {isPc ? (
                            <>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label={<>セッション</>}
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        mb={74}
                                    />
                                </Reveal>
                                <div className="contents-box">
                                    <ul>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeUp}
                                            duration={600}
                                            delay={100}
                                            cascade
                                            damping={0.25}
                                        >
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 1</span>
                                                    オープニング
                                                </div>
                                            </li>
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 2</span>
                                                    ライブコマースとは何か
                                                </div>
                                            </li>
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 3</span>
                                                    様々なライブコマースの事例を紹介
                                                </div>
                                            </li>
                                            <li>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 4</span>
                                                    Q&A
                                                </div>
                                            </li>
                                        </Reveal>
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <Typography
                                    label={<>セッション</>}
                                    size={isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isTa ? 57 : 38}
                                    mb={isTa ? 56 : 24}
                                />
                                <div className="contents-box">
                                    <ul>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 1</span>
                                                オープニング
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 2</span>
                                                ライブコマースとは何か
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 3</span>
                                                様々なライブコマースの事例を紹介
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 4</span>
                                                Q&A
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#6196FC"
                pt={isPc ? 180 : isTa ? 72 : 64}
                pb={isPc ? 180 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="center">
                        {isMo && (
                            <>
                                <Typography
                                    label="こんな方にオススメ"
                                    size={32}
                                    weight={700}
                                    lineHeight={38}
                                    mb={10}
                                    color="white"
                                />
                            </>
                        )}
                        <div className="section-img">
                            <img src="/assets/images/webinar/center.png" alt="" />
                        </div>
                        {isPc ? (
                            <div className="contents-box">
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="こんな方にオススメ"
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        mb={10}
                                        color="white"
                                    />
                                </Reveal>
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li>
                                            <CheckIcon />
                                            E-コマーズ MD / マーケティング / 営業 担当者
                                        </li>
                                        <li>
                                            <CheckIcon />
                                            ECサイトをお持ちの企業担当者
                                        </li>
                                        <li>
                                            <CheckIcon />
                                            普段からライブ配信に興味のある方
                                        </li>
                                        <li>
                                            <CheckIcon />
                                            D2C戦略を悩んでいる担当者
                                        </li>
                                    </Reveal>
                                </ul>
                            </div>
                        ) : (
                            <div className="contents-box">
                                {!isMo && (
                                    <>
                                        <Typography
                                            label="こんな方にオススメ"
                                            size={isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isTa ? 57 : 38}
                                            mb={10}
                                            color="white"
                                        />
                                    </>
                                )}
                                <ul>
                                    <li>
                                        <CheckIcon />
                                        E-コマーズ MD / マーケティング / 営業 担当者
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        ECサイトをお持ちの企業担当者
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        普段からライブ配信に興味のある方
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        D2C戦略を悩んでいる担当者
                                    </li>
                                </ul>
                            </div>
                        )}
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#F8F8F8"
                pt={isPc ? 200 : isTa ? 120 : 64}
                pb={isPc ? 150 : isTa ? 80 : 100}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="bottom">
                        <PC>
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="ライブコマースで作る"
                                    size={64}
                                    weight={700}
                                    lineHeight={76}
                                    mb={14}
                                />
                                <Typography
                                    label="顧客との新しい絆"
                                    size={64}
                                    weight={700}
                                    lineHeight={76}
                                    mb={14}
                                />
                            </Reveal>
                        </PC>
                        <Tablet>
                            <Typography
                                label="ライブコマースで作る"
                                size={48}
                                weight={700}
                                lineHeight={57}
                                mb={10}
                            />
                            <Typography
                                label="顧客との新しい絆"
                                size={48}
                                weight={700}
                                lineHeight={57}
                                mb={10}
                            />
                        </Tablet>
                        <Mobile>
                            <Typography
                                label="ライブコマースで作る"
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                            <Typography
                                label="顧客との新しい絆"
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                        </Mobile>
                        <div className="contents-box">
                            {isPc ? (
                                <article>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeIn}
                                        duration={600}
                                        delay={200}
                                    >
                                        <Typography
                                            label={
                                                <>
                                                    媒体の多様化により顧客との接点が増えた分、競争も激しくなりました。顧客に情報を伝えるだけではなく、絆を作ることの重要性が益々高まっています。
                                                    <br />
                                                    そこで、ライブコマースは顧客に新しい体験を提供し、顧客との絆を作る方法として注目されています。
                                                </>
                                            }
                                            size={22}
                                            lineHeight={35}
                                            mb={8}
                                        />
                                        <Typography
                                            label="本セミナーでは、ライブコマースを活用して顧客との絆を作りたい企業のために、事例に基づきライブコマースの成功戦略について詳しくご紹介します。"
                                            size={22}
                                            lineHeight={35}
                                        />
                                    </Reveal>
                                </article>
                            ) : (
                                <article>
                                    <Typography
                                        label={
                                            <>
                                                媒体の多様化により顧客との接点が増えた分、競争も激しくなりました。顧客に情報を伝えるだけではなく、絆を作ることの重要性が益々高まっています。
                                                <br />
                                                そこで、ライブコマースは顧客に新しい体験を提供し、顧客との絆を作る方法として注目されています。
                                            </>
                                        }
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                        mb={isTa ? 24 : 16}
                                    />
                                    <Typography
                                        label="本セミナーでは、ライブコマースを活用して顧客との絆を作りたい企業のために、事例に基づきライブコマースの成功戦略について詳しくご紹介します。"
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                    />
                                </article>
                            )}
                            <div className="section-img">
                                <img src="/assets/images/webinar/bottom.png" alt="" />
                            </div>
                        </div>
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default Webinar;
