import Reveal from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  HomeSectionInner,
  fadeUp,
  HomeSection,
  screen,
  media,
} from "../../../../pages/home/Home.style";
import Button from "../../Button";
import styled from "styled-components";
import Typography from "../../InnerTypography";

const FinishSectionWrap = styled.div`
  > article {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.mo} {
      padding: 0 20px;
    }
    h1 {
      text-align: center;
    }
  }
`;
interface FinishSectionProps {
  onClick: () => void;
}

const FinishSection = (props: FinishSectionProps) => {
  const { onClick } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation("main");

  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection className="finish" pt={isPc ? 320 : isTa ? 280 : 210} pb={!isMo ? 320 : 210}>
      <HomeSectionInner>
        <FinishSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography.Header
                  fontSize={isPc ? "3.5rem" : "3rem"}
                  fontWeight={700}
                  color="white"
                  style={{ textAlign: "center" }}
                >
                  {language === "ko" ? (
                    <>
                      결과로 증명된 <br className="none-pc" />
                      미디어커머스 솔루션
                    </>
                  ) : (
                    <>成果につながるメディアコマースソリューション</>
                  )}
                </Typography.Header>
                <Typography.Header
                  fontSize={isPc ? "3.5rem" : "3rem"}
                  fontWeight={700}
                  color="white"
                  marginBottom={isPc ? "4.375rem" : "3.625rem"}
                >
                  {language === "ko" ? "도입을 망설이지 마세요." : "Grip Cloudがサポートいたします"}
                </Typography.Header>
                <Button
                  className="gtm-trigger-request-demo"
                  label={t("inquire")}
                  onClick={onClick}
                  style={{ backgroundColor: "#508eff" }}
                />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography.Header
                fontSize={"2rem"}
                fontWeight={700}
                color="white"
                style={{ textAlign: "center" }}
              >
                {language === "ko" ? (
                  <>
                    결과로 증명된
                    <br />
                    미디어커머스 솔루션
                  </>
                ) : (
                  <>成果につながるメディアコマースソリューション</>
                )}
              </Typography.Header>
              <Typography.Header fontSize={"2rem"} fontWeight={700} color="white">
                {language === "ko" ? "도입을 망설이지 마세요." : "Grip Cloudがサポートいたします"}
              </Typography.Header>
              <Button
                className="gtm-trigger-request-demo"
                label={t("inquire")}
                onClick={onClick}
                style={{ backgroundColor: "#508eff", marginTop: "3rem" }}
              />
            </article>
          )}
        </FinishSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default FinishSection;
