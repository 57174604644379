export const FaqData = [
    {
        id: 1,
        title: '라이브 방송 제작 및 쇼호스트도 연결이 가능한가요?',
        content: '그립과 제휴된 제작사를 통해 방송을 제작하고, 쇼호스트로 그리퍼를 섭외하실 수 있습니다. 제휴 제작사와 계약하시는 경우 그립 제휴 할인가로 방송을 제작하실 수 있습니다.',
    },
    {
        id: 2,
        title: '라이브는 카메라(DSLR)로만 가능한가요?',
        content: '모바일 송출 기능이 있어서 카메라/모바일 모두 라이브 진행이 가능합니다.',
    },
    {
        id: 3,
        title: '카페24, 고도몰을 사용 중인데 솔루션을 도입할 수 있나요?',
        content: '카페24, 고도몰 모두 개발 가능하며 이외에 다른 솔루션사를 사용하셔도 개발 및 연동이 가능합니다. 자세한 내용은 문의주시기 바랍니다.',
    },
    {
        id: 4,
        title: '계약과 솔루션 이용은 어떤 식으로 진행되나요?',
        content: '세일즈 담당자와 논의하여 사용하실 요금제를 선택하신 후 계약을 체결합니다. 이후 내부 개발자 혹은 외주 개발사를 통해 개발을 진행하시고, 문의가 있으신 경우 그립 담당 개발자와 개발미팅을 진행하실 수 있습니다. 개발 중에는 그립이 생성해드리는 테스트계정으로 방송을 테스트해볼 수 있습니다.',
    },
    {
        id: 5,
        title: '솔루션을 적용하는 개발은 그립이 하는 건가요?',
        content: '솔루션 적용 개발은 고객님의 내부 개발팀 혹은 외주 개발사를 통해 진행하셔야 합니다.',
    },
    {
        id: 6,
        title: '개발기간은 얼마나 걸리나요?',
        content: 'Javascript를 삽입하는 방식으로 개발하실 경우 1주일 내에도 도입할 수 있습니다(1맨먼스 기준). 자사몰 백오피스에 API를 연동하여 개발하시는 경우 UX/UI 기획과 추가 기능 개발 여부에 따라 다르지만, 일반적으로 6~8주 정도 소요됩니다(4~5맨먼스 기준).',
    },
    {
        id: 7,
        title: '요금제는 어떻게 되나요?',
        content: <ul>
            그립 클라우드 요금제는 솔루션 이용료와 스토리지 비용으로 나뉩니다.
            <li>1) 솔루션 이용료 : 연 구독 요금제와 월 구독 요금제가 있으며, 모두 기본 계약 단위는 1년입니다. <br /><span style={{ paddingLeft: 18 }}>연 구독 요금제는 12개월분 요금을 일시납하시는 요금제이고 할인 혜택이 제공됩니다.</span></li>
            <li>2) VOD 스토리지 비용 : VOD를 그립 서버에 저장하기 위한 비용입니다. 세일즈 담당자를 통해 자세한 내용을 설명받으실 수 있습니다.</li>
        </ul>,
    },
    {
        id: 8,
        title: '계약기간 중간에 요금제 변경도 가능한가요?',
        content: '상위 요금제로 변경하시는 경우에만 가능하며, 계약기간 중 하위 요금제로의 변경은 정책상 어렵습니다.',
    },
    {
        id: 9,
        title: '계약 기간은 어떻게 되나요?',
        content: '계약 기간은 기본 1년입니다.',
    },
]