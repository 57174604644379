import Reveal from 'react-awesome-reveal'
import { Trans } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from "styled-components"
import { HomeSectionInner, fadeUp, HomeSection } from '../../../pages/home/Home.style'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"
import Typography from '../Typography'
import { useRef, useState } from 'react'
import { ContentsCard, ContentsCardEn } from '../../../pages/home/Home.data'
import { useNavigate } from 'react-router-dom'

const ContentsSectionWrap = styled.div`
    > article {
        margin-bottom: 100px;
        @media (min-width:600px) and (max-width: 1199px) {
            margin-bottom: 56px;
        }
        @media (max-width: 599px) {
            margin-bottom: 32px;
        }
    }
`

const ContentsSlideCard = styled.div`
    height: 415px;
    margin-right: 20px;
    background-color: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0px 183px 73px rgba(0, 0, 0, 0.01), 0px 103px 62px rgba(0, 0, 0, 0.05), 0px 46px 46px rgba(0, 0, 0, 0.09), 0px 11px 25px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .dim {
        width: 100%;
        height: 40%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    .copy {
        width: 100%;
        padding: 24px 28px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &:hover {
        transform: scale(1.05);
    }
    @media (max-width: 599px) {
        height: 208px;
        border-radius: 12px;
        .dim {
            height: 70%;
        }
        .copy {
            padding: 24px 12px;
        }
    }
`

interface ContentsSectionProps {
    language: string
}

const ContentsSection = (props: ContentsSectionProps) => {
    const { language } = props
    const navigate = useNavigate()

    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" })
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    const contentsSlideEl = useRef<any>(null)
    const [contentsCurrentSlide, setContentsCurrentSlide] = useState<number>(0)

    const next = () => {
        if (contentsSlideEl.current) {
            contentsSlideEl.current.slickNext()
        }
    }
    const previous = () => {
        if (contentsSlideEl.current) {
            contentsSlideEl.current.slickPrev()
        }
    }

    const contentsSettings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        infinite: true,
        beforeChange: (current: any, next: number) => {
            setContentsCurrentSlide(next)
        },
        appendDots: (dots: any) => (
            <div>
                <ul className='light'>{dots}</ul>
                <button className="slide-button" onClick={previous}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M12 3L6 9L12 15" stroke="#363636" strokeWidth="2" />
                    </svg>
                </button>
                <button className="slide-button" onClick={next}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M6 3L12 9L6 15" stroke="#363636" strokeWidth="2" />
                    </svg>
                </button>
            </div>
        ),
        customPaging: (index: any) => (
            <button
                style={{
                    backgroundColor: contentsCurrentSlide >= index ? '#568DFD' : undefined,
                    borderTopRightRadius: contentsCurrentSlide === index ? '3px' : 0,
                    borderBottomRightRadius: contentsCurrentSlide === index ? '3px' : 0,
                }}
            />
        )
    }

    return (
        <HomeSection pt={isPc ? 200 : isTa ? 150 : 172} pb={isMo ? 220 : 200}>
            <HomeSectionInner>
                <ContentsSectionWrap>
                    {!isMo ?
                        <article>
                            <Reveal className='reveal' keyframes={fadeUp} duration={600} delay={100}>
                                <Typography
                                    label={<><span>Grip cloud</span><Trans i18nKey={'main:notice.title'}>notice.title<br className='none-pc' /></Trans></>}
                                    size={isPc ? 80 : 64} weight={800} lineHeight={isPc ? 80 : 90}
                                />
                            </Reveal>
                        </article>
                        :
                        <article>
                            <Typography
                                label={<><span>Grip cloud</span><Trans i18nKey={'main:notice.title'}>notice.title<br className='none-pc' /></Trans></>}
                                size={34} weight={800} lineHeight={40}
                            />
                        </article>
                    }
                </ContentsSectionWrap>
                <Slider ref={contentsSlideEl} {...contentsSettings}>
                    {language === 'ja' && ContentsCard.map((item: any, i: number) => {
                        return (
                            <div key={i} style={{ width: isPc ? 340 : isTa ? 320 : 170 }}>
                                <ContentsSlideCard onClick={() => navigate(`/jp/contents/${i + 1}`)}>
                                    <img src={item.bg} alt="" />
                                    <div className="dim" />
                                    <div className="copy">
                                        <Typography label={item.title} size={isMo ? 14 : 24} weight={700} lineHeight={isMo ? 20 : 36} color='white' />
                                    </div>
                                </ContentsSlideCard>
                            </div>
                        )
                    })}
                    {language !== 'ja' && ContentsCardEn.map((item: any, i: number) => {
                        return (
                            <div key={i} style={{ width: isPc ? 340 : isTa ? 320 : 170 }}>
                                <ContentsSlideCard onClick={() => navigate(`/en/contents/${i + 1}`)}>
                                    <img src={item.bg} alt="" />
                                    <div className="dim" />
                                    <div className="copy">
                                        <Typography label={item.title} size={isMo ? 14 : 24} weight={700} lineHeight={isMo ? 20 : 36} color='white' />
                                    </div>
                                </ContentsSlideCard>
                            </div>
                        )
                    })}
                </Slider>
            </HomeSectionInner>
        </HomeSection>
    )
}

export default ContentsSection