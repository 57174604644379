export const ContentsDataEn = [
    {
        id: 1,
        image: '/assets/images/contents/content_01.png',
        title: 'Gripping Company Signs Live Commerce Partnership on YouTube.',
        description: <>
            <p>On October 31, 2022, Grip Company (based in Korea, CEO: Kim Hanna) announced a partnership with the video streaming service "YouTube" to offer the live shopping feature "YouTube Shopping".</p>
            <br />
            <p><b>About the Partnership Agreement</b></p>
            <p>Grip Company operates the live shopping platform "Grip" and the live shopping service software (SaaS) "Grip Cloud". With the partnership with YouTube for live shopping, users can easily use the live shopping function on Grip's YouTube channel and channels of creators who have partnered with Grip.</p>
            <p>Grip Company aims to spread the culture of live commerce and create new value by providing the live shopping feature not only to popular brands and famous creators, but also to individual business owners.</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 2,
        image: '/assets/images/contents/content_02.png',
        title: "Olive Young, Korea's #1 health and beauty store, talks live commerce.",
        description: <>
            <p>CJ Olive Young, the company that operates Olive Young, the very popular health and beauty store in Korea, has realized the importance of live commerce and has implemented Grip cloud. They will share why they felt the need for live commerce, why they chose Grip cloud, and the results they have achieved with live commerce.</p>
            <br />
            <p><u>Please introduce your team first.</u></p>
            <p>We are the media commerce team responsible for OLIVE YOUNG, handling everything from scheduling live broadcasts to creating the various visual content that appears on the actual broadcasts and on social media.</p>
            <br />
            <h2>We chose the Grip cloud because of its distinctive gaming features and its reliable server environment.</h2>
            <br />
            <p><u>How did you decide to use Grip Cloud?</u></p>
            <p>The most important reason was the ability to experiment with different ventures through Grip Cloud's unique "gaming" feature. OLIVE YOUNG's main target audience is in their 20s and 30s. In the case of OLIVE YOUNG's live-streaming, even teenagers watch when the live-streaming is for K-pop idol fans, so it is most important to make it fun for a wide range of generations and make them want to watch the live-streaming all the time. Therefore, Grip cloud had a variety of game functions, so we thought it was the right environment for this new challenge of live commerce.</p>
            <p>The stability of the service was also an important factor. During our live streaming sessions before implementing Grip cloud, we experienced system errors that made it difficult to chat smoothly, problems with the "like" function, and other errors. Such errors can lead to customer churn, so we also considered a stable server environment.</p>
            <br />
            <h2>We want to improve not only sales, but also logins, average time spent viewing, and product variety.</h2>
            <br />
            <p><u>How did it perform after getting started?</u></p>
            <p>While sales are important, we have also seen an increase in the percentage of viewers who log in to watch since the introduction of Grip Cloud. Previously, viewers would just watch the live stream, but now more people are logging in to participate in comments and games. As a result, the average viewing time has naturally increased and the number of concurrent viewers has also improved significantly. We believe that our goals and strategies for implementing Grip cloud have been well aligned.</p>
            <p>After implementing Grip cloud, we also saw positive effects from a product perspective. Products that were not ranking well in the app's ranking system were able to improve their rankings after being featured in our live streams. In addition, some products that were previously considered hard to sell ended up generating unexpected sales by being featured as content during our streams. These are just a few examples of the results we have seen since implementing Grip Cloud.</p>
            <br />
            <h2>We want to create live commerce that people will want to tune in to every day.</h2>
            <br />
            <p><u>What do you want to achieve?</u></p>
            <p>Since the main target audience is in their 20s, the entertainment factor is crucial. Entertainment is one of the most important factors in attracting customers and converting them into buyers, so we need to deliver a clear message. It's not easy, but we want to create a live stream that viewers want to tune into every day at a certain time and position ourselves as a leader in live commerce.</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 3,
        image: '/assets/images/contents/content_03.png',
        title: 'Like Icon Customization Function | Expose your brand image and increase engagement - two effects in one!',
        description: <>
            <p>Are you familiar with the Zajonc effect? It has a deep connection to marketing. The Mere Exposure Effect refers to the psychological phenomenon that even if we were not initially interested in something, we are more likely to develop a liking for it as we are repeatedly exposed to it and our wariness fades. For example, if we repeatedly see the same image while watching a live stream, we are more likely to develop a liking for that image. Therefore, we suggest that you use Grip Cloud's "Custom Like Icon" feature to customize the like icon with an image that perfectly represents your brand and increase your brand awareness and engagement.</p>
            <br />
            <h2>Is it important to be "Like" in the first place?</h2>
            <p>The "Like" feature is useful for managing audience engagement! Changes in the number of likes can indicate the audience's reaction. If the number of likes doesn't increase much, you may be experiencing a decrease in viewer engagement. According to our data, live streams with a good audience response tend to have significantly more likes.</p>
            <br />
            <h2>What can I do with the "Like Icon" customization feature?</h2>
            <h3>Custom icons can help promote your brand and products.</h3>
            <p>Using the custom like icon feature, you can freely change the image of the like icon. For example, let's say you change it to your brand logo or product image. During live streaming, when someone clicks the Like button, your brand logo or product image will be displayed to the viewers. Of course, it is recommended to design the image of the Like icon according to the concept and goals of the streaming.</p>
            <br />
            <h3>Can bring pleasure to the viewer.</h3>
            <p>Customizing the like icon can create new excitement for viewers. For example, if you customize the Like icon for each broadcast based on the broadcast concept, viewers will start to look forward to what kind of icon will appear in the next broadcast. The brand's unique icon becomes a reason for viewers to press the Like button more often, and eventually they will naturally remember the brand and product image.</p>
            <br />
            <h2>Ideas for making more use of how to customize icons</h2>
            <br />
            <h3>The "Nth Like" event.</h3>
            <p>To increase brand awareness of custom "like" icons and engage more viewers, we recommend hosting events during live streams that utilize the "like" feature. For example, offering prizes to the viewer who clicks the 10,000th "Like" button can encourage viewers to click "Like" more often than usual. Information about viewers who click "Like" for the Nth time can be confirmed through the administration screen.</p>
            <br />
            <h2>How do I customize a like icon?</h2>
            <p>Customization of the Like icon can be easily done from the administration screen!</p>
            <p>By uploading ready-made PNG image files to the administration screen, the custom "Like" icon can be applied instantly.</p>
            <br />
            <p>The Like Icon Customization feature for more exposure of your brand image and increased viewer engagement! We encourage you to take advantage of this feature.</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 4,
        image: '/assets/images/contents/content_04.png',
        title: "One hour live commerce event generated ¥100 million in sales! Read a case study about Qoo10's live commerce success.",
        description: <>
            <h2>1 out of 15 viewers bought! The key to successful live commerce is planning.</h2>
            <br />
            <p><u>Please introduce yourself first.</u></p>
            <p>I am the team leader of the content team in the marketing department of Qoo10 (eBay Japan). I handle various tasks within the marketing department, but for the content team, we mainly run SNS channels, work with influencers, and produce mainly video content. In a way, our team is responsible for almost all content. Among these, live commerce has seen significant growth and has become a unique feature of Qoo10. This year, we plan to further expand the scale and broadcast more than three times as much as before.</p>
            <br />
            <p><u>What are the main targets of Qoo10? Why did you define them as such?</u></p>
            <p>Our target audience is women in their 10s to early 30s. In fact, over 80% of our actual buyers are women, and of those, over 70% are in their 10s to early 30s, which I think is quite unusual in Japan. So for brands that fit our target audience, our site is quite attractive. Live Commerce also targets the same audience as Qoo10.</p>
            <br />
            <p><u>What are you doing to effectively reach your audience?</u></p>
            <p>As an open platform, Qoo10 does not have its own original products. Instead, we actively use Korean cosmetics that match our target audience. For example, we directly contact the Korean headquarters of brands according to trends and conduct business. We also plan and develop products that can only be purchased on Qoo10 and promote them through influencers. We place great emphasis on creating unique features that are exclusive to Qoo10.</p>
            <p>We also actively conduct simultaneous distribution with the SNS channels we have cultivated so far. Currently, we have about 650,000 followers on Twitter, which is quite a lot for a corporate account. We also allocate budget to audience acquisition as we want customers who have not installed the Qoo10 app to be able to watch our live streams.</p>
            <br />
            <p><u>Please tell us about your planning process.</u></p>
            <p>We have been running the weekly Wednesday live stream since last year, and we have handled everything from planning to sales. We plan to increase the number of live streams, and preparations have already been made. For example, we are also planning channels with famous influencers.</p>
            <p>We have a very big event on Qoo10 about 4 times a year that lasts about 10 days each time. We start planning for this event about 2 months in advance, and target it so that we can always be number one on all channels (such as Twitter trends and hashtags) at the same time. Companies also plan new products around this time, and since we do this event every 2-3 months, the year goes by very quickly.</p>
            <br />
            <p><u>What do you see as the key to differentiation in live commerce?</u></p>
            <p>I think planning is the most important aspect. Not just the concept, but also considering the impact on sales as it is still an e-commerce platform. There have been cases where brands have seen great results with Qoo10's live commerce while not performing well compared to other competitors. We work closely with brands to plan product packaging, considering factors such as target audience, price point, and how to make the product more appealing to customers. In addition, we always ensure that the products are not sold at a lower price than usual, as we place great emphasis on generating sales from live commerce.</p>
            <br />
            <p><u>Are there any indicators that the Qoo10 team focuses on? What are the reasons?</u></p>
            <p>The internal KPIs and the number of viewers are the main metrics we look at. The number of viewers directly translates into the number of page views on the product page, and the conversion rate to purchase is calculated, which ultimately leads to sales. So we prioritize sales, page views and viewers the most. We mainly calculate the conversion rate based on the number of viewers and the number of items sold on Qoo10. Based on these metrics, we have found that approximately 1 in 15-20 viewers tend to make a purchase.</p>
            <br />
            <p><u>How has live trading impacted Qoo10 customers?</u></p>
            <p>We had the slogan "shopping and entertainment" even before we started live trading. So we started the live broadcasts without expecting a lot of sales at first. We planned with the position "Qoo10 is a really interesting place with lots of fun things to do". However, sales are also going well, so we see that the entertainment factor has also "led to sales".</p>
            <br />
            <h2>Live commerce transformed with Grip Cloud</h2>
            <br />
            <p><u>Before implementing Grip Cloud, what were the most difficult or inconvenient aspects of doing live trading?</u></p>
            <p>Before implementing Grip Cloud, Qoo10 had never done live streaming within its app. However, influencers had been doing live streams, mostly on Instagram, which lasted 30 minutes to an hour and had good viewership and comment counts. However, it was difficult to drive viewers to the website and actually convert sales, which was the biggest concern.</p>
            <br />
            <p><u>What made you decide to implement Live Commerce?</u></p>
            <p>While live commerce was thriving overseas and various attempts were being made, it was still relatively unknown in Japan, so our mission was to "take the first position". For Qoo10, the mission of "creating unique content" was also important.</p>
            <br />
            <p><u>What were your impressions of Grip Cloud?</u></p>
            <p>There were some parts of the setup that we were unfamiliar with at first, but thanks to their quick response, we were able to proceed smoothly, and they also worked with us on the planning, production, and other aspects of live commerce.</p>
            <p>Live commerce takes place within the Qoo10 app, and viewers can make purchases instantly while watching the live stream. The ranking of the products is also displayed in real time, making the live stream very dynamic for viewers. As the streamer talks about the product's current ranking, viewers join in the chat, saying things like "You'll be number one today!" to show their excitement. When a product becomes number one, the screen is immediately displayed on the live stream and everyone celebrates together, creating a story of how they all reached the top spot. In addition, archived footage can be linked and efficiently posted to the site. Twitter is also being used for simultaneous live streaming and advertising, and the advertising team has said that the cost per click is very low, making it very efficient.</p>
            <br />
            <p><u>What was the customer's response?</u></p>
            <p>They have been running for over a year now, so regular customers are teaching each other how to use the features, and we are starting to feel that live commerce is "getting through" to people.</p>
            <p>At first, even though customers were familiar with live streaming on other SNS channels, they were not used to being able to watch a live stream and make a purchase at the same time. Therefore, we provided a lot of guidance comments on how to make a purchase.</p>
            <br />
            <h2>Qoo10's goal for live commerce</h2>
            <br />
            <p><u>How are you preparing for the future of live commerce?</u></p>
            <p>We have started working on getting brands to open their own channels. This year, we plan to open Qoo10 studios in Shibuya and Harajuku. Even if brands want to do live streams, they may not be able to due to studio and equipment issues, so we currently handle everything at Qoo10. However, we realize that there is a limit to the number of streams we can handle, so we are creating an environment where brands can log into their own channels at any time and do live commerce on Qoo10. Once this is complete, we hope to play a role in providing live commerce that cannot be offered on other sites.</p>
            <br />
            <p><u>What is the reaction on the brand side?</u></p>
            <p>Many companies are showing great interest. There are still many companies that have no experience with live commerce and are not used to promoting and selling products through live streaming. Therefore, there are many inquiries.</p>
            <br />
            <p><u>What are the categories for which you receive the most requests?</u></p>
            <p>There are many examples of cosmetics brands such as FANCL, ANGFA and Rohto, so the cases are mostly related to cosmetics. In convincing customers to visit the store, live commerce (Qoo10 Live Shopping) often becomes the deciding factor. Basic questions like who the guest or MC will be are also often asked at the beginning. We don't know the process of how they are produced. However, we customize and plan each live stream according to the brand's desired concept, so the brands that have actually done live commerce are quite satisfied. For brands that use models, we try to contact the models and tailor the live stream to the brand's needs as much as possible.</p>
            <br />
            <p><u>What is a success story you are proud of?</u></p>
            <p>We once achieved sales of 100 million yen in a one-hour live commerce session, which was a huge success. The brand immediately wanted to schedule the next live session because of the enthusiastic fans we had gained. In addition, we once sold 10,000 items in just five minutes. At that time, an idol appeared on the show, signed the products and gave them away. It was an entertaining live stream with a lot of engagement. We even promised to dance if we sold 50,000 items, so it was a very enjoyable experience.</p>
            <br />
            <p><u>Are there any projects you would like to do in the future?</u></p>
            <p>We are approaching the second year since the launch of our "MOVE" service, which focuses exclusively on fashion and showcases products through video. Expanding this service is one of our missions this year, so we are taking on the challenge of increasing the number of fashion-focused live streams. We are currently in discussions with production companies about how we will actually expand this, so stay tuned.</p>
            <br />
            <p><u>Conversely, are there any categories you would like to improve?</u></p>
            <p>Compared to the size of the market, the food category is still relatively small. However, we had some successful experiences with food live commerce last year, and we are preparing to expand this approach to increase sales again this year.</p>
            <br />
            <p><u>What is the Japanese live commerce market and how is it growing from Qoo10's perspective?</u></p>
            <p>We believe that our viewership on Qoo10 is still relatively low, so our mission for this year is to attract new viewers. While our existing viewers are already familiar with our content, we need to create new categories and concepts that will attract new viewers and give them a unique experience.</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 5,
        image: '/assets/images/contents/content_05.png',
        title: "Private Live | It's not just about selling! Build fans with live commerce.",
        description: <>
            <p>The reason live commerce is getting attention is not just because it sells well. The real power of live commerce is in creating fans.</p>
            <p>Instead of just showing off the parts of the brand you want to show off, as in a one-way presentation, like a store associate interacting with customers, the role of live commerce is to communicate with viewers in a two-way conversation, giving them the information they really want to know, building trust, and ultimately turning them into fans. In this case, we will introduce the private live feature of the Grip Cloud that can be used to cultivate fans.</p>
            <br />
            <h2>What is Private Live?</h2>
            <p>Private Live is a live stream that can only be viewed by those who have the access code.</p>
            <br />
            <h2>What can I do in Private Live?</h2>
            <p>Customers are often attracted to companies that treat them as special. Many companies already use a ranking system based on the customer's purchase frequency or amount, and offer rewards according to their rank. For example, department stores are well known for holding special events for VIP customers.</p>
            <p>By using Grip cloud's Private Live feature, you can hold special online events for specific customers as well.</p>
            <br />
            <p>Here are three ideas for using it!</p>
            <br />
            <h3>Showcase new products</h3>
            <p>Live commerce doesn't always have to lead viewers to a purchase. What about hosting a showcase event to introduce new products to your fans? </p>
            <p>Be proactive and invite those who are interested in new products. This can help spread word-of-mouth in advance.</p>
            <br />
            <h3>Sales event with a limited quantity</h3>
            <p>You can also use the Private Live feature to efficiently sell limited quantities of products. </p>
            <p>Because it is a private live, you can have more Q&A time than usual and provide in-depth support to increase customer satisfaction with the product.</p>
            <br />
            <h3>Social Events</h3>
            <p>Fans who are proactive in expressing their opinions will appreciate the opportunity to communicate directly with employees or the brand itself. </p>
            <p>Try to show your fans at the event that you value their opinions.</p>
            <br />
            <p>Private livestreams have the advantage of having a clear target audience and being relatively small in number, which allows for more efficient marketing.</p>
            <br />
            <ol>Here is how to set up a private live stream
                <li>Go to the Live Registration screen and set the Private Streaming option to "YES".</li>
                <li>Click the "Generate" button to generate an access code.</li>
                <li>Copy the generated access code and save it.</li>
                <li>Complete the Live Registration process.</li>
                <li>Share the access code with your target audience using their preferred method (e.g., email newsletters, app notifications, push notifications).</li>
            </ol>
            <br />
            <p>The method of live streaming is the same as a regular live stream, and it can be done from a PC or smartphone.</p>
            <br />
            <p>Use the Private Live feature to build a good relationship with your customers and cultivate fans!</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 6,
        image: '/assets/images/contents/content_07.png',
        title: 'Game Features | Tips for increasing engagement in live commerce',
        description: <>
            <p>Introducing gaming features that add "play" to regular live streaming can help increase viewer engagement, a key metric for measuring the success of live commerce. But increasing viewer engagement isn't easy. In addition to offering affordable, high-quality products and communicating with viewers, you also need to grab their attention with something fun.</p>
            <br />
            <p>So, let me introduce you to some gaming features that can be added to regular live streaming to add that element of "fun" and increase viewer engagement!</p>
            <br />
            <h2>Benefits of playing games in Live Commerce</h2>
            <br />
            <h3>Audience enjoyment</h3>
            <p>Many viewers like to click on the screen during live streaming. This is one reason why many live streaming services have added a Like button and configured the screen to display icons when the button is clicked. </p>
            <p>Also, participating in games is more fun than just clicking the Like button.</p>
            <br />
            <h3>Increased comment participation</h3>
            <p>Commenting is essential to the fun of live streaming. It's not just about communication between the streamer and the viewers, but everyone who watches and comments becomes a subject of communication, and it's desirable for everyone to respond to each other's comments. Running games during live streaming makes it easier to encourage viewers to interact with each other. Consider designing game rewards and other incentives to encourage more active commenting.</p>
            <br />
            <h3>Increased viewing time</h3>
            <p>Games also have an impact on the viewing time of live streams. Obviously, the more interesting the live stream, the longer it will be watched. Games provide fresh content for live streams. Grip Cloud's data also shows that the use of game features tends to increase view time.</p>
            <br />
            <h2>About Grip Cloud game features</h2>
            <p>Starting in March 2023, Grip Cloud will have 3 gaming features!</p>
            <br />
            <h3>True or False Game</h3>
            <p>Try using the True or False Game feature to play a True or False quiz with your viewers. By setting up the True or False game, viewers will see "O" and "X" buttons on their screen and can choose their answer.</p>
            <p>You can use them literally as True or False, or you can use them in different ways, such as "Option 1" or "Option 2". Create a quiz that fits your live streaming content and have fun with your viewers!</p>
            <br />
            <h3>First-Come-First-Served</h3>
            <p>Try using the First-Come-First-Served game to make the limited eye-catching products more interesting. Introduce the eye-catching product during the most exciting part of the live stream and conduct the First-Come-First-Served game. </p>
            <p>This will attract viewers who want the product to participate at once, making the live stream even more exciting.</p>
            <br />
            <h3>Raffle Game</h3>
            <p>Using a sweepstakes feature to easily and fairly select winners is recommended when prizes are involved. Events that use a raffle feature are more likely to motivate viewers. </p>
            <p>Attendees are more likely to actively participate and enjoy themselves because there is a chance of winning.</p>
            <br />
            <h2>How to set up game functions</h2>
            <br />
            <ol>(Common) How to set up on a PC
                <img src="/assets/images/contents/content_07_1.png" alt="" />
                <li>Click on the "Games" button in the menu on the right side of the live streaming page.</li>
                <li>Select the game you want from the list of game features and start playing.</li>
            </ol>
            <br />
            <ol>How to set up First-Come-First-Served games
                <img src="/assets/images/contents/content_07_2.png" alt="" />
                <li>click on "First-Come-First-Served" 〉 "0 Players" 〉 set your desired number of winners 〉 click on "Start First-Come-First-Served" button.<br />
                    *You can set the number of winners up to 50.</li>
                <li>check the number of participants in real time on the "First-Come-First-Served" game screen on the right.</li>
                <li>When the number of participants reaches the number of winners, click the "Finish" button.</li>
            </ol>
            <br />
            <ol>How to set up Raffle Game
                <img src="/assets/images/contents/content_07_3.png" alt="" />
                <li>click on "Raffle Game" 〉 click on "0" 〉 set the number of winners you want 〉 click on "Start Raffle Game" button.<br />
                    *You can set the number of winners up to 50!</li>
                <li>Check the number of participants in real time on the [Raffle Game Screen] on the right.</li>
                <li>If the number of participants exceeds the number of winners, click the "End" button in time.</li>
            </ol>
            <br />
            <ol>How to set up an True or False Game
                <img src="/assets/images/contents/content_07_4.png" alt="" />
                <li>Click the [True or False Game] 〉 [Start] button.</li>
                <li>On the live screen, click the [Start Game!] phrase and communicate the question via chat or verbally.</li>
                <li>when a certain number of answers have been collected, click the [Finish] button to end the game.</li>
            </ol>
            <br />
            <ol>(Common) How to set up in the application
                <li>Click the [+] Games icon from the menu button at the bottom of the mobile application screen.</li>
                <li>Click the game you want to play from the game features.</li>
            </ol >
            <br />
            <ol>How to set up First-Come-First-Served games
                <li>Click on the game menu 〉 click on the "First Come First Served" icon 〉 click on the "Confirm" button.</li>
                <li>Enter the desired number of winners 〉 click the "First Come First Served" button to start the game.</li>
                <li>You can check the game status at the bottom left of the screen.</li>
                <li>When the number of participants reaches the number of winners, click the "Finish" button.</li>
            </ol>
            <br />
            <ol>How to set up Raffle Game
                <li>Go to the "Games" menu 〉 click on the "Raffle Game" icon 〉 click on the "Confirm" button.</li>
                <li>Enter the desired number of winners 〉 click the [Start Raffle Game] button to start the game.</li>
                <li>You can check the game status at the bottom left corner of the screen.</li>
                <li>If the number of participants exceeds the number of winners, click the "Quit" button in time to end the game.</li>
            </ol>
            <br />
            <ol>How to set up an True or False Game
                <li>Click on the "Games" menu 〉 click on the "True or False Game" icon 〉 click on the "Confirm" button.</li>
                <li>Click the [Start] button to start the game.</li>
                <li>On the live screen, click [Start Game!] Review the phrases and communicate the question via chat or verbally.</li>
                <li>When a certain number of answers have been collected, click the [End] button to end the game.</li>
            </ol>
        </>,
        date: '2023.03.31'
    },
    {
        id: 7,
        image: '/assets/images/contents/content_09.png',
        title: "Now you've heard it all! All about live and social commerce explained",
        description: <>
            <p>In recent years, the buzzwords often heard in the e-commerce industry are "live commerce" and "social commerce. These have attracted attention as new business methods that are different from traditional online shopping. However, many people may have a vague understanding of what "live commerce" and "social commerce" are, even if they can understand them to some extent. In this article, we will explain the meaning, advantages and disadvantages, and what makes them different.</p>
            <br />
            <h2>What is LiveCommerce?</h2>
            <p>Live commerce is a form of e-commerce that uses live streaming to sell products or services. </p>
            <p>Typically, live commerce combines online shopping with live streaming to demonstrate and sell products. Even if there is no physical store, live streaming is used to convey the appeal of the products and allow viewers to purchase them. Recently, with advances in communication technology and improvements in smartphone capabilities, even small businesses and individual entrepreneurs can easily conduct live streaming. Live commerce can also give viewers a sense of presence and familiarity that was not possible with traditional online shopping, where sellers provided only the information they wanted to convey on product pages. In addition, real-time communication through comments allows for feedback on products.</p>
            <br />
            <h2>What is Social Commerce?</h2>
            <p>Social commerce refers to the practice of selling goods or services using social media platforms or tools such as Facebook, Instagram, Twitter, YouTube, messaging applications such as LINE or WeChat, and more. It often involves using information sharing, word-of-mouth, and influencer marketing to promote the sale of goods or services through social media. Social commerce also includes the use of social media advertising, campaigns, coupons, and other tools to increase brand awareness and drive sales. By communicating and sharing information with customers through social media, companies can deepen their relationships with customers and use feedback to improve their products or services.</p>
            <br />
            <h2>How Live Commerce differs from Social Commerce</h2>
            <p>Live commerce and social commerce both involve using social media platforms to sell products, but there are differences between the two. </p>
            <p>Live commerce involves selling products through live streaming, and while this can be done using social media accounts, it is not always nece</p>ssary to have one. On the other hand, social commerce involves using social media to sell products, and having a social media account is a prerequisite. By leveraging their respective strengths, sellers can build relationships with consumers and consumers can find products that meet their needs.
            <br />
            <h2>Pros and Cons of Live Commerce</h2>
            <ol>Benefits of Live Commerce
                <li>Realistically convey the appeal of the product: In live commerce, it is possible to convey the appeal of the product in a more realistic way by sharing actions such as touching or wearing the product in real time.</li>
                <li>Two-way communication with users: In live commerce, it is possible to have two-way communication with users in real time. It is possible to directly respond to questions and opinions and strengthen the relationship with users.</li>
                <li>Fewer time and place restrictions: Live commerce is conducted online, so there are fewer restrictions on time and place compared to physical stores. Consumers can buy products in their favorite places, such as at home or on the go.</li>
            </ol>
            <br />
            <ol>Disadvantages of Live Commerce
                <li>technical problems: Because live commerce happens in real time, network problems or equipment failures can interrupt live streaming or prevent purchases. </li>
                <li>Lack of know-how: It takes time to acquire the know-how that is important for good live commerce, such as how to plan interesting events or how to make products look more realistic.</li>
            </ol>
            <br />
            <h2>Pros and cons of Social Commerce</h2>
            <ol>Benefits of Social Commerce
                <li>Reduced advertising costs: Since social media is essentially free to use, social commerce can be implemented even without a budget. Since products can be promoted directly through social media, it also has the effect of reducing advertising costs if social media accounts are successfully maintained.</li>
                <li>Increased willingness to buy: Social media is also used for communication, and the introduction of products and word-of-mouth can increase consumers' willingness to buy.</li>
                <li>market research: Analyzing user posts on social media can help identify needs and trends for products and services.</li>
            </ol>
            <br />
            <ol>Disadvantages of Social Commerce
                <li>A highly competitive environment: Social media is a highly competitive environment with high barriers to entry. The unit cost of acquiring followers has also become higher, increasing the burden of advertising and other costs. </li>
                <li>Lack of expertise: If there is no one in-house with the knowledge and expertise to manage social media, such as editing photos and videos, additional operational costs may be incurred.</li>
                <li>Regulatory issues: Social commerce must comply with consumer protection and advertising laws in each country. Failure to comply can lead to legal issues.</li>
            </ol>
        </>,
        date: '2023.03.31'
    },
    {
        id: 8,
        image: '/assets/images/contents/content_10.png',
        title: 'Short movie functionality has been added to the "Grip Cloud" live commerce solution.',
        description: <>
            <p>"On February 16, 2023, Grip Company (Headquarters: Korea, CEO: Kim Hanna) announced the addition of a Short-form feature to "Grip cloud".</p>
            <br />
            <p>"Grip Cloud" is a SaaS (Software as a Service) live commerce solution that allows customers to run live commerce directly on their own e-commerce sites. It provides a stable cloud infrastructure and holds a patent for its gaming features.</p>
            <p>The newly released "Grip Short" is a feature that allows companies to combine short-form video content and product sales on their own e-commerce sites or apps. It is unique in that companies can implement it within their own service rather than using an external SNS. Similar to YouTube Shorts and TikTok, users can skip to the next content through screen switching and can initiate purchases within the video screen, which is a feature of this feature.</p>
            <br />
            <p>Grip Company has recently focused on the combination of short videos and commerce, as global companies are trying to do with the increase in short video viewership. TikTok has released the TikTok Shop feature, which allows users to search for and purchase products within the app, and has been rolled out in Southeast Asia and North America, including Thailand and Malaysia. Instagram has also introduced shopping tags that allow users to purchase products without leaving the app, using a short video feature called Reels.</p>
            <br />
            <p>In the future, the company also plans to release advertising features and favorite features for short-form."</p>
        </>,
        date: '2023.03.31'
    },

]