import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from "styled-components"
import { FeatureSlide } from '../../../pages/home/Home.data'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"
import FeatureCard from './FeatureCard'
import { HomeSection } from '../../../pages/home/Home.style'

const FeatureSectionWrap = styled.div`
    .slick-track {
        display: flex;
    }
    @media (min-width:600px) and (max-width: 1199px) {
        .slick-dots {
            bottom: -70px;
        }
    }
    
    @media (max-width: 599px) {
        .slick-dots {
            bottom: 44px;
            ul {
                height: 8px;
                gap: 0 6px;
                li {
                    display: flex;
                    width: 8px;
                    height: 8px;
                    background-color: rgba(255,255,255,0.4);
                    overflow: hidden;
                    border-radius: 4px;
                    &.slick-active {
                        width: 40px;
                    }
                    button {
                        width: 8px;
                        height: 8px;
                        padding: 0;
                        transition: all 0.3s ease;
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .feature-slide-tab {
        display: flex;
        align-items: center;
        gap: 0 16px;
        margin-bottom: 40px;
        button {
            font-family: ${props => props.theme.fontFamily};
            font-size: 28px;
            font-weight: 700;
            border: 0;
            border-radius: 6px;
            color: #969696;
            padding: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 4px;
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover {
                color: #404040;
            }
            span {
                font-size: 32px;
            }
            &[data-selected='true'] {
                color: white;
            }
        }
        > span {
            color: #8E8E8E;
        }
        @media (min-width:600px) and (max-width: 1199px) {
            gap: 0 12px;
            button {
                font-size: 20px;
                span {
                    font-size: 24px;
                }
            }
        }
    }
`

interface FeatureSectionProps {
    language: string
}

const FeatureSection = (props: FeatureSectionProps) => {
    const { language } = props
    const { t } = useTranslation("main")

    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" })
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    const featureSlideEl = useRef<any>(null)
    const [featureState, setFeatureState] = useState<number>(0)
    const [featureCurrentSlide, setFeatureCurrentSlide] = useState<number>(0)

    const next = () => {
        if (featureSlideEl.current) {
            featureSlideEl.current.slickNext()
        }
    }
    const previous = () => {
        if (featureSlideEl.current) {
            featureSlideEl.current.slickPrev()
        }
    }

    const featureSettings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 500,
        arrows: false,
        infinite: true,
        beforeChange: (current: any, next: number) => {
            setFeatureCurrentSlide(next)
        },
        afterChange: (newIndex: number) => {
            setFeatureState(newIndex)
        },
        appendDots: (dots: any) => (
            <div>
                <ul>{dots}</ul>
                <button className="slide-button" onClick={previous}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M12 3L6 9L12 15" stroke="white" strokeWidth="2" />
                    </svg>
                </button>
                <button className="slide-button" onClick={next}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M6 3L12 9L6 15" stroke="white" strokeWidth="2" />
                    </svg>
                </button>
            </div>
        ),
        customPaging: (index: any) => (
            <button
                style={{
                    backgroundColor: featureCurrentSlide >= index ? '#568DFD' : undefined,
                    borderTopRightRadius: featureCurrentSlide === index ? '3px' : 0,
                    borderBottomRightRadius: featureCurrentSlide === index ? '3px' : 0,
                }}
            />
        ),
        responsive: [
            {
                breakpoint: 599,
                settings: {
                    variableWidth: false,
                    appendDots: (dots: any) => (
                        <div>
                            <ul>{dots}</ul>
                        </div>
                    ),
                    customPaging: (index: any) => (
                        <button
                            style={{
                                backgroundColor: featureCurrentSlide === index ? 'white' : undefined,
                                width: featureCurrentSlide === index ? 40 : 8
                            }}
                        />
                    )
                }
            },
        ],
    }

    return (
        <HomeSection bg='black' pt={isPc ? 120 : isTa ? 74 : 0.1} pl={isPc ? 40 : isTa ? 30 : 0} pb={isPc ? 150 : isTa ? 120 : 0.1}>
            <FeatureSectionWrap>
                {!isMo &&
                    <header className='feature-slide-tab'>
                        {[1, 2, 3, 4, 5].map((item: number, i: number) => {
                            return (
                                <React.Fragment key={i}>
                                    <button
                                        data-selected={featureState === i}
                                        onClick={() => {
                                            if (featureSlideEl.current) {
                                                featureSlideEl.current.slickGoTo(i)
                                            }
                                        }}
                                    >
                                        {language === 'ja' ?
                                            <><span>0{item}</span> {t("feature.tab")}</>
                                            :
                                            <>{t("feature.tab")} <span>0{item}</span></>
                                        }
                                    </button>
                                    {item < 5 &&
                                        <span>|</span>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </header>
                }
                <Slider ref={featureSlideEl} {...featureSettings}>
                    {FeatureSlide.map((item: any, i: number) => {
                        return (
                            <div key={i} style={{ width: isPc ? 1148 : isTa ? 650 : 399 }}>
                                <FeatureCard index={i + 1} item={item} playable={i === featureState} language={language} />
                            </div>
                        )
                    })}
                </Slider>
            </FeatureSectionWrap>
        </HomeSection>
    )
}

export default FeatureSection