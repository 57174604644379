import Reveal from "react-awesome-reveal";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { NewServiceCard } from "../../../../pages/home/Home.data";
import {
  HomeSectionInner,
  fadeUp,
  HomeSection,
  screen,
  media,
} from "../../../../pages/home/Home.style";
import Typography from "../../InnerTypography";

const ServiceSectionWrap = styled.div`
  > article {
    text-align: center;
    margin-bottom: 56px;
  }
  > div {
    width: fit-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;

    ${media.ta} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${media.mo} {
      padding: 0 20px;
      grid-template-columns: 1fr;
    }

    .card-group {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      border-radius: 1.5rem;
      overflow: hidden;
      align-items: center;
      text-align: center;
      transition: all 0.3s ease;

      width: 18.1875rem;
      height: 16.25rem;
      padding: 1.5rem;

      ${media.ta} {
        width: 21.125rem;
      }
      ${media.mo} {
        width: 100%;
        height: 14.375rem;
        padding: 1rem;
      }
      &:hover {
        transform: scale(1.05);
      }
      img {
        width: 4.375rem;
        height: 4.375rem;
      }
      p {
        line-height: 1.2;
      }
    }
  }
`;

const ServiceSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection bg="#F3F3F3" pt={isPc ? 140 : isTa ? 120 : 150} pb={isMo ? 172 : 120}>
      <HomeSectionInner>
        <ServiceSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography.Header3
                  fontWeight={700}
                  fontSize={"1.5rem"}
                  color="#003d99"
                  letterSpacing={"-0.015rem"}
                >
                  <Trans i18nKey={"main:feature.card1.live"}>feature.card1.live</Trans>
                </Typography.Header3>
                <Typography.Header2
                  fontWeight={700}
                  fontSize={"3.125rem"}
                  color="#343434"
                  letterSpacing={"-0.03125rem"}
                >
                  <Trans i18nKey={"main:service.title"}>service.title</Trans>
                </Typography.Header2>
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography.Header3
                fontWeight={700}
                fontSize={"1.5rem"}
                color="#003d99"
                letterSpacing={"-0.015rem"}
              >
                <Trans i18nKey={"main:feature.card1.live"}>feature.card1.live</Trans>
              </Typography.Header3>
              <Typography.Header4 fontWeight={700} fontSize={"1.25rem"} color="#222">
                <Trans i18nKey={"main:service.title"}>service.title</Trans>
              </Typography.Header4>

              {/* <Typography.Paragraph
                fontWeight={400}
                fontSize={"1rem"}
                color="rgba(34,34,34,0.8)"
                textAlign="center"
              >
                <Trans i18nKey={"main:service.desc"}>service.desc</Trans>
              </Typography.Paragraph> */}
            </article>
          )}
          <div>
            {!isMo ? (
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={400}
                delay={1000}
                cascade
                damping={0.25}
              >
                {NewServiceCard(language).map((item: any, i: number) => {
                  return (
                    <div className="card-group" key={i}>
                      <img src={item.icon} alt="" />
                      <Typography.Header4 fontWeight={700} fontSize={"1.25rem"} color="#222">
                        <Trans i18nKey={item.title}>{item.title}</Trans>
                      </Typography.Header4>
                      <Typography.Paragraph
                        fontWeight={400}
                        fontSize={"1rem"}
                        color="rgba(34,34,34,0.8)"
                        textAlign="center"
                      >
                        <Trans i18nKey={item.description}>{item.description}</Trans>
                      </Typography.Paragraph>
                    </div>
                  );
                })}
              </Reveal>
            ) : (
              NewServiceCard(language).map((item: any, i: number) => {
                return (
                  <div className="card-group" key={i}>
                    <img src={item.icon} alt="" />
                    <Typography.Header4 fontWeight={700} fontSize={"1.25rem"} color="#222">
                      <Trans i18nKey={item.title}>{item.title}</Trans>
                    </Typography.Header4>
                    <Typography.Paragraph
                      fontWeight={400}
                      fontSize={"1rem"}
                      color="rgba(34,34,34,0.8)"
                      textAlign="center"
                    >
                      <Trans i18nKey={item.description}>{item.description}</Trans>
                    </Typography.Paragraph>
                  </div>
                );
              })
            )}
          </div>
        </ServiceSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default ServiceSection;
