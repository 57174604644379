import Reveal from "react-awesome-reveal";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { HomeSectionInner, fadeUp, HomeSection, fadeIn } from "../../../pages/home/Home.style";
import Typography from "../Typography";

const PartnerSectionWrap = styled.div`
  @keyframes partnerPrimary {
    from {
      top: 0;
    }
    to {
      top: -910px;
    }
  }
  @keyframes partnerSecondary {
    from {
      top: 910px;
    }
    to {
      top: 0;
    }
  }

  @keyframes partnerPrimaryTa {
    from {
      top: 0;
    }
    to {
      top: -1254px;
    }
  }
  @keyframes partnerSecondaryTa {
    from {
      top: 1254px;
    }
    to {
      top: 0;
    }
  }

  @keyframes partnerPrimaryMo {
    from {
      top: 0;
    }
    to {
      top: -611px;
    }
  }
  @keyframes partnerSecondaryMo {
    from {
      top: 611px;
    }
    to {
      top: 0;
    }
  }
  > article {
    margin-bottom: 80px;
    text-align: center;
  }
  > div {
    height: 480px;
    overflow: hidden;
    text-align: center;
    > div {
      height: 100%;
    }
    .partner-slider-wrap {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      .partner-slider {
        max-width: 1110px;
        width: 100%;
        height: 910px;
        position: absolute;
        top: 0;
        animation-play-state: running;
        &.top {
          animation: partnerPrimary 15s linear 0s infinite;
        }
        &.bottom {
          animation: partnerSecondary 15s linear 0s infinite;
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          width: 688px;
          height: 1254px;
          left: 50%;
          transform: translateX(-50%);
          &.top {
            animation: partnerPrimaryTa 15s linear 0s infinite;
          }
          &.bottom {
            animation: partnerSecondaryTa 15s linear 0s infinite;
          }
        }
        @media (max-width: 767px) {
          width: 335px;
          height: 611px;
          left: 50%;
          transform: translateX(-50%);
          &.top {
            animation: partnerPrimaryMo 20s linear 0s infinite;
          }
          &.bottom {
            animation: partnerSecondaryMo 20s linear 0s infinite;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    > article {
      margin-bottom: 70px;
    }
    > div {
      height: 560px;
    }
  }
  @media (max-width: 767px) {
    > article {
      margin-bottom: 40px;
    }
    > div {
      height: 90vw;
    }
  }
`;

const PartnerSection = () => {
  const { t } = useTranslation("main");

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  return (
    <HomeSection pt={isPc ? 150 : isTa ? 130 : 134} pb={isPc ? 180 : isTa ? 130 : 134}>
      <HomeSectionInner>
        <PartnerSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography
                  label={t("partner.title")}
                  size={isPc ? 80 : 64}
                  weight={800}
                  lineHeight={isPc ? 80 : 90}
                  mb={isPc ? 24 : 20}
                />
                <Typography
                  label={
                    <Trans i18nKey={"main:partner.desc"}>
                      partner.desc
                      <br className="none-pc" />
                    </Trans>
                  }
                  size={24}
                />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography
                label={t("partner.title")}
                size={34}
                weight={800}
                lineHeight={40}
                mb={16}
              />
              <Typography
                label={
                  <Trans i18nKey={"main:partner.desc"}>
                    partner.desc
                    <br />
                  </Trans>
                }
                size={16}
              />
            </article>
          )}
          <div>
            {!isMo ? (
              <Reveal className="reveal" keyframes={fadeIn} duration={600} delay={200}>
                <div className="partner-slider-wrap">
                  <img
                    className="partner-slider top"
                    src={
                      isPc
                        ? "/assets/images/partner_list.png"
                        : isTa
                        ? "/assets/images/partner_list_m.png"
                        : "/assets/images/partner_list_s.png"
                    }
                    alt="partner list"
                  />
                  <img
                    className="partner-slider bottom"
                    src={
                      isPc
                        ? "/assets/images/partner_list.png"
                        : isTa
                        ? "/assets/images/partner_list_m.png"
                        : "/assets/images/partner_list_s.png"
                    }
                    alt="partner list"
                  />
                </div>
              </Reveal>
            ) : (
              <div className="partner-slider-wrap">
                <img
                  className="partner-slider top"
                  src="/assets/images/partner_list_s.png"
                  alt="partner list"
                />
                <img
                  className="partner-slider bottom"
                  src="/assets/images/partner_list_s.png"
                  alt="partner list"
                />
              </div>
            )}
          </div>
        </PartnerSectionWrap>
      </HomeSectionInner>
      {/* <Typography label={t("partner.comment")} size={12} color='#666666' /> */}
    </HomeSection>
  );
};

export default PartnerSection;
