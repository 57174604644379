import Reveal from 'react-awesome-reveal'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from "styled-components"
import { HomeSectionInner, fadeUp, HomeSection } from '../../../pages/home/Home.style'
import Typography from '../Typography'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"
import { NeedSlide } from '../../../pages/home/Home.data'
import { useRef, useState } from 'react'

const NeedSlideCard = styled.div`
    height: 182px;
    display: flex;
    align-items: center;
    gap: 0 20px;
    padding: 20px;
    margin-left: 32px;
    background-color: #FFFFFF;
    border: 1px solid #F8F8F8;
    border-radius: 16px;
    box-shadow: 0px 174px 70px rgba(0, 0, 0, 0.01), 0px 98px 59px rgba(0, 0, 0, 0.05), 0px 44px 44px rgba(0, 0, 0, 0.09), 0px 11px 24px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    img {
        width: 100px;
        height: 100px;
    }
    @media (max-width: 599px) {
        height: auto;
        flex-direction: column;
        gap: 16px 0;
        margin-left: 20px;
        img {
            width: 92px;
            height: 92px;
        }
        p {
            display: flex;
            align-items: center;
        }
    }
`
interface NeedSectionProps {
    language: string
}

const NeedSection = (props: NeedSectionProps) => {
    const { language } = props
    const { t } = useTranslation("main")

    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    const needSlideEl = useRef<any>(null)
    const [needCurrentSlide, setNeedCurrentSlide] = useState<number>(0)

    const next = () => {
        if (needSlideEl.current) {
            needSlideEl.current.slickNext()
        }
    }
    const previous = () => {
        if (needSlideEl.current) {
            needSlideEl.current.slickPrev()
        }
    }

    const needSettings = {
        dots: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    centerMode: false,
                }
            },
        ],
        beforeChange: (current: any, next: number) => {
            setNeedCurrentSlide(next)
        },
        appendDots: (dots: any) => (
            <div style={{ bottom: '-100px' }}>
                <ul className='light'>{dots}</ul>
                <button className="slide-button" onClick={previous}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M12 3L6 9L12 15" stroke="#363636" strokeWidth="2" />
                    </svg>
                </button>
                <button className="slide-button" onClick={next}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M6 3L12 9L6 15" stroke="#363636" strokeWidth="2" />
                    </svg>
                </button>
            </div>
        ),
        customPaging: (index: any) => (
            <button
                style={{
                    backgroundColor: needCurrentSlide >= index ? '#568DFD' : undefined,
                    borderTopRightRadius: needCurrentSlide === index ? '3px' : 0,
                    borderBottomRightRadius: needCurrentSlide === index ? '3px' : 0,
                }}
            />
        )
    }

    return (
        <HomeSection pt={isMo ? 160 : 180} pb={isMo ? 160 : 240}>
            <HomeSectionInner pb={isMo ? 64 : 80}>
                {!isMo ?
                    <article>
                        <Reveal className='reveal' keyframes={fadeUp} duration={600} delay={100} cascade damping={0.25}>
                            <Typography label={t("need.title1")} size={isPc ? 72 : 62} weight={800} lineHeight={isPc ? 72 : 74} mb={isPc ? 24 : 0} />
                            <Typography label={<><span>Grip cloud</span><Trans i18nKey={'main:need.title2'}>need.title2<br className='none-pc' /></Trans></>} size={isPc ? 72 : 62} weight={800} lineHeight={isPc ? 72 : 74} />
                        </Reveal>
                    </article>
                    :
                    <article>
                        <Typography label={t("need.title1")} size={34} weight={800} lineHeight={38} />
                        <Typography label={<><span>Grip cloud</span><Trans i18nKey={'main:need.title2'}>need.title2<br /></Trans></>} size={34} weight={800} lineHeight={40} />
                    </article>
                }
            </HomeSectionInner>
            <Slider ref={needSlideEl} {...needSettings}>
                {NeedSlide.map((item: any, i: number) => {
                    return (
                        <div key={i} style={{ width: isMo ? 240 : 420 }}>
                            <NeedSlideCard>
                                <img src={item.avatar} alt="" />
                                <Typography
                                    label={t(item.description)}
                                    size={isMo ? 16 : 20}
                                    lineHeight={isMo ? 22 : 28}
                                    style={{
                                        height: isMo ? language === 'ja' ? '88px' : '110px' : 'auto'
                                    }}
                                />
                            </NeedSlideCard>
                        </div>
                    )
                })}
            </Slider>
        </HomeSection>
    )
}

export default NeedSection