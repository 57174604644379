import { useState } from 'react'
import Reveal from 'react-awesome-reveal'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled, { keyframes } from "styled-components"
import { HomeSectionInner, fadeUp, HomeSection, fadeIn } from '../../../pages/home/Home.style'
import Typography from '../Typography'

const dotAni = keyframes`
    to {
        background-color: #568DFD;
        box-shadow: 0 0 0 12px rgba(86, 141, 253, 0.2);
    }
`
const lastDotAni = keyframes`
    to {
        opacity: 1;
        box-shadow: 0 0 0 12px rgba(86, 141, 253, 0.2);
    }
`
const onAirAni = keyframes`
    to {
        opacity: 1;
    }
`
const pAni = keyframes`
    to {
        color: white;
    }
`
const lineAni = keyframes`
    to {
        background-color: #568DFD;
    }
`

const ProcessSectionWrap = styled.div`
    > article {
        text-align: center;
        margin-bottom: 36px;
    }
    div.step {
        width: 1070px;
        display: grid;
        grid-template-columns: 24px 1fr 24px 1fr 24px 1fr 24px 1fr 24px;
        grid-template-rows: 1fr;
        margin: 0 auto;
        padding: 120px 0;
        > div {
            display: flex;
            align-items: center;
            &.dot {
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: #4F4F4F;
                position: relative;
                box-shadow: 0 0 0 0 #4F4F4F;
                animation: ${dotAni} 0.5s linear forwards;
                p {
                    position: absolute;
                    top: 72px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: max-content;
                    text-align: center;
                    color: #4F4F4F;
                    animation: ${pAni} 0.5s linear forwards;
                }
                &:nth-child(1) {animation-delay: 0.5s; }
                &:nth-child(3) {animation-delay: 1s; }
                &:nth-child(5) {animation-delay: 1.5s; }
                &:nth-child(7) {animation-delay: 2s; }
                &:nth-child(9) {animation-delay: 2.5s; }
                .last-dot {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 72px;
                    height: 72px;
                    border-radius: 36px;
                    background-color: rgba(86, 141, 253, 0.3);
                    z-index: -1;
                    opacity: 0;
                    animation: ${lastDotAni} 0.5s 2.5s linear forwards;
                }
                .on-air {
                    width: max-content;
                    position: absolute;
                    top: -108px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: #ff0000;
                    border-radius: 2em;
                    padding: 10px 20px;
                    color: white;
                    font-size: 20px;
                    font-weight: 700;
                    opacity: 0;
                    animation: ${onAirAni} 0.5s 3s linear forwards;
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        width: 0;
                        height: 0;
                        border: 8px solid transparent;
                        border-top-color: #ff0000;
                        border-bottom: 0;
                        margin-left: -8px;
                        margin-bottom: -8px;
                    }
                }
            }
            &.line {
                height: 2px;
                background-color: #4F4F4F;
                margin-top: 11px;
                animation: ${lineAni} 1s linear forwards;
                &:nth-child(2) {animation-delay: 1s; }
                &:nth-child(4) {animation-delay: 1.5s; }
                &:nth-child(6) {animation-delay: 2s; }
                &:nth-child(8) {animation-delay: 2.5s; }
            }
        }
    }
    @media (min-width:600px) and (max-width: 1199px) {
        div.step {
            width: calc(100% - 100px);
        }
    }
    @media (max-width: 599px) {
        div.step {
            width: max-content;
            height: max-content;
            grid-template-columns: 1fr;
            grid-template-rows: 20px 100px 20px 100px 20px 100px 20px 100px 20px;
            padding: 20px 0;
            > div {
                justify-content: center;
                &.dot {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    p {
                        top: 50%;
                        left: -150px;
                        transform: translateY(-50%);
                        width: max-content;
                        text-align: left;
                    }
                    .on-air {
                        top: 50%;
                        left: 80px;
                        transform: translateY(-50%);
                        font-size: 20px;
                        &::after {
                            bottom: auto;
                            left: 2px;
	                        top: 50%;
                            border: 8px solid transparent;
                            border-left: 0;
                            border-bottom: auto;
                            border-top-color: transparent;
                            border-right-color: #ff0000;
                            margin-top: -8px;
                            margin-bottom: -8px;
                        }
                    }
                }
                &.line {
                    width: 2px;
                    height: 100%;
                    margin-top: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
`


const ProcessSection = () => {
    const { t } = useTranslation("main")

    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    const [stepAni, setStepAni] = useState<boolean>(false)

    return (
        <HomeSection bg='black' pt={!isMo ? 240 : 70} pb={!isMo ? 260 : 70}>
            <HomeSectionInner pb={isMo ? 64 : 80}>
                <ProcessSectionWrap>
                    {!isMo ?
                        <article>
                            <Reveal className='reveal' keyframes={fadeUp} duration={600} delay={100} cascade damping={0.25}>
                                <Typography label={t("process.title1")} size={isPc ? 80 : 64} weight={800} lineHeight={isPc ? 80 : 76} mb={isPc ? 16 : 8} color='white' />
                                <Typography label={t("process.title2")} size={isPc ? 80 : 64} weight={800} lineHeight={isPc ? 80 : 76} mb={24} color='white' />
                                <Typography label={t("process.desc")} size={24} lineHeight={24} color='white' />
                            </Reveal>
                        </article>
                        :
                        <article>
                            <Typography label={t("process.title1")} size={34} weight={800} lineHeight={38} mb={8} color='white' />
                            <Typography label={t("process.title2")} size={34} weight={800} lineHeight={38} mb={20} color='white' />
                            <Typography label={t("process.desc")} size={16} lineHeight={26} color='white' />
                        </article>
                    }
                    <Reveal className='reveal' keyframes={fadeIn} duration={600} delay={200}
                        onVisibilityChange={(inView: boolean, entry: IntersectionObserverEntry) => {
                            if (inView) {
                                setStepAni(true)
                            } else {
                                setStepAni(false)
                            }
                        }}
                    >
                        <div className='step'>
                            <div className="dot" style={{ animationPlayState: stepAni ? 'running' : 'paused' }}>
                                <Typography label={<Trans i18nKey={'main:process.step1'}>process.step1<br /><br /></Trans>} size={isPc ? 24 : 16} weight={500} lineHeight={isPc ? 36 : 24}
                                    style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '0.5s' }}
                                />
                            </div>
                            <div className="line" style={{ animationPlayState: stepAni ? 'running' : 'paused' }} />
                            <div className="dot" style={{ animationPlayState: stepAni ? 'running' : 'paused' }}>
                                <Typography label={<Trans i18nKey={'main:process.step2'}>process.step2<br /><br /></Trans>} size={isPc ? 24 : 16} weight={500} lineHeight={isPc ? 36 : 24}
                                    style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '1s' }}
                                />
                            </div>
                            <div className="line" style={{ animationPlayState: stepAni ? 'running' : 'paused' }} />
                            <div className="dot" style={{ animationPlayState: stepAni ? 'running' : 'paused' }}>
                                <Typography label={<Trans i18nKey={'main:process.step3'}>process.step3<br /><br /></Trans>} size={isPc ? 24 : 16} weight={500} lineHeight={isPc ? 36 : 24}
                                    style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '1.5s' }}
                                />
                            </div>
                            <div className="line" style={{ animationPlayState: stepAni ? 'running' : 'paused' }} />
                            <div className="dot" style={{ animationPlayState: stepAni ? 'running' : 'paused' }}>
                                <Typography label={<Trans i18nKey={'main:process.step4'}>process.step4<br /><br /></Trans>} size={isPc ? 24 : 16} weight={500} lineHeight={isPc ? 36 : 24}
                                    style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '2s' }}
                                />
                            </div>
                            <div className="line" style={{ animationPlayState: stepAni ? 'running' : 'paused' }} />
                            <div className="dot" style={{ animationPlayState: stepAni ? 'running' : 'paused' }}>
                                <Typography label={<Trans i18nKey={'main:process.step5'}>process.step5<br /><br /></Trans>} size={isPc ? 24 : 16} weight={500} lineHeight={isPc ? 36 : 24}
                                    style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '2.5s' }}
                                />
                                <div className="last-dot" style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '2.5s' }}></div>
                                <div className="on-air" style={{ animationPlayState: stepAni ? 'running' : 'paused', animationDelay: '3s' }}>On Air</div>
                            </div>
                        </div>
                    </Reveal>
                </ProcessSectionWrap>
            </HomeSectionInner>
        </HomeSection>
    )
}

export default ProcessSection