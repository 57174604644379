import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import CountUp from "react-countup";
import {
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import Typography from "../../Typography";
import InnerTypography from "../../InnerTypography";
import { useTranslation } from "react-i18next";

const DataSectionWrap = styled.div`
  padding: 0 20px;
  > article {
    text-align: center;
    margin-bottom: 160px;
  }
  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    figure {
      padding: 0;

      display: inline-flex;
      flex-flow: column nowrap;

      > span:first-of-type {
        line-height: 1.25;
      }
    }
    p {
      span {
        color: white;
      }
    }
  }
  ${media.ta} {
    > article {
      margin-bottom: 70px;
    }
    > div {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 60px;
    }
  }

  ${media.mo} {
    > article {
      margin-bottom: 40px;
    }
    > div {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 40px;
    }
  }
`;

const DataSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection
      className="bokeh"
      bg="black"
      pt={isPc ? 280 : isTa ? 100 : 70}
      pb={isPc ? 280 : isTa ? 100 : 80}
    >
      <HomeSectionInner>
        <DataSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography
                  label={language === "ko" ? "그립 클라우드를 사용하시는 고객들은" : "数字で見る"}
                  size={isPc ? 50 : 48}
                  weight={700}
                  lineHeight={isPc ? 55 : 52.8}
                  color="white"
                />
                <Typography
                  label={
                    language === "ko" ? (
                      <>
                        지금 이 순간도 놀라운 <br className="none-pc" />
                        결과를 만들어내고 있습니다.
                      </>
                    ) : (
                      "Grip Cloudの導入効果"
                    )
                  }
                  size={isPc ? 50 : 48}
                  weight={700}
                  lineHeight={isPc ? 55 : 52.8}
                  color="white"
                />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography
                label={
                  language === "ko" ? (
                    <>
                      그립 클라우드를 사용하시는 고객들은 지금 이 순간도 놀라운 결과를 만들어내고
                      있습니다.
                    </>
                  ) : (
                    <>
                      数字で見る
                      <br />
                      Grip Cloudの導入効果
                    </>
                  )
                }
                size={28}
                weight={700}
                lineHeight={33.6}
                color="white"
                style={{ wordBreak: "keep-all" }}
              />
            </article>
          )}
          <div>
            <figure>
              <InnerTypography.Span
                fontSize={isPc ? "7.5rem" : isTa ? "5rem" : "3.75rem"}
                fontWeight={800}
                letterSpacing={"-1px"}
                color="white"
              >
                +
                {!isMo ? (
                  <CountUp start={0} end={2} duration={1} enableScrollSpy>
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                ) : (
                  "2"
                )}
                min
              </InnerTypography.Span>
              <InnerTypography.Span
                fontSize={isPc ? "1.125rem" : isTa ? "1rem" : "0.875rem"}
                color="white"
              >
                {language === "ko"
                  ? "게임 기능 활용 시 증가하는 평균 시청시간"
                  : "ゲーム機能活用時に増加する平均視聴時間"}
              </InnerTypography.Span>
            </figure>
            <figure>
              <InnerTypography.Span
                fontSize={isPc ? "7.5rem" : isTa ? "5rem" : "3.75rem"}
                fontWeight={800}
                color="white"
              >
                x
                {!isMo ? (
                  <CountUp start={0} end={104} duration={1} enableScrollSpy>
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                ) : (
                  "104"
                )}
                {language === "ko" ? "배" : "倍"}
              </InnerTypography.Span>
              <InnerTypography.Span
                fontSize={isPc ? "1.125rem" : isTa ? "1rem" : "0.875rem"}
                color="white"
              >
                {language === "ko" ? "고객사 평균 뷰 수 증가율*" : "平均ビュー数増加率*"}
              </InnerTypography.Span>
            </figure>
            <figure>
              <InnerTypography.Span
                fontSize={isPc ? "7.5rem" : isTa ? "5rem" : "3.75rem"}
                fontWeight={800}
                color="white"
              >
                +
                {!isMo ? (
                  <CountUp start={0} end={290} duration={1} enableScrollSpy>
                    {({ countUpRef }) => <span ref={countUpRef} />}
                  </CountUp>
                ) : (
                  "290"
                )}
                M
              </InnerTypography.Span>
              <InnerTypography.Span
                fontSize={isPc ? "1.125rem" : isTa ? "1rem" : "0.875rem"}
                color="white"
              >
                {language === "ko" ? "고객사 누적 시청 PV" : "顧客社の累積視聴PV"}
              </InnerTypography.Span>
            </figure>
          </div>
        </DataSectionWrap>
      </HomeSectionInner>
      {!isMo && (
        <Typography
          label={language === "ko" ? "*2021년 대비 2022년" : "*2021年対比2022年"}
          size={12}
          lineHeight={16}
          color="#969696"
        />
      )}
    </HomeSection>
  );
};

export default DataSection;
