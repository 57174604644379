import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ContentsData } from './Content.data'
import { ContentsDataEn } from './Content.data.en'
import { ContentSection, ContentSectionInner, ContentWrap } from './Content.style'

interface ContentProps {
    language: string
}

const Content = (props: ContentProps) => {
    const { language } = props
    const { contentId } = useParams()
    const navigate = useNavigate()
    const [result, setResult] = useState<any>()

    useEffect(() => {
        if (language === 'ja') {
            setResult(ContentsData.find(item => item.id === (contentId && parseInt(contentId))))
        } else {
            setResult(ContentsDataEn.find(item => item.id === (contentId && parseInt(contentId))))
        }
    }, [language, contentId])

    return (
        <ContentWrap>
            <nav>
                <div>
                    <Link to={language === 'ko' ? '/' : language === 'ja' ? '/jp' : '/en'}>Home</Link>
                    <span>/</span>
                    <p>
                        {language === 'ja' ?
                            'ブログ'
                            :
                            'Blog Grip cloud'
                        }
                    </p>
                </div>
            </nav>
            <ContentSection>
                <ContentSectionInner>
                    <h1>{result && result.title}</h1>
                    <label>{result && result.date}</label>
                    <img src={result && result.image} alt="" />
                    <div className="description">
                        {result && result.description}
                    </div>
                    <button onClick={() => language === 'ja' ? navigate('/jp') : navigate('/en')}>
                        {language === 'ja' ?
                            '戻る'
                            :
                            'Go to main'
                        }
                    </button>
                </ContentSectionInner>
            </ContentSection>
        </ContentWrap>
    )
}

export default Content