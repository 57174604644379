import Reveal from 'react-awesome-reveal'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from "styled-components"
import { HomeSectionInner, fadeUp, HomeSection } from '../../../pages/home/Home.style'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"
import Typography from '../Typography'
import { useRef, useState } from 'react'
import { ReviewCard } from '../../../pages/home/Home.data'

const ReviewSlideCard = styled.div`
    height: 310px;
    display: flex;
    flex-direction: column;
    gap: 0 20px;
    padding: 32px;
    margin-right: 20px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 180px 72px rgba(0, 0, 0, 0.01), 0px 101px 61px rgba(0, 0, 0, 0.05), 0px 45px 45px rgba(0, 0, 0, 0.09), 0px 11px 25px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    img {
        width: 180px;
        height: 60px;
        margin-bottom: 16px;
    }
    p + p {
        margin-top: auto;
    }
    @media (max-width: 599px) {
        height: 200px;
        padding: 28px 24px;
        img {
            width: 120px;
            height: 40px;
        }
    }
`

const ReviewSection = () => {
    const { t } = useTranslation("main")

    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" })
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    const reviewSlideEl = useRef<any>(null)
    const [reviewCurrentSlide, setReviewCurrentSlide] = useState<number>(0)

    const next = () => {
        if (reviewSlideEl.current) {
            reviewSlideEl.current.slickNext()
        }
    }
    const previous = () => {
        if (reviewSlideEl.current) {
            reviewSlideEl.current.slickPrev()
        }
    }


    const reviewSettings = {
        dots: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
        ],
        beforeChange: (current: any, next: number) => {
            setReviewCurrentSlide(next)
        },
        appendDots: (dots: any) => (
            <div>
                <ul>{dots}</ul>
                <button className="slide-button" onClick={previous}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M12 3L6 9L12 15" stroke="white" strokeWidth="2" />
                    </svg>
                </button>
                <button className="slide-button" onClick={next}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M6 3L12 9L6 15" stroke="white" strokeWidth="2" />
                    </svg>
                </button>
            </div>
        ),
        customPaging: (index: any) => (
            <button
                style={{
                    backgroundColor: reviewCurrentSlide >= index ? '#568DFD' : undefined,
                    borderTopRightRadius: reviewCurrentSlide === index ? '3px' : 0,
                    borderBottomRightRadius: reviewCurrentSlide === index ? '3px' : 0,
                }}
            />
        )
    }

    return (
        <HomeSection bg='black' pt={!isMo ? 210 : 130} pb={!isMo ? 260 : 180}>
            <HomeSectionInner pb={90}>
                {!isMo ?
                    <article>
                        <Reveal className='reveal' keyframes={fadeUp} duration={600} delay={100} cascade damping={0.25}>
                            <Typography label={<Trans i18nKey={"main:review.title"}>review.title<br /></Trans>} size={isPc ? 80 : 64} weight={800} lineHeight={isPc ? 80 : 90} color='white' mb={24} />
                            <Typography label={t("review.desc")} size={24} color='white' />
                        </Reveal>
                    </article>
                    :
                    <article>
                        <Typography label={<Trans i18nKey={"main:review.title"}>review.title<br /></Trans>} size={34} weight={800} lineHeight={40} color='white' mb={24} />
                        <Typography label={t("review.desc")} size={16} color='white' />
                    </article>
                }

            </HomeSectionInner>
            <Slider ref={reviewSlideEl} {...reviewSettings}>
                {ReviewCard.map((item: any, i: number) => {
                    return (
                        <div key={i} style={{ width: isPc ? 430 : isTa ? 380 : 260 }}>
                            <ReviewSlideCard>
                                <img src={item.logo} alt="" />
                                <Typography label={<Trans i18nKey={item.comment}>{item.comment}<br /><br /><br /></Trans>} size={isPc ? 20 : isTa ? 18 : 12} lineHeight={isPc ? 30 : isTa ? 28 : 18} color='#000000' />
                                <Typography label={t(item.reviewer)} size={isPc ? 16 : isTa ? 12 : 10} color='#888888' />
                            </ReviewSlideCard>
                        </div>
                    )
                })}
            </Slider>
        </HomeSection>
    )
}

export default ReviewSection