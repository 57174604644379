import Reveal from "react-awesome-reveal";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { HomeSectionInner, fadeUp, HomeSection } from "../../../pages/home/Home.style";
import Typography from "../Typography";
import Button from "../Button";
import styled from "styled-components";

const FinishSectionWrap = styled.div`
  height: 100%;
  > article {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
  }
`;

interface FinishSectionProps {
  language: string;
  onClick: () => void;
}

const FinishSection = (props: FinishSectionProps) => {
  const { language, onClick } = props;
  const { t } = useTranslation("main");

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  return (
    <HomeSection bg="black" pt={isPc ? 320 : isTa ? 280 : 270} pb={!isMo ? 320 : 270}>
      <HomeSectionInner style={{ height: "100%" }}>
        <FinishSectionWrap>
          {language === "ja" ? (
            !isMo ? (
              <article>
                <Reveal
                  className="reveal"
                  keyframes={fadeUp}
                  duration={600}
                  delay={100}
                  cascade
                  damping={0.25}
                >
                  <Typography
                    label={t("finish.title1")}
                    size={isPc ? 80 : 64}
                    weight={800}
                    lineHeight={isPc ? 120 : 96}
                    color="white"
                  />
                  <Typography
                    label={
                      <Trans i18nKey={"main:finish.title2"}>
                        finish.title2
                        <br className="none-pc" />
                      </Trans>
                    }
                    size={isPc ? 80 : 64}
                    weight={800}
                    lineHeight={isPc ? 120 : 96}
                    color="white"
                    mb={isPc ? 70 : 110}
                  />
                  <Button label={t("inquire")} onClick={onClick} />
                </Reveal>
              </article>
            ) : (
              <article>
                <Typography
                  label={t("finish.title1")}
                  size={34}
                  weight={800}
                  lineHeight={51}
                  color="white"
                />
                <Typography
                  label={
                    <Trans i18nKey={"main:finish.title2"}>
                      finish.title2
                      <br />
                    </Trans>
                  }
                  size={34}
                  weight={800}
                  lineHeight={51}
                  color="white"
                />
              </article>
            )
          ) : !isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography
                  label={t("finish.title1")}
                  size={isPc ? 80 : 64}
                  weight={800}
                  lineHeight={isPc ? 96 : 77}
                  color="white"
                />
                <Typography
                  label={
                    <Trans i18nKey={"main:finish.title2"}>
                      finish.title2
                      <br />
                    </Trans>
                  }
                  size={isPc ? 80 : 64}
                  weight={800}
                  lineHeight={isPc ? 96 : 77}
                  color="white"
                  mb={isPc ? 70 : 110}
                />
                <Button label={t("inquire")} onClick={onClick} />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography
                label={t("finish.title1")}
                size={34}
                weight={800}
                lineHeight={41}
                color="white"
              />
              <Typography
                label={
                  <Trans i18nKey={"main:finish.title2"}>
                    finish.title2
                    <br />
                  </Trans>
                }
                size={34}
                weight={800}
                lineHeight={41}
                color="white"
              />
            </article>
          )}
        </FinishSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default FinishSection;
