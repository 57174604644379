import styled from "styled-components"

export const CaseWrap = styled.main`
    width: 1360px;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    overflow: hidden;
    section {
        width: 100%;
        padding: 100px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            width: 100%;
        }
        .figure-box {
            display: flex;
            justify-content: space-between;
            figure {
                padding: 0;
                .case-video {
                    display: flex;
                    width: 320px;
                    height: 646px;
                    border-radius: 12px;                   
                    overflow: hidden;
                    isolation: isolate;
                    video {
                        width: 100%;
                    }
                }
                .case-desc {
                    width: 304px;
                    margin-top: 32px;
                }
            }
        }
    }
    &::before{
        content: '';
        position: absolute;
        background: #F1F6FF;
        border-radius: 50%;
        width: 0;
        height: 0;
        top: 55%;
        left: 50%;
        transform: translateX(-50%);
    }
    @media (min-width:600px) and (max-width: 1199px) {
        width: 100%;
        > section {
            align-items: flex-start;
            padding-top: 40px;
        }
        &::before{
            width: 1680px;
            height: 640px;
        }
    }
    @media (max-width: 599px) {
        width: 100%;
        padding : 0 20px;
        > section {
            min-height: 820px;
            align-items: flex-start;
            padding-top: 32px;
        }
        &::before{
            width: 900px;
            height: 600px;
            top: 45%;
        }
    }
`
export const CaseSlideWrap = styled.div`
    figure {
        padding: 0;
        .case-video {
            display: flex;
            width: 320px;
            height: 646px;
            border-radius: 12px;
            overflow: hidden;
            isolation: isolate;
            video {
                width: 100%
            }
        }
        .case-desc {
            width: 304px;
            margin-top: 32px;
        }
    }
    .slick-list {
        overflow: hidden;
    }
    .slick-dots {
        bottom: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 20px;
        ul {
            height: 8px;
            gap: 0 6px;
            display: flex;
            li {
                display: flex;
                width: 8px;
                height: 8px;
                background-color: #CECECE;
                overflow: hidden;
                border-radius: 4px;
                margin: 0;
                &.slick-active {
                    width: 40px;
                }
                button {
                    width: 8px;
                    height: 8px;
                    padding: 0;
                    transition: all 0.3s ease;
                    &::before{
                        display: none;
                    }
                }
            }
        }
        @media (max-width: 599px) {
            bottom: -60px;
        }
    }
    @media (min-width:600px) and (max-width: 1199px) {
        figure {
            width: 320px;
            margin-left: calc(50vw - 200px);
        }
    }

    @media (max-width: 599px) {
        figure {
            width: 230px;
            margin-left: calc(50vw - 130px);
            .case-video {
                width: 230px;
                height: 464px;
                border-radius: 12px;                   
                overflow: hidden;
                isolation: isolate;
            }
            .case-desc {
                width: 230px;
                margin-top: 24px;
                img {
                    height: 40px;
                }
            }
        }
    }
`