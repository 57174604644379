import styled from "styled-components";

const ButtonWrap = styled.button`
  width: 180px;
  height: 56px;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  font-weight: 700;
  padding: 0 1rem;
  border: 0;
  border-radius: 6px;
  color: white;
  background-color: ${(props) => props.theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.primaryDark};
    opacity: 0.8;
  }
  &[data-small="true"] {
    width: auto;
    height: 42px;
  }
  &.mobile-fixed {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    border-radius: 0;
  }
  &.outlined {
    border: 1px solid #c8c8c8;
    background-color: white;
    color: black;
    &:hover {
      background-color: white;
    }
  }
  &[data-text="true"] {
    width: auto;
    height: 42px;
    background-color: transparent;
    color: #343434;
    font-weight: 600;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &.active {
    color: #568dfd;
    font-weight: 700;
  }
  @media (max-width: 599px) {
    &[data-small="true"] {
      height: 35px;
      font-size: 0.875rem;
      padding: 0 0.5rem;
    }
  }
`;

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  label: string | React.ReactNode;
  isSmall?: boolean;
  isText?: boolean;
  active?: boolean;
  type?: "button" | "reset" | "submit" | undefined;
  onClick?: () => void;
}

const Button = (props: ButtonProps) => {
  const { className, label, isSmall, isText, active, type = "button", onClick, style } = props;

  return (
    <ButtonWrap
      className={className}
      onClick={onClick}
      data-small={isSmall}
      data-text={isText}
      data-active={active}
      type={type}
      style={style}
    >
      {label}
    </ButtonWrap>
  );
};

export default Button;
