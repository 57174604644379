export const PaymentData = [
  {
    id: 1,
    title: "Lite",
    color: "#89B0FF",
    description: (
      <>
        부담없이 시작 가능한
        <br />
        스타터 요금제
      </>
    ),
    // benefitTitle: "99만 / 월",
    benefit: [
      "4만 ~ 8만 PV",
      "FHD 지원",
      "방송 횟수 무제한",
      "멀티 채널 스트리밍 지원",
      "사용량 최대 1TB / 월",
    ],
  },
  {
    id: 2,
    title: "Basic",
    color: "#568DFD",
    description: (
      <>
        합리적인 비용으로
        <br />
        운영이 가능한 요금제
      </>
    ),
    // benefitTitle: "150만 ~ 250만 / 월",
    benefit: [
      "8만 ~ 40만 PV",
      "FHD 지원",
      "방송 횟수 무제한",
      "멀티 채널 스트리밍 지원",
      "쇼츠 솔루션 통합 지원",
      "사용량 최대 2 ~ 5TB / 월",
    ],
  },
  {
    id: 3,
    title: "Pro",
    color: "#003D99",
    description: (
      <>
        많은 뷰 수의 방송을 넉넉하게
        <br />
        소화할 수 있는 프리미엄 요금제
      </>
    ),
    // benefitTitle: "250만 ~ 350만 / 월",
    benefit: [
      "40만 ~ 80만 PV",
      "FHD 지원",
      "방송 횟수 무제한",
      "멀티 채널 스트리밍 지원",
      "쇼츠 솔루션 통합 지원",
      "사용량 최대 5 ~ 10TB / 월",
    ],
  },
  {
    id: 4,
    title: "Enterprise",
    color: "#222222",
    description: (
      <>
        높은 트래픽 방송을 동시에 여러 개<br />
        진행할 수 있는 하이엔드 요금제
      </>
    ),
    // benefitTitle: "500만 ~ / 월",
    benefit: [
      "수백만 PV",
      "FHD 지원",
      "방송 횟수 무제한",
      "멀티 채널 스트리밍 지원",
      "쇼츠 솔루션 통합 지원",
      "사용량 10TB ~ / 월",
    ],
  },
];
