import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import Typography from "../../InnerTypography";
import { useTranslation } from "react-i18next";

const ReviewSectionWrap = styled.div`
  ${media.belowTa} {
    padding: 0 20px;
  }
  > article {
    text-align: center;
    margin-bottom: 5.63rem;

    ${media.ta} {
      margin-bottom: 4.375rem;
    }
    ${media.mo} {
      margin-bottom: 2rem;
    }
  }

  > div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4.38rem;

    ${media.belowTa} {
      grid-template-columns: 1fr;
      gap: 3.75rem;

      margin: 0 auto;
      max-width: 39.3125rem;
    }
    ${media.mo} {
      gap: 1.5rem;

      max-width: 21.125rem;
    }

    > div:first-of-type {
      justify-self: flex-end;

      ${media.belowTa} {
        justify-self: center;
      }
    }
    > div:last-of-type {
      justify-self: flex-start;

      .nav {
        margin-bottom: 1.5rem;

        ${media.mo} {
          margin-bottom: 1rem;
        }
        > b {
          font-size: 1.125rem;
          font-weight: 700;
          letter-spacing: -0.01125rem;

          ${media.mo} {
            font-size: 0.875rem;
            letter-spacing: -0.00875rem;
          }
        }
        > b:first-of-type {
          color: #979797;
        }
        > span {
          margin: 0 0.5rem;
          color: #e0e0e0;
        }
        > b:last-of-type {
          color: #5893ff;
        }
      }

      a {
        display: inline-flex;
        align-items: center;
        gap: 0.62rem;
        text-decoration: none;
        color: #003d99;
        text-align: justify;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
      }
    }

    img {
      border-radius: 1.25rem;
      max-width: 35.9375rem;
      max-height: 25.75rem;

      ${media.ta} {
        max-width: 39.3125rem;
        max-height: 28.125rem;
      }

      ${media.mo} {
        max-width: 21.125rem;
        max-height: 15.0625rem;
      }
    }
  }
`;

const ReviewSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection pt={isPc ? 220 : isTa ? 72 : 40} pb={isPc ? 220 : isTa ? 56 : 32}>
      <HomeSectionInner>
        <ReviewSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography.Header3
                  fontWeight={700}
                  fontSize={"1.5rem"}
                  color="#003D99"
                  letterSpacing={"-0.015rem"}
                >
                  {language === "ko" ? "Review & Business insite" : "成功事例 & インサイト"}
                </Typography.Header3>
                <Typography.Header2
                  fontWeight={700}
                  fontSize={"3.125rem"}
                  color="#222"
                  letterSpacing={"-0.03125rem"}
                  marginBottom={"1rem"}
                >
                  {language === "ko"
                    ? "고객사의 생생한 후기와 비즈니스 인사이트"
                    : "お客様のリアルな声とビジネスインサイト"}
                </Typography.Header2>
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography.Header3
                fontWeight={700}
                fontSize={"1.25rem"}
                color="#003D99"
                letterSpacing={"-0.0125rem"}
              >
                {language === "ko" ? "Review & Business insite" : "成功事例 & インサイト"}
              </Typography.Header3>
              <Typography.Header2
                fontWeight={700}
                fontSize={"1.875rem"}
                color="#222"
                letterSpacing={"-0.01875rem"}
              >
                {language === "ko" ? (
                  <>
                    고객사의 생생한 후기와
                    <br />
                    비즈니스 인사이트
                  </>
                ) : (
                  <>
                    お客様のリアルな声と
                    <br />
                    ビジネスインサイト
                  </>
                )}
              </Typography.Header2>
            </article>
          )}
          <div>
            {!isMo ? (
              <Reveal className="reveal" keyframes={fadeUp} duration={600} delay={200}>
                <img src="/assets/images/img_insite_olive.png" alt="olive-insite-article" />
                <div className="article">
                  <div className="nav">
                    <b>{language === "ko" ? "뷰티" : "コスメ"}</b>
                    <span>|</span>
                    <b>{language === "ko" ? "올리브영" : "Olive Young"}</b>
                  </div>
                  <Typography.Header4
                    fontSize={"2.25rem"}
                    fontWeight={700}
                    letterSpacing={"-0.0225rem"}
                    color="#222"
                  >
                    {language === "ko" ? "국내 H&B 시장 1위 올리브영이" : "韓国ドラッグストアNo.1"}
                  </Typography.Header4>
                  <Typography.Header4
                    fontSize={"2.25rem"}
                    fontWeight={700}
                    letterSpacing={"-0.0225rem"}
                    color="#222"
                    marginBottom={"2.19rem"}
                  >
                    {language === "ko"
                      ? "놀라운 매출 성장을 달성한 비법"
                      : "Olive Youngの売上成長の秘訣"}
                  </Typography.Header4>
                  <Typography.Paragraph
                    fontSize={"1.125rem"}
                    fontWeight={400}
                    lineHeight={"1.6875rem"}
                    color="#222"
                    marginBottom={"1.5rem"}
                    {...(isPc ? { maxWidth: "80%" } : { maxWidth: "100%" })}
                  >
                    {language === "ko"
                      ? `H&B 플랫폼 압도적 1위 올리브영은 주 5일 저녁 라이브를 편성하여 매일 다채로운 볼거리와 혜택을 받기 위해 고객이 재방문하는 환경을 마련하고 있습니다. 앱 내에 마련된 라이브 전용관을 통해 ‘지난 라이브 다시보기’ 와 ‘이번 달 많이 산 라이브 상품’을 소개하여 방송이 끝난 뒤에도 제품에 대한 관심을 이끌고 구매를 유도하고 있습니다. 2020년부터 꾸준히 라이브를 운영하고 있는 올리브영의 인사이트를 확인해 보세요.`
                      : `圧倒的な市場シェアを誇るOlive Youngは週5回、ライブ配信を行い多彩な見どころと特典を提供することで、お客様がアプリに再訪問するきっかけを提供します。また、「過去のライブのアーカイブ」や「今月の売れ筋ライブ商品」を紹介し、配信終了後も商品への関心を高め、購入を誘導しています。2020年から定期的にライブ配信をしているOlive Youngのインサイトをご覧ください。`}
                  </Typography.Paragraph>
                  <a
                    href={
                      language === "ko"
                        ? "https://blog.gripcloud.show/oliveyoung-interview"
                        : "https://blog.gripcloud.show/ja-jp/grip-cloud-blog/oliveyoung-interview"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {language === "ko" ? "인터뷰 보기" : "詳しく見る"}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M11 4L17 10L11 16"
                        stroke="#003D99"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path d="M3 10H15" stroke="#003D99" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </a>
                </div>
              </Reveal>
            ) : (
              <>
                <img src="/assets/images/img_insite_olive.png" alt="olive-insite-article" />
                <div className="article">
                  <div className="nav">
                    <b>{language === "ko" ? "뷰티" : "コスメ"}</b>
                    <span>|</span>
                    <b>{language === "ko" ? "올리브영" : "Olive Young"}</b>
                  </div>
                  <Typography.Header4
                    fontSize={"1.5rem"}
                    fontWeight={700}
                    letterSpacing={"-0.015rem"}
                    color="#222"
                  >
                    {language === "ko" ? "국내 H&B 시장 1위 올리브영이" : "韓国ドラッグストアNo.1"}
                  </Typography.Header4>
                  <Typography.Header4
                    fontSize={"1.5rem"}
                    fontWeight={700}
                    letterSpacing={"-0.015rem"}
                    color="#222"
                    marginBottom={"2rem"}
                  >
                    {language === "ko"
                      ? "놀라운 매출 성장을 달성한 비법"
                      : "Olive Youngの売上成長の秘訣"}
                  </Typography.Header4>
                  <Typography.Paragraph
                    fontSize={"0.875rem"}
                    fontWeight={400}
                    lineHeight={"1.3125rem"}
                    color="#222"
                    marginBottom={"1.5rem"}
                  >
                    {language === "ko"
                      ? `H&B 플랫폼 압도적 1위 올리브영은 주 5일 저녁 라이브를 편성하여 매일 다채로운 볼거리와 혜택을 받기 위해 고객이 재방문하는 환경을 마련하고 있습니다. 앱 내에 마련된 라이브 전용관을 통해 ‘지난 라이브 다시보기’ 와 ‘이번 달 많이 산 라이브 상품’을 소개하여 방송이 끝난 뒤에도 제품에 대한 관심을 이끌고 구매를 유도하고 있습니다. 2020년부터 꾸준히 라이브를 운영하고 있는 올리브영의 인사이트를 확인해 보세요.`
                      : `圧倒的な市場シェアを誇るOlive Youngは週5回、ライブ配信を行い多彩な見どころと特典を提供することで、お客様がアプリに再訪問するきっかけを提供します。また、「過去のライブのアーカイブ」や「今月の売れ筋ライブ商品」を紹介し、配信終了後も商品への関心を高め、購入を誘導しています。2020年から定期的にライブ配信をしているOlive Youngのインサイトをご覧ください。`}
                  </Typography.Paragraph>
                  <a
                    href={
                      language === "ko"
                        ? "https://blog.gripcloud.show/oliveyoung-interview"
                        : "https://blog.gripcloud.show/ja-jp/grip-cloud-blog/oliveyoung-interview"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {language === "ko" ? "인터뷰 보기" : "詳しく見る"}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M11 4L17 10L11 16"
                        stroke="#003D99"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path d="M3 10H15" stroke="#003D99" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </a>
                </div>
              </>
            )}
          </div>
        </ReviewSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default ReviewSection;
