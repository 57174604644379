import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import Typography from "../../Typography";

interface CaseCardProps {
  item: any;
  playable: boolean;
}

const CaseCard = (props: CaseCardProps) => {
  const { item, playable } = props;
  const { pathname } = useLocation();

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const playSync = async (ref: HTMLVideoElement) => {
      try {
        await ref.play();
      } catch (error) {
        console.warn("failure by browser policy", error);

        try {
          await ref.play();
        } catch (err) {
          console.error(
            "[CaseCard] Can't autoplay \nplease check and off row power mode your cell phone and some",
            err
          );
        }
      }
    };
    if (videoRef.current instanceof HTMLVideoElement) {
      if (!playable && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      } else if (playable && videoRef.current && videoRef.current.paused) {
        playSync(videoRef.current);
      }
    }
  }, [playable]);

  return (
    <figure>
      <div className="case-video">
        <video
          ref={videoRef}
          loop
          muted
          playsInline
          preload="auto"
          poster={item.videoPoster}
          src={item.videoSrc}
        />
      </div>
      <div className="case-desc">
        {pathname === "/kr/case" ? (
          <Typography label={item.title} size={isPc ? 22 : isTa ? 18 : 14} weight={700} mb={12} />
        ) : (
          <Link to="/kr/case">
            <Typography label={item.title} size={isPc ? 22 : isTa ? 18 : 14} weight={700} />
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M6.38936 15.616L11.5627 10.4427L6.38936 5.26936C6.26592 5.14592 6.168 4.99937 6.10119 4.83809C6.03439 4.6768 6 4.50394 6 4.32936C6 4.15479 6.03439 3.98192 6.10119 3.82064C6.168 3.65935 6.26592 3.5128 6.38936 3.38936C6.5128 3.26592 6.65935 3.168 6.82064 3.10119C6.98192 3.03439 7.15479 3 7.32936 3C7.50394 3 7.6768 3.03439 7.83809 3.10119C7.99937 3.168 8.14592 3.26592 8.26936 3.38936L14.3894 9.50936C14.9094 10.0294 14.9094 10.8694 14.3894 11.3894L8.26936 17.5094C8.14601 17.633 7.99949 17.731 7.83819 17.7979C7.6769 17.8648 7.50399 17.8993 7.32936 17.8993C7.15474 17.8993 6.98183 17.8648 6.82053 17.7979C6.65923 17.731 6.51271 17.633 6.38936 17.5094C5.88269 16.9894 5.86936 16.136 6.38936 15.616Z"
                fill="black"
              />
            </svg>
          </Link>
        )}
        <Typography
          label={item.desc}
          size={isPc ? 16 : isTa ? 14 : 12}
          lineHeight={isPc ? 26 : isTa ? 22 : 18}
          mb={16}
        />
        {!isMo && <img src={item.logoSrc} alt={item.title} />}
      </div>
    </figure>
  );
};

export default CaseCard;
