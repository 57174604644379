import { CSSProperties } from "react";
import styled from "styled-components";

const TypographyWrap = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1.5rem;
  line-height: 1;
  color: ${(props) => props.theme.text};
  span {
    color: ${(props) => props.theme.primary};
  }
`;

interface TypographyProps {
  label: string | React.ReactNode;
  size?: number;
  weight?: number;
  lineHeight?: number;
  letterSpacing?: string;
  color?: string;
  mt?: number;
  mb?: number;
  style?: CSSProperties | undefined;
}

const Typography = (props: TypographyProps) => {
  const { label, size, weight, lineHeight, letterSpacing, color, mt, mb, style } = props;

  return (
    <TypographyWrap
      style={{
        fontSize: `${size}px`,
        fontWeight: weight,
        lineHeight: `${lineHeight}px`,
        letterSpacing: letterSpacing,
        color: color,
        marginTop: `${mt}px`,
        marginBottom: `${mb}px`,
        ...style,
      }}
    >
      {label}
    </TypographyWrap>
  );
};

export default Typography;
