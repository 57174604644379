import "styled-components";
import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    fontFamily: string;
    primary: string;
    primaryDark: string;
    text: string;
  }
}

export const GripTheme: DefaultTheme = {
  fontFamily: "'Pretendard', 'Noto Sans JP', sans-serif",
  primary: "#003D99",
  primaryDark: "#417FFF",
  text: "#343434",
};
