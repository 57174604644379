import styled from "styled-components";

export const PaymentWrap = styled.main`
  width: 1360px;
  max-width: 1920px;
  margin: 0 auto;

  /* position: relative; */

  > div {
    p {
      text-align: center;
    }
  }
  section {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0 120px;
    .figure-box {
      gap: 28px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .header {
    position: sticky;
    top: 60px;
    z-index: 10;
    header {
      width: 100%;
      height: 96px;
      /* background-color: #FFFFFF; */
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      padding: 24px 0;
      div {
        height: 100%;
        padding: 4px;
        background-color: #f8faff;
        border-radius: 24px;
        display: flex;
        gap: 0 4px;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        button {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          border: 0;
          border-radius: 20px;
          background-color: transparent;
          font-size: 14px;
          color: #b3b3b3;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            background-color: white;
            color: #3c3c3c;
          }
          &.active {
            background-color: white;
            font-weight: 500;
            color: #3c3c3c;
          }
        }
      }
    }
  }
  figure {
    width: 334px;
    height: 600px;
    padding: 56px 18px 27px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    > p.center {
      text-align: center;
    }
    ul {
      padding: 0 8px;
      li {
        display: flex;
        align-items: center;
        gap: 0 6px;
        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }
    }
    button {
      width: 100%;
      margin-top: auto;
      border: 0;
      height: 54px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      border-radius: 4px;
      cursor: pointer;
    }
    .line {
      width: 100%;
      height: 6px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .slick-dots {
    bottom: -48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 20px;
    ul {
      margin: 0;
      padding: 0;
      height: 3px;
      display: flex;
      li {
        display: flex;
        width: 32px;
        height: 3px;
        background-color: rgba(255, 255, 255, 0.4);
        margin: 0;
        overflow: hidden;
        &:first-of-type {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        &:last-of-type {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
        button {
          width: 32px;
          height: 3px;
          padding: 0;
          transition: all 0.3s ease;
          &::before {
            display: none;
          }
        }
      }
      &.light {
        li {
          background-color: rgba(200, 200, 200, 0.4);
        }
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 100%;
    height: calc(100vh - 60px);
    padding: 0 40px;
    > article {
      max-width: 900px;
      padding: 100px 0 120px;
      margin: 0 auto;
      figure {
        width: 280px;
        height: 510px;
        padding: 34px 16px 20px;
        margin-left: 20px;
        ul {
          padding: 0;
          li {
            font-size: 14px;
            gap: 0 4px;
            &:not(:first-of-type) {
              margin-top: 8px;
            }
          }
        }
        button {
          height: 46px;
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 599px) {
    width: 100%;
    // height: 1625px;
    padding: 0 20px;
    > article {
      width: 100%;
      padding: 0 0 24px;
      figure {
        width: 100%;
        height: auto;
        padding: 34px 16px 20px;
        margin-bottom: 20px;
        ul {
          padding: 0;
          li {
            font-size: 14px;
            gap: 0 4px;
            &:not(:first-of-type) {
              margin-top: 8px;
            }
          }
        }
        button {
          height: 46px;
          font-size: 14px;
          margin-top: 48px;
        }
      }
    }
  }
`;
