import { useRef } from "react";
import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeIn,
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import useIntersectionObserver from "../../useIntersectionObserver";
import Typography from "../../InnerTypography";
import { useTranslation } from "react-i18next";

const LiveSectionWrap = styled.div`
  position: relative;
  min-height: 658px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  ${media.belowTa} {
    padding: 0 20px;
    grid-template-columns: 1fr;
  }
  > article {
    justify-self: flex-end;

    .frame-group {
      display: flex;
      flex-flow: row nowrap;
      gap: 2.5rem;

      ${media.ta} {
        flex-flow: column nowrap;
        gap: 1.5rem;
      }
      ${media.mo} {
        flex-flow: column nowrap;
        gap: 0.75rem;
        margin-bottom: 2.62rem;
      }
      > div {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
      }
    }

    ${media.belowTa} {
      justify-self: flex-start;
    }
  }
  > aside {
    justify-self: center;

    ${media.ta} {
      justify-content: flex-end;
      position: relative;
      top: -15%;
      right: -20%;
    }
    .live-video {
      display: flex;
      width: 322px;
      height: 654px;
      border-radius: 12px;
      box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      isolation: isolate;
      background-repeat: no-repeat;
      background-size: contain;
      video {
        width: 100%;
      }
    }
  }
`;

const LiveSection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isMo = useMediaQuery({ query: screen.mo });

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoRefMo = useRef<HTMLVideoElement>(null);

  const onIntersect: IntersectionObserverCallback = async ([{ isIntersecting }]) => {
    if (videoRefMo.current instanceof HTMLVideoElement) {
      if (isIntersecting) {
        try {
          await videoRefMo.current.play();
        } catch (error) {
          console.warn("failure by browser policy", error);

          try {
            await videoRefMo.current.play();
          } catch (err) {
            console.error(
              "[LiveSection] Can't autoplay \nplease check and off row power mode your cell phone and some",
              err
            );
          }
        }
      } else {
        videoRefMo.current.pause();
        videoRefMo.current.currentTime = 0;
      }
    }
  };

  const { setTarget } = useIntersectionObserver({ onIntersect });

  return (
    <HomeSection>
      <HomeSectionInner>
        <LiveSectionWrap>
          {!isMo ? (
            <>
              <article>
                <Reveal
                  className="reveal"
                  keyframes={fadeUp}
                  duration={600}
                  delay={100}
                  cascade
                  damping={0.25}
                >
                  <Typography.Header3
                    fontWeight={700}
                    fontSize={"1.5rem"}
                    color="#003d99"
                    letterSpacing={"-0.015rem"}
                  >
                    {t("feature.card1.live")}
                  </Typography.Header3>
                  <Typography.Header2
                    fontWeight={700}
                    fontSize={isPc ? "3.125rem" : "3rem"}
                    color="#222"
                  >
                    {language === "ko" ? "생생한 오프라인" : "オンラインで提供する"}
                  </Typography.Header2>
                  <Typography.Header2
                    fontWeight={700}
                    fontSize={isPc ? "3.125rem" : "3rem"}
                    color="#222"
                    marginBottom={"2rem"}
                  >
                    {language === "ko" ? "쇼핑 경험을 자사몰에서" : "新しい接客体験"}
                  </Typography.Header2>
                  <Typography.Paragraph
                    fontWeight={400}
                    fontSize={"1.25rem"}
                    color="#222"
                    marginBottom={isPc ? "6rem" : "3.75rem"}
                  >
                    {language === "ko"
                      ? "구매 전환율을 높이고, 고객 관계를 강화하세요."
                      : "双方向コミュニケーションで顧客との関係を強化"}
                  </Typography.Paragraph>

                  <div className="frame-group">
                    {(language === "ko"
                      ? ["간편한 연동", "고객 소통 강화", "강력한 마케팅"]
                      : ["簡単な連携", "コミュニケーションの強化", "ゲーム機能"]
                    ).map((str, index) => (
                      <div key={`frame-item-${index}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M2.08398 9.16675L8.33398 15.0001L18.334 4.16675"
                            stroke="#003D99"
                            strokeWidth="3"
                            strokeLinecap="round"
                          />
                        </svg>
                        <Typography.Paragraph fontWeight={600} fontSize={"1.125rem"} color="#222">
                          {str}
                        </Typography.Paragraph>
                      </div>
                    ))}
                  </div>
                </Reveal>
              </article>
              <aside>
                <Reveal
                  className="reveal"
                  keyframes={isMo ? fadeIn : fadeUp}
                  duration={isMo ? 300 : 600}
                  delay={isMo ? 0 : 200}
                  onVisibilityChange={async (inView: boolean, _: IntersectionObserverEntry) => {
                    if (videoRef.current instanceof HTMLVideoElement) {
                      if (inView) {
                        try {
                          await videoRef.current.play();
                        } catch (error) {
                          console.warn("failure by browser policy", error);

                          try {
                            await videoRef.current.play();
                          } catch (err) {
                            console.error(
                              "[LiveSection] Can't autoplay \nplease check and off row power mode your cell phone and some",
                              err
                            );
                          }
                        }
                      } else {
                        videoRef.current.pause();
                        videoRef.current.currentTime = 0;
                      }
                    }
                  }}
                >
                  <div
                    className="live-video"
                    {...(language !== "ko"
                      ? {
                          style: {
                            width: 290,
                            height: 628,
                            backgroundImage: `url(/assets/videos/${
                              language === "ja" ? "jp" : "en"
                            }/intro.png)`,
                          },
                        }
                      : { style: { backgroundImage: `url(/assets/videos/kr/intro.png)` } })}
                  >
                    <video
                      ref={videoRef}
                      loop
                      muted
                      playsInline
                      preload="auto"
                      poster={
                        language === "ko"
                          ? "/assets/videos/kr/intro.png"
                          : `/assets/videos/${language === "ja" ? "jp" : "en"}/intro.png`
                      }
                      src={
                        language === "ko"
                          ? "/assets/videos/kr/intro.mp4"
                          : `/assets/videos/${language === "ja" ? "jp" : "en"}/intro.mp4`
                      }
                    />
                  </div>
                </Reveal>
              </aside>
            </>
          ) : (
            <>
              <article>
                <Typography.Header3
                  fontWeight={700}
                  fontSize={"1.25rem"}
                  color="#003d99"
                  letterSpacing={"-0.0125rem"}
                >
                  {t("feature.card1.live")}
                </Typography.Header3>
                <Typography.Header2 fontWeight={700} fontSize={"1.875rem"} color="#222">
                  {language === "ko" ? "생생한 오프라인" : "オンラインで提供する"}
                </Typography.Header2>
                <Typography.Header2
                  fontWeight={700}
                  fontSize={"1.875rem"}
                  color="#222"
                  marginBottom={"1.25rem"}
                >
                  {language === "ko" ? "쇼핑 경험을 자사몰에서" : "新しい接客体験"}
                </Typography.Header2>
                <Typography.Paragraph
                  fontWeight={400}
                  fontSize={"0.875rem"}
                  color="#222"
                  marginBottom={"2rem"}
                >
                  {language === "ko"
                    ? "구매 전환율을 높이고, 고객 관계를 강화하세요."
                    : "双方向コミュニケーションで顧客との関係を強化"}
                </Typography.Paragraph>

                <div className="frame-group">
                  {(language === "ko"
                    ? ["간편한 연동", "고객 소통 강화", "강력한 마케팅"]
                    : ["簡単な連携", "コミュニケーションの強化", "ゲーム機能"]
                  ).map((str, index) => (
                    <div key={`frame-item-${index}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M2.08398 9.16675L8.33398 15.0001L18.334 4.16675"
                          stroke="#003D99"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                      <Typography.Paragraph fontWeight={600} fontSize={"1.125rem"} color="#222">
                        {str}
                      </Typography.Paragraph>
                    </div>
                  ))}
                </div>
              </article>
              <aside>
                <div
                  className="live-video"
                  ref={setTarget}
                  {...(language !== "ko"
                    ? {
                        style: {
                          width: 290,
                          height: 628,
                          backgroundImage: `url(/assets/videos/${
                            language === "ja" ? "jp" : "en"
                          }/intro.png)`,
                        },
                      }
                    : { style: { backgroundImage: `url(/assets/videos/kr/intro.png)` } })}
                >
                  <video
                    ref={videoRefMo}
                    loop
                    muted
                    playsInline
                    preload="auto"
                    poster={
                      language === "ko"
                        ? "/assets/videos/kr/intro.png"
                        : `/assets/videos/${language === "ja" ? "jp" : "en"}/intro.png`
                    }
                    src={
                      language === "ko"
                        ? "/assets/videos/kr/intro.mp4"
                        : `/assets/videos/${language === "ja" ? "jp" : "en"}/intro.mp4`
                    }
                  />
                </div>
              </aside>
            </>
          )}
        </LiveSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default LiveSection;
