import styled from "styled-components"

export const ContentWrap = styled.div`
    nav {
        width: 100%;
        position: fixed;
        top: 80px;
        background-color: white;
        div {
            width: 1360px;
            max-width: 1920px;
            margin: 0 auto;
            padding: 50px 40px 40px;
            display: flex;
            align-items: center;
            gap: 0 8px;
            a {
                color: #568DFD;
                font-weight: 500;
                text-decoration: none;
                &:hover {
                    color: #417FFF;
                }
            }
            p {
                font-weight: 700;
            }
        }
        @media (min-width:600px) and (max-width: 1199px) {
            top: 60px;
            div {
                width: 100%;
                padding: 64px 30px 36px;
            }
        }
        @media (max-width: 599px) {
            top: 60px;
            div {
                width: 100%;
                padding: 42px 20px 24px;
            }
        }
    }
`

export const ContentSection = styled.section`
    padding-bottom: 120px;
`

export const ContentSectionInner = styled.div`
    width: 1360px;
    max-width: 1920px;
    margin: 114px auto 0;
    padding-left: 40px;
    padding-right: 40px;
    h1 {
        font-size: 56px;
        font-weight: 900;
        line-height: 80px;
        color: #343434;
        /* overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
        margin-bottom: 20px;
    }
    label {
        font-size: 20px;
        color: #969696;
    }
    > img {
        margin-top: 24px;
        margin-bottom: 32px;
    }
    .description {
        font-size: 20px;
        line-height: 32px;
        h2 {
            font-size: 28px;
            line-height: 40px;
        }
        h3 {
            font-size: 24px;
            line-height: 36px;
        }
        ol {
            list-style: decimal;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            li {
                margin-left: 24px;
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
            }
        }
        img {
            display: block;
            margin-top: 16px;
            margin-bottom: 24px;
        }
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #D8D8D8;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        color: #424242;
        margin-top: 24px;
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
            border-color: #424242;
        }
    }
    @media (min-width:600px) and (max-width: 1199px) {
        width: 100%;
        margin: 124px auto 0;
        padding-left: 30px;
        padding-right: 30px;
        h1 {
            font-size: 32px;
            line-height: 46px;
            margin-bottom: 16px;
        }
        label {
            font-size: 14px;
        }
        > img {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 28px;
        }
        .description {
            font-size: 16px;
            line-height: 26px;
            h2 {
                font-size: 24px;
                line-height: 36px;
            }
            h3 {
                font-size: 20px;
                line-height: 32px;
            }
            ol {
                font-size: 20px;
                line-height: 32px;
                li {
                    margin-left: 20px;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            img {
                max-width: 100%;
                margin-top: 14px;
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 599px) {
        width: 100%;
        margin: 90px auto 0;
        padding-left: 20px;
        padding-right: 20px;
        h1 {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 14px;
        }
        label {
            font-size: 12px;
        }
        > img {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 24px;
        }
        .description {
            font-size: 14px;
            line-height: 22px;
            h2 {
                font-size: 20px;
                line-height: 32px;
            }
            h3 {
                font-size: 16px;
                line-height: 26px;
            }
            ol {
                font-size: 16px;
                line-height: 26px;
                li {
                    margin-left: 16px;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            img {
                max-width: 100%;
                margin-top: 12px;
                margin-bottom: 16px;
            }
        }
        button {
            width: 120px;
        }
    }
`