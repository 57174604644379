import styled from "styled-components";
import { keyframes } from "@emotion/react";
import { CustomArrowProps } from "react-slick";

export const fadeUp = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 30px, 0);
        transition-timing-function: ease-in-out;
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const HomeWrap = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  .reveal {
    opacity: 0;
  }
  .scroll-top-button {
    position: fixed;
    right: 14px;
    bottom: 120px;
    z-index: 10000;
    width: 44px;
    height: 44px;
    background: #ffffff;
    border: 1.75px solid #f4f4f4;
    border-radius: 16px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 45.5px 19.5px rgba(0, 0, 0, 0.01))
      drop-shadow(0px 26px 16.25px rgba(0, 0, 0, 0.05))
      drop-shadow(0px 13px 13px rgba(0, 0, 0, 0.09))
      drop-shadow(0px 3.25px 6.5px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
    }
    /* @media (min-width:600px) and (max-width: 1199px) {
            width: 48px;
            height: 48px;
            border-width: 1.5px;
            svg {
                width: 26px;
                height: 26px;
            }
        } */
    @media (max-width: 599px) {
      right: 14px;
      bottom: 114px;
    }
  }
  br.mo {
    display: none;
  }
  @media (min-width: 1200px) {
    br.none-pc {
      display: none;
    }
  }
  @media (max-width: 599px) {
    br.none-mo {
      display: none;
    }
    br.mo {
      display: block;
    }
  }
`;

export const HomeSection = styled.section<{
  bg?: string;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
}>`
  background-color: ${(props) => props.bg};
  padding-top: ${(props) => (props.pt ? `${props.pt}px` : `120px`)};
  padding-bottom: ${(props) => (props.pb ? `${props.pb}px` : `120px`)};
  padding-left: ${(props) => (props.pl ? `${props.pl}px` : 0)};
  padding-right: ${(props) => (props.pr ? `${props.pr}px` : 0)};
  position: relative;
  &.bokeh {
    background: url("/assets/images/bokeh.png") no-repeat center;
    background-size: cover;

    @media (max-width: 599px) {
      background: url("/assets/images/bokeh_mobile.png") no-repeat center;
      background-size: cover;
    }
  }
  &.circle {
    &::before {
      content: "";
      position: absolute;
      background: #f1f6ff;
      border-radius: 50%;
      width: 3700px;
      height: 1400px;
      left: 50%;
      top: 55%;
      transform: translateX(-50%);
    }
    @media (min-width: 600px) and (max-width: 1199px) {
      &::before {
        content: "";
        width: 1500px;
        height: 580px;
        top: 65%;
      }
    }
    @media (max-width: 599px) {
      &::before {
        width: 800px;
        height: 450px;
        top: 65%;
      }
    }
  }
  &.finish {
    background: url("/assets/images/finish_bg.png") no-repeat center;
    background-size: cover;
  }
  &.contents-section-slide-dim {
    &::after {
      content: "";
      position: absolute;
      top: 31%;
      left: 0;
      height: 69%;
      width: 160px;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      @media (min-width: 600px) and (max-width: 1199px) {
        width: 100px;
      }
      @media (max-width: 599px) {
        width: 60px;
      }
    }
  }
  > p {
    position: absolute;
    right: 40px;
    bottom: 30px;
    @media (max-width: 599px) {
      right: 20px;
      bottom: 16px;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    bottom: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 20px;
    ul {
      margin: 0;
      padding: 0;
      height: 3px;
      display: flex;
      li {
        display: flex;
        width: 32px;
        height: 3px;
        background-color: rgba(255, 255, 255, 0.4);
        margin: 0;
        overflow: hidden;
        &:first-of-type {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        &:last-of-type {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
        button {
          width: 32px;
          height: 3px;
          padding: 0;
          transition: all 0.3s ease;
          &::before {
            display: none;
          }
        }
      }
      &.light {
        li {
          background-color: #c8c8c8;
        }
      }
    }
    .slide-button {
      font-size: 0;
      line-height: 0;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      padding: 0;
      &:hover {
        opacity: 0.7;
      }
    }
    @media (max-width: 599px) {
      bottom: -64px;
      gap: 0 10px;
      ul {
        li {
          width: 24px;
          button {
            width: 24px;
          }
        }
      }
    }
  }
  @media (max-width: 599px) {
    min-height: 718px;
  }
`;

export const HomeSectionInner = styled.div<{ pt?: number; pb?: number }>`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: ${(props) => (props.pt ? `${props.pt}px` : 0)};
  padding-bottom: ${(props) => (props.pb ? `${props.pb}px` : 0)};
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 599px) {
    width: 100%;
    padding: 0;
  }
`;

const Arrow = styled("div")`
  &.slick-next,
  &.slick-prev {
    width: 56px;
    height: 56px;
    top: 40%;
    transform: translate(0, -40%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:before {
      content: none !important;
    }
    &.slick-disabled {
      display: none;
    }
    @media only screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
      top: 35%;
      transform: translate(0, -35%);
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

const NextArrow = styled(Arrow)`
  &.slick-next {
    right: 15%;
    @media only screen and (max-width: 767px) {
      right: 5%;
    }
  }
`;

const PrevArrow = styled(Arrow)`
  &.slick-prev {
    left: 15%;
    @media only screen and (max-width: 767px) {
      left: 5%;
    }
  }
`;

export const SliderNextArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props;
  return (
    <NextArrow className={className} onClick={onClick}>
      <img src="/assets/images/slide_next.svg" alt="" />
    </NextArrow>
  );
};

export const SliderPrevArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props;
  return (
    <PrevArrow className={className} onClick={onClick}>
      <img src="/assets/images/slide_prev.svg" alt="" />
    </PrevArrow>
  );
};

const CaseNextArrow = styled(Arrow)`
  &.slick-next {
    right: 8%;
    @media only screen and (max-width: 767px) {
      right: 0;
    }
  }
`;

const CasePrevArrow = styled(Arrow)`
  &.slick-prev {
    left: 8%;
    @media only screen and (max-width: 767px) {
      left: 0;
    }
  }
`;

export const CasePageNextArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props;
  return (
    <CaseNextArrow className={className} onClick={onClick}>
      <img src="/assets/images/slide_next.svg" alt="" />
    </CaseNextArrow>
  );
};

export const CasePagePrevArrow = (props: CustomArrowProps) => {
  const { className, onClick } = props;
  return (
    <CasePrevArrow className={className} onClick={onClick}>
      <img src="/assets/images/slide_prev.svg" alt="" />
    </CasePrevArrow>
  );
};

export const modalStyles = {
  overlay: {
    zIndex: 1200,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: window.innerWidth < 599 ? 0 : 16,
  },
};

export const modalMobileStyles = {
  overlay: {
    zIndex: 1200,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
    borderRadius: 0,
  },
};

export const screen = {
  pc: "(min-width:1200px)",
  ta: "(min-width: 600px) and (max-width: 1199px)",
  mo: "(max-width: 599px)",
};
export const media = {
  ta: `@media ${screen.ta}`,
  belowTa: `@media (max-width: 1199px)`,
  mo: `@media ${screen.mo}`,
};
