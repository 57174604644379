export const ContentsData = [
    {
        id: 1,
        image: '/assets/images/contents/content_01.png',
        title: 'Grip Company、YouTubeとライブコマースパートナシップを締結',
        description: <>
            <p>株式会社Grip Company(本社:韓国、代表取締役：キム・ハンナ)は2022年10月31日、動画配信サービス「YouTube」とパートナーシップを締結し、ライブショッピング機能「YouTube ショッピング」の提供を開始しました。</p>
            <br />
            <b>■パートナーシップ締結に向けて</b>
            <p>Grip Companyはライブコマースプラットフォーム「Grip」およびライブコマースサービス型ソフトウェア(SaaS)「Grip cloud」を運営しています。動画配信サービス「YouTube」とライブショッピングパートナーシップを締結することで、GripのYouTubeチャンネルおよびGripとパートナーシップを結んだクリエイターのチャンネルで手軽にライブショッピング機能を利用できます。</p>
            <p>Grip Companyは人気ブランドや有名クリエイターに限らず、個人事業主までライブショッピング機能を提供することで、ライブコマース文化を広め新たな価値を作り出すことを目指します。</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 2,
        image: '/assets/images/contents/content_02.png',
        title: '韓国No.1ヘルス&ビューティーストア『OLIVE YOUNG(オリーブヤング)』が語るライブコマース',
        description: <>
            <p>韓国で圧倒的な人気を誇るヘルス&ビューティーストア『OLIVE YOUNG(オリーブヤング)』を運営するCJ OLIVE YOUNGはライブコマースの必要性を感じ、Grip cloudを導入いたしました。ライブコマースの必要性を感じたきっかけやGrip cloudを選んだ理由、そしれライブコマースで得られた成果について紹介いたします。</p>
            <br />
            <p>まず、チームについて紹介してください</p>
            <p>OLIVE YOUNG(オリーブヤング)のメディアコマースを担当しているチームです。ライブ配信の企画から本番やSNSに露出される様々な映像などのコンテンツ制作まで行っております。</p>
            <br />
            <h2>決め手はGrip cloudならではのゲーム機能と安定したサーバー環境</h2>
            <br />
            <p>Grip cloud 導入の背景について教えていただけますか?</p>
            <p>最も大きな理由は、Grip cloudならではの「ゲーム機能」を通じて様々な試みができるという点でした。 OLIVE YOUNGの主なターゲットは20代~30代です。 OLIVE YOUNGライブ配信の場合、K-POPアイドルファン向けの企画の時は10代まで視聴しているため、幅広い世代が楽しさを感じ、ずっと見ていたくなるようにすることが最も重要です。そこでGrip cloudは様々なゲーム機能を持っていたので、ライブコマースという新しい挑戦に適した環境だと思いました。</p>
            <p>また、サービスの安定性も重要なポイントでした。Grip cloud導入前に行ったライブ配信では、システムエラーでチャットがスムーズに行えなかったり、いいね機能に不具合があったり、エラーが発生したことがありました。 このようなエラーは顧客の離脱にもつながるため、安定したサーバー環境も検討しました。</p>
            <br />
            <h2>売上だけでなく、ログイン視聴率や平均視聴時間、そして販売される商品の多様性まで</h2>
            <br />
            <p>導入後の成果はどうでしたか?</p>
            <p>売上も重要ですが、Grip cloud導入前に比べてログインして視聴する方の比率が増加しました。 以前はライブを視聴するだけだったのが、今はコメントやゲームなどに参加するためにログインする割合が多くなったのです。 そのため、自然と平均視聴時間も増え、同時接続者数も大幅に改善されました。 Grip cloud導入時の目標と戦略がうまく合致したと思います。</p>
            <p>また、商品の観点からも意味があります。アプリ内のランキングに入らなかった商品がライブを行った後、上位に入る事例が増えています。 販売が難しいと思われていた商品も、コンテンツとして展開することで予想外の売上を達成することもあります。 このように、Grip cloudを導入した後の成果を確認することができました。</p>
            <br />
            <h2>目指しているのは毎日見たいライブコマース</h2>
            <br />
            <p>今後の目標について教えてください</p>
            <p>主なターゲットが20代であるため、コンテンツの面白さが重要です。面白さは入店と購買転換率に最も重要な要素でもあり、その分、明確なメッセージを伝えなければならないです。 簡単ではないですが、毎日決まった時間にアプリを開いて見たくなるライブを作り、ライブコマースでもリーダーとして位置づけしたいと思います。</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 3,
        image: '/assets/images/contents/content_03.png',
        title: 'いいねアイコンカスタム機能!ブランドイメージの露出とエンゲージメントの増加、一つの機能で2つの効果を!',
        description: <>
            <p>単純接触効果(ザイオンス効果)をご存じでしょうか?マーケティングにも深い関係がある単純接触効果は、「最初は興味がなかったモノや人、サービスでも、何度も接するうちに、警戒心が薄れていき、好きになりやすくなる心理的現象のこと」を指します。例えば、ライブ配信を視聴しているうちに、同じイメージを何度もみたら、そのイメージが好きになる可能性が高くなるかもです。そこで、皆様にご提案です!Grip cloudの「いいねアイコンカスタム機能」を活用して、いいねアイコンをブランドにぴったりなイメージでカスタムし、視聴者に認知してもらえませんか?</p>
            <br />
            <h2>そもそも、いいねって重要ですか?</h2>
            <p>いいね機能は視聴者のリテンション管理に役立ちます!いいね数の変化で視聴者の反応を確認できます。いいね数があまり上がらない場合は、視聴者のリテンションが低下している可能性があります。 弊社のデータによると、視聴者の反応が良かった配信では、圧倒的にいいね数を多い傾向もあります。</p>
            <br />
            <h2>いいねアイコンカスタム機能で何ができますか?</h2>
            <h3>ブランドと商品を露出できる</h3>
            <p>いいねアイコンカスタム機能を使うと、いいねアイコンのイメージを自由に変更できます。例えば、ブランドのロゴや商品のイメージに変更したと考えてみましょう。配信中、誰かがいいねボタンを押すために、ブランドのロゴや商品のイメージが視聴者に露出されます。もちろん、いいねアイコンのイメージは配信の企画内容や目標に合わせてデザインすることをおすすめします。</p>
            <h3>視聴者に楽しさを提供できる</h3>
            <p>いいねアイコンカスタム機能は視聴者に新しい楽しさを提供することができます。例えば、配信コンセプトに合わせて配信ごとにいいねアイコンをカスタムして制作した場合、視聴者は次の配信ではどんなアイコンが出るのか期待するようになるでしょう。ブランドならではのアイコンは視聴者がもっといいねを押したくなる理由になりますし、そのうちに自然とブランドと商品イメージを覚えるようになります。</p>
            <br />
            <h2>いいねアイコンカスタムをもっと活用できるアイデア</h2>
            <br />
            <h3> N回目の「いいね」イベント</h3>
            <p>ブランドイメージに合わせたいいねアイコンをより多くの視聴者に露出させるために、いいね機能を活用した配信中のイベントを実施することもおすすめします。例えば、10000回目のいいねを押した人に賞品をプレゼントするイベントを行うと視聴者は普段よりも多くいいねをする傾向があります。N回目に「いいね」を押した視聴者の情報は管理画面から確認ができます。</p>
            <br />
            <h2>いいねアイコンカスタムはどうすればいいですか?</h2>
            <p>いいねアイコンカスタムは管理画面から簡単に設定できます!</p>
            <p>事前に制作されたpngファイルの画像を管理画面にアップロードするだけで、すぐに適用されます。</p>
            <br />
            <p>ブランドイメージをより多く露出できて、視聴者のエンゲージメントも増やせるいいねアイコンカスタム機能!ぜひ活用してみてください。</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 4,
        image: '/assets/images/contents/content_04.png',
        title: '1時間のライブコマースで売上1億円も!Qoo10の成功事例でみるライブコマース',
        description: <>
            <h2>視聴者15人中1人が購入!売れるライブコマースの決め手は企画</h2>
            <br />
            <p>まずは自己紹介をお願いします。</p>
            <p>Qoo10(イーベイジャパン)のマーケティング本部でコンテンツチームのチームリーダーを務めています。マーケティング本部の中で様々な業務を兼任していますが、コンテンツチームの業務としては、SNS関連のチャンネル運営をはじめ、インフルエンサーとのコラボ、主に映像制作が多いです。ある意味、私たちのチームは本当に限りなくすべてのコンテンツを担当しています。その中でもライブコマースは大きな成長を遂げ、今はQoo10ならではのユニークな特徴になりました。 今年はさらに規模を大きくする予定で、今までの3倍以上配信を行う予定です。</p>
            <br />
            <p>Qoo10の主なターゲットはどうなっていますか ? なぜそう定義しましたか ?</p>
            <p>女性で10~30代前半をターゲットにしています。実際の購入者も80 % 以上が女性で、そのうち70 % 以上が10~30代前半と、日本でかなり珍しいケースだと思います。そのため、ターゲットが一致しているブランドにとってはかなり魅力的なサイトであります。ライブコマースもQoo10と同じターゲットです。</p>
            <br />
            <p>ターゲットに効率よくアプローチするためにどのような工夫をしていますか ?</p>
            <p>オープンプラットフォームであるため、Qoo10オリジナルの商品はありません。代わりに、ターゲットがマッチする韓国化粧品を積極的に活用してます。例えば、トレンドに合わせてブランドの韓国本社と直接コンタクトを取り、業務を行っています。Qoo10でしか買えないものを開発して企画し、インフルエンサーを通じて広めるとか。Qoo10ならではの特徴をどう作り出すかに重点を置いています。</p>
            <p>また、これまで培ってきたSNSチャンネルとの同時配信を積極的に行っております。現在、Twitterで65万人ほどのフォロワーを抱えていますが、企業アカウントとしてはかなり多い方ではあります。Qoo10アプリをインストールしていないお客様にも、まずはライブを視聴していただきたいので、視聴者獲得のために予算も使っています。</p>
            <br />
            <p>企画のプロセスについて教えてください。</p>
            <p>週1回水曜日の配信は去年から続けていて、企画からセールスまで一貫して行っています。配信はどんどん増やしていく予定で、すでに準備はできています。例えば、有名インフルエンサーの名前をかかげたチャンネルも企画していますね。</p>
            <p>1年に4回、約10日間程度、Qoo10で非常に大きなイベントを行うのですが、このスケジュールに合わせて、だいたい2ヶ月前に企画を始め、例えば「3月1日同時公開」みたいな感じで、全チャンネル(Twitterのトレンド、ハッシュタグ)で必ず1位を取れるようにターゲティングしています。企業様もこの期間に合わせて新商品を企画してくれていまして、2 - 3ヶ月に1回ずつやっているうちに、1年があっという間に過ぎてしまいます。</p>
            <br />
            <p>ライブコマースで差別化するために重要なのは何だと思いますか ?</p>
            <p>企画が一番重要だと思います。コンセプトもそうですが、最終的にはECなので、売上への影響を意識しております。Qoo10でのライブコマースで良い成果を経験したブランドさんが、競合他社では比較にならないくらい成果が出なかったこともあるそうです。私たちは、お客様がどのようなターゲットで、どのような価格帯で購入するのか、どのような案内をすればより訴求ポイントが強くなるのかという部分を、商品パッケージングを企画する時から、ブランドと密に打ち合わせをするようにしています。 また、いつもより安くないと絶対にライブコーマスはできません。必ず売上が出るように、かなり重視しています。</p>
            <br />
            <p>Qoo10チームで重視している指標はありますか ? その理由は何でしょうか ?</p>
            <p>社内のKPI、そして視聴者数です。視聴者数がそのまま商品ページのPV数につながり、購買コンバージョン率が計算され、売上につながるので、売上 - PV数 - 視聴者数の3つを最も重視して見ています。コンバージョン率は主に視聴者数、累積でQoo10で売れた件数、この2つを比較して計算し参考にしています。この基準でみると、15人~20人に1人は購入する傾向があります。</p>
            <br />
            <p>ライブコマースはQoo10のお客様にどのような影響を与えていますか ?</p>
            <p>私たちはライブコマースを始める前からショッピングとエンターテイメントをスローガンに掲げていました。なので、最初は売上を期待せずにライブを始めました。「すごく面白い、Qoo10に入ったら面白いことがたくさんある」というポジションで計画したのですが、売上も順調に出ているので、面白さが「売上にもつながった」と見ています。</p>
            <br />
            <h2>Grip cloudで変わったライブコマース</h2>
            <br />
            <p>Grip cloud導入に関するお話を伺いたいです。Grip cloudの導入前、ライブコマースを行う上で、最も難しかった点や不便だった点は何でしたか ?</p>
            <p>Grip cloudを導入する前は、Qoo10のアプリ内でライブを行ったことがありませんでした。 ただ、インフルエンサーのチャンネルではライブを行いました。主にインスタグラムでしたが、30分から1時間のライブ配信をして視聴者数やコメント数がかなり良くても、ウェブサイトへの誘導が非常に難しかったです。 実際の商品販売に繋がらないのが一番悩みでした。</p>
            <br />
            <p>ライブコマースの導入を検討したきっかけは何ですか ?</p>
            <p>海外ではすでにライブコマースが盛り上がっていて様々な試みが行われていましたが、日本ではまだほとんどなかったので「最初のポジションを取る」というミッションでした。Qoo10は「ユニークなコンテンツを作る」というミッションが大きかったので。</p>
            <br />
            <p>Grip cloudを導入した感想はいかがでしたか ?</p>
            <p>最初は設定に不慣れな部分もありましたが、素早く対応してくださったおかげでスムーズに進めることができましたし、ライブコマースの企画・制作や他の部分でも協業してくださったです。</p>
            <p>Qoo10のアプリ内でライブ配信が行われ、ライブを見ながらすぐに購入が可能ですし、商品のランキング順位も表示されて、視聴者からみて非常にダイナミックなライブ配信だと思います。ライブ配信で紹介・販売されているその商品がランキングに入ることをリアルタイムでみると、視聴者の方々も楽しみを感じるようです。配信者の方が「今、何位になりました!」のような内容を話すと、チャットで「今日1位になるよ」というような内容を視聴者の方が書いてくださって、とても楽しんでくださっていることが感じられます。ランキング1位になると、ライブですぐにその画面を表示して、お互いにお祝いします。 みんなで1位にしたというストーリー性もありますね。また、アーカイブ映像もすぐにリンクでページに掲載できるように開発し、かなり効率よく運営できるようになりました。 Twitterで同時配信と広告も行っているのですが、広告チームから単価がかなり低く効率が良いと言われています。</p>
            <br />
            <p>お客様の反応はどうでしたか ?</p>
            <p>今は1年以上やっているため、常連の方々がお互いに機能を教えてくださったり、ライブコマースが「伝わった」ことを実感しています。</p>
            <p>はじめはライブ配信は他のSNSチャンネルで経験したことが多くても、ライブ配信を見ながら購入までできるということは慣れてないお客様が多かったです。 そのため、購入する方法について案内コメントをたくさんしました。</p>
            <br />
            <h2>Qoo10が目指すライブコマース</h2>
            <br />
            <p>これからのライブコマースのためにどのような準備をしていますか ?</p>
            <p>ブランド様にチャンネルを開設してもらうための作業を始めました。今年は渋谷や原宿にQoo10スタジオをオープンする予定です。ブランド様がライブをやりたくてもスタジオや機材などの事情でなかなかできないので、今はQoo10にすべてを任せているのですが、私たちにもやれる配信数の限界があると判断しました。そのため、いつでも自社のチャンネルにログインしてQoo10でライブコマースができるような環境を作っています。これが完成すれば、他のサイトではできないライブコマースを提供する役割を果たせるのではないかと期待しています。</p>
            <br />
            <p>ブランド側の反応はどうですか ?</p>
            <p>強い関心を示しております。まだライブコマースの経験がない会社も多く、ライブ配信で商品を紹介し販売することに慣れてないです。 そのため、問い合わせが多いです。</p>
            <br />
            <p>一番問い合わせが多いカテゴリーは何ですか ?</p>
            <p>化粧品の事例が多いので、ファンケル(FANCL)、ANGFA(アンファ)、ロート(rohto)のような化粧品ブランドが多かったです。 入店を説得する際にライブコマース(Qoo10ライブショッピング)が決め手になることも多いです。どのようなゲストやMCが話をしてくれるのかをはじめに基本的な質問も多いですね。どのように制作されるのか、そのプロセスがわからないんです。私たちは毎回、ブランドが希望するコンセプトに合わせてカスタマイズして企画しているので、実際にライブをやったことのあるブランドは満足度がかなり高いです。モデルを起用しているブランドであれば、そのモデルさんとコンタクトを取ったりして、できるだけブランドのニーズに合わせるようにしています。</p>
            <br />
            <p>自慢したい成功事例を一つだけ教えてください。</p>
            <p>1時間のライブコマースで売上が一気に伸びて1億円になったことがあります。 ブランド側が次のライブ日程をすぐに決めようとするほど熱烈なファンになってくださって、とても嬉しかったです。あと、5分で1万個売れたことがあります。その時はアイドルが出演して、実際に商品にサインしてプレゼントするイベントもやったり、エンターテインメント性のあるライブ配信でした。「5万個売れたら実際に踊る」という約束もして、すごく楽しいライブ配信だった記憶があります。</p>
            <br />
            <p>今後やってみたい企画はありますか ?</p>
            <p>「MOVE」というサービスのローンチ2年目を迎えます。ファッションだけに集中したサービスで、動画で商品を紹介するというコンセプトです。 今年はこのサービスの成長もミッションの一つですので、ファッション中心のライブをもっと増やしたいというチャレンジがあります。実際にどう展開していくかは、今、制作会社とも話し合いを続けているので、楽しみにしてください。</p>
            <br />
            <p>逆に改善したいカテゴリーはありますか ?</p>
            <p>食品は市場規模と比べるとまだ規模が小さいです。昨年、数回ほど食品のライブコマースを行って、良い結果を出した事例もあり、今年もこのようなアプローチで増やそうと準備しています。</p>
            <br />
            <p>Qoo10から見た日本のライブコマース市場とその成長は ?</p>
            <p>Qoo10のお客様に対して、まだ視聴者数が少ないと判断しています。 新規視聴者をどうやって獲得するか。今まで見てくださった方はすでによくご存じだと思いますが、新規視聴者も体験できるようにすることが今年のミッションでもあるので、新しいカテゴリー、新しいコンセプトを作る必要があると思います。</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 5,
        image: '/assets/images/contents/content_05.png',
        title: 'プライベートライブ｜販売だけではない!ライブコマースでファンづくり',
        description: <>
            <p>ライブコマースが注目されている理由はよく売れるからだけではありません。ライブコマースの真の力はファンづくりにあります。</p>
            <p>ブランドが見せたいところだけを一方的に見せるのではなく、店舗でスタッフさんがお客様の対応をするように、視聴者と双方向コミュニケーションを取りながら、本当に知りたい情報を伝え、信頼関係を築き、最終的にファンになっていただくことまでがライブコマースの役割です。</p>
            <p>今回はファンづくりに活用できるGrip cloudのプライベートライブ機能をご紹介いたします。</p>
            <br />
            <h2>プライベートライブとは何ですか?</h2>
            <p>プライベートライブは入場コードを知っている方だけが視聴できるライブ配信です。</p>
            <br />
            <h2>プライベートライブで何ができますか?</h2>
            <p>顧客は自分を特別に扱ってくれる企業に心を惹かれやすいです。既に多くの企業が顧客の購入頻度や金額によってランクを分け、そのランクに合わせて特典を提供する施策を活用しております。例えば、百貨店が有名でVIPのための特別なイベントをよく開催しています。</p>
            <p>Grip cloudのプライベートライブ機能を活用すると、オンライン上でも特定の顧客様のために特別なイベントを実施できます。</p>
            <br />
            <p>3つの活用アイデアをご共有いたします!</p>
            <br />
            <h3>新商品のショーケース</h3>
            <p>ライブコマースだからといって必ずしも販売に誘導しなくても大丈夫です。ファンのために新商品をいち早く紹介するショーケースイベントを行うのはいかがでしょうか?</p>
            <p>誰よりも積極的に意見を発信し、新商品に興味を持っている方を招待することで事前に口コミを広げることができるかもです。</p>
            <br />
            <h3>限定数量販売イベント</h3>
            <p>限定数量の商品を効率よく販売したい場合にもプライベートライブを活用できます。</p>
            <p>プライベートライブだからこそ普段より長く質疑応答の時間を持つことができますし、細かい対応により商品に対する顧客満足度を高めることもできます。</p>
            <br />
            <h3>交流イベント</h3>
            <p>自分の意見を発信することに積極的なファンは社員やブランドと直接コミュニケーションできるチャンスを喜びます。</p>
            <p>ファンの意見を大切にしていることをイベントでファンに伝えてみてください。</p>
            <br />
            <p>プライベートライブは視聴者のターゲットが明確で、比較的に少人数であることが多いため、より効率的なマーケティングができるというメリットもあります。</p>
            <br />
            <ol>プライベートライブの設定方法について
                <li>ライブ登録画面からプライベート配信をYESにする</li>
                <li>「生成」ボタンをクリックして入場コードを生成する</li>
                <li>生成された入場コードをコピーしてメモする</li>
                <li>ライブ登録を完了する</li>
                <li>メモした入場コードをターゲットの視聴者に各自の方法で伝える(例:メルマガ、アプリのお知らせ、PUSHなど)</li>
            </ol>
            <br />
            <p>ライブ配信のやり方は普通のライブ配信と同じで、PCやスマホから配信できます。</p>
            <br />
            <p>プライベートライブ機能を活用して、お客様と良好な関係を築き、ファンを育てましょう!</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 6,
        image: '/assets/images/contents/content_06.png',
        title: '[2023年2月] SNSトレンドレポート|Twitter Blueに続き、InstagramとFacebookにも有料プランが登場',
        description: <>
            <h2>SNSニュース</h2>
            <h3>Twitter APIの有料化を再延期</h3>
            <p>Twitter社は2月13日(現地時間)、予定されていた新しい「Twitter API」プラットフォームのローンチを再延期すると発表しました。詳細については近日中に知らせるとしております。Twitter APIはTwitter連携ログインをはじめに、Twitterの分析、RTキャンペーンの抽選など、多くの企業で様々な施策に活用されているため、今後の発表に注目が集まっています。</p>
            <br />
            <h3>Facebook及びInstagramの有料のサブスクリプションサービス「Meta Verified」を開始</h3>
            <p>Meta社は2月19日(現地時間)、Facebook及びInstagramの個人ユーザーが利用できる有料のサブスクリプションサービス「Meta Verified」を開始すると発表しました。まず、オーストラリアとニュージーランドでテスト運用を始め、他の地域へも展開するとしています。月額料金は11.99米ドル(約1600円)からで、主な特典は認証バッジの取得、なりすましアカウントからの保護強化、投稿のリーチ拡大などが公開されています。現時点で法人向けの有料サブスクリプションサービスは発表されておりません。</p>
            <br />
            <h2>話題のキーワード</h2>
            <h3>バレンタインデー</h3>
            <p>2月の定番イベントと言えば、バレンタインデー!今年もバレンタインデーの話でSNSが盛り上がりました。いわゆる「本命チョコ」や「義理チョコ」、「友チョコ」が思い浮かぶ方も多いと思いますが、近年の新しいトレンドは「推しチョコ」!市販のチョコレートで推し色であるチョコレートから、推しのようにデザインして手作りしたチョコレートや、推しが喜びそうな手作りチョコレートまで、様々な「推しチョコ」を楽しむ人が多くなりました。</p>
            <h3>♬ ブラウニー - 和ぬか</h3>
            <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@kanoshima/video/7196684793885592833" data-video-id="7196684793885592833" style={{ maxWidth: '605px', minWidth: '325px' }}> <section> <a target="_blank" title="@kanoshima" href="https://www.tiktok.com/@kanoshima?refer=embed" rel="noreferrer">@kanoshima</a> みんな真似してね🐶🧡ぼくもひとりでもやってみたよ <a title="ブラウニー" target="_blank" href="https://www.tiktok.com/tag/%E3%83%96%E3%83%A9%E3%82%A6%E3%83%8B%E3%83%BC?refer=embed" rel="noreferrer">#ブラウニー</a><a title="和ぬか" target="_blank" href="https://www.tiktok.com/tag/%E5%92%8C%E3%81%AC%E3%81%8B?refer=embed" rel="noreferrer">#和ぬか</a> <a target="_blank" title="♬ ブラウニー - 和ぬか" href="https://www.tiktok.com/music/ブラウニー-6935008190992042754?refer=embed" rel="noreferrer">♬ ブラウニー - 和ぬか</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>
            <p>ダンスエンタメ ボーイズグループ 『パワーパフボーイズ 』のメンバーKANがTikTokにあげたオリジナル振り付けが人気!特に人気YouTuber『コムドット』のメンターゆうたが踊った動画はTikTokで再生回数680万、いいね数50万件を超えています。今年もTikTokで楽曲ｘ振り付け動画の人気は続きそうです。</p>
            <br />
            <h2>注目のSNSプロモーション</h2>
            <h3>#セブンの冷凍食品当たった がトレンド入り x 合計RT数250万以上 | セブンイレブンの中華フェア開催記念キャンペーン</h3>
            <blockquote className="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/%E4%B8%AD%E8%8F%AF%E3%83%95%E3%82%A7%E3%82%A2?src=hash&amp;ref_src=twsrc%5Etfw">#中華フェア</a> 開催記念🥁<br />/<br />対象のセブンプレミアムゴールドの冷凍ピザ、冷凍パスタがいずれか1個無料!<br />期間中、抽選で25万名様にプレゼント🎁<br />＼<br />当たるまで毎日応募可能✨<br />応募締切は2/23(木)<br />▼参加方法<br />1⃣<a href="https://twitter.com/711SEJ?ref_src=twsrc%5Etfw">@711SEJ</a> をフォロー<br />2⃣この投稿をRT<br />3⃣当選結果が通知に届く<a href="https://t.co/AihZNUvcD8">https://t.co/AihZNUvcD8</a> <a href="https://t.co/dBS5mP74nY">pic.twitter.com/dBS5mP74nY</a></p>&mdash; セブン‐イレブン・ジャパン (@711SEJ) <a href="https://twitter.com/711SEJ/status/1626385943765876736?ref_src=twsrc%5Etfw">February 17, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
            <p>セブン‐イレブンが中華フェア開催記念で実施したフォロー&リツイートキャンペーンは、なんと25万名に「セブンプレミアムゴールド 金のマルゲリータ」、「セブンプレミアムゴールド 金のボロネーゼ」、「セブンプレミアムゴールド 金の蟹トマトクリーム」いずれか1個無料クーポンをプレゼントする太っ腹なキャンペーンでした。当たった場合はクーポンがすぐ届き、近くのセブンイレブンで簡単に使えるため、当選報告も多く、#セブンの冷凍食品当たった がトレンド入りしました。キャンペーンで中華フェアの認知を広めただけではなく、冷凍食品の口コミも増えた施策でした。</p>
            <br />
            <h3>#KISSぐらいじゃ落ちない | TikTokのブランドエフェクトを活用したM・A・Cの新商品プロモーション</h3>
            <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@taketaroutime/video/7195761187684994306" data-video-id="7195761187684994306" style={{ maxWidth: '605px', minWidth: '325px' }}> <section> <a target="_blank" title="@taketaroutime" href="https://www.tiktok.com/@taketaroutime?refer=embed" rel="noreferrer">@taketaroutime</a> みんな、これの凄さは塗ったらわかるよ、マジで落ちにくい、てかポッシュ可愛いなおい❗️🤎 <a title="美容男子" target="_blank" href="https://www.tiktok.com/tag/%E7%BE%8E%E5%AE%B9%E7%94%B7%E5%AD%90?refer=embed" rel="noreferrer">#美容男子</a> <a title="macコスメ" target="_blank" href="https://www.tiktok.com/tag/mac%E3%82%B3%E3%82%B9%E3%83%A1?refer=embed" rel="noreferrer">#MACコスメ</a> <a title="kissぐらいじゃ落ちない" target="_blank" href="https://www.tiktok.com/tag/kiss%E3%81%90%E3%82%89%E3%81%84%E3%81%98%E3%82%83%E8%90%BD%E3%81%A1%E3%81%AA%E3%81%84?refer=embed" rel="noreferrer">#KISSぐらいじゃ落ちない</a> <a title="pr" target="_blank" href="https://www.tiktok.com/tag/pr?refer=embed" rel="noreferrer">#pr</a> <a target="_blank" title="♬ KISSぐらいじゃ落ちない - MAC" href="https://www.tiktok.com/music/KISSぐらいじゃ落ちない-7191737325142050817?refer=embed" rel="noreferrer">♬ KISSぐらいじゃ落ちない - MAC</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>
            <p>M・A・Cが2月3日(金)に発売した「M･A･C ロックド キス インク リップカラー」のプロモーションとしてブランドエフェクトを使ったハッシュタグチャレンジ「#KISSぐらいじゃ落ちない」を開始しました。TikTokのユーザーはエフェクトを使うことで新製品の中の3色「エクストラ チリ」「メティキュラス」「ポッシュ」を疑似体験し、自分に似合う色を見つけることができます。</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 7,
        image: '/assets/images/contents/content_07.png',
        title: 'ゲーム機能｜ライブコマースのエンゲージメントをあげるコツ',
        description: <>
            <p>ライブコマースの成果を測るために重要な指標の一つは視聴者のエンゲージメントです。しかし、視聴者のエンゲージメントを上げることは簡単ではありません。安くて品質の良い商品や視聴者とコミュニケーションだけではなく、視聴者の目を引く「遊び」も必要です。</p>
            <p>そこで、普段のライブ配信に「遊び」を加えることができるゲーム機能をご紹介いたします!</p>
            <br />
            <h2>ライブコマースでゲームをするメリット</h2>
            <br />
            <h3>視聴者の楽しさ</h3>
            <p>多くの視聴者は画面をクリックすることを楽しんでいます。 これは多くのライブ配信サービスがいいね機能を追加して、いいねボタンをクリックするとアイコンが見えるように画面を構成する理由でもあります。</p>
            <p>しかも、ゲームに参加することは、いいねボタンをクリックするよりも楽しいです。</p>
            <br />
            <h3>コメントの参加度増加</h3>
            <p>ライブ配信の面白さにコメントは欠かせません。 配信者と視聴者の間だけでなく、視聴してコメントを書くみんながコミュニケーションの対象になり、お互いのコメントに反応するのが望ましいです。 ライブ配信中にゲームを実施するで、視聴者がお互いに意見を交わすことを促しやすくなります。もっと積極的にコメントを誘導するためにゲームの特典を設計するなど工夫してみてください。</p>
            <br />
            <h3>視聴時間の増加</h3>
            <p>ゲームはライブ配信の視聴時間にも影響を与えます。ライブ配信が面白ければ面白いほど、視聴時間が増えることは言うまでもないでしょう。ゲームはライブ配信に新しいコンテンツを提供してくれます。Grip cloudのデータでも、ゲーム機能を使うと視聴時間が増加する傾向が分かりました。</p>
            <br />
            <h2>Grip cloudのゲーム機能について</h2>
            <p>2023年3月の時点でGrip cloudには3つのゲーム機能があります!</p>
            <br />
            <h3>OXゲーム</h3>
            <p>OXゲーム機能を活用して視聴者とOXクイズをやってみてください。OXゲームを設定すると、視聴者の画面に"O"と"X"のボタンが表示され、答えを選択できるようになります。</p>
            <p>文字通りに正しい、正しくないとしても活用できますが、1番、2番のように使うこともできます。 ライブ配信の企画に合わせてクイズを出して視聴者と一緒に楽しんでください!</p>
            <br />
            <h3>先着順ゲーム</h3>
            <p>もっとも早くボタンをクリックしたユーザーが当選する先着順ゲーム。限られた目玉商品をより面白く販売したいときは先着順ゲームを活用してみてください。</p>
            <p>ライブ配信が一番盛り上がったタイミングで目玉商品を紹介し、先着順ゲームをすると、商品が欲しい視聴者が一気に参加してライブ配信もより盛り上がります。</p>
            <br />
            <h3>抽選</h3>
            <p>賞品があるときは簡単で公平に当選者を選定できる抽選機能を活用するのがおすすめです。</p>
            <p>抽選機能を活用したイベントは視聴者のモチベーションアップにつながりやすいです。当選する可能性があることで、参加者はより積極的に参加したり、より楽しめることが多いです。</p>
            <br />
            <h2>ゲーム機能を設定する方法</h2>
            <br />
            <ol>
                (共通)PCでの設定方法
                <img src="/assets/images/contents/content_07_1.png" alt="" />
                <li>
                    ライブ配信画面の右側 [メニュー画面] で[ゲーム]ボタンをクリックします。
                </li>
                <li>
                    ゲーム機能一覧から希望するゲームを実行します。
                </li>
            </ol>
            <br />
            <ol>
                先着順ゲームの設定方法
                <img src="/assets/images/contents/content_07_2.png" alt="" />
                <li>
                    [先着順ゲーム] 〉 [0人]をクリック 〉 希望する当選人数を設定 〉 [先着順開始]ボタンをクリックします。<br />
                    *当選人数は最大50人まで設定できます!
                </li>
                <li>
                    右側の[先着順ゲーム画面]でリアルタイムの参加人数を確認できます。
                </li>
                <li>
                    参加人数が当選人数に達したら、[終了]ボタンをクリックします。
                </li>
            </ol>
            <br />
            <ol>
                抽選の設定方法
                <img src="/assets/images/contents/content_07_3.png" alt="" />
                <li>
                    [抽選する] 〉 [0人]クリック 〉 希望する当選人数を設定 〉 [抽選開始]ボタンをクリックします。<br />
                    *人数は最大50人まで設定できます!
                </li>
                <li>
                    右側の[抽選画面]でリアルタイムの参加人数を確認することができます。
                </li>
                <li>
                    参加人数が当選人数を超えたら、良いタイミングで[終了]ボタンをクリックします。
                </li>
            </ol>
            <br />
            <ol>
                OXゲームの設定方法
                <img src="/assets/images/contents/content_07_4.png" alt="" />
                <li>
                    [OXゲーム] 〉 [開始する] ボタンをクリックします。<br />
                    ライブ画面で [ゲームスタート!]のフレーズを確認して、チャットもしくは口頭で質問を伝えます。<br />
                    一定数の回答が集まったら[終了する]ボタンをクリックしてゲームを終了します。
                </li>
            </ol>
            <br />
            <ol>
                (共通)アプリでの設定方法
                <li>
                    モバイルアプリ画面の下にあるメニューボタンから[+]ゲームアイコンをクリックします。
                </li>
                <li>
                    ゲーム機能のうち、希望のゲームをクリックします。
                </li>
            </ol>
            <br />
            <ol>
                先着順ゲームの設定方法
                <li>
                    ゲームメニュー 〉 [先着順]アイコンをクリック 〉 [確認]ボタンをクリックします。
                </li>
                <li>
                    希望する当選人数を入力 〉 [先着順開始]ボタンを押してゲームを開始します。
                </li>
                <li>
                    画面の左下でゲーム状況を確認できます。
                </li>
                <li>
                    参加人数が当選人数に達したら、[終了]ボタンをクリックします。
                </li>
            </ol>
            <br />
            <ol>
                抽選の設定方法
                <li>
                    ゲームメニュー 〉 [抽選]アイコンをクリック 〉 [確認]ボタンをクリックします。
                </li>
                <li>
                    希望する当選人数を入力 〉 [抽選開始]ボタンを押してゲームを開始します。
                </li>
                <li>
                    画面の左下でゲーム状況を確認できます。
                </li>
                <li>
                    参加人数が当選人数を超えたら、良いタイミングで [終了]ボタンをクリックしてゲームを終了します。
                </li>
            </ol>
            <br />
            <ol>OXゲームの設定方法
                <li>
                    ゲームメニュー 〉 [OX]アイコンをクリック 〉 [確認]ボタンをクリックします。
                </li>
                <li>
                    [開始]ボタンを押してゲームを開始します。
                </li>
                <li>
                    ライブ画面で[ゲームスタート!]フレーズを確認して、チャットもしくは口頭で質問を伝えます。
                </li>
                <li>
                    一定数の回答が集まったら[終了]ボタンをクリックしてゲームを終了します。
                </li>
            </ol>
        </>,
        date: '2023.03.31'
    },
    {
        id: 8,
        image: '/assets/images/contents/content_08.png',
        title: '[2023年版] ライブコマース初心者のための基本知識まとめ',
        description: <>
            <h2>ライブコマースとは?</h2>
            <p>ライブコマースは、リアルタイムを意味する「ライブ」と商売を意味する「コマース」を合わせた言葉で、リアルタイムのライブ配信とショッピングを組み合わせたことを意味します。言い換えるとライブ配信を見ながら買い物をすることです。</p>
            <p>ライブコマースは従来のEコマースとどう異なるのでしょうか?</p>
            <p>最も大きな違いは、リアルタイムの双方向コミュニケーションです。これまでEコマースは、商品の詳細ページのように、消費者は販売者が提供する情報を受動的に得るしかありませんでした。しかし、ライブコマースは配信者(販売者)が視聴者、すなわち消費者とリアルタイムでコミュニケーションし、消費者が欲しい情報をその都度教えることができます。このようにリアルタイムで情報を得て商品を間接体験することができ、商品が気に入ったら簡単に購入できる利便性のため、ライブコマース市場は日々拡大しています。</p>
            <br />
            <h2>ライブコマースが注目される理由</h2>
            <h3>Eコマースの成長</h3>
            <p>経済産業省が実施した「<a href="https://www.meti.go.jp/press/2022/08/20220812005/20220812005.html">令和3年度デジタル取引環境整備事業(電子商取引に関する市場調査)</a>」によると、令和3年の日本国内のBtoC-EC(消費者向け電子商取引)市場規模は、20.7兆円(前年19.3兆円、前々年19.4兆円、前年比7.35%増)に拡大しています。また、EC化率もBtoC-ECで8.78%(前年比0.7ポイント増)と増加傾向にあり、商取引の電子化が引き続き進展しています。 このように日本でもEコマース市場は成長しており、一方で競走も激しくなっているため、差別化のために新しいコマース形式であるライブコマースに興味を持つ企業が増えています。</p>
            <br />
            <h3>ライブ配信の普及</h3>
            <p>クロス・マーケティングが実施した「ライブ配信に関する調査(2022年)」によると、全体で48.4%がライブ配信を視聴したことがあると答えました。InstagramやYouTubeなど人気なソーシャルネットワークサービスは揃ってLIVE配信機能があり、17LIVEやPocochaなどライブ配信サービスも次々と増えております。コロナをきっかけに外出の自粛でおうち時間が長くなったことや、オフラインでのライブイベントができなくなりオンラインイベントが増えたこと、そして、いわゆるZoom飲み会のように日常の中でもライブ配信を通じてコミュニケーションを取る経験が一般化したことで、ライブ配信の文化は普及しました。</p>
            <br />
            <h3>ライブコマース大国、中国</h3>
            <p>中国のライブコマース市場は急速に成長しており、前瞻产业研究院の調査によると2023年には4兆9000億元を超えると予想されております。中国での圧倒的な成長は中国市場への進出を考える企業だけではなく、中国のビジネスモデルを他の国で展開しようとする企業にも注目されており、ライブコマースが全世界で注目されている理由の一つになります。</p>
            <br />
            <ol>ライブコマースのメリット
                <li>
                    消費者とリアルタイムで双方向コミュニケーションを取りながら、商品についての情報だけではなく面白さを提供することができるため、単純に販売で終わるのではなく顧客との信頼関係を築くことができます。
                </li>
                <li>
                    マーケティング予算が限られている場合でもスマホだけで実施できます。
                </li>
            </ol>
            <br />
            <ol>ライブコマースの注意点
                <li>
                    配信の品質:ライブコマースは映像や音声の品質が重要になります。最近では通信技術の発達やスマホの性能の改善によりスマホだけでも配信ができますが、安定的なネットワークを確保し、映像や音声のクオリティを向上させるための対策を行う必要はあります。
                </li>
                <li>
                    法令の遵守:ライブコマースも一種のオンライン販売であるため、法令を遵守することが必要です。特に、商品の表示や広告に関する法律には、十分な理解が必要です。
                </li>
            </ol>
            <br />
            <h2>ライブコマースの事例</h2>
            <h3>ライブコマースの失敗事例</h3>
            <p>「メルカリチャンネル」、「BASE LIVE」、「Yahoo!ショッピング」の「ショッピングLIVE」などのサービスがありましたが、今はサービスの提供を終了しています。</p>
            <h3>ライブコマースの成功事例</h3>
            <p>Qoo 10は自社アプリで毎週水曜日、定期的にライブコマース(Qoo10ライブショッピング)を行っており、40分で4000セット完売するなど成功事例があります。</p>
            <br />
            <h2>ライブコマースのやり方</h2>
            <h3>SNSでライブコマースする</h3>
            <p>InstagramやYouTubeなどライブ配信機能があるSNSを活用してライブコマースすることができます。既存のSNSアカウントがある場合、活用できるといった点がメリットになりますが、購入するためにはライブ配信の画面から離脱しなければいけにため、購入まで誘導することが難しいデメリットがあります。</p>
            <h3>ライブコマース機能があるECモールでライブコマースする</h3>
            <p>Qoo10のようにライブコマース機能があるECモールに入店してライブコマースすることができます。ECモールからライブコマースをサポートしてもらえる可能性もありますが、自社のスケジュールや目標に合わせて自由に実施することはできない可能性もあります。</p>
            <h3>自社ECサイトやアプリでライブコマースする</h3>
            <p>自社ECサイトやアプリにライブコマースができるように開発して、直接ライブコマースすることができます。ライブコマースの視聴から商品の購入まで自社サイトで完結できるメリットがあります。また、自社サイトであるため外部要因なく安定的に運用できるため、長期的なブランディングやロイヤルカスタマーの育成に向いております。社内に開発チームがあれば直接開発することもできますが、より早く導入したい場合や社内に開発チームがない場合はGrip cloudのようなライブコマースソリューションを利用する方法もあります。</p>
        </>,
        date: '2023.03.31'
    },
    {
        id: 9,
        image: '/assets/images/contents/content_09.png',
        title: '今更聞けない?!ライブコマースとソーシャルコマースのすべてについて解説',
        description: <>
            <p>近年、Eコマース業界でよく耳にする話題のキーワードと言えば、「ライブコマース」と「ソーシャルコマース」ではないでしょうか。これらは従来のオンラインショッピングとは異なる、新しい形のビジネス手法として注目を浴びています。しかし、今更ながらも「ライブコマース」と「ソーシャルコマース」が何か説明しようとしたらなんとなくわかるけど説明は難しいという方も多いと思います。ここではライブコマースとソーシャルコマースの意味、メリットとデメリット、そして何が異なるのかについて説明いたします。</p>
            <br />
            <h2>ライブコマースとは?</h2>
            <p>ライブコマースとは、ライブ配信を利用して商品やサービスを販売するEコマースの形態の一つです。</p>
            <p>一般的に、ライブコマースは、オンラインショッピングとライブ配信を組み合わせて、商品の実演や販売を行います。実店舗がない場合でも、ライブ配信を通じて商品の魅力を伝え、視聴者が商品を購入できるようにすることが特徴です。最近では通信技術とスマホ端末の機能改善により、スマホでもライブ配信が用意になったため、小規模な企業や個人事業主でも手軽にライブ配信ができるようになりました。また、ライブコマースは商品ページなどで販売者が伝えたい情報だけを一方的に提供する従来のオンラインショッピングにはなかった臨場感や親近感を視聴者に与えることができます。また、コメントを通じてリアルタイムでコミュニケーションが可能なため、商品に対するフィードバックを得ることもできます。</p>
            <br />
            <h2>ソーシャルコマースとは?</h2>
            <p>ソーシャルコマースとは、Facebook、Instagram、Twitter、YouTubeなどのソーシャルメディアのプラットフォームやツール、LINEやWeChatなどのメッセージアプリケーションなどを使って、商品やサービスを販売することを指します。例えば、ソーシャルメディア上での情報共有や口コミ、インフルエンサーの紹介などを活用して、商品やサービスの販売を促進する、いわゆるSNSマーケティングがほとんどソーシャルコマースの例になります。また、ソーシャルメディア上での広告やキャンペーン、クーポンなどを利用することで、商品やサービスの認知度を高めることもできます。また、ソーシャルメディア上でのコミュニケーションや情報共有ができるため、顧客との関係を深め、商品やサービスの改善につなげることもできます。</p>
            <br />
            <h2>ライブコマースとソーシャルコマースの違い</h2>
            <p>ライブコマースとソーシャルコマースは、どちらもSNSなどのプラットフォームを活用した販売手法である点は共通していますが、異なる点もあります。</p>
            <p>ライブコマースは「ライブ配信」といった行動を通して商品を販売することであり、ライブコマースをするためにSNSを活用することもできますが、必ずしもSNSアカウントが必要はありません。一方で、ソーシャルコマースは「ソーシャルメディア」を活用して商品を販売することであり、前提として必ずSNSアカウントが必要になります。それぞれの特徴を生かして、販売者は消費者との関係を構築し、消費者は自分に合った商品を選ぶことができるようになっています。</p>
            <br />
            <h2>ライブコマースのメリットとデメリット</h2>
            <ol>ライブコマースのメリット
                <li>
                    商品の魅力をリアルに伝えられる:ライブコマースでは、商品を手に取って触ったり、着用したりすることをリアルタイムで共有できるため、商品の魅力をよりリアルに伝えることができます。
                </li>
                <li>
                    ユーザーと双方向コミュニケーションができる:ライブコマースでは、ユーザーとリアルタイムで双方向コミュニケーションをとることができます。質問や意見に対して直接返信することができ、ユーザーとの関係をより強めることができます。
                </li>
                <li>
                    時間や場所による制限が少ない:ライブコマースは、オンライン上で行われるため、実店舗と比べて時間や場所による制限が少ないです。消費者は自宅や外出先など、自分の好きな場所で商品を購入することができます。
                </li>
            </ol>
            <br />
            <ol>ライブコマースのデメリット
                <li>
                    技術的な問題:ライブコマースは、リアルタイムで行われるため、ネットワークの不具合や機器のトラブルなどによって、ライブ配信が途切れてしまったり、購入ができなくなることがあります。
                </li>
                <li>
                    ノウハウ不足:どのような企画が面白いか、どのように商品を映したらよりリアルに見えるのかなど、良いライブコマースのために重要なノウハウを身に着けるまで多少時間が
                </li>
            </ol>
            <br />
            <h2>ソーシャルコマースのメリットとデメリット</h2>
            <ol>ソーシャルコマースのメリット
                <li>
                    広告費の削減:基本的にソーシャルメディアの利用は無料であるため、ソーシャルコマースは予算がなくても実施できます。ソーシャルメディアを利用して直接商品を宣伝できるため、うまくソーシャルメディアのアカウントを育てた場合は広告費を削減する効果もあります。
                </li>
                <li>
                    購買意欲の高まり:ソーシャルメディアは、コミュニケーションの場としても利用されており、商品を紹介したり口コミをすることで、消費者の購買意欲を高めることができます。
                </li>
                <li>
                    マーケットリサーチ:ソーシャルメディア上のユーザーの投稿を分析することで、商品やサービスに対するニーズやトレンドを把握することができます。
                </li>
            </ol>
            <ol>ソーシャルコマースのデメリット
                <li>
                    激しい競争環境:ソーシャルメディアは激しい競争環境にあり、参入障壁が高くなっております。フォロワーの獲得単価も高くなり、広告費などの負担が増えております。
                </li>
                <li>
                    ノウハウ不足:写真や動画の編集などソーシャルメディアを運営するための知識やノウハウを持っている人材が社内にいない場合は運用のために追加コストがかかう可能性があります。
                </li>
                <li>
                    規制の問題:ソーシャルコマースは、各国の消費者保護法や広告表示法に従う必要があります。規制に違反することで、法的な問題が発生する可能性があります。
                </li>
            </ol>
        </>,
        date: '2023.03.31'
    },
    {
        id: 10,
        image: '/assets/images/contents/content_10.png',
        title: 'ライブコマースソリューション「Grip cloud」にショートムービー機能が追加されました。',
        description: <>
            <p>「Grip cloud」は、顧客が自社ECサイトで直接ライブコマースができるようにするSaaS(Software as a Service)形式のライブコマースソリューションで、安定的なクラウドインフラストラクチャを提供しており、ゲーム機能の特許も保有しております。</p>
            <p>新しくリリースした「Grip Short(グリップショート)」は、企業が自社ECサイトやアプリでショートムービー形式の動画コンテンツと商品販売を組み合わせることができる機能でございます。企業が外部のSNSではなく、自社サービス内で実装できるという点で差別化が図られております。YouTube Short、TikTokと同じように画面切り替えを通じて次のコンテンツに進み、動画画面内で購買を誘導することができることが特徴でございます。</p>
            <br />
            <p>Grip Companyは、最近ショートムービーの視聴量増加に伴い、グローバル企業がショートムービーとコマースの結合を試みている点に注目しました。 「TikTok」はユーザーがアプリで商品を検索し購入できる機能である「TikTokショップ(Shop)」をリリースし、タイ、マレーシアなど東南アジア地域と北米地域に適用しており、Instagramはショートムービー機能である「リール」でユーザーがアプリを離れることなく商品を購入できるショッピング可能なタグを導入したことがあります。</p>
            <br />
            <p>今後、ショートムービーでの広告機能、お気に入り機能などのリリースも予定されております。</p>
        </>,
        date: '2023.03.31'
    },

]