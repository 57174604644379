import styled from "styled-components"

export const FaqWrap = styled.main`
    width: 1360px;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    overflow: hidden;
    > p {
        text-align: center;
    }
    section {
        padding: 70px 0 140px;
        .szh-accordion {
            display: flex;
            flex-direction: column;
            gap: 16px 0;
            .szh-accordion__item {
                background-color: white;
                border-radius: 16px;
                border: 2px solid transparent;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
                transition: all 0.3s ease;
                &.szh-accordion__item--expanded {
                    background: rgba(86, 141, 253, 0.04);
                    border-color:#568DFD;
                    button {
                        .icon {
                            transform: rotate(45deg);
                        }
                    }
                }
                button {
                    width: 100%;
                    border: 0;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    font-family: 'Pretendard', sans-serif;
                    font-size: 22px;
                    font-weight: 700;
                    padding: 32px 24px;
                    text-align: left;
                    color: #343434;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    span {
                        margin-left: 16px;
                        margin-right: auto;
                    }
                    svg {
                        transition: all 0.15s ease;
                    }
                }
                .szh-accordion__item-content {
                    font-size: 1.2rem;
                    line-height: 1.7;
                    padding: 0 120px 32px 80px;
                    word-break: keep-all;
                    transition: all 0.3s ease;
                }
            }
        }
    }
    @media (min-width:600px) and (max-width: 1199px) {
        width: 100%;
        > section {
            .szh-accordion {
                gap: 12px 0;
                .szh-accordion__item {
                    button {
                        font-size: 20px;
                        padding: 30px 20px;
                        span {
                            margin-left: 12px;
                        }
                        .q {
                            width: 28px;
                            height: 28px;
                        }
                        .icon {
                            display: none;
                        }
                    }
                    .szh-accordion__item-content {
                        line-height: 24px;
                        padding: 0 20px 30px 20px;
                    }
                }
            }
        }
    }
    @media (max-width: 599px) {
        width: 100%;
        padding : 0 20px;
        > section {
            .szh-accordion {
                gap: 12px 0;
                .szh-accordion__item {
                    button {
                        font-size: 16px;
                        padding: 24px 20px;
                        span {
                            margin-left: 10px;
                        }
                        .q {
                            width: 28px;
                            height: 28px;
                        }
                        .icon {
                            display: none;
                        }
                    }
                    .szh-accordion__item-content {
                        font-size: 14px;
                        padding: 0 20px 24px 20px;
                    }
                }
            }
        }
    }
`