import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import FeatureCard from "./FeatureCard";
import { HomeSection, screen } from "../../../../pages/home/Home.style";
import { useTranslation } from "react-i18next";

const FeatureSectionWrap = styled.div`
  .slick-track {
    display: flex;
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    .slick-dots {
      bottom: -70px;
    }
  }

  @media (max-width: 599px) {
    .slick-dots {
      bottom: 44px;
      ul {
        height: 8px;
        gap: 0 6px;
        li {
          display: flex;
          width: 8px;
          height: 8px;
          background-color: rgba(255, 255, 255, 0.4);
          overflow: hidden;
          border-radius: 4px;
          &.slick-active {
            width: 40px;
          }
          button {
            width: 8px;
            height: 8px;
            padding: 0;
            transition: all 0.3s ease;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .feature-slide-tab {
    display: flex;
    align-items: center;
    gap: 0 16px;
    margin-bottom: 40px;
    button {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 28px;
      font-weight: 700;
      border: 0;
      border-radius: 6px;
      color: #969696;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 4px;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        color: white;
      }
      span {
        font-size: 32px;
      }
      &[data-selected="true"] {
        color: white;
      }
    }
    > span {
      color: #8e8e8e;
    }
    @media (min-width: 600px) and (max-width: 1199px) {
      gap: 0 12px;
      button {
        font-size: 20px;
      }
    }
  }
`;

const featureContentsKr = (language: string) => [
  {
    id: 1,
    tab: language === "ko" ? "원클릭 연동" : "簡単な連携",
    title:
      language === "ko" ? (
        <>
          손쉬운
          <br />
          원클릭 연동
        </>
      ) : (
        <>
          ローコードで
          <br />
          簡単に連携
        </>
      ),
    desc:
      language === "ko" ? (
        <>
          복잡한 스크립트 작업 없이 자사의 웹 또는
          <br />
          앱에 간편하게 연동하세요.
        </>
      ) : (
        <>
          複雑なスクリプト作業なしで
          <br />
          自社のウェブやアプリに簡単に連携
        </>
      ),
  },
  {
    id: 2,
    tab: language === "ko" ? "데이터 대시보드" : "ダッシュボード",
    title:
      language === "ko" ? (
        <>
          데이터
          <br />
          대시보드
        </>
      ) : (
        <>
          一目で見れる
          <br />
          統計データ
        </>
      ),
    desc:
      language === "ko" ? (
        <>
          방송 분석은 그립 클라우드가 할게요.
          <br />
          라이브가 끝난 후, 중요한 지표들을
          <br />
          대시보드에서 한 눈에 확인할 수 있습니다.
        </>
      ) : (
        <>
          配信の成果分析もお任せ！
          <br />
          ライブ終了後、重要な指標を
          <br />
          ダッシュボードで一目で確認可能
        </>
      ),
  },
  {
    id: 3,
    tab: language === "ko" ? "게임기능" : "ゲーム",
    title:
      language === "ko" ? (
        <>
          시청 시간을 11% 늘리는
          <br />
          강력한 게임기능
        </>
      ) : (
        <>
          視聴時間を11%増やす
          <br />
          強力なゲーム機能
        </>
      ),
    desc:
      language === "ko" ? (
        <>
          오래 볼수록 구매율도 높아집니다. 다양하고 효과적인 <br />
          게임 기능으로 시청자들의 몰입도를 높일 수 있습니다.
        </>
      ) : (
        <>
          視聴時間が増えるとコンバージョン率もアップ！ <br />
          ゲーム機能で視聴者のエンゲージメントを増やしましょう。
        </>
      ),
  },
  {
    id: 4,
    tab: language === "ko" ? "VOD 재방송" : "VOD 再配信",
    title:
      language === "ko" ? (
        <>
          VOD로 <br />
          재방송
        </>
      ) : (
        <>
          VODで <br />
          再配信
        </>
      ),
    desc:
      language === "ko" ? (
        <>
          기존에 진행한 라이브 또는 직접 업로드
          <br />
          한 VOD로 재방송을 진행하세요. 리소스
          <br />
          는 절감하고 조회수와 매출 증대 효과를
          <br />
          누릴 수 있습니다.
        </>
      ) : (
        <>
          以前実施したライブやアップロードした
          <br />
          VODを再配信することで、
          <br />
          リソースを削減しながら
          <br />
          再生回数と売上を向上させることができます。
        </>
      ),
  },
  {
    id: 5,
    tab: language === "ko" ? "동시송출" : "同時配信",
    title:
      language === "ko" ? (
        <>
          라이브 하나로
          <br />
          유튜브부터 <br />
          인스타그램까지
        </>
      ) : (
        <>
          1回のライブで
          <br />
          YouTubeから <br />
          Instagramまで
        </>
      ),
    desc:
      language === "ko" ? (
        <>
          라이브를 최대 3개 플랫폼에 동시 송출할 수 있습니다.
          <br />한 방송으로 3배 이상의 효과를 누리세요.
        </>
      ) : (
        <>
          最大3つのプラットフォームで同時配信可能
          <br />
          1回の配信で最大限の効果を!
        </>
      ),
  },
  {
    id: 6,
    tab: language === "ko" ? "방송관리툴" : "配信管理ツール",
    title:
      language === "ko" ? (
        <>
          원클릭
          <br />
          방송관리툴
        </>
      ) : (
        <>
          簡単な
          <br />
          配信管理ツール
        </>
      ),
    desc:
      language === "ko" ? (
        <>
          채팅 관리, 게임, Q&A 등 라이브 진행 시 <br />
          필요한 기능들을 툴 하나로 관리할 수 있어요.
        </>
      ) : (
        <>
          チャット管理、ゲーム、Q&Aなど <br />
          必要な機能を簡単に管理できます。
        </>
      ),
  },
];

const FeatureSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  const featureSlideEl = useRef<any>(null);
  const [featureState, setFeatureState] = useState<number>(0);
  const [featureCurrentSlide, setFeatureCurrentSlide] = useState<number>(0);

  const next = () => {
    if (featureSlideEl.current) {
      featureSlideEl.current.slickNext();
    }
  };
  const previous = () => {
    if (featureSlideEl.current) {
      featureSlideEl.current.slickPrev();
    }
  };

  const featureSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    speed: 500,
    arrows: false,
    infinite: true,
    beforeChange: (current: any, next: number) => {
      setFeatureCurrentSlide(next);
    },
    afterChange: (newIndex: number) => {
      setFeatureState(newIndex);
    },
    appendDots: (dots: any) => (
      <div>
        <ul>{dots}</ul>
        <button className="slide-button" onClick={previous}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M12 3L6 9L12 15" stroke="white" strokeWidth="2" />
          </svg>
        </button>
        <button className="slide-button" onClick={next}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M6 3L12 9L6 15" stroke="white" strokeWidth="2" />
          </svg>
        </button>
      </div>
    ),
    customPaging: (index: any) => (
      <button
        style={{
          backgroundColor: featureCurrentSlide >= index ? "#568DFD" : undefined,
          borderTopRightRadius: featureCurrentSlide === index ? "3px" : 0,
          borderBottomRightRadius: featureCurrentSlide === index ? "3px" : 0,
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 599,
        settings: {
          variableWidth: false,
          appendDots: (dots: any) => (
            <div>
              <ul>{dots}</ul>
            </div>
          ),
          customPaging: (index: any) => (
            <button
              style={{
                backgroundColor: featureCurrentSlide === index ? "white" : undefined,
                width: featureCurrentSlide === index ? 40 : 8,
              }}
            />
          ),
        },
      },
    ],
  };

  return (
    <HomeSection
      bg="black"
      pt={isPc ? 120 : isTa ? 74 : 0.1}
      pl={isPc ? 40 : isTa ? 30 : 0}
      pb={isPc ? 150 : isTa ? 120 : 0.1}
    >
      <FeatureSectionWrap>
        {!isMo && (
          <header className="feature-slide-tab">
            {featureContentsKr(language).map((item: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  <button
                    data-selected={i === featureState}
                    onClick={() => {
                      if (featureSlideEl.current) {
                        featureSlideEl.current.slickGoTo(i);
                      }
                    }}
                  >
                    0{item.id} {item.tab}
                  </button>
                  {item.id < 6 && <span>|</span>}
                </React.Fragment>
              );
            })}
          </header>
        )}
        <Slider ref={featureSlideEl} {...featureSettings}>
          {featureContentsKr(language).map((item: any, i: number) => {
            return (
              <div key={i} style={{ width: isPc ? 1148 : isTa ? 650 : 399 }}>
                <FeatureCard index={i + 1} item={item} playable={i === featureState} />
              </div>
            );
          })}
        </Slider>
      </FeatureSectionWrap>
    </HomeSection>
  );
};

export default FeatureSection;
