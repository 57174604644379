import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, EbookSectionWrap } from "./Ebook.style";
// import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import InquiryForm from "../../common/component/InquiryForm";

const EbookJp = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });

    return (
        <HomeWrap>
            <HomeSection
                bg="#EBDFFF"
                pt={isPc ? 110 : isTa ? 72 : 64}
                pb={isPc ? 140 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="center">
                        {!isPc && (
                            <div>
                                <Typography
                                    label="このような方に"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="おすすめです。"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                />
                            </div>
                        )}
                        <div className="section-img">
                            <img src="/assets/images/ebook/center.png" alt="" />
                        </div>
                        <div className="contents-box">
                            {isPc && (
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="このような方に"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="おすすめです。"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </Reveal>
                            )}
                            {isPc ? (
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            新しいECモールに興味のある事業責任者
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            プロモーションでお悩みのマーケティング担当
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            ライブ配信の経験がある方
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            ライブコマースをやる理由が気になる方
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            ライブコマースの成功事例が知りたい方
                                        </li>
                                    </Reveal>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        新しいECモールに興味のある事業責任者
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        プロモーションでお悩みのマーケティング担当
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        ライブ配信の経験がある方
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        ライブコマースをやる理由が気になる方
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        ライブコマースの成功事例が知りたい方
                                    </li>
                                </ul>
                            )}
                        </div>
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 130 : isTa ? 72 : 64} pb={isPc ? 130 : isTa ? 90 : 40}>
                <HomeSectionInner>
                    <EbookSectionWrap className="bottom">
                        <div className="contents-box">
                            {!isPc && (
                                <>
                                    <Typography
                                        label="このような内容を"
                                        size={isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isTa ? 57 : 38}
                                        mb={isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="ご確認できます。"
                                        size={isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isTa ? 57 : 38}
                                    />
                                </>
                            )}
                            {isPc && (
                                <>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <Typography
                                            label="このような内容を"
                                            size={64}
                                            weight={700}
                                            lineHeight={76}
                                            mb={14}
                                        />
                                        <Typography
                                            label="ご確認できます。"
                                            size={64}
                                            weight={700}
                                            lineHeight={76}
                                        />
                                    </Reveal>
                                    <article>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeIn}
                                            duration={600}
                                            delay={200}
                                        >
                                            <Typography
                                                label="Qoo10はライブコマースを導入して大きな成果をあげました。1回の配信で1億円以上の売上を達成し、また別の配信では5分で1万個の商品が販売された記録もあります。Qoo10がライブコマースを導入したきっかけは何でしょうか?"
                                                size={22}
                                                lineHeight={33}
                                                mb={24}
                                            />
                                            <Typography
                                                label="Qoo10は新しいコンテンツを求めてライブコマースを導入いたしました。このホワイトペーパーではライブコマースを通じてお客様と新しい関係を構築し、より豊かなショッピング経験を提供している「Qoo10 ライブショッピング」の現在と未来についてのインタビュー内容をまとめました。"
                                                size={22}
                                                lineHeight={33}
                                                mb={24}
                                            />
                                            <Typography
                                                label="Qoo10コンテンツチームが話す「日本で成功するライブコマース」をご確認ください。"
                                                size={22}
                                                lineHeight={33}
                                            />
                                        </Reveal>
                                    </article>
                                </>
                            )}
                        </div>
                        <div className="section-img">
                            <img src="/assets/images/ebook/bottom.png" alt="" />
                        </div>
                        {!isPc && (
                            <article>
                                <Typography
                                    label="Qoo10はライブコマースを導入して大きな成果をあげました。1回の配信で1億円以上の売上を達成し、また別の配信では5分で1万個の商品が販売された記録もあります。Qoo10がライブコマースを導入したきっかけは何でしょうか?"
                                    size={isTa ? 16 : 14}
                                    lineHeight={isTa ? 24 : 18}
                                    mb={isTa ? 16 : 8}
                                />
                                <Typography
                                    label="Qoo10は新しいコンテンツを求めてライブコマースを導入いたしました。このホワイトペーパーではライブコマースを通じてお客様と新しい関係を構築し、より豊かなショッピング経験を提供している「Qoo10 ライブショッピング」の現在と未来についてのインタビュー内容をまとめました。"
                                    size={isTa ? 16 : 14}
                                    lineHeight={isTa ? 24 : 18}
                                    mb={isTa ? 16 : 8}
                                />
                                <Typography
                                    label="Qoo10コンテンツチームが話す「日本で成功するライブコマース」をご確認ください。"
                                    size={isTa ? 16 : 14}
                                    lineHeight={isTa ? 24 : 18}
                                />
                            </article>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 60 : isTa ? 60 : 34} pb={isPc ? 1 : isTa ? 80 : 20}>
                <EbookSectionWrap className="top">
                    <div className="section-img">
                        <img src="/assets/images/ebook/top-jp.jpg" alt="ebook" />
                    </div>
                    <div className="form-box">
                        <InquiryForm language="ja" />
                    </div>
                </EbookSectionWrap>
            </HomeSection>
        </HomeWrap>
    );
};

export default EbookJp;
