import { useState } from "react";
import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, EbookSectionWrap } from "./Ebook.style";
import Button from "../../common/component/Button";

const EbookSecond = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 60 : isTa ? 60 : 34} pb={isPc ? 1 : isTa ? 80 : 20}>
                <HomeSectionInner>
                    <EbookSectionWrap className="top second">
                        <div className="contents-box">
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="주목할 만한 10가지"
                                    size={isPc ? 64 : isTa ? 46 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="라이브 커머스 사례집"
                                    size={isPc ? 64 : isTa ? 46 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 24 : isTa ? 16 : 8}
                                />
                            </Reveal>
                            <article>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeIn}
                                    duration={600}
                                    delay={200}
                                >
                                    <Typography
                                        label="라이브를 통해 생생하게 고객과 만나고"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={4}
                                    />
                                    <Typography
                                        label="새로운 비즈니스 기회를 창출하는"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={4}
                                    />
                                    <Typography
                                        label="기업의 성공 스토리"
                                        size={isPc ? 24 : isTa ? 18 : 16}
                                        lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                        mb={isPc ? 64 : isTa ? 46 : 28}
                                    />
                                </Reveal>
                                {(isPc || isTa) && (
                                    <Button
                                        className="cta-button"
                                        label={"무료 다운로드"}
                                        style={{ width: 150, height: 48, zIndex: 1 }}
                                        onClick={() => {
                                            typeof window.gtag === "function" &&
                                                window.gtag("event", "free_download", {
                                                    event_category: "ebook_free_download",
                                                    event_label: "ebook_vol2",
                                                    lang: "kr",
                                                });

                                            window.location.href =
                                                "https://share.hsforms.com/1akGYqfTUQ1G0VgYygqomiweg9vg";
                                        }}
                                    />
                                )}
                            </article>
                        </div>
                        <div className="section-img">
                            <img
                                src="/assets/images/ebook/book-230620.png"
                                alt="세상에 없던 10가지 라이브 이북 표지"
                            />
                        </div>
                        {isMo && (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    className="cta-button"
                                    label={"무료 다운로드"}
                                    style={{ width: 150, height: 48, zIndex: 1 }}
                                    onClick={() => {
                                        typeof window.gtag === "function" &&
                                            window.gtag("event", "free_download", {
                                                event_category: "ebook_free_download",
                                                event_label: "ebook_vol2",
                                                lang: "kr",
                                            });

                                        window.location.href =
                                            "https://share.hsforms.com/1akGYqfTUQ1G0VgYygqomiweg9vg";
                                    }}
                                />
                            </div>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#E6EEFF"
                pt={isPc ? 110 : isTa ? 72 : 64}
                pb={isPc ? 140 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="center second">
                        {!isPc && (
                            <div>
                                <Typography
                                    label="이런 분들께"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="이 책을 추천합니다."
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                />
                            </div>
                        )}
                        <div className="section-img">
                            <img
                                src="/assets/images/ebook/Illustrator-230620-1.png"
                                alt="라이브커머스를 방송 중인 여성"
                            />
                        </div>
                        <div className="contents-box">
                            {isPc && (
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="이런 분들께"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="이 책을 추천합니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </Reveal>
                            )}
                            {isPc ? (
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            효과적으로 제품을 프로모션하고 싶은 이커머스 운영 담당자
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            차별화된 고객 경험을 만들며, 팬커머스를 강화하고 싶은
                                            사업자
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            제품 정보를 입체적으로 전달하고 싶은 MD
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            온라인을 통해 오프라인 방문을 유도하고 싶은 사업자
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#6196FC"} />
                                            생생한 라이브 커머스 성공 사례가 궁금한 누구나
                                        </li>
                                    </Reveal>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        효과적으로 제품을 프로모션하고 싶은 이커머스 운영 담당자
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        차별화된 고객 경험을 만들며, 팬커머스를 강화하고 싶은 사업자
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        제품 정보를 입체적으로 전달하고 싶은 MD
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        온라인을 통해 오프라인 방문을 유도하고 싶은 사업자
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#6196FC"} />
                                        생생한 라이브 커머스 성공 사례가 궁금한 누구나
                                    </li>
                                </ul>
                            )}
                        </div>
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 130 : isTa ? 72 : 64} pb={isPc ? 130 : isTa ? 90 : 40}>
                <HomeSectionInner>
                    <EbookSectionWrap className="bottom">
                        <div className="contents-box">
                            {!isPc && (
                                <>
                                    <Typography
                                        label="이런 내용을"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="책에 담았습니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </>
                            )}
                            {isPc && (
                                <>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <Typography
                                            label="이런 내용을"
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                            mb={isPc ? 14 : isTa ? 10 : 8}
                                        />
                                        <Typography
                                            label="책에 담았습니다."
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        />
                                    </Reveal>
                                    <article>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeIn}
                                            duration={600}
                                            delay={200}
                                        >
                                            <Typography
                                                label="최근 들어 눈에 띄게 많아진 판매 방법이 있습니다. 바로 모바일에서 ‘라이브’로 제품을 판매하는 ‘커머스’입니다. TV를 통해서만 이뤄졌던 영상 판매가 이제는 모바일 기기를 통해 언제든지 접근 가능한 형태로 변화했습니다. 그리고 영상의 장점과 온라인의 편리성이 만나 새로운 커머스를 만들어냈습니다."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="영상을 활용한 커머스는 계속해서 늘어나고, 라이브 코너를 만들어 운영하는 기업들도 연이어 등장하고 있습니다. 라이브 커머스는 어떤 새로운 기회를 창출하고 있을까요? 이번 Grip Insight 리포트에서는 이들의 변화를 가장 가까이에서 지켜본 그립의 직원들을 통해 10가지 라이브 사례를 소개합니다."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="사례를 살펴보다 보면, 우리 브랜드 컬러에 맞는 Live에 대한 아이디어를 얻을 수 있을지도 모릅니다. Live라는 방식을 통해 고객과 만나는 접점을 늘리고, 새로운 기회를 창출하시기 바랍니다."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                        </Reveal>
                                    </article>
                                </>
                            )}
                        </div>
                        <div className="section-img">
                            <img
                                src="/assets/images/ebook/illustrator-230620-2.png"
                                alt="장바구니를 끌고 있는 여성"
                            />
                        </div>
                        {!isPc && (
                            <article>
                                <Typography
                                    label="최근 들어 눈에 띄게 많아진 판매 방법이 있습니다. 바로 모바일에서 ‘라이브’로 제품을 판매하는 ‘커머스’입니다. TV를 통해서만 이뤄졌던 영상 판매가 이제는 모바일 기기를 통해 언제든지 접근 가능한 형태로 변화했습니다. 그리고 영상의 장점과 온라인의 편리성이 만나 새로운 커머스를 만들어냈습니다."
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                <Typography
                                    label="영상을 활용한 커머스는 계속해서 늘어나고, 라이브 코너를 만들어 운영하는 기업들도 연이어 등장하고 있습니다. 라이브 커머스는 어떤 새로운 기회를 창출하고 있을까요? 이번 Grip Insight 리포트에서는 이들의 변화를 가장 가까이에서 지켜본 그립의 직원들을 통해 10가지 라이브 사례를 소개합니다."
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                {!isMo && (
                                    <Typography
                                        label="사례를 살펴보다 보면, 우리 브랜드 컬러에 맞는 Live에 대한 아이디어를 얻을 수 있을지도 모릅니다. Live라는 방식을 통해 고객과 만나는 접점을 늘리고, 새로운 기회를 창출하시기 바랍니다."
                                        size={isPc ? 22 : 20}
                                        lineHeight={isPc ? 33 : 30}
                                        mb={24}
                                    />
                                )}
                                {open && isMo && (
                                    <Typography
                                        label="사례를 살펴보다 보면, 우리 브랜드 컬러에 맞는 Live에 대한 아이디어를 얻을 수 있을지도 모릅니다. Live라는 방식을 통해 고객과 만나는 접점을 늘리고, 새로운 기회를 창출하시기 바랍니다."
                                        size={14}
                                        lineHeight={22}
                                        mb={20}
                                    />
                                )}
                                {isMo && (
                                    <Button
                                        className="outlined"
                                        label={
                                            <>
                                                {open ? "내용 간단히 보기" : "내용 펼쳐보기"}
                                                <svg
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                    style={{
                                                        transform: !open
                                                            ? "rotate(0)"
                                                            : "rotate(180deg)",
                                                    }}
                                                >
                                                    <path
                                                        d="M2.6665 5.8335L7.99984 11.1668L13.3332 5.8335"
                                                        stroke="#363636"
                                                        strokeWidth="1.77778"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </>
                                        }
                                        onClick={handleOpen}
                                    />
                                )}
                            </article>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default EbookSecond;
