import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, WebinarSectionWrap } from "./Webinar.style";
// import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import InquiryForm from "../../common/component/InquiryForm";
import { Mobile, PC, Tablet } from "../../common/component/MediaQuery";

const Webinar = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    return (
        <HomeWrap>
            <HomeSection pt={isPc ? 120 : isTa ? 60 : 34} pb={isPc ? 90 : isTa ? 80 : 40}>
                <HomeSectionInner>
                    <WebinarSectionWrap className="top vol2">
                        <div className="col-1">
                            <div className="text-container">
                                <div className="text-title">
                                    <p>
                                        <span>쇼퍼블 콘텐츠</span>에
                                    </p>
                                    <p>주목해야 하는 이유</p>
                                </div>
                                <div className="text-description">
                                    <p>발견부터 구매까지 한 번에! 원스톱 쇼핑 경험</p>
                                    <div className="schedule">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21"
                                                height="20"
                                                viewBox="0 0 21 20"
                                                fill="none"
                                            >
                                                <g opacity="0.3">
                                                    <g clipPath="url(#clip0_28_404)">
                                                        <path
                                                            d="M7.28571 2.48108C7.87835 2.48108 8.35714 2.9233 8.35714 3.47066V4.46025H12.6429V3.47066C12.6429 2.9233 13.1217 2.48108 13.7143 2.48108C14.3069 2.48108 14.7857 2.9233 14.7857 3.47066V4.46025H16.3929C17.2801 4.46025 18 5.12512 18 5.94462V7.429H3V5.94462C3 5.12512 3.71987 4.46025 4.60714 4.46025H6.21429V3.47066C6.21429 2.9233 6.69308 2.48108 7.28571 2.48108ZM3 8.41858H18V16.0156C18 16.8351 17.2801 17.4999 16.3929 17.4999H4.60714C3.71987 17.4999 3 16.8351 3 16.0156V8.41858Z"
                                                            fill="#444444"
                                                        />
                                                    </g>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_28_404">
                                                        <rect
                                                            width="15"
                                                            height="15.8333"
                                                            fill="white"
                                                            transform="translate(3 1.66663)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span>23.09.21 목 오후 4시</span>
                                        </div>
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21"
                                                height="20"
                                                viewBox="0 0 21 20"
                                                fill="none"
                                            >
                                                <g opacity="0.3">
                                                    <path
                                                        d="M5.7085 8.49996C5.7085 5.44287 7.97737 3.54163 10.5002 3.54163C13.023 3.54163 15.2918 5.44287 15.2918 8.49996C15.2918 9.34012 14.9657 10.4263 14.068 11.8005C13.2811 13.0049 12.1073 14.3495 10.5036 15.8329C8.8963 14.3491 7.72063 13.0043 6.93293 11.7998C6.03451 10.426 5.7085 9.33997 5.7085 8.49996ZM6.9585 8.33329C6.9585 10.2855 8.54796 11.875 10.5002 11.875C12.4524 11.875 14.0418 10.2855 14.0418 8.33329C14.0418 6.38109 12.4524 4.79163 10.5002 4.79163C8.54796 4.79163 6.9585 6.38109 6.9585 8.33329Z"
                                                        stroke="#444444"
                                                        strokeWidth="3.75"
                                                    />
                                                </g>
                                            </svg>
                                            <span>온라인</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-img">
                                <img src="/assets/images/webinar/top_230921.png" alt="webinar" />
                            </div>
                        </div>
                        <div className="form-box">
                            {isPc && (
                                <Typography
                                    label="아래 양식을 작성하시면 웨비나 신청이 완료됩니다."
                                    size={26}
                                    weight={600}
                                    mb={32}
                                />
                            )}
                            <InquiryForm
                                language="ko"
                                formId="d4dc4e52-a9e2-43c2-b959-cc0fb49f9544"
                            />
                        </div>
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#F6E2FF"
                pt={isPc ? 160 : isTa ? 98 : 72}
                pb={isPc ? 90 : isTa ? 142 : 50}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="session">
                        {isPc ? (
                            <>
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label={<>그립 클라우드 웨비나 세션을 소개합니다.</>}
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        mb={74}
                                    />
                                </Reveal>
                                <div className="contents-box">
                                    <ul>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeUp}
                                            duration={600}
                                            delay={100}
                                            cascade
                                            damping={0.25}
                                        >
                                            <li style={{ opacity: 0 }}>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 1</span>
                                                    쇼퍼블 콘텐츠란? 개념과 주목 받는 배경
                                                </div>
                                            </li>
                                            <li style={{ opacity: 0 }}>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 2</span>
                                                    쇼퍼블 콘텐츠의 효과
                                                </div>
                                            </li>
                                            <li style={{ opacity: 0 }}>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 3</span>
                                                    기업별 쇼퍼블 콘텐츠 활용 사례
                                                </div>
                                            </li>
                                            <li style={{ opacity: 0 }}>
                                                <img
                                                    src="/assets/images/webinar/session_icon.png"
                                                    alt=""
                                                />
                                                <div>
                                                    <span>Part 4</span>
                                                    Q&A
                                                </div>
                                            </li>
                                        </Reveal>
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <Typography
                                    label={
                                        <>
                                            그립 클라우드 <br />
                                            웨비나 세션을 소개합니다.
                                        </>
                                    }
                                    size={isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isTa ? 57 : 38}
                                    mb={isTa ? 56 : 24}
                                />
                                <div className="contents-box">
                                    <ul>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 1</span>
                                                {isTa ? (
                                                    <>쇼퍼블 콘텐츠란? 개념과 주목 받는 배경</>
                                                ) : (
                                                    <>
                                                        쇼퍼블 콘텐츠란? <br />
                                                        개념과 주목 받는 배경
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 2</span>
                                                쇼퍼블 콘텐츠의 효과
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 3</span>
                                                기업별 쇼퍼블 콘텐츠 활용 사례
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/webinar/session_icon.png"
                                                alt=""
                                            />
                                            <div>
                                                <span>Part 4</span>
                                                Q&A
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#A561FC"
                pt={isPc ? 180 : isTa ? 72 : 64}
                pb={isPc ? 180 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="center">
                        {isMo && (
                            <>
                                <Typography
                                    label="이런 분들께"
                                    size={32}
                                    weight={700}
                                    lineHeight={38}
                                    mb={10}
                                    color="white"
                                />
                                <Typography
                                    label="웨비나를 추천합니다."
                                    size={32}
                                    weight={700}
                                    lineHeight={38}
                                    color="white"
                                />
                            </>
                        )}
                        <div className="section-img">
                            <img src="/assets/images/webinar/center.png" alt="" />
                        </div>
                        {isPc ? (
                            <div className="contents-box">
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="이런 분들께"
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        mb={10}
                                        color="white"
                                    />
                                    <Typography
                                        label="웨비나를 추천합니다."
                                        size={64}
                                        weight={700}
                                        lineHeight={76}
                                        color="white"
                                    />
                                </Reveal>
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon />
                                            자사몰/앱을 운영하고 있는 기업 담당자
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon />
                                            온드 미디어 컨텐츠를 커머스에 활용하고 싶은 마케터
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon />
                                            D2C 전략을 고민하고 있는 담당자
                                        </li>
                                        <li style={{ opacity: 0 }}>
                                            <CheckIcon />
                                            미디어 커머스의 전망이 궁금한 누구나
                                        </li>
                                    </Reveal>
                                </ul>
                            </div>
                        ) : (
                            <div className="contents-box">
                                {!isMo && (
                                    <>
                                        <Typography
                                            label="이런 분들께"
                                            size={isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isTa ? 57 : 38}
                                            mb={10}
                                            color="white"
                                        />
                                        <Typography
                                            label="웨비나를 추천합니다."
                                            size={isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isTa ? 57 : 38}
                                            color="white"
                                        />
                                    </>
                                )}
                                <ul>
                                    <li>
                                        <CheckIcon />
                                        자사몰/앱을 운영하고 있는 기업 담당자
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        온드 미디어 컨텐츠를 커머스에 활용하고 싶은 마케터
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        D2C 전략을 고민하고 있는 담당자
                                    </li>
                                    <li>
                                        <CheckIcon />
                                        미디어 커머스의 전망이 궁금한 누구나
                                    </li>
                                </ul>
                            </div>
                        )}
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection
                bg="#FFFFFF"
                pt={isPc ? 200 : isTa ? 120 : 64}
                pb={isPc ? 150 : isTa ? 80 : 100}
            >
                <HomeSectionInner>
                    <WebinarSectionWrap className="bottom vol2">
                        <PC>
                            <Reveal
                                className="reveal"
                                keyframes={fadeUp}
                                duration={600}
                                delay={100}
                                cascade
                                damping={0.25}
                            >
                                <Typography
                                    label="콘텐츠의 가치를"
                                    size={64}
                                    weight={700}
                                    lineHeight={76}
                                    mb={14}
                                />
                                <Typography
                                    label="구매로 바로 연결시키세요."
                                    size={64}
                                    weight={700}
                                    lineHeight={76}
                                    mb={14}
                                />
                            </Reveal>
                        </PC>
                        <Tablet>
                            <Typography
                                label="콘텐츠의 가치를"
                                size={48}
                                weight={700}
                                lineHeight={57}
                                mb={10}
                            />
                            <Typography
                                label="구매로 바로 연결시키세요."
                                size={48}
                                weight={700}
                                lineHeight={57}
                                mb={10}
                            />
                        </Tablet>
                        <Mobile>
                            <Typography
                                label="콘텐츠의 가치를"
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                            <Typography
                                label="구매로 바로 연결시키세요."
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            />
                            {/* <Typography
                                label="증가할 수밖에 없습니다."
                                size={32}
                                weight={700}
                                lineHeight={38}
                                mb={10}
                            /> */}
                        </Mobile>
                        <div className="contents-box">
                            {isPc ? (
                                <article>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeIn}
                                        duration={600}
                                        delay={200}
                                    >
                                        <Typography
                                            label="그동안 마케팅과 커머스는 분리되어 진행되었습니다. SNS 콘텐츠는 구매 전환을 일으키는데 보조적인 수단으로 여겨졌죠.  하지만 콘텐츠에서 바로 구매로 연결될 수 있는 쇼퍼블 콘텐츠(Shoppable contents)가 떠오르면서 커머스의 지형이 바뀌고 있습니다."
                                            size={22}
                                            lineHeight={35}
                                            mb={8}
                                        />
                                        <Typography
                                            label="콘텐츠로부터 시작되는 커머스. 기존보다 유저 저니는 짧아지고, 인게이지먼트는 향상됩니다. 고객의 인게이지먼트가 올라가면 매출은 자연스럽게 오를 수 밖에 없습니다."
                                            size={22}
                                            lineHeight={35}
                                            mb={8}
                                        />
                                        <Typography
                                            label="본 웨비나에서 쇼퍼블 콘텐츠를 주목해야 하는 이유와 기업별 활용 사례를 소개해드리겠습니다."
                                            size={22}
                                            lineHeight={35}
                                        />
                                    </Reveal>
                                </article>
                            ) : (
                                <article>
                                    <Typography
                                        label="그동안 마케팅과 커머스는 분리되어 진행되었습니다. SNS 콘텐츠는 구매 전환을 일으키는데 보조적인 수단으로 여겨졌죠.  하지만 콘텐츠에서 바로 구매로 연결될 수 있는 쇼퍼블 콘텐츠(Shoppable contents)가 떠오르면서 커머스의 지형이 바뀌고 있습니다."
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                        mb={isTa ? 24 : 16}
                                    />
                                    <Typography
                                        label="콘텐츠로부터 시작되는 커머스. 기존보다 유저 저니는 짧아지고, 인게이지먼트는 향상됩니다. 고객의 인게이지먼트가 올라가면 매출은 자연스럽게 오를 수 밖에 없습니다."
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                        mb={isTa ? 24 : 16}
                                    />
                                    <Typography
                                        label="본 웨비나에서 쇼퍼블 콘텐츠를 주목해야 하는 이유와 기업별 활용 사례를 소개해드리겠습니다."
                                        size={isTa ? 20 : 14}
                                        lineHeight={isTa ? 32 : 22}
                                    />
                                </article>
                            )}
                            <div className="section-img">
                                <img src="/assets/images/webinar/webinar_230921.png" alt="" />
                            </div>
                        </div>
                    </WebinarSectionWrap>
                </HomeSectionInner>
            </HomeSection>
        </HomeWrap>
    );
};

export default Webinar;
