import { useState, useEffect } from "react";
import { HomeWrap, modalStyles } from "./Home.style";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import IntroSection from "../../common/component/home/IntroSection";
import LogoSlideSection from "../../common/component/home/LogoSlideSection";
import DataSection from "../../common/component/home/DataSection";
import NeedSection from "../../common/component/home/NeedSection";
import WhySection from "../../common/component/home/WhySection";
import FeatureSection from "../../common/component/home/FeatureSection";
import ServiceSection from "../../common/component/home/ServiceSection";
import ProcessSection from "../../common/component/home/ProcessSection";
import PartnerSection from "../../common/component/home/PartnerSection";
import ReviewSection from "../../common/component/home/ReviewSection";
import ContentsSection from "../../common/component/home/ContentsSection";
import FinishSection from "../../common/component/home/FinishSection";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import ContactUsModal from "../../common/component/ContactUsModal";

import NewIntroSection from "../../common/component/home/kr/IntroSection";
import NewPartnerSection from "../../common/component/home/kr/PartnerSection";
import NewDataSection from "../../common/component/home/kr/DataSection";
import NewLiveSection from "../../common/component/home/kr/LiveSection";
import NewFeatureSection from "../../common/component/home/kr/FeatureSection";
import NewServiceSection from "../../common/component/home/kr/ServiceSection";
import NewShortsSection from "../../common/component/home/kr/ShortsSection";
import NewQuickSection from "../../common/component/home/kr/QuickSection";
import NewShortIntroSection from "../../common/component/home/kr/ShortsIntroSection";
import NewWidgetSection from "../../common/component/home/kr/WidgetSection";
import NewReviewSection from "../../common/component/home/kr/ReviewSection";
import NewFinishSection from "../../common/component/home/kr/FinishSection";

interface HomeProps {
  language: string;
}

const Home = (props: HomeProps) => {
  const { language } = props;
  const { t } = useTranslation("main");
  const [topScrollButton, setTopScrollButton] = useState(false);
  const isMo = useMediaQuery({ query: "(max-width:599px)" });
  const [isOpenContentUsModal, setIsOpenContentUsModal] = useState(false);
  const navigate = useNavigate();

  function openContentUsModal() {
    setIsOpenContentUsModal(true);
  }

  function closeContentUsModal() {
    setIsOpenContentUsModal(false);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 1000) {
        setTopScrollButton(true);
      } else {
        setTopScrollButton(false);
      }
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <HomeWrap>
      {topScrollButton && (
        <button className="scroll-top-button" onClick={scrollToTop}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M19.9375 33.3602C19.4529 33.3602 19.0469 33.196 18.7196 32.8676C18.3912 32.5403 18.227 32.1343 18.227 31.6497V12.5345L9.88816 20.8734C9.54605 21.2155 9.13952 21.3865 8.66855 21.3865C8.19873 21.3865 7.79276 21.2155 7.45066 20.8734C7.10855 20.5312 6.9375 20.1321 6.9375 19.676C6.9375 19.2198 7.10855 18.8207 7.45066 18.4786L18.7401 7.18914C18.9112 7.01809 19.0965 6.89664 19.2961 6.8248C19.4956 6.7541 19.7094 6.71875 19.9375 6.71875C20.1656 6.71875 20.3868 6.7541 20.6012 6.8248C20.8144 6.89664 20.9923 7.01809 21.1349 7.18914L32.4243 18.4786C32.7664 18.8207 32.9375 19.2198 32.9375 19.676C32.9375 20.1321 32.7664 20.5312 32.4243 20.8734C32.0822 21.2155 31.6757 21.3865 31.2047 21.3865C30.7349 21.3865 30.3289 21.2155 29.9868 20.8734L21.648 12.5345V31.6497C21.648 32.1343 21.4844 32.5403 21.1571 32.8676C20.8287 33.196 20.4221 33.3602 19.9375 33.3602Z"
              fill="#3C3C3C"
            />
          </svg>
        </button>
      )}
      {language === "en" ? (
        <>
          <IntroSection
            language={language}
            onClick={() => (isMo ? navigate("/inquiry") : openContentUsModal())}
          />
          <LogoSlideSection />
          <DataSection language={language} />
          <NeedSection language={language} />
          <WhySection language={language} />
          <FeatureSection language={language} />
          <ServiceSection />
          <ProcessSection />
          <PartnerSection />
          <ReviewSection />
          <ContentsSection language={language} />
          <FinishSection
            language={language}
            onClick={() => (isMo ? navigate("/inquiry") : openContentUsModal())}
          />
        </>
      ) : (
        <>
          <NewIntroSection onClick={() => (isMo ? navigate("/inquiry") : openContentUsModal())} />
          <NewPartnerSection />
          <NewDataSection />
          <NewLiveSection />
          <NewFeatureSection />
          <NewServiceSection />
          <NewShortsSection />
          <NewQuickSection />
          <NewShortIntroSection />
          <NewWidgetSection />
          <NewReviewSection />
          <NewFinishSection onClick={() => (isMo ? navigate("/inquiry") : openContentUsModal())} />
        </>
      )}
      {/** @deprecated */}
      {/* {isMo &&
                <Button className='mobile-fixed' label={t("intro.btn")} onClick={() => navigate('/inquiry')} />
            } */}
      <ReactModal
        isOpen={isOpenContentUsModal}
        onRequestClose={closeContentUsModal}
        contentLabel="ContactUs Modal"
        style={modalStyles}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
      >
        <ContactUsModal language={language} onClose={closeContentUsModal} />
      </ReactModal>
    </HomeWrap>
  );
};

export default Home;
