import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { GripTheme } from "./GripTheme";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, useLocation } from "react-router-dom";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);

if (container.hasChildNodes()) {
    ReactDOM.hydrateRoot(
        container,
        <React.StrictMode>
            <BrowserRouter>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={GripTheme}>
                        <HelmetProvider>
                            <ScrollToTop />
                            <HubspotProvider>
                                <App />
                            </HubspotProvider>
                        </HelmetProvider>
                    </ThemeProvider>
                </I18nextProvider>
            </BrowserRouter>
        </React.StrictMode>
    );
} else {
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={GripTheme}>
                        <HelmetProvider>
                            <ScrollToTop />
                            <HubspotProvider>
                                <App />
                            </HubspotProvider>
                        </HelmetProvider>
                    </ThemeProvider>
                </I18nextProvider>
            </BrowserRouter>
        </React.StrictMode>
    );
}
