import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Typography from "./Typography";
import { useTranslation } from "react-i18next";
import InquiryForm from "./InquiryForm";

const InquiryPropsWrap = styled.div<{ hasTitle?: boolean }>`
    width: 660px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    header {
        padding: 56px 0 24px;
        > div {
            display: flex;
            align-items: center;
            gap: 0 10px;
            text-decoration: none;
        }
    }
    section {
        padding: 0 40px 100px;
    }
    @media (min-width: 600px) and (max-width: 1199px) {
        width: 580px;
        header {
            padding: 50px 0 44px;
            a {
                gap: 0 8px;
            }
        }
    }
    @media (max-width: 599px) {
        width: 100%;
        header {
            padding: ${(props) => (props.hasTitle ? "40px 20px 0px" : "40px 20px 28px")};
            a {
                gap: 0 8px;
            }
        }
        section {
            padding: 0 20px 100px;
        }
    }
`;

interface InquiryProps {
    language: string;
}

const Inquiry = (props: InquiryProps) => {
    const { language } = props;
    const { t } = useTranslation("main");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const formId = searchParams?.get("formId");

    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });

    return (
        <InquiryPropsWrap hasTitle={!!formId}>
            <header>
                <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.9063 26.375L8.375 15.875C8.25 15.75 8.16167 15.6146 8.11 15.4688C8.0575 15.3229 8.03125 15.1667 8.03125 15C8.03125 14.8333 8.0575 14.6771 8.11 14.5312C8.16167 14.3854 8.25 14.25 8.375 14.125L18.9063 3.59375C19.1979 3.30208 19.5625 3.15625 20 3.15625C20.4375 3.15625 20.8125 3.3125 21.125 3.625C21.4375 3.9375 21.5938 4.30208 21.5938 4.71875C21.5938 5.13542 21.4375 5.5 21.125 5.8125L11.9375 15L21.125 24.1875C21.4167 24.4792 21.5625 24.8383 21.5625 25.265C21.5625 25.6925 21.4063 26.0625 21.0938 26.375C20.7813 26.6875 20.4167 26.8438 20 26.8438C19.5833 26.8438 19.2188 26.6875 18.9063 26.375Z"
                            fill="black"
                        />
                    </svg>
                    {!formId && (
                        <Typography
                            label={t("inquire")}
                            size={isPc ? 32 : isTa ? 28 : 24}
                            weight={700}
                        />
                    )}
                </div>
            </header>
            <section>
                <InquiryForm language={language} formId={formId} />
            </section>
        </InquiryPropsWrap>
    );
};

export default Inquiry;
