import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useMatch } from "react-router-dom";

interface FormProps {
    language: string;
    formId?: string;
}

const InquiryForm = (props: FormProps) => {
    const { language, formId } = props;

    const KO = "b784b8f5-6ea7-4ff8-8a90-744e002bd1bc";
    const EN = "d845d87b-46f7-441e-ac7e-bc20a82211d8";
    const JP = "92b3e1da-927c-4cec-86ed-2d9d57c1fdbd";
    const KrEbook = "4ec4401d-2e3b-4153-bd94-a767b69f6e31";
    const KrWebinar = "626bed0c-bd4e-477f-a6bd-6af8c2ce540f";
    const JpEbook = "4d1e3bf3-37f9-47a9-85c8-b5370514583b";
    const JpWebinar = "3a05a8ee-ca3b-4c28-9fb1-a3757d55bdff";

    const matchKrEbook = useMatch("/kr/contents/ebook/*");
    const matchKrWebinar = useMatch("/kr/contents/webinar/*");
    const matchJpEbook = useMatch("/jp/contents/ebook/*");
    const matchJpWebinar = useMatch("/jp/contents/webinar/*");

    const navigate = useNavigate();

    const thanks = async () => {
        const toastId = toast.success(
            language === "ko" ? (
                matchKrEbook || matchKrWebinar ? (
                    <>
                        감사합니다.
                        <br />
                        자료는 제출하신 이메일로 전달하였습니다.
                        <br />
                        잠시 후 그립클라우드 홈페이지로 이동합니다.
                    </>
                ) : (
                    "감사합니다."
                )
            ) : language === "ja" ? (
                matchJpEbook || matchJpWebinar ? (
                    <>
                        ありがとうございます。
                        <br />
                        資料をメールにてお送りいたします。
                    </>
                ) : (
                    "ありがとうございます！"
                )
            ) : (
                "Thank you!"
            ),
            {
                duration: 3000,
                position: "top-center",
                iconTheme: {
                    primary: "#568DFD",
                    secondary: "#fff",
                },
            }
        );

        setTimeout(async () => {
            await toast.dismiss(toastId);
            if (matchKrEbook || matchKrWebinar) {
                navigate("/kr");
            }
            if (matchJpEbook || matchJpWebinar) {
                navigate("/jp");
            }
        }, 3000);
    };

    useHubspotForm({
        portalId: "24273916",
        formId:
            formId ||
            (language === "ko"
                ? matchKrEbook
                    ? KrEbook
                    : matchKrWebinar
                    ? KrWebinar
                    : KO
                : language === "ja"
                ? matchJpEbook
                    ? JpEbook
                    : matchJpWebinar
                    ? JpWebinar
                    : JP
                : EN),
        target: "#inquiryForm",
        onFormSubmitted: thanks,
    });

    // useHubspotForm({
    //     portalId: "24273916",
    //     formId:
    //         language === "ko"
    //             ? matchKrEbook
    //                 ? KrEbook
    //                 : matchKrWebinar
    //                 ? KrWebinar
    //                 : KO
    //             : language === "ja"
    //             ? matchJpEbook
    //                 ? JpEbook
    //                 : matchJpWebinar
    //                 ? JpWebinar
    //                 : JP
    //             : EN,
    //     target: "#inquiryForm",
    //     onFormSubmitted: thanks,
    // });

    return (
        <div>
            <div id="inquiryForm"></div>
        </div>
    );
};

export default InquiryForm;
