import React, { useEffect, useRef, useState } from "react";
import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { CaseCardContents } from "../home/Home.data";
import { CasePageNextArrow, CasePagePrevArrow, fadeUp } from "../home/Home.style";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import CaseCard from "../../common/component/home/kr/CaseCard";
import { CaseSlideWrap, CaseWrap } from "./Case.style";

const Case = () => {
  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const slideVideoRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<null[] | HTMLVideoElement[]>([]);
  const [caseState, setCaseState] = useState<number>(0);
  const [caseCurrentSlide, setCaseCurrentSlide] = useState<number>(0);

  useEffect(() => {
    const playSync = async (ref: HTMLVideoElement) => {
      try {
        await ref.play();
      } catch (error) {
        console.warn("failure by browser policy", error);

        try {
          await ref.play();
        } catch (err) {
          console.error(
            "[Case] Can't autoplay \nplease check and off row power mode your cell phone and some",
            err
          );
        }
      }
    };
    if (slideVideoRef.current instanceof HTMLVideoElement) {
      if (!caseState && slideVideoRef.current && !slideVideoRef.current.paused) {
        slideVideoRef.current.pause();
        slideVideoRef.current.currentTime = 0;
      } else if (caseState && slideVideoRef.current && slideVideoRef.current.paused) {
        playSync(slideVideoRef.current);
      }
    }
  }, [caseState]);

  const caseSettings = {
    dots: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    infinite: false,
    nextArrow: <CasePageNextArrow />,
    prevArrow: <CasePagePrevArrow />,
    beforeChange: (current: any, next: number) => {
      setCaseCurrentSlide(next);
    },
    afterChange: (newIndex: number) => {
      setCaseState(newIndex);
    },
    appendDots: (dots: any) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (index: any) => (
      <button
        style={{
          backgroundColor: caseCurrentSlide === index ? "#568DFD" : undefined,
          width: caseCurrentSlide === index ? 40 : 8,
        }}
      />
    ),
  };

  return (
    <CaseWrap>
      <Reveal className="reveal" keyframes={fadeUp} duration={600} delay={100}>
        <Typography
          label="고객사 사례"
          size={isPc ? 64 : isTa ? 48 : 30}
          weight={700}
          mt={isMo ? 24 : 70}
          style={{ textAlign: "center" }}
        />
      </Reveal>
      {isPc ? (
        <>
          <section>
            <Reveal
              duration={0}
              onVisibilityChange={async (inView: boolean, entry: IntersectionObserverEntry) => {
                if (inView && entry.isIntersecting) {
                  try {
                    videoRef.current[0] instanceof HTMLVideoElement &&
                      (await videoRef.current[0].play());
                    videoRef.current[1] instanceof HTMLVideoElement &&
                      (await videoRef.current[1].play());
                    videoRef.current[2] instanceof HTMLVideoElement &&
                      (await videoRef.current[2].play());
                  } catch (error) {
                    console.warn("failure by browser policy", error);

                    try {
                      videoRef.current[0] instanceof HTMLVideoElement &&
                        (await videoRef.current[0].play());
                      videoRef.current[1] instanceof HTMLVideoElement &&
                        (await videoRef.current[1].play());
                      videoRef.current[2] instanceof HTMLVideoElement &&
                        (await videoRef.current[2].play());
                    } catch (err) {
                      console.error(
                        "[Case] Can't autoplay \nplease check and off row power mode your cell phone and some",
                        err
                      );
                    }
                  }
                } else {
                  videoRef.current[0]!.pause();
                  videoRef.current[0]!.currentTime = 0;
                  videoRef.current[1]!.pause();
                  videoRef.current[1]!.currentTime = 0;
                  videoRef.current[2]!.pause();
                  videoRef.current[2]!.currentTime = 0;
                }
              }}
            >
              <div className="figure-box">
                {CaseCardContents.slice(0, 3).map((item: any, index: number) => {
                  return (
                    <figure key={index}>
                      <div className="case-video">
                        <video
                          ref={(element) => {
                            videoRef.current[index] = element;
                          }}
                          loop
                          muted
                          playsInline
                          preload="auto"
                        >
                          <source src={item.videoSrc} type="video/mp4" />
                        </video>
                      </div>
                      <div className="case-desc">
                        <Typography
                          label={item.title}
                          size={isPc ? 20 : isTa ? 16 : 14}
                          weight={700}
                          mb={12}
                        />
                        <Typography
                          label={item.desc}
                          size={isPc ? 16 : isTa ? 16 : 14}
                          lineHeight={26}
                          mb={16}
                        />
                        <img src={item.logoSrc} alt={item.title} />
                      </div>
                    </figure>
                  );
                })}
              </div>
            </Reveal>
          </section>
          <section>
            <Reveal
              duration={0}
              onVisibilityChange={async (inView: boolean, entry: IntersectionObserverEntry) => {
                if (inView && entry.isIntersecting) {
                  try {
                    videoRef.current[3] instanceof HTMLVideoElement &&
                      (await videoRef.current[3].play());
                    videoRef.current[4] instanceof HTMLVideoElement &&
                      (await videoRef.current[4].play());
                    videoRef.current[5] instanceof HTMLVideoElement &&
                      (await videoRef.current[5].play());
                  } catch (error) {
                    console.warn("failure by browser policy", error);

                    try {
                      videoRef.current[3] instanceof HTMLVideoElement &&
                        (await videoRef.current[3].play());
                      videoRef.current[4] instanceof HTMLVideoElement &&
                        (await videoRef.current[4].play());
                      videoRef.current[5] instanceof HTMLVideoElement &&
                        (await videoRef.current[5].play());
                    } catch (err) {
                      console.error(
                        "[Case] Can't autoplay \nplease check and off row power mode your cell phone and some",
                        err
                      );
                    }
                  }
                } else {
                  videoRef.current[3]!.pause();
                  videoRef.current[3]!.currentTime = 0;
                  videoRef.current[4]!.pause();
                  videoRef.current[4]!.currentTime = 0;
                  videoRef.current[5]!.pause();
                  videoRef.current[5]!.currentTime = 0;
                }
              }}
            >
              <div className="figure-box">
                {CaseCardContents.slice(3, 6).map((item: any, index: number) => {
                  const i = index + 3;
                  return (
                    <figure key={index}>
                      <div className="case-video">
                        <video
                          ref={(element) => {
                            videoRef.current[i] = element;
                          }}
                          loop
                          muted
                          playsInline
                          preload="auto"
                        >
                          <source src={item.videoSrc} type="video/mp4" />
                        </video>
                      </div>
                      <div className="case-desc">
                        <Typography
                          label={item.title}
                          size={isPc ? 20 : isTa ? 16 : 14}
                          weight={700}
                          mb={12}
                        />
                        <Typography
                          label={item.desc}
                          size={isPc ? 16 : isTa ? 16 : 14}
                          lineHeight={26}
                          mb={16}
                        />
                        <img src={item.logoSrc} alt={item.title} />
                      </div>
                    </figure>
                  );
                })}
              </div>
            </Reveal>
          </section>
          <section>
            <Reveal
              duration={0}
              onVisibilityChange={async (inView: boolean, entry: IntersectionObserverEntry) => {
                if (inView && entry.isIntersecting) {
                  try {
                    videoRef.current[6] instanceof HTMLVideoElement &&
                      (await videoRef.current[6].play());
                    videoRef.current[7] instanceof HTMLVideoElement &&
                      (await videoRef.current[7].play());
                    videoRef.current[8] instanceof HTMLVideoElement &&
                      (await videoRef.current[8].play());
                  } catch (error) {
                    console.warn("failure by browser policy", error);

                    try {
                      videoRef.current[6] instanceof HTMLVideoElement &&
                        (await videoRef.current[6].play());
                      videoRef.current[7] instanceof HTMLVideoElement &&
                        (await videoRef.current[7].play());
                      videoRef.current[8] instanceof HTMLVideoElement &&
                        (await videoRef.current[8].play());
                    } catch (err) {
                      console.error(
                        "[Case] Can't autoplay \nplease check and off row power mode your cell phone and some",
                        err
                      );
                    }
                  }
                } else {
                  videoRef.current[6]!.pause();
                  videoRef.current[6]!.currentTime = 0;
                  videoRef.current[7]!.pause();
                  videoRef.current[7]!.currentTime = 0;
                  videoRef.current[8]!.pause();
                  videoRef.current[8]!.currentTime = 0;
                }
              }}
            >
              <div className="figure-box">
                {CaseCardContents.slice(6).map((item: any, index: number) => {
                  const i = index + 6;
                  return (
                    <figure key={index}>
                      <div className="case-video">
                        <video
                          ref={(element) => {
                            videoRef.current[i] = element;
                          }}
                          loop
                          muted
                          playsInline
                          preload="auto"
                        >
                          <source src={item.videoSrc} type="video/mp4" />
                        </video>
                      </div>
                      <div className="case-desc">
                        <Typography
                          label={item.title}
                          size={isPc ? 20 : isTa ? 18 : 14}
                          weight={700}
                          mb={12}
                        />
                        <Typography
                          label={item.desc}
                          size={isPc ? 16 : isTa ? 14 : 12}
                          lineHeight={isPc ? 26 : isTa ? 22 : 18}
                          mb={16}
                        />
                        <img src={item.logoSrc} alt={item.title} />
                      </div>
                    </figure>
                  );
                })}
              </div>
            </Reveal>
          </section>
        </>
      ) : (
        <section>
          <CaseSlideWrap>
            <Slider {...caseSettings}>
              {CaseCardContents.map((item: any, index: number) => {
                return <CaseCard key={index} item={item} playable={index === caseState} />;
              })}
            </Slider>
          </CaseSlideWrap>
        </section>
      )}
    </CaseWrap>
  );
};

export default Case;
