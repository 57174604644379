import React from "react"
import { useMediaQuery } from "react-responsive"

interface MediaQueryProps {
    children: React.ReactNode
}

const Mobile = (props: MediaQueryProps) => {
    const isMobile = useMediaQuery({
        query: "(max-width:599px)"
    })
    return <React.Fragment>{isMobile && props.children}</React.Fragment>
}

const Tablet = (props: MediaQueryProps) => {
    const isTablet = useMediaQuery({
        query: "(min-width:600px) and (max-width:1199px)"
    })
    return <React.Fragment>{isTablet && props.children}</React.Fragment>
}

const PC = (props: MediaQueryProps) => {
    const isPc = useMediaQuery({
        query: "(min-width:1200px)"
    })
    return <React.Fragment>{isPc && props.children}</React.Fragment>
}

export { Mobile, Tablet, PC }