import { useMediaQuery } from 'react-responsive'
import Typography from '../../common/component/Typography'
import { FaqWrap } from './Faq.style'
import { FaqData } from './faq.data'
import { Accordion, AccordionItem } from '@szhsin/react-accordion'


const Faq = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" })
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" })
    const isMo = useMediaQuery({ query: "(max-width:599px)" })

    return (
        <FaqWrap>
            <Typography label='자주 묻는 질문' size={isPc ? 64 : isTa ? 48 : 30} weight={700} mt={isMo ? 24 : 70} />
            <section>
                <Accordion>
                    {FaqData.map((item, index) => (
                        <AccordionItem
                            key={index}
                            header={
                                <>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className='q'>
                                        <circle cx="20" cy="20" r="20" fill="#6198FD" />
                                        <path d="M18.7031 22.3203L20.918 25.2148C20.6133 25.2852 20.2852 25.3203 19.9453 25.3203C17.3086 25.3086 15.5859 23.3398 15.5859 19.7188C15.5859 16.0859 17.3086 14.1289 19.9453 14.1172C22.5586 14.1289 24.293 16.0859 24.2813 19.7188C24.293 21.5938 23.8242 23.0352 23.0156 23.9727L21.7266 22.3203H18.7031ZM12 19.7188C12 25.2383 15.4219 28.4375 19.9453 28.4375C21 28.4375 22.0078 28.2617 22.9219 27.9219L24.2813 29.6094H27.5859L25.1719 26.5625C26.8359 25.0742 27.8672 22.7539 27.8672 19.7188C27.8672 14.1758 24.4336 11 19.9453 11C15.4219 11 12 14.1758 12 19.7188Z" fill="white" />
                                    </svg>
                                    <span>{item.title}</span>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" className='icon'>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.7563 16.2652L13.7563 26.2654C13.762 26.5931 13.8962 26.9055 14.1299 27.1352C14.3637 27.365 14.6783 27.4937 15.0061 27.4937C15.3339 27.4937 15.6485 27.365 15.8823 27.1352C16.116 26.9055 16.2502 26.5931 16.2559 26.2654V16.2652L26.2562 16.2652C26.5839 16.2595 26.8962 16.1253 27.126 15.8915C27.3557 15.6578 27.4844 15.3431 27.4844 15.0154C27.4844 14.6876 27.3557 14.373 27.126 14.1392C26.8962 13.9054 26.5839 13.7712 26.2562 13.7656H16.2559L16.2559 3.76529C16.2455 3.44082 16.1092 3.13312 15.876 2.90732C15.6427 2.68152 15.3308 2.55532 15.0061 2.55544C14.6815 2.55555 14.3696 2.68197 14.1365 2.90794C13.9034 3.1339 13.7674 3.44169 13.7572 3.76618L13.7563 13.7656L3.75603 13.7656C3.59008 13.7627 3.42521 13.7929 3.27105 13.8544C3.11688 13.9159 2.97652 14.0075 2.85814 14.1238C2.73975 14.2402 2.64573 14.3789 2.58155 14.532C2.51736 14.6851 2.48431 14.8494 2.48431 15.0154C2.48431 15.1813 2.51736 15.3457 2.58155 15.4987C2.64573 15.6518 2.73976 15.7906 2.85814 15.9069C2.97652 16.0232 3.11689 16.1148 3.27105 16.1763C3.42521 16.2379 3.59007 16.2681 3.75603 16.2652L13.7563 16.2652Z" fill="#343434" stroke="#343434" strokeWidth="0.5" />
                                    </svg>
                                </>
                            }
                        >
                            {item.content}
                        </AccordionItem>
                    ))}
                </Accordion>
            </section>

        </FaqWrap>
    )
}

export default Faq