import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeIn,
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import Typography from "../../InnerTypography";
import { useState } from "react";
import { shortsIntroMenuItems } from "../../../../pages/home/Home.data";
import { useTranslation } from "react-i18next";

const ShortsIntroSectionWrap = styled.div<{ language: string }>`
  > article {
    text-align: center;
    margin-bottom: 5.63rem;

    ${media.ta} {
      margin-bottom: 3.13rem;
    }
    ${media.mo} {
      margin-bottom: 2.5rem;
    }
  }
  > div {
    display: grid;
    grid-template-columns: 1.4fr minmax(auto, 29.375rem);
    gap: 1.87rem;

    max-width: 75rem;

    margin: 0 auto;

    ${media.belowTa} {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
    ${media.mo} {
      grid-template-columns: 1fr;
      padding: 0 20px;
    }
    .contents {
      height: 100%;
      background: #f7f8fa;
      border-radius: 1.25rem;

      padding: 3.125rem 3.75rem;

      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;

      ${media.ta} {
        padding: 2rem;
        flex-flow: column nowrap;
      }

      ${media.mo} {
        padding: 1.5rem;
        flex-flow: column nowrap;
      }

      > div:first-of-type {
        text-align: center;

        img {
          width: 15rem;
          height: ${({ language }) => (language === "ko" ? "33.125rem" : "29.3125rem")};
          object-fit: cover;

          ${media.ta} {
            width: 17.75rem;
            height: 38.75rem;
          }
          ${media.mo} {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      > div.description {
        flex: 1;
        display: inline-flex;
        flex-flow: column nowrap;
        justify-content: center;

        ${media.ta} {
          max-width: 17.75rem;
          margin: 0 auto;
        }
      }
    }
    .list {
      ${media.mo} {
        order: -1;
      }
      ul,
      li {
        list-style: none;
      }
      ul {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;

        ${media.mo} {
          flex-flow: row wrap;
          gap: 0.5rem;
        }
        li {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          gap: 1rem;
          padding: 1.88rem;
          border-radius: 1rem;

          ${media.ta} {
            padding: 1.5rem 1.88rem;
          }
          ${media.mo} {
            border-radius: 0.625rem;
            padding: 0.75rem;
            gap: 0.63rem;
            flex: 45%;
          }

          span {
            color: #222;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.8125rem;

            ${media.mo} {
              font-size: 0.875rem;
              font-weight: 700;
              line-height: 1.35938rem;
            }
          }

          &.active {
            background: #f7f8fa;
          }

          &:hover {
            background: #f7f8fa;
          }
        }
      }
    }
  }
`;

const ShortsIntroSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  const [active, setActive] = useState<number>(0);

  return (
    <HomeSection
      style={{ minHeight: "auto" }}
      pt={isPc ? 140 : isTa ? 120 : 100}
      pb={isMo ? 100 : 120}
    >
      <HomeSectionInner style={{ padding: 0 }}>
        <ShortsIntroSectionWrap language={language}>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography.Header3
                  fontWeight={700}
                  fontSize={"1.5rem"}
                  color="#003D99"
                  letterSpacing={"-0.015rem"}
                >
                  {language === "ko" ? "Shorts" : "ショート動画"}
                </Typography.Header3>
                <Typography.Header2
                  fontWeight={700}
                  fontSize={"3.125rem"}
                  color="#222"
                  letterSpacing={"-0.03125rem"}
                  marginBottom={"1rem"}
                >
                  {language === "ko" ? "무궁무진한 쇼츠 활용법" : "ショートの活用法は無限大"}
                </Typography.Header2>
                <Typography.Paragraph fontWeight={400} fontSize={"1.25rem"} color="#222">
                  {language === "ko"
                    ? "매력적인 콘텐츠는 구매로 이어지는 지름길이 됩니다."
                    : "魅力的なコンテンツは購入につながる近道です。"}
                </Typography.Paragraph>
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography.Header3
                fontWeight={700}
                fontSize={"1.25rem"}
                color="#003D99"
                letterSpacing={"-0.0125rem"}
              >
                {language === "ko" ? "Shorts" : "ショート動画"}
              </Typography.Header3>
              <Typography.Header2
                fontWeight={700}
                fontSize={"1.875rem"}
                color="#222"
                letterSpacing={"-0.01875rem"}
                marginBottom={"0.5rem"}
              >
                {language === "ko" ? "무궁무진한 쇼츠 활용법" : "ショートの活用法は無限大"}
              </Typography.Header2>
            </article>
          )}
          <div>
            {!isMo ? (
              <>
                <Reveal className="reveal" keyframes={fadeIn} duration={600} delay={200}>
                  <div className="contents">
                    <div>{shortsIntroMenuItems(language)[active].image}</div>
                    <div className="description">
                      <Typography.Header3
                        fontSize={"1.5rem"}
                        fontWeight={700}
                        lineHeight={"2.175rem"}
                        color="#222"
                      >
                        {shortsIntroMenuItems(language)[active].text}
                      </Typography.Header3>
                      <Typography.Header4
                        fontSize={"1.5rem"}
                        fontWeight={500}
                        lineHeight={"2.175rem"}
                        color="rgba(52, 52, 52, 0.7)"
                      >
                        {shortsIntroMenuItems(language)[active].description_1}
                      </Typography.Header4>
                      <Typography.Header4
                        fontSize={"1.5rem"}
                        fontWeight={500}
                        lineHeight={"2.175rem"}
                        color="rgba(52, 52, 52, 0.7)"
                      >
                        {shortsIntroMenuItems(language)[active].description_2}
                      </Typography.Header4>
                    </div>
                  </div>
                </Reveal>
                <div className="list">
                  <ul>
                    {shortsIntroMenuItems(language).map((item, index) => (
                      <Reveal
                        key={`shorts-intro-item-${index}`}
                        className="reveal"
                        keyframes={fadeIn}
                        duration={600}
                        delay={index * 200 + 200}
                      >
                        <li
                          {...(active === index && { className: "active" })}
                          onClick={() => setActive(index)}
                        >
                          {item.svg}
                          <span>{item.text}</span>
                        </li>
                      </Reveal>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="contents">
                  <div>{shortsIntroMenuItems(language)[active].image}</div>
                  <div className="description">
                    <Typography.Header3
                      fontSize={"1rem"}
                      fontWeight={700}
                      lineHeight={"1.45rem"}
                      color="#222"
                    >
                      {shortsIntroMenuItems(language)[active].text}
                    </Typography.Header3>
                    <Typography.Header4
                      fontSize={"1rem"}
                      fontWeight={500}
                      lineHeight={"1.45rem"}
                      color="rgba(52, 52, 52, 0.7)"
                    >
                      {shortsIntroMenuItems(language)[active].description_1}
                    </Typography.Header4>
                    <Typography.Header4
                      fontSize={"1rem"}
                      fontWeight={500}
                      lineHeight={"1.45rem"}
                      color="rgba(52, 52, 52, 0.7)"
                    >
                      {shortsIntroMenuItems(language)[active].description_2}
                    </Typography.Header4>
                  </div>
                </div>
                <div className="list">
                  <ul>
                    {shortsIntroMenuItems(language).map((item, index) => (
                      <li
                        {...(active === index && { className: "active" })}
                        key={`shorts-intro-item-${index}`}
                        onClick={() => setActive(index)}
                      >
                        {item.svg}
                        <span>{item.text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </ShortsIntroSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default ShortsIntroSection;
