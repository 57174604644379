import styled from "styled-components"

export const PolicyWrap = styled.div`
    h1 {
        font-size: 2em;
        margin-bottom: 24px;
    }
    h2{
        margin-top: 40px;
        margin-bottom: 24px;
    }
    ul{
        padding-left: 18px;
        li{
            list-style: disc;
            margin-bottom: 8px;
        }
    }
    ol{
        padding-left: 18px;
        li{
            list-style: decimal;
            margin-bottom: 8px;
        }
    }
    div {
        p {
            margin-bottom: 16px;
        }
        h4 {
            margin-top: 24px;
            margin-bottom: 16px;
        }
        div {
            margin-bottom: 16px;
            p {
                margin-bottom: 8px;
            }   
        }
        ol, ul{
            margin-bottom: 16px;
        }
    }

    @media (min-width:600px) and (max-width: 1199px) {
        
    }
    @media (max-width: 599px) {

    }
`