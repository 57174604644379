import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeIn,
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import Typography from "../../InnerTypography";
import { useTranslation } from "react-i18next";

const WidgetSectionWrap = styled.div`
  > article {
    text-align: center;
    margin-bottom: 5.63rem;

    ${media.ta} {
      margin-bottom: 3.13rem;
    }
    ${media.mo} {
      margin-bottom: 2.5rem;
    }
  }
  > div {
    width: 100%;
    text-align: center;

    img {
      max-width: 75rem;
      max-height: 39.125rem;

      ${media.belowTa} {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const WidgetSection = () => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection
      style={{ minHeight: "auto" }}
      bg="#F7F8FA"
      pt={isPc ? 140 : isTa ? 120 : 100}
      pb={isMo ? 100 : 120}
    >
      <HomeSectionInner style={{ padding: 0 }}>
        <WidgetSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography.Header3
                  fontWeight={700}
                  fontSize={"1.5rem"}
                  color="#003D99"
                  letterSpacing={"-0.015rem"}
                >
                  {language === "ko" ? "Page & Widget" : "ウィジェット & ページ"}
                </Typography.Header3>
                <Typography.Header2
                  fontWeight={700}
                  fontSize={"3.125rem"}
                  color="#222"
                  letterSpacing={"-0.03125rem"}
                  marginBottom={"1rem"}
                >
                  {language === "ko" ? "브랜드 페이지에 손쉬운 적용" : "特設ページも簡単に制作"}
                </Typography.Header2>
                <Typography.Paragraph fontWeight={400} fontSize={"1.25rem"} color="#222">
                  {language === "ko" ? (
                    isPc ? (
                      "라이브, 쇼츠 전용 페이지와 위젯을 활용하여 차별화된 미디어커머스 경험을 제공하세요."
                    ) : (
                      <>
                        라이브, 쇼츠 전용 페이지와 위젯을 활용하여
                        <br />
                        차별화된 미디어커머스 경험을 제공하세요.
                      </>
                    )
                  ) : (
                    "ウィジェット機能とページ機能を活用してオリジナルな特設ページも簡単に制作できます。"
                  )}
                </Typography.Paragraph>
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography.Header3
                fontWeight={700}
                fontSize={"1.25rem"}
                color="#003D99"
                letterSpacing={"-0.0125rem"}
              >
                {language === "ko" ? "Page & Widget" : "ウィジェット & ページ"}
              </Typography.Header3>
              <Typography.Header2
                fontWeight={700}
                fontSize={"1.875rem"}
                color="#222"
                letterSpacing={"-0.01875rem"}
                marginBottom={"0.5rem"}
              >
                {language === "ko" ? "브랜드 페이지에 손쉬운 적용" : "特設ページも簡単に制作"}
              </Typography.Header2>
              <Typography.Paragraph fontWeight={400} fontSize={"0.875rem"} color="#222">
                {language === "ko" ? (
                  <>
                    라이브, 쇼츠 전용 페이지와 위젯을 활용하여
                    <br />
                    차별화된 미디어커머스 경험을 제공하세요.
                  </>
                ) : (
                  <>
                    ウィジェット機能とページ機能を活用してオリジ
                    <br />
                    ナルな特設ページも簡単に制作できます。
                  </>
                )}
              </Typography.Paragraph>
            </article>
          )}
          <div>
            {!isMo ? (
              <Reveal className="reveal" keyframes={fadeIn} duration={600} delay={200}>
                <img
                  src={
                    language === "ko"
                      ? "/assets/images/widget_article.png"
                      : `/assets/images/widget_article_${language}.png`
                  }
                  alt="widget-article"
                />
              </Reveal>
            ) : (
              <img
                src={
                  language === "ko"
                    ? "/assets/images/widget_article.png"
                    : `/assets/images/widget_article_${language}.png`
                }
                alt="widget-article"
              />
            )}
          </div>
        </WidgetSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default WidgetSection;
