import Reveal from "react-awesome-reveal";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { HomeSectionInner, fadeUp, HomeSection } from "../../../pages/home/Home.style";
import Typography from "../Typography";
import CountUp from "react-countup";
import Divider from "../Divider";

const DataSectionWrap = styled.div`
  > article {
    text-align: center;
    margin-bottom: 140px;
  }
  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    figure {
      padding: 0;
    }
    p {
      span {
        font-family: "Pretendard";
        color: white;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    > article {
      margin-bottom: 100px;
    }
    > div {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 50px;
    }
  }

  @media (max-width: 599px) {
    > article {
      margin-bottom: 40px;
    }
    > div {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, auto);
      grid-row-gap: 31px;
    }
  }
`;
interface DataSectionProps {
  language: string;
}

const DataSection = (props: DataSectionProps) => {
  const { language } = props;
  const { t } = useTranslation("main");

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  return (
    <HomeSection bg="black" pt={isPc ? 250 : isTa ? 180 : 99} pb={isPc ? 300 : isTa ? 180 : 99}>
      <HomeSectionInner>
        <DataSectionWrap>
          {!isMo ? (
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography
                  label={
                    <Trans i18nKey={"main:data.title1"}>
                      data.title1
                      <br className="none-pc" />
                    </Trans>
                  }
                  size={isPc ? 80 : 64}
                  weight={700}
                  lineHeight={isPc ? 80 : 90}
                  mb={isPc ? 24 : 0}
                  color="white"
                />
                <Typography
                  label={t("data.title2")}
                  size={isPc ? 80 : 64}
                  weight={700}
                  lineHeight={isPc ? 80 : 90}
                  color="white"
                />
              </Reveal>
            </article>
          ) : (
            <article>
              <Typography
                label={
                  <Trans i18nKey={"main:data.title1"}>
                    data.title1
                    <br />
                  </Trans>
                }
                size={34}
                weight={700}
                lineHeight={40}
                color="white"
              />
              <Typography
                label={t("data.title2")}
                size={34}
                weight={700}
                lineHeight={40}
                color="white"
              />
            </article>
          )}
          <div>
            <figure>
              <Typography
                label={
                  <>
                    {!isMo ? (
                      <CountUp start={0.0} end={32} decimals={1} duration={1} enableScrollSpy>
                        {({ countUpRef }) => <span ref={countUpRef} />}
                      </CountUp>
                    ) : (
                      <span>32</span>
                    )}
                    <span>%</span>
                  </>
                }
                size={isPc ? 120 : isTa ? 80 : 60}
                weight={800}
                letterSpacing="-1px"
                mb={isMo ? 4 : 16}
                color="white"
              />
              <Typography
                label={t("data.traffic")}
                size={isPc ? 18 : isTa ? 16 : 14}
                color="#7A7A7A"
              />
            </figure>
            {isMo && <Divider />}
            <figure>
              <Typography
                label={
                  <>
                    {!isMo ? (
                      <CountUp start={0.0} end={10} duration={1} enableScrollSpy>
                        {({ countUpRef }) => <span ref={countUpRef} />}
                      </CountUp>
                    ) : (
                      <span>10</span>
                    )}
                    <span>X+</span>
                  </>
                }
                size={isPc ? 120 : isTa ? 80 : 60}
                weight={800}
                letterSpacing="-1px"
                mb={isMo ? 4 : 16}
                color="white"
              />
              <Typography
                label={t("data.roas")}
                size={isPc ? 18 : isTa ? 16 : 14}
                color="#7A7A7A"
              />
            </figure>
            {isMo && <Divider />}
            <figure>
              <Typography
                label={
                  <>
                    {language === "ja" ? (
                      !isMo ? (
                        <CountUp start={0} end={117} duration={1} enableScrollSpy>
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      ) : (
                        <span>117</span>
                      )
                    ) : !isMo ? (
                      <CountUp start={0.0} end={1.17} decimals={2} duration={1} enableScrollSpy>
                        {({ countUpRef }) => <span ref={countUpRef} />}
                      </CountUp>
                    ) : (
                      <span>1.17</span>
                    )}
                    <span>{t("data.number")}+</span>
                  </>
                }
                size={isPc ? 120 : isTa ? 80 : 60}
                weight={800}
                letterSpacing="-1px"
                mb={isMo ? 4 : 16}
                color="white"
              />
              <Typography
                label={t("data.time")}
                size={isPc ? 18 : isTa ? 16 : 14}
                color="#7A7A7A"
              />
            </figure>
          </div>
        </DataSectionWrap>
      </HomeSectionInner>
      <Typography label={t("data.comment")} size={12} lineHeight={16} color="#969696" />
    </HomeSection>
  );
};

export default DataSection;
