import { useState } from "react";
import { Reveal } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import Typography from "../../common/component/Typography";
import { HomeSection, HomeSectionInner, HomeWrap, fadeIn, fadeUp } from "../home/Home.style";
import { CheckIcon, EbookSectionWrap } from "./Ebook.style";
// import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import InquiryForm from "../../common/component/InquiryForm";
import Button from "../../common/component/Button";

const Ebook = () => {
    const isPc = useMediaQuery({ query: "(min-width:1200px)" });
    const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
    const isMo = useMediaQuery({ query: "(max-width:599px)" });

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <HomeWrap>
            <HomeSection
                bg="#EBDFFF"
                pt={isPc ? 110 : isTa ? 72 : 64}
                pb={isPc ? 140 : isTa ? 50 : 32}
            >
                <HomeSectionInner>
                    <EbookSectionWrap className="center">
                        {!isPc && (
                            <div>
                                <Typography
                                    label="이런 분들께"
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    mb={isPc ? 14 : isTa ? 10 : 8}
                                />
                                <Typography
                                    label="이 책을 추천합니다."
                                    size={isPc ? 64 : isTa ? 48 : 32}
                                    weight={700}
                                    lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                />
                            </div>
                        )}
                        <div className="section-img">
                            <img src="/assets/images/ebook/center.png" alt="" />
                        </div>
                        <div className="contents-box">
                            {isPc && (
                                <Reveal
                                    className="reveal"
                                    keyframes={fadeUp}
                                    duration={600}
                                    delay={100}
                                    cascade
                                    damping={0.25}
                                >
                                    <Typography
                                        label="이런 분들께"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="이 책을 추천합니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </Reveal>
                            )}
                            {isPc ? (
                                <ul>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            새로운 오픈마켓 서비스에 관심이 많은 사업자
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            오픈마켓에서 선보일 프로모션과 콘텐츠가 고민인 마케터
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            타채널에서 라이브를 진행해본 라이브 담당자
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            라이브 코너를 런칭하는 이유가 궁금한 분
                                        </li>
                                        <li>
                                            <CheckIcon fill={"#A17DEF"} />
                                            생생한 라이브커머스 운영 후기를 듣고 싶은 누구나
                                        </li>
                                    </Reveal>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        새로운 오픈마켓 서비스에 관심이 많은 사업자
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        오픈마켓에서 선보일 프로모션과 콘텐츠가 고민인 마케터
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        타채널에서 라이브를 진행해본 라이브 담당자
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        라이브 코너를 런칭하는 이유가 궁금한 분
                                    </li>
                                    <li>
                                        <CheckIcon fill={"#A17DEF"} />
                                        생생한 라이브커머스 운영 후기를 듣고 싶은 누구나
                                    </li>
                                </ul>
                            )}
                        </div>
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 130 : isTa ? 72 : 64} pb={isPc ? 130 : isTa ? 90 : 40}>
                <HomeSectionInner>
                    <EbookSectionWrap className="bottom">
                        <div className="contents-box">
                            {!isPc && (
                                <>
                                    <Typography
                                        label="이런 내용을"
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        mb={isPc ? 14 : isTa ? 10 : 8}
                                    />
                                    <Typography
                                        label="책에 담았습니다."
                                        size={isPc ? 64 : isTa ? 48 : 32}
                                        weight={700}
                                        lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                    />
                                </>
                            )}
                            {isPc && (
                                <>
                                    <Reveal
                                        className="reveal"
                                        keyframes={fadeUp}
                                        duration={600}
                                        delay={100}
                                        cascade
                                        damping={0.25}
                                    >
                                        <Typography
                                            label="이런 내용을"
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                            mb={isPc ? 14 : isTa ? 10 : 8}
                                        />
                                        <Typography
                                            label="책에 담았습니다."
                                            size={isPc ? 64 : isTa ? 48 : 32}
                                            weight={700}
                                            lineHeight={isPc ? 76 : isTa ? 57 : 38}
                                        />
                                    </Reveal>
                                    <article>
                                        <Reveal
                                            className="reveal"
                                            keyframes={fadeIn}
                                            duration={600}
                                            delay={200}
                                        >
                                            <Typography
                                                label="Qoo10(eBay Japan)은 자사몰에서 라이브커머스를 운영함으로써 대단한 성과를 이루었습니다. 한 방송에서는 1시간 동안 1억 엔 이상의 매출을 달성했고, 다른 방송에서는 5분 만에 1만 개의 상품을 판매하는 기록을 세웠습니다. Qoo10이 자체 라이브 코너를 만들게 된 이유는 무엇일까요?"
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="온라인 쇼핑을 하는 고객은 항상 편리함과 신뢰감 사이에서 갈등을 느끼곤 합니다. Qoo10은 이러한 고객 갈등을 해소하고자 라이브커머스라는 새로운 도전에 나섰습니다. 처음은 타채널 라이브로 시작했지만 얼마 지나지 않아 직접 라이브 코너를 런칭했죠. 이러한 변화는 고객과 새로운 관계를 형성시켰고 Qoo10에 대한 호감을 바탕으로 더 풍부한 쇼핑 경험을 만들어냈습니다."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                            <Typography
                                                label="본 아티클에선 어디에서도 말하지 않았던 Qoo10 라이브커머스의 현재와 종착점을 인터뷰로 만나볼 수 있습니다. Qoo10 콘텐츠팀이 밝힌 라이브커머스 도입기와 함께 라이브커머스를 운영하며 느낀 변화를 아티클로 확인해보세요."
                                                size={isPc ? 22 : isTa ? 16 : 14}
                                                lineHeight={isPc ? 33 : isTa ? 24 : 18}
                                                mb={isPc ? 24 : isTa ? 16 : 8}
                                            />
                                        </Reveal>
                                    </article>
                                </>
                            )}
                        </div>
                        <div className="section-img">
                            <img src="/assets/images/ebook/bottom.png" alt="" />
                        </div>
                        {!isPc && (
                            <article>
                                <Typography
                                    label="Qoo10(eBay Japan)은 자사몰에서 라이브커머스를 운영함으로써 대단한 성과를 이루었습니다. 한 방송에서는 1시간 동안 1억 엔 이상의 매출을 달성했고, 다른 방송에서는 5분 만에 1만 개의 상품을 판매하는 기록을 세웠습니다. Qoo10이 자체 라이브 코너를 만들게 된 이유는 무엇일까요?"
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                <Typography
                                    label="온라인 쇼핑을 하는 고객은 항상 편리함과 신뢰감 사이에서 갈등을 느끼곤 합니다. Qoo10은 이러한 고객 갈등을 해소하고자 라이브커머스라는 새로운 도전에 나섰습니다. 처음은 타채널 라이브로 시작했지만 얼마 지나지 않아 직접 라이브 코너를 런칭했죠. 이러한 변화는 고객과 새로운 관계를 형성시켰고 Qoo10에 대한 호감을 바탕으로 더 풍부한 쇼핑 경험을 만들어냈습니다."
                                    size={isPc ? 22 : isTa ? 20 : 14}
                                    lineHeight={isPc ? 33 : isTa ? 30 : 22}
                                    mb={!isMo ? 24 : 20}
                                />
                                {!isMo && (
                                    <Typography
                                        label="본 아티클에선 어디에서도 말하지 않았던 Qoo10 라이브커머스의 현재와 종착점을 인터뷰로 만나볼 수 있습니다. Qoo10 콘텐츠팀이 밝힌 라이브커머스 도입기와 함께 라이브커머스를 운영하며 느낀 변화를 아티클로 확인해보세요."
                                        size={isPc ? 22 : 20}
                                        lineHeight={isPc ? 33 : 30}
                                        mb={24}
                                    />
                                )}
                                {open && isMo && (
                                    <Typography
                                        label="본 아티클에선 어디에서도 말하지 않았던 Qoo10 라이브커머스의 현재와 종착점을 인터뷰로 만나볼 수 있습니다. Qoo10 콘텐츠팀이 밝힌 라이브커머스 도입기와 함께 라이브커머스를 운영하며 느낀 변화를 아티클로 확인해보세요."
                                        size={14}
                                        lineHeight={22}
                                        mb={20}
                                    />
                                )}
                                {isMo && (
                                    <Button
                                        className="outlined"
                                        label={
                                            <>
                                                {open ? "내용 간단히 보기" : "내용 펼쳐보기"}
                                                <svg
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                    style={{
                                                        transform: !open
                                                            ? "rotate(0)"
                                                            : "rotate(180deg)",
                                                    }}
                                                >
                                                    <path
                                                        d="M2.6665 5.8335L7.99984 11.1668L13.3332 5.8335"
                                                        stroke="#363636"
                                                        strokeWidth="1.77778"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </>
                                        }
                                        onClick={handleOpen}
                                    />
                                )}
                            </article>
                        )}
                    </EbookSectionWrap>
                </HomeSectionInner>
            </HomeSection>
            <HomeSection pt={isPc ? 60 : isTa ? 60 : 34} pb={isPc ? 1 : isTa ? 80 : 20}>
                <EbookSectionWrap className="top">
                    <div className="section-img">
                        <img src="/assets/images/ebook/top.jpg" alt="ebook" />
                    </div>
                    <div className="form-box">
                        <InquiryForm language="ko" />
                    </div>
                </EbookSectionWrap>
            </HomeSection>
        </HomeWrap>
    );
};

export default Ebook;
