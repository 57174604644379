export const NeedSlide = [
  {
    avatar: "/assets/images/avatar01.png",
    description: "need.card.desc1",
  },
  {
    avatar: "/assets/images/avatar02.png",
    description: "need.card.desc2",
  },
  {
    avatar: "/assets/images/avatar03.png",
    description: "need.card.desc3",
  },
  {
    avatar: "/assets/images/avatar04.png",
    description: "need.card.desc4",
  },
  {
    avatar: "/assets/images/avatar05.png",
    description: "need.card.desc5",
  },
];

export const WhyGrip = [
  {
    title: "why.grip",
    list: [
      { description: "why.grip.desc1" },
      { description: "why.grip.desc2" },
      { description: "why.grip.desc3" },
      { description: "why.grip.desc4" },
    ],
  },
];

export const WhyOther = [
  {
    title: "why.competitor",
    list: [
      { description: "why.competitor.desc1" },
      { description: "why.competitor.desc2" },
      { description: "why.competitor.desc3" },
      { description: "why.competitor.desc4" },
    ],
  },
];

export const FeatureSlide = [
  {
    title: "main:feature.card1.title",
    description: "main:feature.card1.desc",
  },
  {
    title: "main:feature.card2.title",
    description: "main:feature.card2.desc",
    comment: "main:feature.card2.comment",
  },
  {
    title: "main:feature.card3.title",
    description: "main:feature.card3.desc",
    comment: "main:feature.card3.comment",
  },
  {
    title: "main:feature.card4.title",
    description: "main:feature.card4.desc",
    comment: "main:feature.card4.comment",
  },
  {
    title: "main:feature.card5.title",
    description: "main:feature.card5.desc",
  },
];

export const ServiceCard = [
  {
    icon: "/assets/images/icon_game.png",
    title: "main:service.card1.title",
    description: "main:service.card1.desc",
  },
  {
    icon: "/assets/images/icon_login.png",
    title: "main:service.card2.title",
    description: "main:service.card2.desc",
  },
  {
    icon: "/assets/images/icon_custom.png",
    title: "main:service.card3.title",
    description: "main:service.card3.desc",
  },
  {
    icon: "/assets/images/icon_highlight.png",
    title: "main:service.card4.title",
    description: "main:service.card4.desc",
  },
  {
    icon: "/assets/images/icon_datadownload.png",
    title: "main:service.card5.title",
    description: "main:service.card5.desc",
  },
  {
    icon: "/assets/images/icon_singleurl.png",
    title: "main:service.card6.title",
    description: "main:service.card6.desc",
  },
];
export const NewServiceCard = (
  language: string
): { icon: string; title: string; description: string }[] => [
  {
    icon: "/assets/images/icon_game.png",
    title: language === "ko" ? "퀴즈 고도화" : "クイズ",
    description:
      language === "ko"
        ? "라이브 전 사전 퀴즈 등록,<br/>간편 참여 및 자동 종료 설정"
        : "事前クイズ登録が可能<br/>簡単参加・自動終了設定",
  },
  {
    icon: "/assets/images/icon_login.png",
    title: language === "ko" ? "비회원 로그인" : "非会員チャット",
    description:
      language === "ko"
        ? "회원가입 없이도 게스트로<br/>활발한 채팅 참여 유도"
        : "会員登録なしでも<br/>チャット可能",
  },
  {
    icon: "/assets/images/icon_custom.png",
    title: language === "ko" ? "커스텀 설정" : "カスタム設定",
    description:
      language === "ko"
        ? "브랜드 니즈에 맞게<br/>라이브 플레이어 UI 커스텀"
        : "ブランドに合わせて<br/>ライブプレイヤーのUIカスタム",
  },
  {
    icon: "/assets/images/icon_highlight.png",
    title: language === "ko" ? "하이라이트 설정" : "ハイライト",
    description:
      language === "ko"
        ? "라이브 종료 후 VOD의 핵심 구간을<br/>하이라이트 설정"
        : "ライブ終了後、VODで<br/>ハイライト区間を設定可能",
  },
  {
    icon: "/assets/images/icon_datadownload.png",
    title: language === "ko" ? "데이터 다운로드" : "データのダウンロード",
    description:
      language === "ko"
        ? "조회 수, 동접자 수 등 방송 성과<br/>지표 엑셀 파일 다운로드"
        : "配信の成果指標をファイルで<br/>ダウンロード可能",
  },
  {
    icon: "/assets/images/icon_singleurl.png",
    title: language === "ko" ? "Single URL" : "シングル URL",
    description:
      language === "ko"
        ? "사전 마케팅 부터 라이브 종료 후까지<br/>하나의 URL로 자동 변환"
        : "事前告知からライブ終了後まで<br/>一つのURLで自動変換",
  },
];

export const ReviewCard = [
  {
    logo: "/assets/images/review_ssg.png",
    comment: "main:review.card1.comment",
    reviewer: "review.card1.reviewer",
  },
  {
    logo: "/assets/images/review_kurly.png",
    comment: "main:review.card2.comment",
    reviewer: "review.card2.reviewer",
  },
  {
    logo: "/assets/images/review_oliveyoung.png",
    comment: "main:review.card3.comment",
    reviewer: "review.card3.reviewer",
  },
  {
    logo: "/assets/images/review_wadiz.png",
    comment: "main:review.card4.comment",
    reviewer: "review.card4.reviewer",
  },
  {
    logo: "/assets/images/review_kbbank.png",
    comment: "main:review.card5.comment",
    reviewer: "review.card5.reviewer",
  },
  {
    logo: "/assets/images/review_allmytour.png",
    comment: "main:review.card6.comment",
    reviewer: "review.card6.reviewer",
  },
];

export const ContentsCard = [
  {
    bg: "/assets/images/contents/content_bg_01.png",
    title: "Grip Company、YouTubeとライブコマースパートナシップを締結",
  },
  {
    bg: "/assets/images/contents/content_bg_02.png",
    title: "韓国No.1ヘルス&ビューティーストア『OLIVE YOUNG(オリーブヤング)』が語るライブコマース",
  },
  {
    bg: "/assets/images/contents/content_bg_03.png",
    title:
      "いいねアイコンカスタム機能|ブランドイメージの露出とエンゲージメントの増加、一つの機能で2つの効果を!",
  },
  {
    bg: "/assets/images/contents/content_bg_04.png",
    title: "1時間のライブコマースで売上1億円も! Qoo10の成功事例でみるライブコマース",
  },
  {
    bg: "/assets/images/contents/content_bg_05.png",
    title: "プライベートライブ｜販売だけではない！ライブコマースでファンづくり",
  },
  {
    bg: "/assets/images/contents/content_bg_06.png",
    title:
      "[2023年2月] SNSトレンドレポート|Twitter Blueに続き、InstagramとFacebookにも有料プランが登場",
  },
  {
    bg: "/assets/images/contents/content_bg_07.png",
    title: "ゲーム機能｜ライブコマースのエンゲージメントをあげるコツ",
  },
  {
    bg: "/assets/images/contents/content_bg_08.png",
    title: "[2023年版] ライブコマース初心者のための基本知識まとめ",
  },
  {
    bg: "/assets/images/contents/content_bg_09.png",
    title: "今更聞けない？！ライブコマースとソーシャルコマースのすべてについて解説",
  },
  {
    bg: "/assets/images/contents/content_bg_10.png",
    title: "ライブコマースソリューション「Grip cloud」にショートムービー機能が追加されました。",
  },
];

export const ContentsCardEn = [
  {
    bg: "/assets/images/contents/content_bg_01.png",
    title: "Gripping Company Signs Live Commerce Partnership on YouTube.",
  },
  {
    bg: "/assets/images/contents/content_bg_02.png",
    title: "Olive Young, Korea's #1 health and beauty store, talks live commerce.",
  },
  {
    bg: "/assets/images/contents/content_bg_03.png",
    title:
      "Like Icon Customization Function | Expose your brand image and increase engagement - two effects in one!",
  },
  {
    bg: "/assets/images/contents/content_bg_04.png",
    title:
      "One hour live commerce event generated ¥100 million in sales! Read a case study about Qoo10's live commerce success.",
  },
  {
    bg: "/assets/images/contents/content_bg_05.png",
    title: "Private Live | It's not just about selling! Build fans with live commerce.",
  },
  {
    bg: "/assets/images/contents/content_bg_07.png",
    title: "Game Features | Tips for increasing engagement in live commerce",
  },
  {
    bg: "/assets/images/contents/content_bg_09.png",
    title: "Now you've heard it all! All about live and social commerce explained",
  },
  {
    bg: "/assets/images/contents/content_bg_10.png",
    title: 'Short movie functionality has been added to the "Grip Cloud" live commerce solution.',
  },
];

export const ContentsCardKr = [
  {
    bg: "/assets/images/contents/content_kr_bg_01.png",
    title: "뷰티컬리, 라방으로 설화수 역대급 매출 달성",
    link: "https://www.sedaily.com/NewsView/29KJANIJ33",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_02.png",
    title: "CU, 라방에 홈배송…유튜버 빠더너스 콜라보 상품 출시",
    link: "https://www.hankyung.com/press-release/article/202301164333P",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_03.png",
    title: "휴젤 웰라쥬, 올영라이브서 1억원대 매출 달성",
    link: "https://www.hankyung.com/press-release/article/202301276446P",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_04.png",
    title: "신세계인터내셔날, 라방서 필립플레인X스눕독 국내 첫선",
    link: "http://www.dt.co.kr/contents.html?article_no=2023012902109932102003&ref=naver",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_05.png",
    title: "KB국민은행, 라방 진출… 생활금융 플랫폼으로 한 발 더",
    link: "http://kpenews.com/View.aspx?No=2583808",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_06.png",
    title: "요기요, 퀵커머스 방송 ‘요마트 라이브’ 시작",
    link: "https://www.sedaily.com/NewsView/26CC1JG04C",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_07.png",
    title: "그립, 클라우드 솔루션 게임 기능 특허 2종 취득",
    link: "https://www.epnc.co.kr/news/articleView.html?idxno=218652",
  },
  {
    bg: "/assets/images/contents/content_kr_bg_08.png",
    title: "그립, 클라우드 사업 일본 진출…현지 반응 ‘신선’",
    link: "https://magazine.hankyung.com/job-joy/article/202109077450d",
  },
];

export const CaseCardContents = [
  {
    id: 1,
    title: "라이브로 선공개하는 나이키 매장",
    desc: "부산 신세계 센텀시티점 나이키 매장 정식 오픈일 하루 전에 라이브로 먼저 매장을 선보입니다. 나이키의 어시스트 서비스와 매력적인 상품들을 생동감 있게 소개합니다.",
    videoSrc: "/assets/videos/kr/case_shinsegae.mp4",
    videoPoster: "/assets/videos/kr/case_shinsegae.png",
    logoSrc: "/assets/images/case/case_logo_shinsegae.png",
  },
  {
    id: 2,
    title: "뉴이니스프리 쇼케이스",
    desc: "새로운 슬로건과 로고, 컬러로 재탄생한 이니스프리를 소개합니다. 신제품과 함께 완전히 새로워진 이니스프리를 화면 너머로 전합니다.",
    videoSrc: "/assets/videos/kr/case_innisfree.mp4",
    videoPoster: "/assets/videos/kr/case_innisfree.png",
    logoSrc: "/assets/images/case/case_logo_innisfree.png",
  },
  {
    id: 3,
    title: "보일러에 친숙함을 더하다",
    desc: "경동나비엔의 앞서가는 콘덴싱 기술을 라이브로 생생하게 소개합니다. 시청자들이 평소에 생활하며 알기 어려웠던 보일러에 대한 궁금증을 실시간으로 해결해드립니다.",
    videoSrc: "/assets/videos/kr/case_navien.mp4",
    videoPoster: "/assets/videos/kr/case_navien.png",
    logoSrc: "/assets/images/case/case_logo_navien.png",
  },
  {
    id: 4,
    title: "반려동물과 함께 떠나는 펫캉스",
    desc: "반려동물과 함께 떠날 수 있는 펫캉스를 소개합니다. 반려동물과 기억에 남을 휴가를 보낼 리조트 숙박권과 이벤트로 제공하는 반려동물용 간식을 선보입니다.",
    videoSrc: "/assets/videos/kr/case_allmytour.mp4",
    videoPoster: "/assets/videos/kr/case_allmytour.png",
    logoSrc: "/assets/images/case/case_logo_allmytour.png",
  },
  {
    id: 5,
    title: "쇼호스트와 함께 참여하는 이벤트",
    desc: "라이브를 통해 새로 진행하는 이벤트를 소개합니다. 쇼호스트가 모바일로 이벤트에 참가하는 과정을 직접 보여주며 시청자의 참여를 독려합니다.",
    videoSrc: "/assets/videos/kr/case_happypoint.mp4",
    videoPoster: "/assets/videos/kr/case_happypoint.png",
    logoSrc: "/assets/images/case/case_logo_happypoint.png",
  },
  {
    id: 6,
    title: "즉석에서 갈아 마시는 착즙쥬스",
    desc: "즉석에서 과일과 야채를 착즙하는 과정을 보여줍니다. 백화점에 가지 않아도 착즙기의 낮은 소음과 과일 껍질까지 남겨주는 착즙 성능을 확인할 수 있습니다.",
    videoSrc: "/assets/videos/kr/case_hurom.mp4",
    videoPoster: "/assets/videos/kr/case_hurom.png",
    logoSrc: "/assets/images/case/case_logo_hurom.png",
  },
  {
    id: 7,
    title: "온라인으로 만나는 라이브 갤러리 투어",
    desc: "아트에디션 매니저가 윤혜진, 정재철 작가의 작품을 바로 옆에서 안내하듯 소개합니다. 시청자들은 갤러리에 직접 온듯 생생하게 작품을 감상하고 이야기를 나눕니다.",
    videoSrc: "/assets/videos/kr/case_hyundai.mp4",
    videoPoster: "/assets/videos/kr/case_hyundai.png",
    logoSrc: "/assets/images/case/case_logo_hyundai.png",
  },
  {
    id: 8,
    title: "게임을 활용한 고객 참여형 라이브",
    desc: "OX퀴즈를 활용한 밸런스 게임을 진행합니다. 시청자가 선호하는 쇼호스트를 선택해서 대결하는 방식으로 이끌자, 수백 명의 시청자가 흥미진진하게 게임에 임합니다.",
    videoSrc: "/assets/videos/kr/case_oliveyoung.mp4",
    videoPoster: "/assets/videos/kr/case_oliveyoung.png",
    logoSrc: "/assets/images/case/case_logo_oliveyoung.png",
  },
  {
    id: 9,
    title: "컬리 지정 농장에서 신선함을 전하다",
    desc: "컬리가 지정한 농장에서 재배되는 신선한 샤인머스캣을 집으로 배송합니다. 농장에서 바로 당도를 측정해서 보여주어 시청자들은 믿고 구매할 수 있습니다.",
    videoSrc: "/assets/videos/kr/case_kurly.mp4",
    videoPoster: "/assets/videos/kr/case_kurly.png",
    logoSrc: "/assets/images/case/case_logo_kurly.png",
  },
];

export const shortsIntroMenuItems = (language: string) => [
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <path
          d="M24.7275 44.3229C24.8932 44.4306 25.1068 44.4306 25.2725 44.3229L42.5225 33.1116C42.6644 33.0193 42.75 32.8616 42.75 32.6923V16C42.75 15.7239 42.5261 15.5 42.25 15.5H7.75C7.47386 15.5 7.25 15.7239 7.25 16V32.6923C7.25 32.8616 7.33562 33.0193 7.47753 33.1116L24.7275 44.3229Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 16C6.25 15.1716 6.92157 14.5 7.75 14.5H42.25C43.0784 14.5 43.75 15.1716 43.75 16V32.6923C43.75 33.2001 43.4931 33.6733 43.0674 33.95L25.8174 45.1614C25.3204 45.4844 24.6796 45.4844 24.1826 45.1614L6.93258 33.95C6.50685 33.6733 6.25 33.2001 6.25 32.6923V16ZM8.25 16.5V32.421L25 43.3074L41.75 32.421V16.5H8.25Z"
          fill="black"
        />
        <path
          d="M24.7462 5.37026C24.9035 5.27697 25.0991 5.27697 25.2564 5.37026L42.2929 15.4764C42.6156 15.6678 42.6203 16.1332 42.3015 16.3312L25.2651 26.9105C25.1035 27.0108 24.8991 27.0108 24.7375 26.9105L7.70107 16.3312C7.38233 16.1332 7.38705 15.6678 7.70975 15.4764L24.7462 5.37026Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.236 4.5102C24.7078 4.23032 25.2948 4.23032 25.7666 4.5102L42.8031 14.6163C43.7711 15.1906 43.7853 16.5869 42.8291 17.1807L25.7926 27.76C25.308 28.0609 24.6946 28.0609 24.21 27.76L7.17353 17.1807C6.21729 16.5869 6.23147 15.1906 7.19956 14.6163L24.236 4.5102ZM8.92839 15.9162L25.0013 25.8972L41.0742 15.9162L25.0013 6.38165L8.92839 15.9162Z"
          fill="black"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M24 45V27.25H26V45H24Z" fill="black" />
      </svg>
    ),
    text: language === "ko" ? "제품 상세 소개" : "商品の紹介",
    description_1: language === "ko" ? "제품 언박싱 등 입체적으로" : "商品の魅力をリアルに伝えて",
    description_2: language === "ko" ? "제품을 소개하여 궁금증 해소" : "顧客の“知りたい”を解消",
    image: (
      <img
        src={
          language === "ko"
            ? "/assets/images/shorts_intro_1.png"
            : `/assets/images/shorts_intro_${language}_1.png`
        }
        alt="shorts-intro-1"
      />
    ),
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <path
          d="M10.5392 12.8892C10.8366 12.3091 11.6658 12.3091 11.9631 12.8892L13.0454 15.001C13.1642 15.2328 13.3885 15.3923 13.6465 15.4284L16.0155 15.7599C16.681 15.853 16.9421 16.6755 16.4521 17.1354L14.7771 18.7077C14.5799 18.8928 14.4896 19.165 14.537 19.4312L14.9377 21.6807C15.0534 22.3303 14.3773 22.8327 13.7887 22.5348L11.6125 21.433C11.3853 21.318 11.117 21.318 10.8898 21.433L8.71362 22.5348C8.125 22.8327 7.44899 22.3303 7.56468 21.6807L7.96534 19.4312C8.01276 19.165 7.92244 18.8928 7.72524 18.7077L6.05025 17.1354C5.56027 16.6755 5.82136 15.853 6.4869 15.7599L8.85585 15.4284C9.11385 15.3923 9.33812 15.2328 9.45694 15.001L10.5392 12.8892Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.65041 12.4331C10.3194 11.1278 12.1851 11.1278 12.8542 12.4331L13.8891 14.4524L16.1551 14.7695C17.6526 14.979 18.2401 16.8297 17.1376 17.8645L15.5416 19.3626L15.9233 21.5054C16.1836 22.9668 14.6626 24.0974 13.3382 23.4269L11.2523 22.3709L9.16641 23.4269C7.84201 24.0974 6.32098 22.9668 6.58128 21.5054L6.96293 19.3626L5.36697 17.8645C4.26451 16.8297 4.85198 14.979 6.34943 14.7695L8.6155 14.4524L9.65041 12.4331ZM11.2523 13.6926L10.348 15.4571C10.0806 15.9787 9.57604 16.3375 8.99554 16.4187L7.04042 16.6923L8.41074 17.9786C8.85443 18.395 9.05766 19.0075 8.95095 19.6066L8.62056 21.4616L10.4393 20.5408C10.9504 20.2821 11.5542 20.2821 12.0653 20.5408L13.884 21.4616L13.5536 19.6066C13.4469 19.0075 13.6501 18.395 14.0938 17.9786L15.4641 16.6923L13.509 16.4187C12.9285 16.3375 12.4239 15.9787 12.1566 15.4571L11.2523 13.6926Z"
          fill="black"
        />
        <path
          d="M24.2775 6.01544C24.5671 5.40762 25.4323 5.40762 25.7219 6.01543L26.8741 8.43345C26.9884 8.67337 27.2144 8.84085 27.4772 8.88041L30.0988 9.27495C30.7463 9.37239 31.0089 10.1639 30.5481 10.629L28.616 12.5796C28.4354 12.762 28.3532 13.02 28.3952 13.2733L28.8464 15.998C28.9552 16.6554 28.2598 17.15 27.6746 16.8313L25.3823 15.5832C25.1437 15.4533 24.8556 15.4533 24.6171 15.5831L22.3248 16.8313C21.7396 17.15 21.0441 16.6554 21.153 15.998L21.6042 13.2733C21.6461 13.02 21.564 12.762 21.3833 12.5796L19.4512 10.629C18.9905 10.1639 19.2531 9.37239 19.9006 9.27495L22.5221 8.88041C22.7849 8.84085 23.011 8.67337 23.1253 8.43345L24.2775 6.01544Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3758 5.58527C24.0275 4.21767 25.974 4.21767 26.6257 5.58526L27.7322 7.90736L30.2487 8.28609C31.7055 8.50532 32.2964 10.2861 31.2597 11.3328L29.3995 13.2107L29.834 15.8347C30.079 17.3138 28.5141 18.4265 27.1974 17.7096L25.0008 16.5135L22.8041 17.7096C21.4874 18.4265 19.9226 17.3138 20.1675 15.8347L20.602 13.2107L18.7418 11.3328C17.7051 10.2861 18.2961 8.50532 19.7528 8.28609L22.2693 7.90736L23.3758 5.58527ZM25.0008 6.8245L24.0291 8.86362C23.7719 9.40344 23.2633 9.78028 22.672 9.86927L20.44 10.2052L22.0949 11.8759C22.5014 12.2863 22.6862 12.8668 22.5918 13.4367L22.2076 15.7571L24.14 14.7049C24.6766 14.4127 25.3249 14.4127 25.8615 14.7049L27.7939 15.7571L27.4097 13.4367C27.3153 12.8668 27.5002 12.2863 27.9067 11.8759L29.5615 10.2052L27.3295 9.86927C26.7382 9.78028 26.2297 9.40344 25.9724 8.86362L25.0008 6.8245Z"
          fill="black"
        />
        <path
          d="M38.0392 12.8892C38.3366 12.3091 39.1658 12.3091 39.4631 12.8892L40.5454 15.001C40.6642 15.2328 40.8885 15.3923 41.1465 15.4284L43.5155 15.7599C44.181 15.853 44.4421 16.6755 43.9521 17.1354L42.2771 18.7077C42.0799 18.8928 41.9896 19.165 42.037 19.4312L42.4377 21.6807C42.5534 22.3303 41.8773 22.8327 41.2887 22.5348L39.1125 21.433C38.8853 21.318 38.617 21.318 38.3898 21.433L36.2136 22.5348C35.625 22.8327 34.949 22.3303 35.0647 21.6807L35.4653 19.4312C35.5128 19.165 35.4224 18.8928 35.2252 18.7077L33.5502 17.1354C33.0603 16.6755 33.3214 15.853 33.9869 15.7599L36.3559 15.4284C36.6139 15.3923 36.8381 15.2328 36.9569 15.001L38.0392 12.8892Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.1504 12.4331C37.8194 11.1278 39.6851 11.1278 40.3542 12.4331L41.3891 14.4524L43.6551 14.7695C45.1526 14.979 45.7401 16.8297 44.6376 17.8645L43.0416 19.3626L43.4233 21.5054C43.6836 22.9668 42.1626 24.0974 40.8382 23.4269L38.7523 22.3709L36.6664 23.4269C35.342 24.0974 33.821 22.9668 34.0813 21.5054L34.4629 19.3626L32.867 17.8645C31.7645 16.8297 32.352 14.979 33.8494 14.7695L36.1155 14.4524L37.1504 12.4331ZM38.7523 13.6926L37.848 15.4571C37.5806 15.9787 37.076 16.3375 36.4955 16.4187L34.5404 16.6923L35.9107 17.9786C36.3544 18.395 36.5577 19.0075 36.4509 19.6066L36.1206 21.4616L37.9393 20.5408C38.4504 20.2821 39.0542 20.2821 39.5653 20.5408L41.384 21.4616L41.0536 19.6066C40.9469 19.0075 41.1501 18.395 41.5938 17.9786L42.9641 16.6923L41.009 16.4187C40.4285 16.3375 39.9239 15.9787 39.6566 15.4571L38.7523 13.6926Z"
          fill="black"
        />
        <path
          d="M25.0023 33.5C18.6683 33.5 13.4805 37.9984 13.0335 43.7001C12.999 44.1405 13.3605 44.5 13.8023 44.5H36.2023C36.6441 44.5 37.0056 44.1406 36.971 43.7001C36.524 37.9984 31.3362 33.5 25.0023 33.5Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0569 43.5H35.9482C35.4024 38.5139 30.7777 34.5 25.0025 34.5C19.2274 34.5 14.6027 38.5139 14.0569 43.5ZM12.0368 43.6219C12.5299 37.3327 18.2168 32.5 25.0025 32.5C31.7883 32.5 37.4752 37.3327 37.9682 43.6219C38.0523 44.6947 37.175 45.5 36.2025 45.5H13.8025C12.8301 45.5 11.9527 44.6947 12.0368 43.6219Z"
          fill="black"
        />
        <path
          d="M31 27.5C31 30.8137 28.3137 33.5 25 33.5C21.6863 33.5 19 30.8137 19 27.5C19 24.1863 21.6863 21.5 25 21.5C28.3137 21.5 31 24.1863 31 27.5Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 22.5C22.2386 22.5 20 24.7386 20 27.5C20 30.2614 22.2386 32.5 25 32.5C27.7614 32.5 30 30.2614 30 27.5C30 24.7386 27.7614 22.5 25 22.5ZM18 27.5C18 23.634 21.134 20.5 25 20.5C28.866 20.5 32 23.634 32 27.5C32 31.366 28.866 34.5 25 34.5C21.134 34.5 18 31.366 18 27.5Z"
          fill="black"
        />
      </svg>
    ),
    text: language === "ko" ? "고객 후기" : "レビュー",
    description_1: language === "ko" ? "실제 고객 후기 영상을" : "お客様のレビュー動画で",
    description_2: language === "ko" ? "쇼츠로 활용하여 신뢰 확보" : "信頼度をアップ",
    image: (
      <img
        src={
          language === "ko"
            ? "/assets/images/shorts_intro_2.png"
            : `/assets/images/shorts_intro_${language}_2.png`
        }
        alt="shorts-intro-2"
      />
    ),
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <path
          d="M38.8724 8.33219C39.9007 8.92592 40.2531 10.2409 39.6593 11.2692L29.3868 29.0618C28.7931 30.0902 27.4781 30.4425 26.4497 29.8488C25.4214 29.2551 25.069 27.9401 25.6628 26.9118L35.9353 9.11917C36.529 8.09081 37.844 7.73847 38.8724 8.33219Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3573 7.50479C40.8427 8.36239 41.3516 10.2618 40.494 11.7472L30.2215 29.5398C29.3639 31.0252 27.4645 31.5341 25.9791 30.6765C24.4937 29.8189 23.9847 27.9195 24.8423 26.4341L35.1149 8.64154C35.9725 7.15612 37.8719 6.64719 39.3573 7.50479ZM38.8389 10.7916C39.1688 10.2203 38.973 9.48976 38.4017 9.15991C37.8304 8.83007 37.0999 9.02581 36.77 9.59712L26.4975 27.3897C26.1676 27.961 26.3634 28.6916 26.9347 29.0214C27.506 29.3513 28.2365 29.1555 28.5664 28.5842L38.8389 10.7916Z"
          fill="black"
        />
        <path
          d="M6.91406 21.5911C6.91406 20.7995 7.55581 20.1577 8.34744 20.1577H40.8374C41.629 20.1577 42.2708 20.7995 42.2708 21.5911V22.0689C42.2708 31.5685 34.5698 39.2694 25.0702 39.2694H24.1146C14.615 39.2694 6.91406 31.5685 6.91406 22.0689V21.5911Z"
          fill="#D2DDF5"
        />
        <path d="M41.5 20.5L8 20L6.5 23.5L8 26H41.5L42 24L41.5 20.5Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.95312 21.5914C5.95312 20.272 7.0227 19.2024 8.34209 19.2024H40.832C42.1514 19.2024 43.221 20.272 43.221 21.5914V22.0692C43.221 32.0965 35.0922 40.2253 25.0649 40.2253H24.1093C14.0819 40.2253 5.95312 32.0965 5.95312 22.0692V21.5914ZM8.34209 21.1136C8.07821 21.1136 7.8643 21.3275 7.8643 21.5914V22.0692C7.8643 31.041 15.1374 38.3141 24.1093 38.3141H25.0649C34.0367 38.3141 41.3098 31.041 41.3098 22.0692V21.5914C41.3098 21.3275 41.0959 21.1136 40.832 21.1136H8.34209Z"
          fill="black"
        />
        <path
          d="M7.85938 39.7468C7.85938 39.483 8.07329 39.269 8.33717 39.269H40.8271C41.091 39.269 41.3049 39.483 41.3049 39.7468V42.6136C41.3049 42.8775 41.091 43.0914 40.8271 43.0914H8.33717C8.07329 43.0914 7.85938 42.8775 7.85938 42.6136V39.7468Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.91406 39.7473C6.91406 38.9557 7.55581 38.314 8.34744 38.314H40.8374C41.629 38.314 42.2708 38.9557 42.2708 39.7473V42.6141C42.2708 43.4057 41.629 44.0475 40.8374 44.0475H8.34744C7.5558 44.0475 6.91406 43.4057 6.91406 42.6141V39.7473ZM8.82524 40.2251V42.1363H40.3596V40.2251H8.82524Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5838 6.30176C25.1116 6.30176 25.5394 6.72959 25.5394 7.25734V8.09118C25.5394 9.81708 24.1403 11.2162 22.4144 11.2162H13.2435C12.6458 11.2162 12.1612 11.7008 12.1612 12.2986C12.1612 12.8963 12.6458 13.3809 13.2435 13.3809H18.1702C19.9387 13.3809 21.3724 14.8146 21.3724 16.5831V17.7688H19.4612V16.5831C19.4612 15.8701 18.8832 15.2921 18.1702 15.2921H13.2435C11.5902 15.2921 10.25 13.9518 10.25 12.2986C10.25 10.6453 11.5902 9.30503 13.2435 9.30503H22.4144C23.0848 9.30503 23.6282 8.76157 23.6282 8.09118V7.25734C23.6282 6.72959 24.056 6.30176 24.5838 6.30176Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.2605 27.3247H7.85938V25.4136H42.2605V27.3247Z"
          fill="black"
        />
      </svg>
    ),
    text: language === "ko" ? "레시피" : "レシピ",
    description_1: language === "ko" ? "간단하게 따라할 수 있는" : "簡単に真似できるレシピで",
    description_2:
      language === "ko" ? (
        <>
          레시피 정보를 제공하여
          <br />
          구매 욕구 자극
        </>
      ) : (
        "購買欲求をアップ"
      ),
    image: (
      <img
        src={
          language === "ko"
            ? "/assets/images/shorts_intro_3.png"
            : `/assets/images/shorts_intro_${language}_3.png`
        }
        alt="shorts-intro-3"
      />
    ),
  },
  {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <path
          d="M28.0965 7.40019C28.9752 6.52151 30.3998 6.52151 31.2785 7.40019L36.5818 12.7035C37.4605 13.5822 37.4605 15.0068 36.5818 15.8855C35.7031 16.7642 34.2785 16.7642 33.3998 15.8855L28.0965 10.5822C27.2178 9.7035 27.2178 8.27887 28.0965 7.40019Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3894 6.69312C28.6586 5.42391 30.7164 5.42391 31.9856 6.69312L37.2889 11.9964C38.5581 13.2656 38.5581 15.3234 37.2889 16.5926C36.0197 17.8618 33.9619 17.8618 32.6927 16.5926L27.3894 11.2893C26.1202 10.0201 26.1202 7.96232 27.3894 6.69312ZM30.5714 8.10733C30.0832 7.61917 29.2918 7.61917 28.8036 8.10733C28.3155 8.59549 28.3155 9.38694 28.8036 9.8751L34.1069 15.1784C34.5951 15.6666 35.3865 15.6666 35.8747 15.1784C36.3628 14.6902 36.3628 13.8988 35.8747 13.4106L30.5714 8.10733Z"
          fill="black"
        />
        <path
          d="M18.5496 16.9463C19.4283 16.0677 20.8529 16.0677 21.7316 16.9463L27.0349 22.2496C27.9136 23.1283 27.9136 24.5529 27.0349 25.4316C26.1562 26.3103 24.7316 26.3103 23.8529 25.4316L18.5496 20.1283C17.671 19.2496 17.671 17.825 18.5496 16.9463Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8425 16.2395C19.1117 14.9703 21.1695 14.9703 22.4387 16.2395L27.742 21.5428C29.0112 22.812 29.0112 24.8698 27.742 26.139C26.4728 27.4082 24.415 27.4082 23.1458 26.139L17.8425 20.8357C16.5733 19.5665 16.5733 17.5087 17.8425 16.2395ZM21.0245 17.6537C20.5364 17.1656 19.7449 17.1656 19.2567 17.6537C18.7686 18.1419 18.7686 18.9333 19.2567 19.4215L24.56 24.7248C25.0482 25.2129 25.8397 25.2129 26.3278 24.7248C26.816 24.2366 26.816 23.4452 26.3278 22.957L21.0245 17.6537Z"
          fill="black"
        />
        <path
          d="M22.6172 24.194L10.2428 36.5683C9.46177 37.3494 8.19544 37.3494 7.41439 36.5683L-nan -nanL7.41439 36.5683C6.63334 35.7873 6.63335 34.521 7.41439 33.7399L19.7888 21.3655L22.6172 24.194Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.2028 24.194L19.7886 22.7798L8.12135 34.447C7.73082 34.8375 7.73082 35.4707 8.12135 35.8612C8.51187 36.2518 9.14503 36.2518 9.53556 35.8612L21.2028 24.194ZM10.9498 37.2754C9.7782 38.447 7.87871 38.447 6.70713 37.2754C5.53556 36.1039 5.53556 34.2044 6.70713 33.0328L19.7886 19.9513L24.0312 24.194L10.9498 37.2754Z"
          fill="black"
        />
        <path
          d="M23.1434 8.81462C24.0221 7.93594 25.4467 7.93594 26.3254 8.81462L35.1642 17.6535C36.0429 18.5321 36.0429 19.9568 35.1642 20.8354C34.2855 21.7141 32.8609 21.7141 31.9822 20.8354L23.1434 11.9966C22.2647 11.1179 22.2647 9.6933 23.1434 8.81462Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4363 8.10767C23.7055 6.83846 25.7633 6.83846 27.0325 8.10767L35.8713 16.9465C37.1405 18.2157 37.1405 20.2735 35.8713 21.5427C34.6021 22.8119 32.5443 22.8119 31.2751 21.5427L22.4363 12.7039C21.1671 11.4347 21.1671 9.37687 22.4363 8.10767ZM25.6183 9.52188C25.1301 9.03373 24.3386 9.03373 23.8505 9.52188C23.3623 10.01 23.3623 10.8015 23.8505 11.2896L32.6893 20.1285C33.1775 20.6166 33.9689 20.6166 34.4571 20.1285C34.9452 19.6403 34.9452 18.8489 34.4571 18.3607L25.6183 9.52188Z"
          fill="black"
        />
        <path
          d="M19.9637 11.9964C20.8424 11.1177 22.267 11.1177 23.1457 11.9964L31.9845 20.8352C32.8632 21.7139 32.8632 23.1385 31.9845 24.0172C31.1058 24.8959 29.6812 24.8959 28.8025 24.0172L19.9637 15.1784C19.085 14.2997 19.085 12.8751 19.9637 11.9964Z"
          fill="#D2DDF5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2566 11.2893C20.5258 10.0201 22.5836 10.0201 23.8528 11.2893L32.6916 20.1281C33.9608 21.3973 33.9608 23.4551 32.6916 24.7243C31.4224 25.9935 29.3646 25.9935 28.0954 24.7243L19.2566 15.8855C17.9874 14.6163 17.9874 12.5585 19.2566 11.2893ZM22.4386 12.7035C21.9504 12.2154 21.159 12.2154 20.6708 12.7035C20.1826 13.1917 20.1826 13.9831 20.6708 14.4713L29.5096 23.3101C29.9978 23.7983 30.7893 23.7983 31.2774 23.3101C31.7656 22.822 31.7656 22.0305 31.2774 21.5424L22.4386 12.7035Z"
          fill="black"
        />
        <path
          d="M42.8549 37.6017C42.8549 40.5041 40.4564 42.857 37.4978 42.857C34.5391 42.857 32.1406 40.5041 32.1406 37.6017C32.1406 33.8641 36.0427 29.8074 37.1864 28.6988C37.3617 28.5288 37.6339 28.5288 37.8092 28.6988C38.9528 29.8074 42.8549 33.8641 42.8549 37.6017Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.484 27.7902C37.0503 27.2444 37.9512 27.2444 38.5175 27.7902C39.134 28.3843 40.4933 29.7667 41.7049 31.5114C42.8976 33.2286 44.0484 35.4388 44.0484 37.6621C44.0484 41.1887 41.1169 44.0475 37.5007 44.0475C33.8846 44.0475 30.9531 41.1887 30.9531 37.6621C30.9531 35.4388 32.1039 33.2286 33.2965 31.5114C34.5082 29.7667 35.8675 28.3843 36.484 27.7902ZM37.5007 29.5248C36.8517 30.181 35.8408 31.2795 34.9303 32.5906C33.8009 34.2167 32.9264 36.0283 32.9264 37.6621C32.9264 40.1259 34.9744 42.1231 37.5007 42.1231C40.0271 42.1231 42.0751 40.1259 42.0751 37.6621C42.0751 36.0283 41.2006 34.2167 40.0712 32.5906C39.1607 31.2795 38.1498 30.181 37.5007 29.5248Z"
          fill="black"
        />
      </svg>
    ),
    text: language === "ko" ? "꿀팁 공유" : "コツの共有",
    description_1: language === "ko" ? "체형별, 상황별 코디 추천 등" : "体型別・状況別コーデなど",
    description_2:
      language === "ko" ? (
        <>
          제품 사용 꿀팁을 제공하여
          <br />
          구매 욕구 자극
        </>
      ) : (
        <>
          利用シーンに合わせた
          <br />
          コツの共有で購買欲求を刺激
        </>
      ),
    image: (
      <img
        src={
          language === "ko"
            ? "/assets/images/shorts_intro_4.png"
            : `/assets/images/shorts_intro_${language}_4.png`
        }
        alt="shorts-intro-4"
      />
    ),
  },
];
